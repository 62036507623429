import * as React from 'react';
import { useHistory } from 'react-router';
import type { AttributeOption } from '@api/models';
import { AlternateText, FlexColumns, FlexRows, Loader, Loading, SearchBar } from '@components/common';

import FilterContainer from '../FilterBar';
import type { Base, ColorFormula, FormulationGroup, GetResponse } from '@api/formulation/models';
import api from '@api/formulation';
import ColorFormulaSection from './ColorFormulaSection';
import { Optional } from '@app/utils/types';

type ViewProps = {
  history: ReturnType<typeof useHistory>;
  group: FormulationGroup;
};

type LoadingStates = 0 | 1 | 5;
const loadingStates: { [key: string]: LoadingStates } = {
  initial: 0,
  none: 5,
  pagination: 1,
};
type ViewState = {
  loading?: number;
  formulas: GetResponse<ColorFormula>;
  selectedBaseID?: string;
  searchTerm: string;
  selectedFilters: AttributeOption[];
};

class ColorFormulaList extends React.Component<ViewProps, ViewState> {
  state = {
    formulas: { count: -1, docs: [] } as GetResponse<ColorFormula>,
    loading: loadingStates.initial,
    searchTerm: '',
    selectedBaseID: undefined,
    selectedFilters: [] as AttributeOption[],
  };
  async componentDidMount() {
    const { group } = this.props;
    if (group.base_lookup.length === 0) {
      return;
    }

    this.loadFormulas(loadingStates.initial, true)();

    this.setState({
      formulas: { docs: [], count: -1 },
    });
  }

  loadFormulas(state: LoadingStates, allowDups: boolean = true) {
    return async () => {
      const { group } = this.props;
      const { formulas, loading, searchTerm, selectedFilters } = this.state;
      if (loading === state && !allowDups) {
        return;
      }

      if (formulas.count !== -1) {
        this.setState({
          loading: loadingStates.pagination,
        });
      }

      let attributes;
      if (selectedFilters.length > 0) {
        attributes = {
          $all: selectedFilters.map(x => ({ k: x.key, v: x.value })),
        };
      }

      if (searchTerm.length > 0) {
        attributes = {
          ...attributes,
          $elemMatch: {
            k: 'name',
            v: { $options: 'i', $regex: `(.*)${searchTerm}(.*)` },
          },
        };
      }

      let skip = 0;
      if (state !== loadingStates.initial) {
        skip = formulas.docs.length;
      }

      const res = await api.color_formulas.search(
        group.id,
        {
          attributes,
          formulation_group_id: group.id,
        },
        { limit: 250, skip },
      );

      if (state !== loadingStates.initial) {
        formulas.docs = formulas.docs.concat(res.docs);
      } else {
        formulas.count = res.count || 0;
        formulas.docs = res.docs;
      }

      this.setState({
        formulas,
        loading: loadingStates.none,
      });
    };
  }
  handleBaseSelect = (c: Base) => {
    const { selectedBaseID } = this.state;
    let newBaseID = c.id as Optional<string>;
    if (selectedBaseID === newBaseID) {
      newBaseID = undefined;
    }
    this.setState({
      selectedBaseID: newBaseID,
    });
  };

  handleSearchTermSubmit = (searchTerm: string) => {
    this.setState({ searchTerm }, this.loadFormulas(loadingStates.initial, false));
  };

  handleFilterChange = (selectedFilters: AttributeOption[]) => {
    this.setState({ selectedFilters }, this.loadFormulas(loadingStates.initial, false));
  };
  loadFiltersCall = (): Promise<GetResponse<AttributeOption>> => {
    const { group } = this.props;
    return api.color_formulas.attribute_options(group.id);
  };
  render() {
    const { group, history } = this.props;
    const { formulas, loading } = this.state;
    if (loading === loadingStates.initial) {
      return <Loading text="Loading formulas" />;
    }

    return (
      <FlexColumns style={{ flexWrap: 'wrap' }}>
        <FlexRows style={{ width: '100%' }}>
          <SearchBar onSubmit={this.handleSearchTermSubmit} />
          <FilterContainer loadFilters={this.loadFiltersCall} handleChange={this.handleFilterChange} />
        </FlexRows>
        <ColorFormulaSection
          loadMore={this.loadFormulas(loadingStates.pagination, false)}
          count={formulas.count!}
          formulas={formulas.docs}
          group={group}
          history={history}
        />
        <LoadingData show={loading === loadingStates.pagination} />
      </FlexColumns>
    );
  }
}

function LoadingData({ show }: { show: boolean }) {
  if (!show) {
    return null;
  }

  return (
    <FlexColumns style={{ width: '100%' }}>
      <AlternateText textAlign="center" fontSize="16px" style={{ margin: 12 }}>
        Loading Color Formulas
      </AlternateText>
      <div style={{ flex: 1 }} />
      <Loader />
    </FlexColumns>
  );
}

export default function __Page(props: { group: FormulationGroup }) {
  const history = useHistory();
  return <ColorFormulaList {...props} history={history} />;
}
