import React from 'react';
import { AlternateText, FlexColumns, FlexRows, ImageCircle, PrimaryText } from '@components/common';
import { SdkDetail } from '@api/cas/models';
import moment from 'moment';
export default function PlatformSdkList({
  onItemClick,
  sdks,
}: {
  onItemClick: (d: SdkDetail) => void | any;
  sdks: SdkDetail[];
}) {
  return (
    <FlexColumns>
      {sdks.map(s => {
        const { theme } = s.subscription;
        const image = theme.images.splash;
        let bg = theme.colors?.splashBackgroundColor || '#000';

        return (
          <FlexRows key={s.id} style={{ width: 300 }}>
            <ImageCircle onClick={() => onItemClick(s)} url={image.png ?? null} diameter={256} backgroundColor={bg} />
            <PrimaryText fontSize="12px" textAlign="center">
              {s.subscription.package_name}
            </PrimaryText>
            <AlternateText fontSize="10px" textAlign="center" lineHeight={0.5}>
              {s.id}
            </AlternateText>
            <AlternateText fontSize="10px" textAlign="center">
              {moment(s.created_at).calendar()}
            </AlternateText>
          </FlexRows>
        );
      })}
    </FlexColumns>
  );
}
