import React from 'react';
import styled from 'styled-components';

const ImgContainer = styled.div<{ bg?: string; diameter: number }>`
  width: ${({ diameter }) => diameter / 2}px;
  height: ${({ diameter }) => diameter / 2}px;

  overflow: hidden;
  margin: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  border-color: white;
  border-radius: 1px;
  border-style: solid;
  border-radius: ${({ diameter }) => diameter / 4}px;

  background-color: ${props => props.bg};
`;
const Image = styled.img<{ diameter: number }>`
  object-fit: contain;

  width: ${({ diameter }) => diameter / 2 - 8 * 2}px;
  height: ${({ diameter }) => diameter / 2 - 8 * 2}px;

  padding: 12px;
`;

type ViewProps = {
  onClick?: () => void;
  diameter: number;
  url?: string;
  backgroundColor?: string;
};

export default function ImageCircle({ onClick, backgroundColor, diameter, url }: ViewProps) {
  return (
    <ImgContainer onClick={onClick} diameter={diameter} bg={backgroundColor}>
      <Image draggable={false} diameter={diameter} src={url} />
    </ImgContainer>
  );
}
