import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { DropdownProps } from 'semantic-ui-react';

import { AlternateText, Dropdown, FlexColumns, FlexRows, PrimaryText } from '@components/common';

import type { AttributeOption } from '@api/models';
import type { GetResponse } from '@api/formulation/models';
import theme from '@theme';

const Wrapper = styled.div`
  background-color: ${theme.dropdown.background};
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  margin-right: 12px;
  margin-top: 12px;

  :hover {
    background-color: ${theme.border.selectedItem};
  }
`;

type ViewProps = {
  loadFilters: () => Promise<GetResponse<AttributeOption>>;

  handleChange: (selectedItems: AttributeOption[]) => void | any;
};

type SelectableAttributeOption = AttributeOption & {
  isSelected: boolean;
};

type ViewState = {
  attributes: SelectableAttributeOption[];
  keys: string[];
};

const defaultSelectedItem = {
  id: '',
  isSelected: false,
  key: '',
  url: '',
  value: 'Select Value',
};
export function renderTrigger(label: string, selectedItem: SelectableAttributeOption = defaultSelectedItem) {
  let url = '';
  if (selectedItem && selectedItem.url) {
    ({ url } = selectedItem);
  }

  let value = '';
  if (selectedItem && selectedItem.value) {
    ({ value } = selectedItem);
  }
  return (
    <FlexColumns style={{ alignItems: 'center', minWidth: 128 }}>
      {url.length > 0 && <img src={url} style={{ borderRadius: '50%' }} />}

      <FlexRows style={{ marginLeft: url.length > 0 ? 8 : 0 }}>
        <PrimaryText fontWeight={500}>{label}</PrimaryText>
        <AlternateText>{value}</AlternateText>
      </FlexRows>
    </FlexColumns>
  );
}

export default class FilterContainer extends React.Component<ViewProps, ViewState> {
  state = {
    attributes: [] as SelectableAttributeOption[],
    keys: [] as string[],
  };

  componentDidMount = async () => {
    const { loadFilters } = this.props;
    const res = await loadFilters();
    const attributes = res.docs.map(x => ({ ...x, isSelected: false }));
    const docs = attributes.filter(x => x.id !== 'placeholder_image');

    this.setState({
      attributes,
      keys: _.uniq(_.map(docs, 'key')),
    });
  };

  handleDeselect = (key: string) => () => {
    const { attributes } = this.state;
    attributes
      .filter(x => x.key === key)
      .forEach(item => {
        Object.assign(item, { isSelected: false });
      });

    this.setState(
      {
        attributes,
      },
      () => {
        const { handleChange } = this.props;
        handleChange(attributes.filter(x => x.isSelected));
      },
    );
  };

  handleSelection = (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { attributes } = this.state;
    const item = attributes.find(x => x.id === data.value);
    if (item) {
      // not happy about this
      const key = (data.value as string).split('$$')[0].replace('_', ' ');
      attributes.forEach(x => {
        if (x.key.toLowerCase() === key && x.id !== data.value) {
          x.isSelected = false;
        }
      });

      item.isSelected = !item.isSelected;

      this.setState({ attributes }, () => {
        const { handleChange } = this.props;
        handleChange(attributes.filter(x => x.isSelected));
      });
    }
  };

  renderFilter = (key: string) => {
    const { attributes } = this.state;
    if (!attributes) {
      return null;
    }

    const options = attributes.filter(x => x.key === key);
    const selectedItem = options.find(x => x.isSelected);
    return (
      <Wrapper key={key}>
        <Dropdown
          trigger={renderTrigger(key, selectedItem)}
          options={options.map(o => ({ text: o.value, value: o.id }))}
          icon={null}
          onChange={this.handleSelection}
          style={{ backgroundColor: 'transparent' }}
        />

        <ClearButton onClick={this.handleDeselect(key)} show={selectedItem} />
      </Wrapper>
    );
  };

  render = () => {
    const { keys } = this.state;
    return <FlexColumns>{keys.map(this.renderFilter)}</FlexColumns>;
  };
}

const ClearButtonWrapper = styled.div`
  align-items: center;
  justify-content: center;
  border-color: ${theme.button.cancelColor};
  border-style: solid;
  border-width: 1px;

  :hover {
    background-color: ${theme.button.cancelColor};
  }
`;
function ClearButton({ show, onClick }: { show?: any; onClick: () => void }) {
  if (!show) {
    return null;
  }

  return (
    <ClearButtonWrapper onClick={onClick}>
      <PrimaryText textAlign="center" fontWeight={500}>
        Clear
      </PrimaryText>
    </ClearButtonWrapper>
  );
}
