import React from 'react';

import AddProductImage from './AddProductImage';
import ImagePreviewList from './ImagePreviewList';
import { Button, FlexRows, Loading, Modal, ModuleSegment } from '../../../common';
import theme from '@theme';
import ImagePreview from './ImagePreview';
import vpapi from '@api/vpapi';
import { Product, ProductImage } from '@api/vpapi/models';
import { Optional } from '@app/utils/types';

type ViewProps = {
  onProductChange: (p: Product) => void;
  product: Product;
};

type ViewState = {
  //from image manager
  selectedImage?: ProductImage;
  loadingText?: string;
  modalType?: 'images';
};
class EditProductImages extends React.Component<ViewProps, ViewState> {
  state = {
    loadingText: undefined,
    modalType: undefined,
    selectedImage: undefined,
  };

  get selectedImage(): Optional<ProductImage> {
    const { product } = this.props;
    if (product.product_images.length === 0) {
      return undefined;
    }

    const { selectedImage } = this.state;
    if (!selectedImage) {
      return product.product_images[0];
    }

    return selectedImage;
  }
  get showAddProductImageAsModal() {
    const { product } = this.props;
    return product.product_images && product.product_images.length > 0;
  }

  get moduleButtons() {
    const buttons = [];
    if (this.selectedImage) {
      buttons.push(
        <Button
          outline
          key="extra_colors_button"
          onClick={this.handleExtractColors}
          color={theme.button.neutralColor}
          text="Extract Colors"
        />,
      );
    }

    buttons.push(this.AddImageButton);

    if (this.selectedImage) {
      buttons.push(
        <Button
          key="delete_image"
          onClick={this.handleDeleteImage}
          color={theme.button.cancelColor}
          text="Delete Image"
        />,
      );
    }

    return buttons;
  }

  get AddImageButton() {
    return (
      <Button
        onClick={() => {
          this.setState({
            modalType: 'images',
          });
        }}
        key="add_image"
        outline
        color={theme.button.createColor}
        text="Add Image"
      />
    );
  }

  handleProductImage = (images: ProductImage[]) => {
    const { onProductChange, product } = this.props;

    product.addImages(images);
    onProductChange(product);

    this.handleModalClose();
  };

  handleDeleteImage = () => {
    const { onProductChange, product } = this.props;

    const { selectedImage } = this;
    if (selectedImage) {
      product.removeImage(selectedImage);

      onProductChange(product);
    }
  };

  handleExtractColors = async (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const { product, onProductChange } = this.props;

    this.setState({
      loadingText: 'Extracting Colors from Image',
    });

    try {
      const { selectedImage } = this;
      if (!selectedImage) {
        return;
      }
      const compositions = await vpapi.images.extractColors(selectedImage.url);

      compositions.forEach(cd => {
        product.addCompositionDetail(cd);
      });

      onProductChange(this.props.product);
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({
        loadingText: undefined,
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      modalType: undefined,
    });
  };

  handleImageSelect = (image: ProductImage) => {
    this.setState({
      selectedImage: image,
    });
  };

  ModuleTitle = () => (
    <FlexRows>
      <ModuleSegment.TitleText>Images</ModuleSegment.TitleText>
      <ModuleSegment.SubTitleText>Click to learn more</ModuleSegment.SubTitleText>
    </FlexRows>
  );
  render() {
    const { product } = this.props;
    const { loadingText, modalType } = this.state;
    if (loadingText) {
      return (
        <ModuleSegment
          containerStyle={{ height: 768, width: '100%' }}
          title={this.ModuleTitle}
          buttons={this.moduleButtons}
        >
          <Loading text={loadingText} />
        </ModuleSegment>
      );
    }

    if (modalType === 'images') {
      return (
        <Modal
          modalProps={{
            closeIcon: true,
            closeOnEscape: true,
            onClose: this.handleModalClose,
            open: true,
          }}
        >
          <ModuleSegment title="Add Product Image">
            <AddProductImage onImageUpload={this.handleProductImage} />
          </ModuleSegment>
        </Modal>
      );
    }

    const { selectedImage } = this;
    if (!selectedImage) {
      return (
        <ModuleSegment title={this.ModuleTitle} buttons={this.moduleButtons}>
          <ImagePreview image={selectedImage} />
        </ModuleSegment>
      );
    }

    return (
      <ModuleSegment title={this.ModuleTitle} buttons={this.moduleButtons}>
        <ImagePreview image={selectedImage} />

        <ImagePreviewList
          images={product.product_images}
          selectedImage={selectedImage}
          onItemClick={this.handleImageSelect}
        />
      </ModuleSegment>
    );
  }
}

export { EditProductImages };
export default EditProductImages;
