import * as React from 'react';

import type { AttributeOption } from '@api/models';
import type { FormulationGroup, FormulationProduct } from '@api/formulation/models';
import api from '@api/formulation';
import routeUtils from '@utils/route-utils';
import { Loading, SearchBar } from '../../common';
import { ProductList } from './styled-components';
import FilterBar from './FilterBar';
import { useHistory } from 'react-router';
import { Optional } from '@app/utils/types';

type ViewState = {
  products?: FormulationProduct[];
  searchTerm: string;
  selectedFilters: AttributeOption[];
};

type ViewProps = {
  history: ReturnType<typeof useHistory>;
  group: FormulationGroup;
};

export default class FormulationProductList extends React.Component<ViewProps, ViewState> {
  original_options: AttributeOption[] = [];

  state = {
    products: undefined as Optional<FormulationProduct[]>,
    searchTerm: '',
    selectedFilters: [] as AttributeOption[],
  };
  async componentDidMount() {
    try {
      const { group } = this.props;

      const r = await api.products.all({
        customer_id: group.customer._id,
        formulation_group_id: group._id,
      });

      this.setState({ products: r.docs });
    } catch (err) {
      const { history } = this.props;
      history.replace(routeUtils.formulationGroups());
    }
  }

  handleFilterSelect = (selectedFilters: AttributeOption[]) => {
    this.setState(
      {
        selectedFilters,
      },
      this.search,
    );
  };

  search = async () => {
    const { group } = this.props;

    const { searchTerm, selectedFilters } = this.state;
    let attributes;
    if (selectedFilters.length > 0) {
      attributes = {
        $all: selectedFilters.map(x => ({
          $elemMatch: { k: x.key, v: { $in: [x.value] } },
        })),
      };
    }
    const res = await api.products.search({
      query: {
        $or: [
          {
            product_name: {
              $options: 'i',
              $regex: `.*${searchTerm}.*`,
            },
          },
          {
            product_id: {
              $options: 'i',
              $regex: `.*${searchTerm}.*`,
            },
          },
        ],
        attributes,
        customer_id: group.customer._id,
        formulation_group_id: group.id,
      },
    });

    this.setState({
      products: res.docs,
    });
  };
  handleSearchTermChange = (searchTerm: string) => {
    this.setState({ searchTerm }, this.search);
  };
  handleProductClick = (p: FormulationProduct) => {
    const { history } = this.props;
    history.push(routeUtils.formulationProduct(p.formulation_group_id, p._id));
  };
  loadFiltersCall = () => {
    const { group } = this.props;
    return api.formulation_groups.attribute_options(group.id);
  };
  render() {
    const { products } = this.state;
    if (!products) {
      return <Loading text="Fetching products" />;
    }

    return (
      <div>
        <SearchBar onSubmit={this.handleSearchTermChange} />
        <FilterBar loadFilters={this.loadFiltersCall} handleChange={this.handleFilterSelect} />
        <ProductList products={products} onItemClick={this.handleProductClick} />
      </div>
    );
  }
}
