import * as React from 'react';
import AWSPubSub from '@api/aws/AWSPubSub';
import cas from '@api/cas';
import User from '@api/cas/user';

export function useAppEffects(user?: User, forceUpdate?: () => void) {
  React.useEffect(() => {
    if (!user) {
      console.log('Error - no user');
      return;
    }

    AWSPubSub.init(user);
  }, [user]);

  React.useEffect(() => {
    console.log('refreshing profile');
    cas.users.getProfile().then(forceUpdate).catch(forceUpdate);
  }, [user, forceUpdate]);
}
