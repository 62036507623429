import * as React from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { Loading, ModuleSegment } from '@components/common';

import cas from '@api/cas';
import type { Purchase } from '@api/cas/models';
import ExternalPurchasesQuad from './ExternalPurchasesQuad';
import OrderLookupView from './OrderLookupView';
import { Optional } from '@app/utils/types';

type ExpectedQuery = {
  email: string;
  code: string;
  order_id: string;
  pos: 'Apple-iap' | 'shopify' | '';
};
function convertSearch(location: ReturnType<typeof useLocation>): Optional<ExpectedQuery> {
  const params = qs.parse(location.search, {
    parseNumbers: false,
  });
  if (params.code && params.order_id && params.pos) {
    return params as ExpectedQuery;
  }

  return undefined;
}

type Purchases = Optional<Purchase[]>;

async function fetchPurchases(location: ReturnType<typeof useLocation>): Promise<Purchase[]> {
  const filter = convertSearch(location);
  return cas.users.getPurchases({
    filter: filter,
  });
}

export default function () {
  const location = useLocation();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [purchases, setPurchases] = React.useState<Purchases>(undefined);
  React.useEffect(() => {
    if (loading) {
      fetchPurchases(location)
        .then(items => {
          setLoading(false);
          setPurchases(items);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    }
  });

  if (loading) {
    return (
      <ModuleSegment title="External Purchases">
        <Loading text="Checking for external purchases" />
      </ModuleSegment>
    );
  }

  const params = qs.parse(location.search);
  if (!purchases || params.a === 'find') {
    return (
      <OrderLookupView
        showBackButton={!!params.a}
        onPurchases={p => {
          setPurchases(p);
        }}
      />
    );
  }

  return <ExternalPurchasesQuad purchases={purchases} />;
}
