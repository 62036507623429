import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from './App';
import 'semantic-ui-css/semantic.min.css';

import './index.css';

import theme from './theme';

(document.body as any).style = `
    background: ${theme.mainBackground};
`;

// After
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!);
const history = createBrowserHistory();
root.render(
  <Router history={history}>
    <App />
  </Router>,
);
