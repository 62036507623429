import * as React from 'react';
import _ from 'lodash';
import { Spectrum } from '@variablecolor/colormath';
import {
  Button,
  Checkbox,
  Flash,
  FlashContentT,
  FlexColumns,
  InputLabel,
  LabeledInput,
  ModuleSegment,
} from '@components/common';
import theme from '@theme';
import SpectrumGraph from '@components/palettes/paletteItems/SpectrumGraph';
import api from '@api/formulation';
import { Optional } from '@app/utils/types';
import { SpecularMode } from '@api/formulation/models';
import { Redirect, useHistory, useParams } from 'react-router';

type ViewProps = {
  history: ReturnType<typeof useHistory>;

  group_id: string;
  color_formula_id: string;
};

type ViewState = {
  instrument?: string;
  specular?: Optional<SpecularMode>;
  spectrum?: Spectrum;
  flash?: FlashContentT;
};

class AddSpectrum extends React.Component<ViewProps, ViewState> {
  state = {
    flash: undefined,
    instrument: undefined as Optional<string>,
    spectrum: undefined as Optional<Spectrum>,
    specular: 'excluded' as SpecularMode,
  };

  get isSavable() {
    const { spectrum, instrument, specular } = this.state;
    return spectrum && instrument && instrument.length > 0 && specular;
  }

  get buttons() {
    if (!this.isSavable) {
      return undefined;
    }
    return [
      <Button
        key="saver"
        outline
        color={theme.button.createColor}
        onClick={this.handleSaveClick}
        text="Save Spectrum"
      />,
    ];
  }

  handleSaveClick = async () => {
    const { color_formula_id, group_id } = this.props;

    const res = await api.color_formulas.get({
      _id: color_formula_id,
      formulation_group_id: group_id,
    });
    if (res.docs.length === 0) {
      this.setState({
        flash: {
          color: 'red',
          message: 'Color formula not found',
        },
      });
      return;
    }
    const formula = res.docs[0];

    const { instrument, specular, spectrum } = this.state;
    const canPerformSaveOp =
      formula.spectrums.find(x => x.instrument === instrument && x.specular === specular) === undefined;
    if (!canPerformSaveOp) {
      this.setState({
        flash: {
          color: 'red',
          message:
            `Color formula contains color definition for ${instrument || ''} ` +
            `with specular mode as ${specular || ''}`,
        },
      });
    }

    if (spectrum) {
      formula.spectrums.push({
        ...spectrum.toJSON(),

        instrument: instrument ?? '',

        specular: specular ?? 'excluded',
      });
    }

    await api.color_formulas.update(formula);

    const { history } = this.props;
    history.goBack();
  };
  handleRawSpectralData = (_e: React.SyntheticEvent<HTMLInputElement>, data: { value: string }) => {
    const curve = data.value.split(',').map(parseFloat);
    if (curve.length !== 31) {
      this.setState({
        flash: {
          color: 'red',
          message: `Parsed ${curve.length} values, but expected 31`,
        },
      });
      return;
    }

    if (curve.find(x => isNaN(x)) !== undefined) {
      console.log('found NaN');
      this.setState({
        flash: {
          color: 'red',
          message: 'Parsed content that was not a number',
        },
      });
      return;
    }

    this.setState({
      flash: undefined,
      spectrum: Spectrum.fromJSON({
        curve,
        start: 400,
        step: 10,
      }),
    });
  };
  handleSpecularChange = (e: React.SyntheticEvent<HTMLElement>, isChecked: boolean) => {
    const { innerText: specular } = e.currentTarget;
    this.setState({
      specular: (isChecked ? specular.toLowerCase() : 'excluded') as SpecularMode,
    });
  };

  handleInstrumentChange = (_e: React.SyntheticEvent<HTMLInputElement>, data: { value: string }) => {
    this.setState({
      instrument: data.value,
    });
  };

  SpectrumChart = () => {
    const { spectrum, instrument, specular } = this.state;
    if (!spectrum) {
      return null;
    }

    return (
      <SpectrumGraph
        key={_.uniqueId('forcing_refresh')}
        spectra={[
          {
            ...spectrum,
            name: `${instrument || 'None'} (${specular || 'None'})`,
          },
        ]}
      />
    );
  };
  render = () => {
    const { instrument, specular, flash } = this.state;
    const { SpectrumChart } = this;
    return (
      <ModuleSegment title="Spectrum Creator" buttons={this.buttons}>
        <Flash content={flash} />
        <LabeledInput
          label="Instrument Name"
          onChange={this.handleInstrumentChange}
          inputProps={{
            defaultValue: instrument,
            placeholder: 'Enter name of your Spectrometer. (e.g. DC500, Spectro One, Spectro One Pro)',
          }}
        />

        <FlexColumns>
          <InputLabel
            label="Specular Mode"
            containerStyle={{
              flexGrow: 0,

              marginBottom: 16,
              marginRight: 16,
              marginTop: 16,
            }}
          />
          <Checkbox
            checked={specular === 'excluded'}
            text="Excluded"
            containerStyle={{}}
            onCheckChange={this.handleSpecularChange}
          />
          <Checkbox
            checked={specular === 'included'}
            text="Included"
            containerStyle={{ marginLeft: 16 }}
            onCheckChange={this.handleSpecularChange}
          />
          <div style={{ flex: 1 }} />
        </FlexColumns>
        <LabeledInput
          label="Spectral Data"
          onChange={this.handleRawSpectralData}
          inputProps={{
            placeholder: 'Enter comma delimited intensities at 10nm intervals, starting at 400nm.',
          }}
        />

        <SpectrumChart />
      </ModuleSegment>
    );
  };
}
export default function __Page() {
  const history = useHistory();
  const { group_id, color_formula_id } = useParams<{ group_id: string; color_formula_id: string }>();
  if (!group_id || !color_formula_id) {
    return <Redirect to="/formulations" />;
  }

  return <AddSpectrum group_id={group_id} color_formula_id={color_formula_id} history={history} />;
}
