import * as React from 'react';
import _ from 'lodash';
import { Illuminant, IlluminantT, LabColor, LabColorIndexType, Observer, ObserverT } from '@variablecolor/colormath';
import { CardView, FlexColumns, ModuleSegment } from '@components/common';
import theme from '@theme';
import { round } from '@utils';
import { LabContainer } from './components';
import { DropdownProps } from '@components/common';

function Output({ lab }: { lab: LabColor }) {
  const rgb = lab.toRGB();
  const lch = lab.toLCH();
  const xyz = lab.toXYZ();
  const xyy = xyz.toXyY();
  const luv = xyz.toLUV();

  const lines = [
    [{ text: 'RGB' }, { text: `${round(rgb.r, 0)}, ${round(rgb.g, 0)}, ${round(rgb.b, 0)}` }],

    [{ text: 'HEX' }, { text: rgb.toHex() }],

    [{ text: 'XYZ' }, { text: `${round(xyz.X, 3)}, ${round(xyz.Y, 3)}, ${round(xyz.Z, 3)}` }],
    [{ text: 'XyY' }, { text: `${round(xyy.X, 3)}, ${round(xyy.y, 3)}, ${round(xyy.Y, 3)}` }],
    [{ text: 'Luv' }, { text: `${round(luv.L, 3)}, ${round(luv.u, 3)}, ${round(luv.v, 3)}` }],
    [{ text: 'LCH' }, { text: `${round(lch.L, 3)}, ${round(lch.c, 3)}, ${round(lch.h, 3)}` }],
    ...Illuminant.All.map(ill => {
      const l = lab.toLab(ill);
      return [
        { text: `LAB ${ill.toString()} ${Observer.toDisplayString(l.observer)}` },
        { text: `${round(l.L, 3)}, ${round(l.a, 3)}, ${round(l.b, 3)}` },
      ];
    }),
  ];
  return (
    <FlexColumns>
      <div
        style={{
          backgroundColor: rgb.toHex(),
          borderColor: theme.border.neutral,
          borderStyle: 'solid',
          borderWidth: 1,
          height: 128,
          marginBottom: 10,
          width: '100%',
        }}
      />

      {lines.map(x => (
        <CardView key={_.uniqueId('_card')} lines={x} />
      ))}
    </FlexColumns>
  );
}

type ViewProps = {};
type ViewState = {
  lab: LabColor;
};
export default class ColorCalculator extends React.Component<ViewProps, ViewState> {
  state = {
    lab: new LabColor(10, 100, -28, Illuminant.D50, Observer.TWO_DEGREE),
  };

  handleColorSpaceChange = (_event: React.SyntheticEvent<HTMLElement>, { id, value }: DropdownProps) => {
    const { lab } = this.state;
    if (id === 'illuminant') {
      (lab.illuminant as any) = value as IlluminantT;
    }

    if (id === 'observer') {
      (lab.observer as any) = value as ObserverT;
    }
    this.setState({
      lab,
    });
  };
  handleInputLabChange = (index: 'L' | 'a' | 'b') => (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { lab } = this.state;
    const {
      currentTarget: { valueAsNumber },
    } = e;
    if (!valueAsNumber) {
      console.log('no value');
      return;
    }

    (lab as LabColorIndexType)[index] = valueAsNumber;

    this.setState({
      lab,
    });
  };
  render = () => {
    const { lab } = this.state;

    return (
      <>
        <ModuleSegment containerStyle={{ margin: 4.5, minWidth: 256 }} title="Input Lab">
          <LabContainer
            onChange={this.handleInputLabChange}
            lab={lab}
            onColorSpaceChange={this.handleColorSpaceChange}
          />
        </ModuleSegment>

        <ModuleSegment containerStyle={{ margin: 4.5, minWidth: 256 }} title="Output">
          <Output lab={lab} />
        </ModuleSegment>
      </>
    );
  };
}
