import React from 'react';
import styled from 'styled-components';

import { Button, Dropdown, SearchBar } from '../../../common';
import theme from '../../../../theme';

const FilterOptions = styled.div`
  display: flex;
  margin-top: 12px;
  width: 100%;
  justify-content: center;
  margin-bottom: 12px;
`;

const FiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

type ViewProps = {
  defaultValue?: string;
  onFilterReset?: () => void;
  onCategorySelection: (cats: string[]) => void | any;
  onVendorSelection: (vendors: string[]) => void | any;
  onSearchTermChange: (searchTerm: string) => void | any;
  selectedCats: string[];
  selectedVendors: any;
  categories: string[];
  vendors: Array<string>;
};
class ProductGroupFilters extends React.Component<ViewProps> {
  get showCategories() {
    const { categories } = this.props;
    return categories.length > 1;
  }

  get showVendors() {
    const { vendors } = this.props;
    return vendors.length > 1;
  }

  get showClearButton() {
    return this.showCategories || this.showVendors;
  }
  render() {
    const {
      defaultValue,
      categories,
      vendors,
      onCategorySelection,
      onVendorSelection,
      onSearchTermChange,
      selectedCats,
      selectedVendors,
      onFilterReset,
    } = this.props;

    return (
      <div>
        <FiltersHeader>
          {this.showClearButton && (
            <Button color={theme.button.cancelColor} outline onClick={onFilterReset} text="Clear Filters" />
          )}
        </FiltersHeader>
        <FilterOptions>
          {categories.length > 0 && (
            <Dropdown
              placeholder="Categories"
              options={categories.map(v => ({ key: v, text: v, value: v }))}
              value={selectedCats}
              fluid
              multiple
              search
              style={{ marginRight: 4 }}
              selection
              onChange={(_1, { value }) => onCategorySelection?.(value as string[])}
            />
          )}

          <Dropdown
            placeholder="Organization"
            options={vendors.map(v => ({ key: v, text: v, value: v }))}
            value={selectedVendors}
            onChange={(_e, { value }) => {
              if (onVendorSelection) {
                onVendorSelection(value as string[]);
              }
            }}
            fluid
            multiple
            search
            selection
          />
        </FilterOptions>

        <SearchBar onSubmit={onSearchTermChange} inputProps={{ defaultValue }} />
      </div>
    );
  }
}

export default ProductGroupFilters;
