import _ from 'lodash';

const required_attrs = ['name', 'code', 'sort_order'];
const numerical_attrs = ['sort_order'];

export interface ProductAttribute {
  id: string;

  attr_key: string;

  attr_value: string;

  displayable: boolean;
}

export default {
  isHideable(attr: ProductAttribute) {
    return _.includes(required_attrs, attr.attr_key) === false;
  },
  isNumerical(attr: ProductAttribute) {
    return _.includes(numerical_attrs, attr.attr_key);
  },
};
