import React from 'react';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import ProductPreview from './ProductPreview';
import { AlternateText, FlexColumns, FlexRows, PrimaryText } from '../common';
import theme from '../../theme';
import { Product } from '../../api/vpapi/models';

const FlexGrid = styled(FlexColumns)`
  margin-top: 25px;

  flex-wrap: wrap;
  flex-direction: none;
`;

const Title = styled(PrimaryText).attrs({
  fontSize: '11px',
  textAlign: 'center',
})`
  margin: 0px;

  width: 128px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const HoverableContainer = styled(FlexRows)<{ selected?: boolean; hoverColor: string }>`
  margin: 12px;
  align-items: center;

  ${props => {
    if (props.selected) {
      return `
        margin: 10px;
        border-style: solid;
        border-color: ${props.hoverColor};
        border-width: 2px;
        border-radius: 2px;
        `;
    }

    return '';
  }}

  &:hover {
    margin: 10px;
    border-style: solid;
    border-color: ${({ hoverColor }) => hoverColor};
    border-width: 2px;
    border-radius: 2px;
    cursor: pointer;
  }
`;
const OpacityContainer = styled.div`
  &:hover {
    background-color: black;
    opacity: 0.95;
  }
`;

const DragIndicatorIcon = styled.i.attrs({
  className: 'ellipsis vertical icon',
})`
  position: absolute;
  left: 2px;
  top: 4px;
  color: white;
`;

const TopRightLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  z-index: 300;
`;

interface ViewProps {
  selected: boolean;
  numDragging: number;
  isDragging: boolean;
  product: Product;
  onClick?: (p: Product) => void;
}

export function ProductListItem2({ product, onClick, selected, numDragging, isDragging }: ViewProps) {
  const [isHovering, setHovering] = React.useState(false);
  const onMouseEnter = React.useCallback(() => {
    setHovering(true);
  }, [setHovering]);
  const onMouseLeave = React.useCallback(() => setHovering(true), [setHovering]);
  const onContainerClick = React.useCallback(() => onClick?.(product), [onClick, product]);

  const multiSelectDrag = numDragging > 1 && isDragging;
  return (
    <HoverableContainer
      selected={selected}
      hoverColor={theme.table.hoverBackground}
      onClick={onContainerClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        style={{
          position: 'relative',
        }}
      >
        {isHovering && <DragIndicatorIcon />}
        <OpacityContainer>
          {multiSelectDrag && (
            <TopRightLabel>
              <Label circular color="red" content={numDragging} />
            </TopRightLabel>
          )}
          <ProductPreview height={96} hex_colors={product.hex_colors} image_url={product.image_url} />
        </OpacityContainer>
      </div>
      <Title>{product.attrValForKey('name')}</Title>
      <AlternateText textAlign="center" fontSize="11px">
        {product.updatedAt().format('MMM DD, YYYY')}
      </AlternateText>
      <AlternateText textAlign="center" fontSize="11px">
        {product.updatedAt().format('h:mm A')}
      </AlternateText>
    </HoverableContainer>
  );
}

export function ProductListItem({ product, onClick }: { product: Product; onClick?: (p: Product) => void }) {
  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick(product);
    }
  }, [product, onClick]);

  return (
    <HoverableContainer hoverColor={theme.table.hoverBackground}>
      <FlexRows
        style={{
          alignItems: 'center',
        }}
        onClick={handleClick}
      >
        <ProductPreview height={96} hex_colors={product.hex_colors} image_url={product.image_url} />
        <Title>{product.attrValForKey('name')}</Title>
        <AlternateText textAlign="center" fontSize="11px">
          {product.updatedAt().format('MMM DD, YYYY')}
        </AlternateText>
        <AlternateText textAlign="center" fontSize="11px">
          {product.updatedAt().format('h:mm A')}
        </AlternateText>
      </FlexRows>
    </HoverableContainer>
  );
}

type ProductListProps = {
  onItemClick?: (p: Product) => void;
  products: Product[];
  renderProduct?: (p: Product) => React.ReactNode;
};

export const ProductList = ({ onItemClick, products, renderProduct }: ProductListProps) => (
  <FlexGrid>
    {products.map(p => {
      return renderProduct?.(p) || <ProductListItem key={p.dbid} product={p} onClick={onItemClick} />;
    })}
  </FlexGrid>
);
