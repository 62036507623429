import React from 'react';
import styled from 'styled-components';
import {
  AlternateText,
  Button,
  FlexColumns,
  FlexRows,
  ImageCircle,
  Modal,
  ModuleSegment,
  PrimaryText,
  ModalProps,
} from '@components/common';
import theme from '@theme';
import { Subscription } from '@api/cas/models';

const SubscriptionWrapper = styled(FlexRows)`
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;

  width: 150px;

  flex-grow: 1;
  flex-shrink: 1;

  padding: 2px;
`;

type ViewProps = {
  subscriptions?: Subscription<any>[];
  onItemClick?: (s: Subscription<any>) => void;
  handleDeleteClick?: (subscription: Subscription<any>) => void | any;
};
class SubscriptionList extends React.Component<ViewProps> {
  static defaultProps = {
    handleDeleteClick: undefined,
    onItemClick: () => {
      console.log('');
    },
    subscriptions: [],
  };

  renderSubscription = (subscription: Subscription<any>) => {
    const { handleDeleteClick, onItemClick } = this.props;
    const { theme: subscriptionTheme, package_name } = subscription;
    const bg = subscriptionTheme.colors.splashBackgroundColor;
    const { splash } = subscriptionTheme.images;
    return (
      <SubscriptionWrapper key={`id_${subscription.id}`}>
        <ImageCircle
          url={splash ? splash.png : undefined}
          backgroundColor={bg}
          diameter={300}
          onClick={() => onItemClick?.(subscription)}
        />

        <PrimaryText fontSize="bold" textAlign="center">
          {subscription.brand_name}
        </PrimaryText>
        <AlternateText textAlign="center" fontSize="12px">
          {package_name}
        </AlternateText>

        <div style={{ alignSelf: 'center', margin: 12 }}>
          <DeleteButton subscription={subscription} handleDeleteClick={handleDeleteClick} />
        </div>
      </SubscriptionWrapper>
    );
  };

  ModuleTitle = () => {
    const { subscriptions } = this.props;
    if (!subscriptions) {
      return <ModuleSegment.TitleText>App Subscriptions</ModuleSegment.TitleText>;
    }

    return (
      <FlexRows>
        <ModuleSegment.TitleText>App Subscriptions</ModuleSegment.TitleText>
        <ModuleSegment.SubTitleText>{`${subscriptions.length} Items`}</ModuleSegment.SubTitleText>
      </FlexRows>
    );
  };
  render = () => {
    const { subscriptions } = this.props;
    return (
      <ModuleSegment title={this.ModuleTitle} containerStyle={{ minWidth: 600 }}>
        <FlexColumns>{subscriptions && subscriptions.map(this.renderSubscription)}</FlexColumns>
      </ModuleSegment>
    );
  };
}
export default SubscriptionList;

type DeleteButtonProps = {
  handleDeleteClick?: (s: Subscription<any>) => void;
  subscription: Subscription<any>;
};
function DeleteButton({ subscription, handleDeleteClick }: DeleteButtonProps) {
  if (!handleDeleteClick) {
    return null;
  }

  const deletionModal = React.createRef<React.Component<ModalProps, any, any>>();
  const TriggerButton = <Button color="red" outline text="Unsubscribe" />;
  return (
    <Modal modalRef={deletionModal} modalProps={{ trigger: TriggerButton }}>
      <ModuleSegment title="Confirm Action">
        <AlternateText>
          Unsubscribing from this App Package will revoke access to Product Library Content. Are you sure you want to
          unsubscribe?
        </AlternateText>

        <FlexColumns style={{ justifyContent: 'flex-end', marginTop: 12 }}>
          <Button
            color="red"
            text="Yes, Unsubscribe"
            onClick={e => {
              e.preventDefault();

              handleDeleteClick(subscription);

              if (deletionModal.current) {
                deletionModal.current.setState({ open: false });
              }
            }}
          />
          <Button
            color={theme.button.actionColor}
            outline
            text="Cancel"
            onClick={() => {
              if (deletionModal.current) {
                deletionModal.current.setState({ open: false });
              }
            }}
          />
        </FlexColumns>
      </ModuleSegment>
    </Modal>
  );
}
