import * as React from 'react';
import _ from 'lodash';
import { useParams } from 'react-router';

import type { PlatformDetailT } from '../types';
import PlatformDetail from './PlatformDetail';
import { Loading } from '@components/common';
import cas from '@api/cas';
import ThirdPartyPlatformDetail from '../ThirdPartyPlatformDetail';

function usePlaformDetailFromParams() {
  const { platformID } = useParams<{ platformID: string }>();
  const [original, setOriginal] = React.useState<PlatformDetailT | null>(null);
  const [platform, setPlatform] = React.useState<PlatformDetailT | null>(null);

  React.useEffect(() => {
    cas.platforms
      .get(platformID)
      .then(p => {
        setPlatform(_(p).cloneDeep() as any as PlatformDetailT);
        setOriginal(p as any as PlatformDetailT);
      })
      .catch(e => console.log(e));
  }, [setPlatform, platformID]);

  return {
    platformID,
    platform,
    onChange: (p: PlatformDetailT) => {
      setPlatform(_.cloneDeep(p));
    },
    savePlatform: React.useCallback(
      (p: PlatformDetailT) => {
        cas.platforms.update(p);

        setOriginal(p);
        setPlatform(_.cloneDeep(p));
      },
      [setPlatform, setOriginal],
    ),

    resetPlatform: React.useCallback(() => {
      console.log('Original', original);
      setPlatform(original);
    }, [setPlatform, original]),

    refreshPlatform: React.useCallback(() => {
      cas.platforms
        .get(platformID)
        .then(p => {
          setPlatform(p as any as PlatformDetailT);
        })
        .catch(e => console.log(e));
    }, [setPlatform, platformID]),
  };
}

export default function PlatformDetailWrapper() {
  const { platformID, platform, savePlatform, onChange, resetPlatform, refreshPlatform } = usePlaformDetailFromParams();
  if (!platform) {
    return <Loading text="Fetching App Details" />;
  }

  if (platformID === 'xsd4eq2kjl0') {
    return <ThirdPartyPlatformDetail handleCreate={refreshPlatform} platform={platform} />;
  }

  return (
    <PlatformDetail
      onReset={resetPlatform}
      onSave={savePlatform}
      refreshPlatform={refreshPlatform}
      platform={platform}
      onChange={onChange}
    />
  );
}
