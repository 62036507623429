import productGroups from './productGroupsAPI';
import products from './products';
import filters from './filters';
import images from './images';
import models from './models';

export default {
  filters,
  images,
  models,
  productGroups,
  products,
};
