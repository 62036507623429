import * as React from 'react';
import qs from 'query-string';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import routeUtils from '@utils/route-utils';
import theme from '@theme';
import ColorFormulaList from './ColorFormulaList/index';
import { Button, ModuleSegment, Switch } from '@components/common';
import type { FormulationGroup } from '@api/formulation/models';
import FormulationProductList from './FormulationProductList';

type ViewProps = {
  location: ReturnType<typeof useLocation>;
  history: ReturnType<typeof useHistory>;

  group: FormulationGroup;
};

type TabType = 'products' | 'formulas';

export default class Container extends React.Component<ViewProps> {
  get buttons() {
    const { group } = this.props;
    if (!group) {
      return undefined;
    }

    let creationButtonInfo;
    switch (this.selectedType) {
      default:
      case 'products':
        creationButtonInfo = {
          link: routeUtils.uploadFormulationProducts(group.id),
          text: 'Create Products',
        };
        break;

      case 'formulas':
        creationButtonInfo = {
          link: routeUtils.uploadColorFormulas(group.id),
          text: 'Create Formulas',
        };
        break;
    }

    return [
      <NavLink to={creationButtonInfo.link} key="creator">
        <Button color={theme.button.createColor} text={creationButtonInfo.text} />
      </NavLink>,
      <NavLink to={routeUtils.formulationGroupSettings(group.id)} key="library-details">
        <Button color={theme.button.neutralColor} outline text="Library Details" />
      </NavLink>,
    ];
  }
  get tabItems() {
    return [
      {
        name: 'Products',
        onClick: this.handleTabChange('products'),
        selected: this.selectedType === 'products',
        value: 'products',
      },
      {
        name: 'Formulas',
        onClick: this.handleTabChange('formulas'),
        selected: this.selectedType === 'formulas',
        value: 'formulas',
      },
    ];
  }
  get selectedType(): TabType {
    const { location } = this.props;
    const params = qs.parse(location.search);

    const tab = params.type as TabType | undefined;
    switch (tab) {
      case 'formulas':
      case 'products':
        return params.type! as TabType;

      default:
        return 'products';
    }
  }

  handleTabChange = (t: TabType) => () => {
    const { location, history } = this.props;
    location.search = qs.stringify({
      ...qs.parse(location.search),
      type: t,
    });

    history.push(`${location.pathname}?${location.search}`);
  };

  Content = () => {
    const { selectedType } = this;
    switch (selectedType) {
      case 'formulas':
        return <ColorFormulaList {...this.props} />;

      case 'products':
        return <FormulationProductList {...this.props} />;

      default:
        return <FormulationProductList {...this.props} />;
    }
  };
  render() {
    const { Content } = this;
    return (
      <div>
        <div
          style={{
            margin: 'auto',
            marginTop: theme.dimensions.contentPadding,
            minWidth: 256,
            width: '50%',
          }}
        >
          <Switch items={this.tabItems} />
        </div>
        <ModuleSegment
          title="Formulation Group"
          containerStyle={{ margin: theme.dimensions.contentPadding }}
          buttons={this.buttons}
        >
          <Content />
        </ModuleSegment>
      </div>
    );
  }
}
