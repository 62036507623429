import styled from 'styled-components';

export default styled.div<{ height?: string; width?: string }>`
  height: ${({ height = '80vh' }) => height};
  width: ${({ width = '100%' }) => width};

  margin-bottom: 12px;
  margin-top: 12px;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
