import * as React from 'react';
import { DropdownItemProps, DropdownProps, Label, LabelProps } from 'semantic-ui-react';
import styled from 'styled-components';
import theme from '@theme';
import type { Editable } from '@api/vpapi/models';
import { Dropdown, FlexColumns, InputLabel, PrimaryText } from '@components/common';
import ProductFilter from '@api/vpapi/models/ProductFilter';

const FilterItemContainer = styled(FlexColumns)`
  margin: 1px;

  flex-wrap: nowrap;
`;

const DeleteIcon = styled.img.attrs({ src: '/icons/delete.png' })`
  height: 16px;
  width: 16px;
`;

const ButtonContainer = styled.div`
  background-color: ${theme.table.rowBackground};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 2px;

  width: ${theme.dimensions.input.height};

  :hover {
    cursor: pointer;
    background-color: ${theme.button.cancelColor};
  }
`;

type Option = { text: string; value: string };

type ViewProps = {
  handleRemoveFilterValue: (arg0: string, arg1: string) => void | any;
  handleClearFilterValues: (filter: ProductFilter) => void | any;
  handleAddFilter: (key: string, value: string) => void | any;
  filter: ProductFilter;
  editables?: Editable[];
  onProductChange: () => void | any;
};

class FilterItem extends React.Component<ViewProps> {
  static defaultProps = {
    editables: [],
  };

  get filterOptions(): Option[] {
    const {
      filter: { filter_key },
      editables,
    } = this.props;
    if (!editables) {
      return [];
    }

    const options = editables
      .filter(e => e.name.toLowerCase() === filter_key.toLowerCase())
      .reduce((arr: Option[], editable: Editable) => {
        if (editable.items && editable.items.length > 0) {
          const opts: Option[] = editable.items
            .filter(item => item.name.length > 0 && !arr.find(i => i.value === item.name))
            .map(item => ({ text: item.name, value: item.name }));

          return [...arr, ...opts];
        }

        return arr;
      }, []);

    return options;
  }

  render() {
    const { filter } = this.props;
    return (
      <FilterItemContainer>
        <InputLabel containerStyle={{ margin: 0 }} label={filter.filter_key.toUpperCase()} />

        <FilterOptionsDropdown
          filter={filter}
          options={this.filterOptions}
          addFilter={this.addFilter}
          onChange={this.onDropdownChange}
          removeFilterValue={this.removeFilterValue}
        />

        <ButtonContainer onClick={this.deleteFilter}>
          <DeleteIcon />
        </ButtonContainer>
      </FilterItemContainer>
    );
  }

  deleteFilter = () => {
    const { filter, handleClearFilterValues } = this.props;
    handleClearFilterValues(filter);
  };

  removeFilterValue = (_e: React.MouseEvent<HTMLElement>, { value }: LabelProps) => {
    const { filter, handleRemoveFilterValue } = this.props;
    handleRemoveFilterValue(filter.filter_key, value);
  };

  addFilter = (_e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    const { filter, handleAddFilter } = this.props;
    handleAddFilter(filter.filter_key, value as string);

    this.forceUpdate();
  };

  onDropdownChange = (_e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    const { filter, onProductChange } = this.props;

    (value as string[]).forEach(v => filter.addValue(v));
    onProductChange();
    this.forceUpdate();
  };
}

type FilterOptionsDropdownProps = {
  options: Option[];
  filter: ProductFilter;
  addFilter: (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  onChange: (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  removeFilterValue: (e: React.MouseEvent<HTMLElement>, data: LabelProps) => void;
};

function FilterOptionsDropdown(props: FilterOptionsDropdownProps) {
  const { options, filter, removeFilterValue, addFilter, onChange } = props;

  return (
    <Dropdown
      search
      selection
      multiple
      allowAdditions
      minCharacters={0}
      options={options}
      value={filter.filter_value}
      onAddItem={addFilter}
      onChange={onChange}
      style={{
        backgroundColor: theme.table.rowBackground,
        borderRadius: 0,
        marginBottom: 2,
      }}
      fluid
      renderLabel={(item: DropdownItemProps, _index: number, defaultLabelProps: LabelProps) => {
        const labelProps = defaultLabelProps;
        delete labelProps.onRemove;

        labelProps.content = <HoverablePrimaryText fontSize="65%">{item.text}</HoverablePrimaryText>;

        return <Label style={{ backgroundColor: theme.button.neutralColor }} {...labelProps} />;
      }}
      onLabelClick={removeFilterValue}
    />
  );
}

const HoverablePrimaryText = styled(PrimaryText)`
  :hover {
    opacity: 0.35;
  }
`;

export default FilterItem;
