import * as React from 'react';
import styled from 'styled-components';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import theme from '@theme';

const B = styled.button<{
  padding: number;
  paddingLeft: number;
  paddingRight: number;
  fontSize: number;
  outline?: boolean;
  color?: string;
}>`
  color: ${theme.text.primary};

  padding: ${props => props.padding}px;
  padding-left: ${props => props.paddingLeft}px;
  padding-right: ${props => props.paddingRight}px;

  outline: none;

  margin-right: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  font-size: ${props => props.fontSize}px;
  font-weight: 400;

  white-space: nowrap;

  cursor: pointer;

  background-color: ${props => (props.outline ? 'transparent' : props.color)};

  border-radius: 30px/30px;
  border-color: ${props => (props.outline ? props.color : 'transparent')};
  border-width: 2px;
  border-style: solid;

  :hover {
    background-color: ${props => (props.outline ? props.color : 'transparent')};

    border-color: ${props => (props.outline ? 'transparent' : props.color)};
    border-width: 2px;
  }
`;

type ReflessButton = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'ref'
>;
export type ButtonProps = ReflessButton & {
  key?: string;
  outline?: boolean;
  color: string;
  text: string;
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  fontSize?: number;
  paddingLeft?: number;
  paddingRight?: number;
  padding?: number;
  icon?: SemanticICONS;
};

export default function Button({
  icon,
  text,
  fontSize = 12,
  padding = 5,
  paddingLeft = 10,
  paddingRight = 10,
  ...props
}: ButtonProps) {
  return (
    <B {...props} fontSize={fontSize} padding={padding} paddingLeft={paddingLeft} paddingRight={paddingRight}>
      {icon && <Icon name={icon} />}
      {text}
    </B>
  );
}
