import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { ChromaReadingJSON, Palette, PaletteItem, SpectroReadingJSON } from '@api/appdata/model';
import type { PaletteSearchResponse } from '@api/appdata';
import appData from '@api/appdata';
import AWSPubSub from '@api/aws/AWSPubSub';
import routeUtils from '@utils/route-utils';
import { FlashContentT } from '@app/components/common';
import { Optional } from '@app/utils/types';
import config from '@app/config';

async function query(skip: number) {
  return appData.searchPalettes({
    limit: 20,
    query: {
      isDeleted: false,
    },
    skip,
    sort: ['locallyUpdatedAt:-1'],
  });
}

function useAppDataNotification(cb: () => void) {
  React.useEffect(() => {
    const awsSubscription = AWSPubSub.emitter.addListener(AWSPubSub.eventNames.appDataNotification, cb);

    return function cleanup() {
      if (awsSubscription) {
        awsSubscription.remove();
      }
    };
  });
}

export default function usePalettes() {
  const { paletteID } = useParams<{ paletteID: string }>();
  const location = useLocation();
  const history = useHistory();
  const [flash, setFlash] = React.useState<Optional<FlashContentT>>(undefined);
  const [data, setPalettes] = React.useState<Optional<PaletteSearchResponse>>();
  const [selectedPalette, setSelectedPalette] = React.useState<Optional<Palette>>();

  useAppDataNotification(() => {
    query(0).then(setPalettes);
  });

  React.useEffect(() => {
    if (paletteID && selectedPalette?.uuid !== paletteID) {
      appData
        .searchPalettes({ limit: 1, query: { _id: { $eq: paletteID } } })
        .then(({ palettes: [p] }) => setSelectedPalette(p));
    }

    if (!paletteID && selectedPalette) {
      setSelectedPalette(undefined);
    }
  }, [paletteID, selectedPalette]);

  React.useEffect(() => {
    if (!data) {
      console.log('useEffect loading palettes');
      query(0).then(setPalettes);
    }
  }, [data]);

  const savePalette = (p: Palette) => {
    appData.uploadPalettes([p]);
    if (p.paletteItems.length > 0) {
      appData.uploadPaletteItems(p.paletteItems);
    }
  };

  return {
    flash,
    palettes: data,
    selectedPalette,
    selectedPaletteID: paletteID,

    savePalette,

    loadPalettes: React.useCallback(
      async (skip: number) => {
        query(skip).then(x => {
          setPalettes({
            palettes: [...(data?.palettes || []), ...x.palettes].sort((a, b) => {
              return b.locallyCreatedAtDate.getTime() - a.locallyCreatedAtDate.getTime();
            }),
            count: data?.count || x.count,
            last_updated_at: x.last_updated_at,
          });
        });
      },
      [data],
    ),

    createPalette: ({ name, notes }: { name: string; notes: string }) => {
      const palette = new Palette({
        lastViewedAt: new Date().toISOString(),
        name,
        notes,
        isDeleted: false,
        appVersionCreator: '',
        organization_id: '',
        platform_id: config.getPlatformID(),
      });

      if (data) {
        setPalettes({
          palettes: [palette, ...data.palettes],
          count: data.count + 1,
          last_updated_at: data.last_updated_at,
        });
      }

      savePalette(palette);

      if (!location.pathname.includes(palette.uuid)) {
        history.replace(routeUtils.savedColors(palette));
      }
    },

    moveSavedColorTo: React.useCallback(
      (sourcePaletteID: string, targetPalette: Palette, paletteItemID: string) => {
        if (data) {
          const sourcePalette = data.palettes.find(({ uuid }) => sourcePaletteID === uuid);
          const sourcePaletteItem = sourcePalette?.paletteItems.find(({ uuid }) => uuid === paletteItemID);
          if (!sourcePalette || !sourcePaletteItem) {
            setFlash({ color: 'red', message: 'Failed to locate source item' });
            return;
          }

          sourcePalette.paletteItems = sourcePalette.paletteItems.filter(({ uuid }) => uuid !== paletteItemID);
          sourcePalette.paletteItemCount -= 1;

          sourcePaletteItem.paletteID = targetPalette.uuid;
          targetPalette.paletteItems.push(sourcePaletteItem);
          targetPalette.paletteItemCount += 1;

          setPalettes({
            palettes: [...data.palettes],
            count: data.count,
            last_updated_at: data.last_updated_at,
          });

          // if (sourcePaletteID === paletteID) {
          // setSelectedPalette({ ...sourcePalette });
          // } This code looks a bit sus (corey)

          appData.uploadPaletteItems([sourcePaletteItem]).then(() =>
            AWSPubSub.publish(AWSPubSub.palettesTopic(targetPalette.uuid), {
              msg: 'sync',
              paletteID: targetPalette.uuid,
              paletteItemID,
            }),
          );
        }
      },
      [data, setPalettes],
    ),

    saveScanTo: React.useCallback(
      (scanID: string, p: Palette) => {
        appData
          .fetchReading(scanID)
          .then(scan => {
            if (!scan) {
              setFlash({ color: 'red', message: 'Failed to locate scan.' });
              return;
            }

            const json = scan.toServerJSON();
            const item = new PaletteItem({
              chromaReading: !scan.isSpectro() ? (json as ChromaReadingJSON) : undefined,
              displayOrder: p.paletteItems.length * 2,
              name: '',
              notes: '',
              paletteID: p.uuid,
              spectrumReading: scan.isSpectro() ? (json as SpectroReadingJSON) : undefined,
              sub_model_type: scan.isSpectro() ? 'spectrum' : 'chromaReading',

              platform_id: config.getPlatformID(),
              organization_id: '',
              appVersionCreator: '',
            });

            p.paletteItems.push(item);
            p.paletteItemCount += 1;

            if (data) {
              setPalettes({
                palettes: [...data.palettes],
                count: data.count,
                last_updated_at: data.last_updated_at,
              });
            }

            return appData.uploadPaletteItems([item]);
          })
          .then(() =>
            AWSPubSub.publish(AWSPubSub.palettesTopic(p.uuid), {
              msg: 'sync',
              paletteID: p.uuid,
              scanID,
            }),
          );
      },
      [setFlash, setPalettes, data],
    ),

    deletePalette: React.useCallback(
      (p: Palette) => {
        p.isDeleted = true;

        appData.deletePaletteItems(p.uuid);

        savePalette(p);

        if (data) {
          setPalettes({
            palettes: data.palettes.filter(({ uuid }) => uuid !== p.uuid),
            count: data.count - 1,
            last_updated_at: data.last_updated_at,
          });
        }

        if (location.pathname.includes(p.uuid)) {
          history.replace(routeUtils.savedColors());
        }
      },
      [setPalettes, data, location, history],
    ),
  };
}
