import React from 'react';
import styled from 'styled-components';

import type { PlatformDetailPackageUI } from '../types';
import { AlternateText, FlexRows, ImageCircle, PrimaryText } from '@components/common';
import theme from '@theme';

// import theme from '../../theme';

const HoverContainer = styled(FlexRows)`
  margin-top: 16px;

  align-items: center;
  display: inline-flex;

  background-color: ${({ selected }: { selected?: boolean }) => {
    if (selected) {
      return theme.table.selectedBackground;
    }

    return undefined;
  }}

  :hover {
    border-color: ${theme.border.selectedItem};
    border-style: solid;
    border-width: 2px;
  }
`;

type ViewProps = {
  isSelectable: boolean;
  p: PlatformDetailPackageUI;
  onClick?: (p: PlatformDetailPackageUI) => void;
};

export default ({ p, p: { theme: packageTheme, package_name, brand_name }, isSelectable, onClick }: ViewProps) => {
  const bg = packageTheme.colors?.splashBackgroundColor || '#000';
  const splash = packageTheme.images?.splash?.png;

  return (
    <HoverContainer selected={!!isSelectable && !!p.is_dirty} onClick={() => onClick?.(p)}>
      <ImageCircle diameter={256} url={splash} backgroundColor={bg} />
      <div style={{ overflow: 'hidden', width: 256 }}>
        <PrimaryText fontSize="12px" single textAlign="center">
          {package_name}
        </PrimaryText>
        <AlternateText fontSize="10px" textAlign="center">
          {brand_name}
        </AlternateText>
      </div>
    </HoverContainer>
  );
};
