import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

import QueryString from 'query-string';
import appData, { ScanHistoryResponse } from '@api/appdata';
import { Button, Loading, ModuleSegment, NoScanHistoryContent } from '@components/common';
import Pagination from '@components/common/Pagination';
import Pager from '@utils/Pager';
import theme from '@theme';
import ThreeContainer from '@utils/three/ThreeContainer';
import ScanListItem from './ScanListItem';
import User from '@api/cas/user';
import { Optional } from '@app/utils/types';

const ScrollContainer = styled.div`
  height: 80vh;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  overflow-y: scroll;

  -ms-overflow-style: none;
`;

const HistoryModuleSegment = styled(ModuleSegment).attrs({
  containerStyle: {
    flex: 6,
    flexGrow: 3,
    height: '95vh',
  },
})``;

function EmptyView() {
  return (
    <HistoryModuleSegment
      title="Scan History"
      bodyStyle={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <NoScanHistoryContent />
    </HistoryModuleSegment>
  );
}
type ViewState = {
  scans?: ScanHistoryResponse;
};

type ScanHistoryViewProps = {
  user: User;
  location: ReturnType<typeof useLocation>;
  history: ReturnType<typeof useHistory>;

  // onScanChange: Scan => void|any
};
class ScanHistoryView extends React.Component<ScanHistoryViewProps, ViewState> {
  pager: Pager = Pager.fromParams(50);
  state = {
    scans: undefined as Optional<ScanHistoryResponse>,
  };

  setScans = (data?: ScanHistoryResponse) => {
    if (!data) {
      return;
    }

    if (this.pager.numItems !== data.scans.length) {
      this.pager.numItems = data.count;
    }

    this.setState({
      scans: data,
    });
  };

  get scans() {
    const { scans: container } = this.state;
    if (!container) {
      return [];
    }

    const { scans } = container;
    if (!scans) {
      return [];
    }
    const { from, perPage } = this.pager;
    return scans.slice(from, from + perPage);
  }

  get showVizualization(): boolean {
    const { location } = this.props;
    const { m } = QueryString.parse(location.search, { parseNumbers: true });
    return !!m && m === 1;
  }
  componentDidMount = async () => {
    appData.fetchReadings().then(this.setScans);
  };

  onPageChange = () => {
    this.forceUpdate();
  };

  handleVisualize = () => {
    const { history, location } = this.props;
    let search = '';
    if (!this.showVizualization) {
      search = 'm=1';
      if (location.search) {
        search += `&${location.search}`;
      }
    }

    history.push(`${location.pathname}?${search}`);

    this.forceUpdate();
  };
  get moduleButtons() {
    const { user } = this.props;
    if (user.hasPaidFeature('vizualizations')) {
      return [
        <Button
          key="action"
          outline
          color={theme.button.alternativeActionColor}
          text={this.showVizualization ? 'View Details' : 'Vizualize'}
          onClick={this.handleVisualize}
        />,
      ];
    }
    return undefined;
  }

  get moduleText() {
    const { scans } = this.state;
    if (!scans) {
      return 'Loading Scan History';
    }

    if (this.showVizualization) {
      return 'Drag n rotate to view colors. ';
    }

    return `${scans.scans.length} SCANS`;
  }

  ScanHistoryView = () => {
    if (this.scans.length === 0 && this.pager.page === 1) {
      return <EmptyView />;
    }

    return (
      <HistoryModuleSegment
        title={ModuleSegment.ModuleTitle('Scan History', this.moduleText)}
        buttons={this.moduleButtons}
      >
        <ScrollContainer>
          {this.scans.map(s => (
            <ScanListItem
              onClick="Scan"
              title={s.isSpectro() ? 'Spectro Scan' : 'Muse Scan'}
              dragData={{ scanID: s.uuid, type: 'Scan' }}
              item={s}
              key={s.uuid}
            />
          ))}

          <div style={{ width: '100%' }}>
            <Pagination pager={this.pager} onPageChange={this.onPageChange} />
          </div>
        </ScrollContainer>
      </HistoryModuleSegment>
    );
  };

  Visualize = () => {
    const { scans } = this.state;
    if (!scans || scans.dataPoints.length === 0) {
      return <EmptyView />;
    }

    return (
      <ModuleSegment
        containerStyle={{
          flex: 5,
          flexGrow: 3,
          height: '100%',
          minHeight: window.innerHeight * 0.82,
          width: '100%',
        }}
        bodyStyle={{
          height: window.innerHeight * 0.82,
        }}
        title={ModuleSegment.ModuleTitle('Scan History', this.moduleText)}
        buttons={this.moduleButtons}
      >
        <ThreeContainer
          backgroundColor={theme.segment.moduleBackground}
          productColors={scans.dataPoints}
          onClick={() => {
            console.log('Clikster');
          }}
        />
      </ModuleSegment>
    );
  };

  render = () => {
    const { scans } = this.state;
    if (!scans) {
      return (
        <HistoryModuleSegment title="Scan History">
          <Loading text="Fetching scan history" />
        </HistoryModuleSegment>
      );
    }

    const { Visualize, ScanHistoryView: ScanHistoryDetails, showVizualization } = this;
    if (showVizualization) {
      return <Visualize />;
    }

    return <ScanHistoryDetails />;
  };
}

export default function __Page({ user }: { user: User }) {
  const history = useHistory();
  const location = useLocation();
  return <ScanHistoryView history={history} location={location} user={user} />;
}
