import _ from 'lodash';
import qs from 'query-string';
import User from '@api/cas/user';
import type { AttributeOption } from '@api/models';
import type {
  BulkResponse,
  ColorFormula,
  ColorFormulaRequest,
  Customer,
  CustomerFilterOptions,
  FormulaGroupOptions,
  FormulationGroup,
  FormulationGroupPostRequest,
  FormulationModel,
  FormulationProduct,
  FormulationProductRequest,
  GetResponse,
} from './models';
import utils from './utils';
import fetch from '../fetch2';
import config from '../../config';

const ErrNotFound = new Error('id not found');
const {
  server: { formulations: endpoints },
} = config;

async function get<T>(url: string, params: any = {}): Promise<GetResponse<T>> {
  return fetch.get(`${url}?${qs.stringify(params)}`);
}

async function bulk<T>(url: string, allItems: T[]): Promise<BulkResponse> {
  let chunkCount = 10;
  if (chunkCount > allItems.length) {
    chunkCount = 1;
  }

  const chunkSize = allItems.length / chunkCount;
  const requests = Array.from(Array(chunkCount).keys());

  const promises = requests
    .map(i => allItems.slice(i * chunkSize, i * chunkSize + chunkSize))
    .map(items => {
      return new Promise<BulkResponse>(fulfill => {
        fetch
          .post<BulkResponse>(url, { items })
          .then(fulfill)
          .catch(e => fulfill({ ids: [], error_message: e.message }));
      });
    });

  const results: BulkResponse[] = await Promise.all(promises);
  return results.reduce(
    (obj: BulkResponse, x: BulkResponse) => {
      const curr: BulkResponse = { ids: obj.ids.concat(x.ids) };
      if (x.error_message) {
        curr.error_message = `${obj.error_message || ''}\n${x.error_message}`;
      }
      return curr;
    },
    { ids: [] },
  );
}

const api = {
  color_formulas: {
    async attribute_options(id: string): Promise<GetResponse<AttributeOption>> {
      return get(`${endpoints.color_formulas(id)}/_options`);
    },

    async bulk(id: string, items: ColorFormula[]): Promise<BulkResponse> {
      return bulk(`${endpoints.color_formulas(id)}/_bulk`, items);
    },

    async delete(groupID: string, id: string) {
      return fetch.del(`${endpoints.color_formulas(groupID)}/${id}`);
    },

    async get(filter: ColorFormulaRequest): Promise<GetResponse<ColorFormula>> {
      return get(endpoints.color_formulas(filter.formulation_group_id), {
        ...filter,
      });
    },

    async options(groupID: string): Promise<GetResponse<any>> {
      return fetch.get(`${endpoints.color_formulas(groupID)}/_options`);
    },

    async post(f: ColorFormula): Promise<any> {
      return fetch.post(endpoints.color_formulas(f.formulation_group_id), f);
    },

    async search(id: string, query: any, options: any = {}): Promise<GetResponse<ColorFormula>> {
      return fetch.post(`${endpoints.color_formulas(id)}/_search`, {
        options,
        query,
      });
    },

    async update(formula: ColorFormula) {
      return fetch.post(endpoints.color_formulas(formula.formulation_group_id), formula);
    },
  },

  customers: {
    async all(filter: CustomerFilterOptions = {}) {
      return get<Customer>(endpoints.customers, filter);
    },

    async create(name: string) {
      let res = await fetch.post<{ _id: string }>(endpoints.customers, { name });

      return (await get<Customer>(endpoints.customers, { _id: res._id })).docs[0];
    },
  },

  formulation_groups: {
    async all(filter: FormulaGroupOptions = {}): Promise<GetResponse<FormulationGroup>> {
      return get(endpoints.formulation_groups, filter);
    },

    async attribute_options(id: string): Promise<GetResponse<AttributeOption>> {
      return get(`${endpoints.formulation_groups}/${id}/_options`);
    },

    async create(item: FormulationGroupPostRequest): Promise<FormulationGroup> {
      const { _id } = await fetch.post<{ _id: string }>(endpoints.formulation_groups, item);

      const res = await get<FormulationGroup>(endpoints.formulation_groups, { _id });
      return res.docs[0];
    },

    async delete(id: string): Promise<void> {
      return fetch.del(`${endpoints.formulation_groups}/${id}`);
    },

    async publish(id: string): Promise<void> {
      return fetch.post(`${endpoints.formulation_groups}/${id}/_publish`, {});
    },

    async detail(id: string): Promise<FormulationGroup> {
      const res: GetResponse<FormulationGroup> = await get(endpoints.formulation_groups, { _id: id });
      if (res.docs.length === 0) {
        throw ErrNotFound;
      }

      const item = res.docs[0];
      item.models = (await this.get_model(id)).docs;

      return item;
    },

    async get_model(id: string): Promise<GetResponse<FormulationModel>> {
      return fetch.get(endpoints.formulation_models(id));
    },

    isOwnedByCurrentUser(group: FormulationGroup): boolean {
      const user = User.load();
      if (!user) {
        return false;
      }
      const { email, is_system_admin } = user;
      const permissions = { is_owner: 1 };
      return is_system_admin || _.some(group.permissions, { email, permissions });
    },
    async refresh(id: string): Promise<void> {
      return fetch.get(endpoints.formulation_groups_refresh(id));
    },

    async train(id: string): Promise<void> {
      return fetch.post(endpoints.train, { formulation_group_id: id });
    },

    async upsert(item: FormulationGroup): Promise<void> {
      return fetch.post(endpoints.formulation_groups, item);
    },
  },

  products: {
    async all(filter: FormulationProductRequest): Promise<GetResponse<FormulationProduct>> {
      return get(endpoints.products, filter);
    },

    async bulk(id: string, products: FormulationProduct[]): Promise<BulkResponse> {
      return bulk(endpoints.bulk_product(id), products);
    },

    async delete(groupID: string, id: string) {
      return fetch.del(`${endpoints.formulation_groups}/${groupID}/products/${id}`);
    },

    async detail(id: string): Promise<FormulationProduct> {
      const res: GetResponse<FormulationProduct> = await get(endpoints.products, { _id: id });
      if (res.docs.length === 0) {
        throw ErrNotFound;
      }

      res.docs.forEach(p => {
        p.attributes.sort((x, y) => x.k.localeCompare(y.k));
      });

      return res.docs[0];
    },

    async search(query: any): Promise<GetResponse<FormulationProduct>> {
      console.log(query.search);
      return fetch.post(`${endpoints.products}/_search`, query);
    },

    async upsert(p: FormulationProduct): Promise<void> {
      return fetch.post(`${endpoints.products}`, p);
    },

    utils: utils.products,
  },
};

export default api;
