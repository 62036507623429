import * as React from 'react';
import _ from 'lodash';
import { NavLink, useHistory, useParams } from 'react-router-dom';

import SpectrumGraph from '@components/palettes/paletteItems/SpectrumGraph';
import routeUtils from '@utils/route-utils';
import theme from '@theme';
import { Button, LabeledInput, Loading, ModuleSegment } from '@components/common';
import api from '@api/formulation';
import type { ColorFormula, FormulationGroup } from '@api/formulation/models';
import Attributes, { AttributeItem } from '../../Common/Attributes';
import { Optional } from '@app/utils/types';

type ViewProps = {
  history: ReturnType<typeof useHistory>;

  colorFormulaID: string;
  group: FormulationGroup;
};
class ColorFormulaDetail extends React.Component<ViewProps, { formula: Optional<ColorFormula> }> {
  original?: ColorFormula;

  state = {
    formula: undefined as Optional<ColorFormula>,
  };
  componentDidMount = async () => {
    const { group, colorFormulaID } = this.props;

    const formula = await api.color_formulas.get({
      _id: colorFormulaID,
      formulation_group_id: group.id,
    });

    if (formula.docs.length === 0) {
      const { history } = this.props;
      history.goBack();
      return;
    }
    this.original = _.cloneDeep(formula.docs[0]);

    this.setState({
      formula: formula.docs[0],
    });
  };

  get isDirty() {
    const { formula } = this.state;
    return !_.isEqual(formula, this.original);
  }
  get buttons() {
    const buttons = [];

    if (this.isDirty) {
      buttons.push(<Button text="Save Formula" color={theme.button.createColor} onClick={this.handleSaveClick} />);
    }

    buttons.push(
      <Button outline text="Delete Formula" color={theme.button.cancelColor} onClick={this.handleDeleteClick} />,
    );

    return buttons;
  }

  handleSaveClick = async () => {
    const { formula } = this.state;
    if (!formula) {
      return;
    }

    await api.color_formulas.update(formula);

    this.original = _.cloneDeep(formula);

    this.forceUpdate();
  };

  handleDeleteClick = async () => {
    const { formula } = this.state;
    if (!formula) {
      return;
    }
    await api.color_formulas.delete(formula.formulation_group_id, formula._id);

    const { history } = this.props;
    history.goBack();
  };

  handleAttributeChange(a: AttributeItem) {
    return (_e: React.SyntheticEvent<HTMLInputElement>, data: { value: string }) => {
      const { formula } = this.state;
      if (!formula) {
        return;
      }

      const item = formula.attributes.find(x => x.k === a.k);
      if (!item) {
        return;
      }

      item.v = data.value;

      this.setState({
        formula,
      });
    };
  }

  handleFormulaChange({ id }: { id: string }) {
    return (_e: React.SyntheticEvent<HTMLInputElement>, data: { value: string }) => {
      const { formula } = this.state;
      if (!formula) {
        return;
      }

      const item = formula.colorants.find(x => x.id === id);
      if (!item) {
        return;
      }

      item.value = parseFloat(data.value);

      this.setState({
        formula,
      });
    };
  }

  handleAddAttribute = (a: AttributeItem) => () => {
    const { formula } = this.state;
    if (!formula) {
      return;
    }

    formula.attributes.push(a);
    this.setState({ formula });
  };

  Formula = (props: { colorants: { id: string; value: number }[] }) => {
    const { colorants } = props;
    return (
      <ModuleSegment
        title="Formula Makeup"
        containerStyle={{
          marginLeft: theme.dimensions.invertedContentPadding,
          marginRight: theme.dimensions.invertedContentPadding,
        }}
      >
        {colorants.map(c => (
          <LabeledInput
            onChange={this.handleFormulaChange(c)}
            label={c.id}
            inputProps={{ type: 'number', value: c.value }}
            key={c.id}
          />
        ))}
      </ModuleSegment>
    );
  };

  get spectrumButtons() {
    const { formula } = this.state;
    if (!formula) {
      return undefined;
    }
    return [
      <NavLink
        key="create-spectrum"
        to={routeUtils.uploadColorFormulaSpectrum(formula.formulation_group_id, formula._id)}
      >
        <Button outline text="Add Spectrum" color={theme.button.createColor} />
      </NavLink>,
    ];
  }
  Spectrums = () => {
    const { formula } = this.state;
    if (!formula) {
      return null;
    }

    return (
      <ModuleSegment
        buttons={this.spectrumButtons}
        title="Spectral Curves"
        containerStyle={{
          marginLeft: theme.dimensions.invertedContentPadding,
          marginRight: theme.dimensions.invertedContentPadding,
        }}
      >
        <SpectrumGraph
          spectra={formula.spectrums.map(s => ({
            ...s,
            name: `${s.instrument} (${s.specular})`,
          }))}
        />
      </ModuleSegment>
    );
  };

  render = () => {
    const { formula } = this.state;
    if (!formula) {
      return <Loading text="Loading color formula details" />;
    }

    const { Formula, Spectrums } = this;
    return (
      <ModuleSegment title="Color Formula Details" buttons={this.buttons}>
        <div
          style={{
            backgroundColor: formula.hex,
            height: '25vh',
            width: '100%',
          }}
        />
        <Attributes
          attributes={formula.attributes}
          onChange={this.handleAttributeChange}
          onAddItem={this.handleAddAttribute}
        />
        <Formula colorants={formula.colorants} />
        <Spectrums />
      </ModuleSegment>
    );
  };
}

export default function __Page(props: { group: FormulationGroup }) {
  const { color_formula_id } = useParams<{ color_formula_id: string }>();
  const history = useHistory();

  return <ColorFormulaDetail {...props} history={history} colorFormulaID={color_formula_id} />;
}
