import config from '../../config';
import Product, { ProductChangeSet, ProductJSON } from './models/Product';
import api from '../fetch2';
import { SearchConfig } from './SearchConfig';

type FetchProductsRequest = {
  from: number;
  size: number;
  searchConfig: SearchConfig;
};

interface ApiResponse<T> {
  docs: T[];
  count: number;
}
export default {
  async destroy(dbid: string): Promise<boolean> {
    try {
      await api.del(`${config.server.productsURL}/${dbid}`);
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  },

  async fetchProducts(opts: FetchProductsRequest): Promise<ApiResponse<Product>> {
    const { from, size, searchConfig } = opts;
    const json = await api.get<ApiResponse<ProductJSON>>(
      `${config.server.get_products}?from=${from}&size=${size}&${searchConfig.toAPIQueryString()}`,
    );
    if (json.count === 0) {
      json.docs = [];
    }

    json.docs.forEach((p: ProductJSON, i: number) => {
      json.docs[i] = new Product(p);
    });

    return json as ApiResponse<Product>;
  },

  async getProduct(dbid: string): Promise<Product> {
    try {
      const json = await api.get<ProductJSON>(`${config.server.productsURL}?pid=${dbid}`);
      return new Product(json);
    } catch (err) {
      console.warn(err);
      throw err;
    }
  },

  async update(product: ProductChangeSet) {
    return api.post(`${config.server.productsURL}/${product.dbid}/_update`, product).catch(console.warn);
  },
};
