// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_cognito_identity_pool_id: 'us-east-1:d95668fc-5fb6-46bc-afa1-dcb5bac6ba3b',
  aws_cognito_region: 'us-east-1',
  aws_project_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_S97uZKKps',
  aws_user_pools_web_client_id: '7ibn6kvvpr3aflup0p7jphcps0',
};

export default awsmobile;
