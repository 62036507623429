import * as React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import theme from '../../../theme';
import { AppVisibilityIcon } from '../Image/Icons';

interface TextProps {
  single?: boolean;
  fontSize?: string;
  textAlign?: string;
  lineHeight?: number;
  fontWeight?: number | 'bold' | 'bolder';
  color?: string;
  style?: React.CSSProperties;
}
const Text = styled.div<TextProps>`
 ${({ single }) => (single ? ' overflow: hidden; text-overflow: ellipsis; white-space: nowrap;' : '')}

  font-weight: ${({ fontWeight }) => fontWeight}};
  text-align: ${({ textAlign = 'left' }) => textAlign};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  
  color: ${({ color }) => color || theme.text.primary};
`;
const PrimaryText = styled(Text)`
  color: ${theme.text.primary};
`;
const AlternateText = styled(Text)`
  color: ${theme.text.alternate};
`;

const Header = styled.div<TextProps>`
  font-size: ${({ fontSize = '18px' }) => fontSize};
  font-weight: ${({ fontWeight = 500 }) => fontWeight};

  ${({ single }) => (single ? ' overflow: hidden; text-overflow: ellipsis; white-space: nowrap;' : '')}

  text-align: ${({ textAlign = 'left' }) => textAlign};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color || theme.text.primary};
`;

function HeaderWithIcon({ title, tooltip }: { tooltip: string; title: string }) {
  const Trigger = (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <Header>{title}</Header>
      <AppVisibilityIcon alt={tooltip} />
    </div>
  );

  return <Popup content={tooltip} trigger={Trigger} />;
}

export { Header, PrimaryText, AlternateText, HeaderWithIcon, Text };
