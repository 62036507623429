import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { ProductAttribute, ProductCompositionDetail } from '@api/vpapi/models';
import { FlexRows, LabeledInput, ModuleSegment } from '../../common';
import theme from '@theme';
import { CompositionContainer } from '../../products/edit/composition-details';
import ProductImageTable from './ImageTable';
import { PaletteItem } from '@api/appdata/model';

type ViewProps = {
  paletteItem: {
    readonly product_attributes?: ProductAttribute[];
    name: string;
    notes: string;
    readonly images: () => string[];
    readonly composition_details: ProductCompositionDetail[];
  };
  handleSave?: (item: PaletteItem) => void | any;
};

function Attributes({ attrs }: { attrs?: ProductAttribute[] }) {
  if (!attrs || attrs.length === 0) {
    return null;
  }

  return (
    <ModuleSegment title="Attributes">
      {attrs.map(attr => (
        <LabeledInput
          key={_.uniqueId('palette-item-')}
          containerStyle={{ flex: 1 }}
          label={attr.attr_key}
          inputProps={{
            disabled: true,
            placeholder: '',
            value: attr.attr_value,
          }}
        />
      ))}
    </ModuleSegment>
  );
}

function Labels({ paletteItem, handleSave }: ViewProps) {
  if (!handleSave) {
    return null;
  }

  return (
    <div>
      <LabeledInput
        label="name"
        inputProps={{
          autoFocus: true,
          defaultValue: paletteItem.name,
          placeholder: 'Enter new name',
          type: 'text',
        }}
        onChange={({ currentTarget: { value } }: React.SyntheticEvent<HTMLInputElement>) => {
          paletteItem.name = value;
        }}
      />
      <LabeledInput
        label="notes"
        inputProps={{
          autoFocus: true,
          defaultValue: paletteItem.notes,
          placeholder: 'Enter folder notes',
          type: 'text',
        }}
        onChange={({ currentTarget: { value } }: React.SyntheticEvent<HTMLInputElement>) => {
          paletteItem.notes = value;
        }}
      />
    </div>
  );
}

export default React.memo<ViewProps>(({ paletteItem, handleSave }: ViewProps) => (
  <FlexRows style={{ marginTop: 10, width: '100%' }}>
    <Labels paletteItem={paletteItem} handleSave={handleSave} />

    <NegativeSpacer>
      <Attributes attrs={paletteItem.product_attributes} />
    </NegativeSpacer>

    {paletteItem.composition_details.length > 0 && (
      <NegativeSpacer>
        <CompositionContainer disable_for_ecd product={paletteItem as any} />
      </NegativeSpacer>
    )}

    <div style={{ padding: '2.5%' }} />

    <NegativeSpacer>
      <ProductImageTable images={paletteItem.images().map(url => ({ id: url, url }))} />
    </NegativeSpacer>
  </FlexRows>
));

const NegativeSpacer = styled.div`
  margin-left: ${theme.dimensions.invertedContentPadding};
  margin-right: ${theme.dimensions.invertedContentPadding};
  margin-top: 0px;
  margin-bottom: 0px;
`;
