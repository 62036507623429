import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { DropdownProps } from '@components/common';
import { ModuleSegment } from '@components/common';
import { PaidPlanJSON, PlanID, UserSearchJSON } from '@api/cas/models';
import PaidSubscriptionOptions from './PaidSubscriptionOptions';
import { SaveButton } from './button';
import cas from '@api/cas';

type ViewProps = {
  user: UserSearchJSON;
  onSubscribeUser: (args: PaidPlanJSON) => void | any;
};

type ViewState = {
  plan?: { subscription: PaidPlanJSON };
};

export default class PaidSubscriptionQuad extends React.Component<ViewProps, ViewState> {
  state = {} as ViewState;
  originalSubscriptionOptions: any;

  async componentDidMount() {
    const { user } = this.props;

    this.originalSubscriptionOptions = await cas.payment.subscriptions(user.user.email);

    this.setState({
      plan: _.cloneDeep(this.originalSubscriptionOptions),
    });
  }

  handleFeatureCheckChange =
    (sub: 'bulk_edit' | 'vizualizations' | 'package_codes') => (_e: React.SyntheticEvent, isChecked: boolean) => {
      const plan = this.state.plan?.subscription;
      if (!plan?.options) {
        return;
      }

      plan.options.features[sub] = isChecked;

      this.setState({
        plan: this.state.plan,
      });
    };

  handleAccessCheckChange =
    (sub: 'organizations' | 'product_groups') => (_e: React.SyntheticEvent, isChecked: boolean) => {
      const plan = this.state.plan?.subscription;
      if (!plan || !plan.options) {
        return;
      }
      plan.options.access_control[sub] = isChecked;
      this.setState({
        plan: this.state.plan,
      });
    };

  onPlanChange = (_e: React.SyntheticEvent, { value }: DropdownProps) => {
    const plan = this.state.plan?.subscription;

    this.setState({
      plan: {
        subscription: {
          options: plan?.options as any,
          effective_til: (plan ? plan.effective_til : undefined) as any,
          plan_id: value as PlanID,
          platforms: [],
        },
      },
    });
  };

  onValueChanged = ({ currentTarget: { value, id, valueAsNumber } }: React.SyntheticEvent<HTMLInputElement>) => {
    switch (id) {
      case 'formulations':
      case 'additional_libraries': {
        const plan = this.state.plan?.subscription;
        if (!plan || !plan.options) {
          break;
        }

        let val: string[];
        if (value.length === 0) {
          val = [];
        } else if (value.indexOf(',') === -1) {
          val = [value];
        } else {
          val = value.split(',');
        }

        if (id === 'formulations') {
          plan.options.features.formulations = val;
        } else {
          plan.options[id] = val;
        }
        this.setState({ plan: this.state.plan });
        break;
      }
      case 'effective_til': {
        const effective_til = moment(value);
        if (effective_til.year() < 3000) {
          let { plan } = this.state;
          if (!plan) {
            plan = {
              subscription: {
                effective_til: effective_til.toISOString(),
                plan_id: 'VC-BASIC',
              } as any,
            };
          }

          plan!.subscription!.effective_til = effective_til.toISOString();

          this.setState({ plan });
        }
        break;
      }

      case 'max_products':
      case 'max_organizations':
      case 'max_distinct_users_subscriptions': {
        const subscription = this.state.plan?.subscription;
        if (!valueAsNumber || !subscription || !subscription.options) {
          break;
        }

        subscription.options[id] = valueAsNumber;
        this.setState({ plan: this.state.plan });
        break;
      }
    }
  };

  subscribeUser = () => {
    const plan = this.state.plan?.subscription;
    if (!plan || !plan.plan_id || !plan.effective_til) {
      return;
    }

    this.props.onSubscribeUser?.(plan);
  };

  render() {
    const { user } = this.props;
    const { plan } = this.state;

    return (
      <ModuleSegment title="Manage Paid Subscription">
        <PaidSubscriptionOptions
          onPlanChange={this.onPlanChange}
          plan={plan}
          onValueChanged={this.onValueChanged}
          onAccessControlCheckChange={this.handleAccessCheckChange}
          onFeatureCheckChange={this.handleFeatureCheckChange}
        />

        <SaveButton user={user} plan={plan?.subscription} onClick={this.subscribeUser} />
      </ModuleSegment>
    );
  }
}
