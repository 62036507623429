import _ from 'lodash';
import { BrandDetail, PackageDetail, ProductGroupDescriptor } from '../../cas/models';
import pgUtils from '../../productGroupUtils';

interface SelectableDescriptor extends ProductGroupDescriptor {
  isSelected: boolean;
}
type ConstructorArgs = {
  allGroups: ProductGroupDescriptor[];
  enabledGroups: ProductGroupDescriptor[];
  backingModel: BrandDetail | PackageDetail;

  bulkEditOnServer?: (
    id: string | number,
    enables: Array<ProductGroupDescriptor>,
    disables: Array<ProductGroupDescriptor>,
  ) => void | any;

  enableGroupsOnServer?: (id: string, groups: ProductGroupDescriptor[]) => void | any;
  disableGroupsOnServer?: (id: string, groups: ProductGroupDescriptor[]) => void | any;

  onCommit?: () => void;
};
class ProductGroupHelper {
  backingModel: BrandDetail | PackageDetail;
  changes: {
    enable: ProductGroupDescriptor[];
    disable: ProductGroupDescriptor[];
  };
  groups: SelectableDescriptor[];
  onCommit?: () => void;

  bulkEditOnServer?: (
    id: any,
    enables: Array<ProductGroupDescriptor>,
    disables: Array<ProductGroupDescriptor>,
  ) => void | any;

  enableGroupsOnServer?: (id: any, groups: ProductGroupDescriptor[]) => void | any;
  disableGroupsOnServer?: (id: any, groups: ProductGroupDescriptor[]) => void | any;

  constructor({
    allGroups,
    enabledGroups,
    backingModel,
    bulkEditOnServer,
    enableGroupsOnServer,
    disableGroupsOnServer,
    onCommit,
  }: ConstructorArgs) {
    this.backingModel = backingModel;
    this.onCommit = onCommit;

    this.bulkEditOnServer = bulkEditOnServer;
    this.enableGroupsOnServer = enableGroupsOnServer;
    this.disableGroupsOnServer = disableGroupsOnServer;

    this.changes = {
      disable: [],
      enable: [],
    };

    this.groups = allGroups.map(g => ({
      category: g.category,
      id: g.id,
      isSelected: enabledGroups.find(sg => sg.id === g.id) !== undefined,
      is_editable: g.is_editable,
      name: g.name,
      product_count: g.product_count,
      updated_at: g.updated_at,
      vendor: g.vendor,
    }));
  }

  toggleGroup(g: ProductGroupDescriptor) {
    const productGroup = this.groups.find(pg => pg.id === g.id);
    if (!productGroup) {
      return;
    }

    const { isSelected: isRemoving } = productGroup;

    if (!isRemoving) {
      productGroup.isSelected = true;

      const indexOf = this.changes.disable.findIndex(pg => pg.id === productGroup.id);
      if (indexOf !== -1) {
        _.remove(this.changes.disable, pg => pg.id === productGroup.id);
      } else {
        this.changes.enable.push(g);
      }
    } else {
      productGroup.isSelected = false;

      const indexOf = this.changes.enable.findIndex(pg => pg.id === productGroup.id);
      if (indexOf !== -1) {
        _.remove(this.changes.enable, pg => pg.id === productGroup.id);
      } else {
        this.changes.disable.push(g);
      }
    }
  }

  commitChanges() {
    // Send changes to server
    if (this.bulkEditOnServer) {
      this.bulkEditOnServer(this.backingModel.id, this.changes.enable, this.changes.disable);
    } else {
      if (this.enableGroupsOnServer) {
        this.enableGroupsOnServer(this.backingModel.id, this.changes.enable);
      }

      if (this.disableGroupsOnServer) {
        this.disableGroupsOnServer(this.backingModel.id, this.changes.disable);
      }
    }

    this.changes = {
      disable: [],
      enable: [],
    };

    this.backingModel.product_groups = this.selectedGroups();

    this.onCommit?.();
  }

  revertChanges() {
    this.changes.enable.forEach(g => {
      const productGroup = this.groups.find(pg => pg.id === g.id);
      if (productGroup) {
        productGroup.isSelected = false;
      }
    });

    this.changes.disable.forEach(g => {
      const productGroup = this.groups.find(pg => pg.id === g.id);
      if (productGroup) {
        productGroup.isSelected = true;
      }
    });

    this.changes = {
      disable: [],
      enable: [],
    };
  }

  pendingDisablesText(): string {
    const disables = this.changes.disable.length;

    return disables > 0 ? `${disables} groups queued to be disabled` : '';
  }

  pendingEnablesText(): string {
    const enables = this.changes.enable.length;

    return enables > 0 ? `${enables} groups queued to be enabled` : '';
  }

  selectedGroups(): ProductGroupDescriptor[] {
    return this.groups.filter(g => g.isSelected);
  }
}

function NewProductGroupHelperForBrand(brand: BrandDetail, onCommit?: () => void | any): ProductGroupHelper {
  const pgHelper = new ProductGroupHelper({
    allGroups: brand.available_product_groups,
    backingModel: brand,
    bulkEditOnServer: undefined,

    disableGroupsOnServer: pgUtils.revokeProductGroupBrandAccess,
    enableGroupsOnServer: pgUtils.enableProductGroupBrandAccess,
    enabledGroups: brand.product_groups,
    onCommit,
  });

  return pgHelper;
}

function NewProductGroupHelperForPackage(p: PackageDetail, onCommit?: () => void | any): ProductGroupHelper {
  const pgHelper = new ProductGroupHelper({
    allGroups: p.available_product_groups,
    backingModel: p,
    bulkEditOnServer: pgUtils.modifyPackageProductGroupsOnCas as any,

    disableGroupsOnServer: undefined,

    enableGroupsOnServer: undefined,
    enabledGroups: p.product_groups,

    onCommit,
  });

  return pgHelper;
}

export { ProductGroupHelper, NewProductGroupHelperForBrand, NewProductGroupHelperForPackage };
