import * as React from 'react';
import qs from 'query-string';

import styled from 'styled-components';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';

import { ModuleSegment } from './Login';
import { AlternateText, Button, Flash, FlexRows, LabeledInput, FlashContentT, Loading } from '@components/common';

import cas from '@api/cas';
import theme from '@theme';
import { Optional } from '@app/utils/types';

const LoginLink = styled(Link).attrs({
  children: 'Back to Login',
  to: '/login',
})`
  cursor: pointer;

  margin-top: 18px;
  text-align: center;

  color: ${theme.text.alternate};
  font-size: 90%;

  :hover {
    color: ${theme.text.primary};
    font-weight: bold;
  }
`;

export default function ResetPassword() {
  const history = useHistory();
  const { search } = useLocation();
  const { token } = qs.parse(search);

  const [flash, setFlash] = React.useState<Optional<FlashContentT>>(undefined);
  const [status, setStatus] = React.useState({
    loading: true,
    isComplete: false,
  });

  const [pw1, setPassword1] = React.useState('');
  const onPW1Change = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => setPassword1(e.currentTarget.value),
    [setPassword1],
  );

  const [pw2, setPassword2] = React.useState('');
  const onPW2Change = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => setPassword2(e.currentTarget.value),
    [setPassword2],
  );

  const handleSubmit = React.useCallback(() => {
    setStatus({ loading: true, isComplete: false });
    setFlash(undefined);
    cas.users
      .resetPassword({ password: pw1, password_2: pw2, reset_token: token } as any)
      .then(() => setStatus({ loading: false, isComplete: true }))
      .catch(e => {
        setStatus({ loading: false, isComplete: false });
        setFlash(e.flashProps);
      });
  }, [pw1, pw2, token, setFlash, setStatus]);

  React.useEffect(() => {
    if (!token) {
      console.log('no token present');
      history.replace('/');
      return;
    }

    if (cas.isAuthenticated()) {
      cas.users.logout().then(() => {
        setStatus({ loading: false, isComplete: false });
      });
    } else {
      setStatus({ loading: false, isComplete: false });
    }
  }, [token, history]);

  if (status.loading) {
    return (
      <div style={{ width: '75%', margin: 'auto' }}>
        <ModuleSegment title="Password Reset">
          <Loading />
        </ModuleSegment>
      </div>
    );
  }

  if (status.isComplete) {
    return <PasswordChangeSuccessful />;
  }

  return (
    <div style={{ width: '75%', margin: 'auto' }}>
      <ModuleSegment title="Password Reset">
        <Flash content={flash} />

        <LabeledInput
          label="New Password"
          id="password"
          onChange={onPW1Change}
          inputProps={{
            placeholder: 'Enter New Password',
            type: 'password',
            value: pw1,
          }}
        />

        <LabeledInput
          label="Confirm Password"
          onChange={onPW2Change}
          inputProps={{
            placeholder: 'Verify your password',
            type: 'password',
            value: pw2,
          }}
        />
        <FlexRows>
          <Button
            padding={theme.dimensions.largeButtonPadding}
            style={{ flex: 1 }}
            color={theme.button.createColor}
            onClick={handleSubmit}
            text="Submit"
          />

          <LoginLink />
        </FlexRows>
      </ModuleSegment>
    </div>
  );
}

function PasswordChangeSuccessful() {
  return (
    <div style={{ width: '75%', margin: 'auto' }}>
      <ModuleSegment title="Success!">
        <AlternateText>Your new password can now be used to log in from any of our mobile apps.</AlternateText>

        <NavLink
          to="/login"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 8,
          }}
        >
          <Button padding={theme.dimensions.largeButtonPadding} color={theme.button.actionColor} text="Back to Login" />
        </NavLink>
      </ModuleSegment>
    </div>
  );
}
