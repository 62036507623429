import * as React from 'react';
import { ProductGroupListItem } from '../brands/packages/ProductGroupSelector';
import { FlexColumns } from '../common';
import type { ProductGroupDescriptor } from '@api/cas/models';

type ViewProps<T extends ProductGroupDescriptor> = {
  productGroups: T[];
  containerStyle?: any;
  onProductGroupClick: (item: T) => void | any;
};

const listItemStyle = {
  flexGrow: 1,
  flexShrink: 1,
  width: 450,
};

/* ProductGroupsList - sfc that displays list of product libraries */
export default function <T extends ProductGroupDescriptor>(props: ViewProps<T>) {
  const { productGroups, onProductGroupClick, containerStyle } = props;
  return (
    <FlexColumns style={containerStyle}>
      {productGroups.map(pg => (
        <div key={pg.id} style={listItemStyle}>
          <ProductGroupListItem onGroupClick={onProductGroupClick} group={pg} selected={(pg as any).isSelected} />
        </div>
      ))}
    </FlexColumns>
  );
}
