import { Optional } from '@app/utils/types';
import { Feature, Session, Subscription, UserFeatureID, AccessControlID, UserJSON } from './models';

export default class User {
  email: string;

  marketing_opt_out: boolean;

  nickname: string;

  company: string;

  is_email_verified: boolean;
  is_system_admin: boolean;

  subscriptions: Subscription<any>[];

  session: Session;

  user_features: Feature<any>[];

  private constructor(params: { [key: string]: any }) {
    this.email = params.email! as string;

    this.session = params.session as Session;
    this.subscriptions = (params.subscriptions ?? []) as Subscription<any>[];
    this.user_features = (params.user_features ?? []) as Feature<any>[];

    this.is_email_verified = params.is_email_verified || false;
    this.is_system_admin = params.is_system_admin || false;

    this.nickname = params.nickname || '';
    this.company = params.company || '';

    this.marketing_opt_out = params.marketing_opt_out || false;
  }
  static load(): Optional<User> {
    const user = localStorage.getItem('user');
    const subscriptions = localStorage.getItem('subscriptions');
    const user_features = localStorage.getItem('user_features');
    const session = localStorage.getItem('session');
    if (user && subscriptions && session) {
      return new User({
        ...JSON.parse(user),
        session: JSON.parse(session),
        subscriptions: JSON.parse(subscriptions),
        user_features: JSON.parse(user_features || '[]'),
      });
    }

    return undefined;
  }

  static storeAuth = (json: UserJSON) => {
    localStorage.setItem('user', JSON.stringify(json.user));
    const { session } = json;
    if (session) {
      localStorage.setItem('session', JSON.stringify(session));
      localStorage.setItem('session_token', session.token);
    }

    localStorage.setItem('user_features', JSON.stringify(json.user_features));
    localStorage.setItem('subscriptions', JSON.stringify(json.subscriptions));
  };

  static clearAuth = () => {
    // wipe out all things for this session
    // e.g. selected_brand_id, selected_user_brand_id, selected_product_group_id
    sessionStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('session');
    localStorage.removeItem('session_token');
    localStorage.removeItem('subscriptions');
    localStorage.removeItem('user_features');
  };

  get user() {
    return this;
  }

  get isAuthed() {
    return true;
  }

  get hasPaidSubscription() {
    return !!this.session.plan_options;
  }
  hasAccessControl = (id?: AccessControlID): boolean => {
    const {
      is_system_admin,
      session: { plan_options },
    } = this;
    if (is_system_admin) {
      return true;
    }
    if (!plan_options || !id) {
      return false;
    }

    // block access control to formulations
    if (id === 'formulations') {
      return false;
    }

    return plan_options.access_control[id];
  };

  hasPaidFeature = (id: 'vizualizations' | 'package_codes' | 'bulk_edit'): boolean => {
    return this.is_system_admin || !!this.session.plan_options?.features[id];
  };
  hasFeature<T>(id: UserFeatureID): Optional<Feature<T>> {
    return this.user_features?.find(f => f.id === id);
  }

  get session_token() {
    return this.session.token;
  }

  get is_developer() {
    return [
      'at@variableinc.com',
      'andrew.temple@variableinc.com',
      'corey.mann@variableinc.com',
      'wade.gasior@variableinc.com',
      'cody.whitt@variableinc.com',
      'wadegasior@gmail.com',
    ].includes(this.email);
  }
}
