import config from '../../config';
import { Product } from './models';

const importOperationType = { op: 'insert' };

//TODO: move this to another file
async function importProducts(
  products: Product[],
  index: number = 0,
  doColorExtraction: boolean = false,
): Promise<Product[]> {
  // Get the ending position of this chunk
  const endPosition = Math.min(index + 1000, products.length);

  // Slice the products to get the chunk about to be uploaded
  const chunk = products.slice(index, endPosition + 1);

  // Prealloc the operation as it will be the same for all products
  const operation = `${JSON.stringify(importOperationType)}\n`;

  // Build the new line delimited JSON format
  let body = '';
  chunk.forEach(value => {
    body += `${operation + JSON.stringify(value)}\n`;
  });

  // Update the index for the next recursive call if available
  index = endPosition + 1;

  const headers = {
    Accept: 'application/json',
    CASP: config.getPlatformID(),
    CAST: config.api_token,
    'Content-Type': 'text/plain',
    'Session-Token': localStorage.getItem('session_token')!,
  };

  if (doColorExtraction) {
    (headers as any)['Extract-Colors'] = 't';
  }

  /**const json = **/ await fetch(config.server.bulk_products, {
    body,
    headers,
    method: 'POST',
  });
  // Base Case for Recursion.
  if (index >= products.length) {
    // Give the caller the set of products
    return products;
  }

  return importProducts(products, index);
}

export default {
  importProducts,
};

export { importProducts };
