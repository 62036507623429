import * as React from 'react';
import styled from 'styled-components';
import { Label, SemanticCOLORS } from 'semantic-ui-react';
import { Optional } from '@app/utils/types';

export type FlashContentT = {
  message: string;
  color: SemanticCOLORS;
};
export interface FlashProps {
  content?: FlashContentT;
}

const Container = styled(Label)`
  &&& {
    margin: 20px 0;
    padding: 14px;
    font-size: 125%;
    font-weight: 200;
    color: white;
    width: 100%;
    text-align: center;
  }
`;

export default function FlashNotice({ content }: FlashProps) {
  const [dismiss, dismissNotice] = React.useState(false);
  const [id, setID] = React.useState<Optional<ReturnType<typeof setInterval>>>(undefined);

  // Post for  1 minute
  React.useEffect(() => {
    if ((content && dismiss) || !content) {
      return;
    }
    if (!id) {
      const interval = setInterval(() => {
        dismissNotice(true);
      }, 1 * 1000 * 60);
      setID(interval);
    }

    return function cleanup() {
      clearInterval(id);
    };
  }, [id, content, dismiss]);

  if ((content && dismiss) || !content) {
    return null;
  }

  return <Container content={content.message} color={content.color} />;
}
