import * as React from 'react';

import { Scatter } from 'react-chartjs-2';
import { SpectrumJSON } from '@variablecolor/colormath';
import * as chartjs from 'chart.js';

import Chart from 'chart.js/auto';

Chart.register(
  chartjs.CategoryScale,
  chartjs.LinearScale,
  chartjs.PointElement,
  chartjs.LineElement,
  chartjs.Title,
  chartjs.Tooltip,
  chartjs.Legend,
);

type Props = {
  spectra: (SpectrumJSON & { name: string })[];
};

const stdDataset = [
  {
    borderColor: '#406AAD',
    fill: false,
    lineTension: 0.1, //hex,
    pointBackgroundColor: '#406AADEE', //hex,
    pointBorderColor: '#406AADEE',
    pointBorderWidth: 1,
    pointHitRadius: 10,
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#fff',
    pointHoverBorderWidth: 2,
    pointHoverRadius: 5,
    pointRadius: 1,
    showLine: true, //hex,
  },
  {
    borderColor: '#3BB260',
    fill: false,
    lineTension: 0.1, //hex,
    pointBackgroundColor: '#3BB260', //hex,
    pointBorderColor: '#3BB260',
    pointBorderWidth: 1,
    pointHitRadius: 10,
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#fff',
    pointHoverBorderWidth: 2,
    pointHoverRadius: 5,
    pointRadius: 1,
    showLine: true, //hex,
  },
  {
    borderColor: '#fe2211',
    fill: false,
    lineTension: 0.1, //hex,
    pointBackgroundColor: '#fe2211', //hex,
    pointBorderColor: '#fe2211',
    pointBorderWidth: 1,
    pointHitRadius: 10,
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#fff',
    pointHoverBorderWidth: 2,
    pointHoverRadius: 5,
    pointRadius: 1,
    showLine: true, //hex,
  },
  {
    borderColor: '#fe2211',
    fill: false,
    lineTension: 0.1, //hex,
    pointBackgroundColor: '#fe2211', //hex,
    pointBorderColor: '#fe2211',
    pointBorderWidth: 1,
    pointHitRadius: 10,
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#fff',
    pointHoverBorderWidth: 2,
    pointHoverRadius: 5,
    pointRadius: 1,
    showLine: true, //hex,
  },
  {
    borderColor: '#fe2211',
    fill: false,
    lineTension: 0.1, //hex,
    pointBackgroundColor: '#fe2211', //hex,
    pointBorderColor: '#fe2211',
    pointBorderWidth: 1,
    pointHitRadius: 10,
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#fff',
    pointHoverBorderWidth: 2,
    pointHoverRadius: 5,
    pointRadius: 1,
    showLine: true, //hex,
  },
  {
    borderColor: '#fe2211',
    fill: false,
    lineTension: 0.1, //hex,
    pointBackgroundColor: '#fe2211', //hex,
    pointBorderColor: '#fe2211',
    pointBorderWidth: 1,
    pointHitRadius: 10,
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#fff',
    pointHoverBorderWidth: 2,
    pointHoverRadius: 5,
    pointRadius: 1,
    showLine: true, //hex,
  },
];

function useSpectrums({ spectra }: Props): {
  data: chartjs.ChartData<'scatter'>;
  options: chartjs.ChartOptions<'scatter'>;
} {
  // let minX = _(spectra).minBy(x => x.start)!.start;
  // let maxSpectrum = _(spectra).maxBy(x => x.start + x.step * x.curve.length)!;
  // let maxX = maxSpectrum.start + maxSpectrum.step * maxSpectrum.curve.length;

  return {
    options: {
      // datasetFill: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
        },
      },

      responsive: true,
      showLine: true,
    },

    data: {
      datasets: spectra.map(({ name, start, step, curve }, index) => ({
        ...stdDataset[index],
        data: curve.map((c, i) => ({
          x: start + i * step,
          y: c,
        })),
        label: name,
      })),
    },
  };
}
export default function SpectrumGraph(props: Props) {
  const { data, options } = useSpectrums(props);
  if (props.spectra?.length === 0) {
    return null;
  }

  return <Scatter data={data} options={options} />;
}
