import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import theme from '../../theme';
import { FlexColumns, FlexRows } from './common';
import { AlternateText, PrimaryText } from './Text';
import Modal from './Modal';

const ModuleContainer = styled.div<{ inverted?: boolean }>`
  padding: ${theme.dimensions.contentPadding};
  background-color: ${({ inverted }) => (inverted ? theme.text.primary : theme.segment.moduleBackground)};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
`;

const SubTitleText = styled(AlternateText)`
  font-weight: 400;
`;
const H2 = styled(PrimaryText)`
  /** weight and size approved by wes **/
  font-weight: 800;
  font-size: 21px;
  margin: 0;

  color: ${({ inverted }: { inverted?: boolean }) => (inverted ? theme.segment.moduleBackground : theme.text.primary)};
`;

const HelpIcon = ({ show }: { show?: boolean }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        alignItems: 'center',
        borderColor: 'white',
        borderRadius: 12,
        color: theme.text.primary,
        display: 'flex',
        height: 24,
        justifyContent: 'center',
        margin: 'auto',
        marginLeft: 0,
        marginRight: 4,
        width: 24,
      }}
    >
      <Icon name="info circle" size="large" />
    </div>
  );
};

export type ModuleSegmentTitle = (() => Element | null | React.ReactElement<any> | Node) | string;
type Props = {
  title: ModuleSegmentTitle;
  buttons?: JSX.Element[];
  containerStyle?: React.CSSProperties;
  handleDrop?: React.DragEventHandler<HTMLDivElement>;
  handleDragOver?: React.DragEventHandler<HTMLDivElement>;
  bodyStyle?: any;
  children: React.ReactNode;
  helpModal?: {
    Content: () => JSX.Element;
    title: string;
  };
  inverted?: boolean;
};

export default class ModuleSegment extends React.PureComponent<Props> {
  static ModuleTitle(title: string, subtitle: string) {
    return (): React.ReactElement<any> => (
      <FlexRows>
        <ModuleSegment.TitleText>{title}</ModuleSegment.TitleText>
        <ModuleSegment.SubTitleText>{subtitle}</ModuleSegment.SubTitleText>
      </FlexRows>
    );
  }
  static TitleText = H2;
  static SubTitleText = SubTitleText;
  static defaultProps = {
    bodyStyle: undefined,
    buttons: [],
    containerStyle: undefined,
    handleDragOver: undefined,
    handleDrop: undefined,
    helpModal: undefined,
    inverted: false,
  };

  Title = () => {
    const { helpModal, title: moduleSegmentTitle, inverted } = this.props;
    const TitleNode = (
      <FlexColumns style={{ flexGrow: 1 }}>
        <>
          <HelpIcon show={!!helpModal} />
          {typeof moduleSegmentTitle === 'function' && moduleSegmentTitle()}
          {typeof moduleSegmentTitle === 'string' && <H2 inverted={inverted}>{moduleSegmentTitle}</H2>}
        </>
      </FlexColumns>
    );

    if (!helpModal) {
      return TitleNode;
    }

    const { Content, title: helpModelTitle } = helpModal;
    return (
      <Modal modalProps={{ trigger: TitleNode }}>
        <ModuleSegment title={helpModelTitle} containerStyle={{ lineHeight: 2 }}>
          <Content />
        </ModuleSegment>
      </Modal>
    );
  };

  Header = () => {
    const { buttons } = this.props;

    const { Title } = this;
    return (
      <FlexColumns style={{ flexWrap: 'nowrap' }}>
        <Title />

        <ButtonContainer>{buttons && buttons.map(Button => Button)}</ButtonContainer>
      </FlexColumns>
    );
  };

  render = () => {
    const { children, containerStyle, handleDragOver, handleDrop, bodyStyle, inverted } = this.props;
    const { Header } = this;
    const formalBodyStyle = {
      color: inverted ? theme.text.alternate : theme.segment.moduleBackground,
      marginTop: 9,
      width: '100%',

      ...bodyStyle,
    };

    return (
      <ModuleContainer
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        id="module"
        inverted={inverted}
        style={containerStyle}
      >
        <Header />

        <div style={formalBodyStyle}>{children}</div>
      </ModuleContainer>
    );
  };
}
