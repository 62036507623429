import * as React from 'react';
import _ from 'lodash';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router';
import theme from '@theme';
import { ModuleSegment, FlexRows, Button } from '@components/common';
import { FormulationGroup } from '@api/formulation/models';
import ProductGroupsList from '../../productGroups/ProductGroupsList';
import { ProductGroupFilters } from '../../brands/packages/ProductGroupSelector';
import routeUtils from '@utils/route-utils';

function useGroupListButtons() {
  const history = useHistory();
  return [
    <Button
      key="creator"
      text="Create Library"
      onClick={() => {
        history.push('/formulations/libraries/_new');
      }}
      color={theme.button.createColor}
    />,
  ];
}

function useArrayParam(key: string): string[] {
  const location = useLocation();
  const params = qs.parse(location.search);
  const value = params[key] || [];
  if (Array.isArray(value)) {
    return value as string[];
  }

  return [value];
}

function useParam<T>(key: string, defaultValue: T): T {
  const location = useLocation();
  const params = qs.parse(location.search);
  return (params[key] || defaultValue) as T;
}

function useFilters(groups: FormulationGroup[]) {
  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search);
  const [selectedCategories, setCategories] = React.useState(useArrayParam('category'));
  const [selectedVendors, setVendors] = React.useState(useArrayParam('vendors'));
  const [searchTerm, setSearchTerm] = React.useState(useParam('search', ''));

  const [filteredGroups, setFilterGroups] = React.useState<FormulationGroup[]>([]);
  React.useEffect(() => {
    const filterGroups = groups.filter(item => {
      if (selectedCategories.length > 0 && !selectedCategories.includes(item.category)) {
        return false;
      }

      if (selectedVendors.length > 0 && !selectedVendors.includes(item.vendor)) {
        return false;
      }

      if (searchTerm.length > 0) {
        return item.name.includes(searchTerm) || item.vendor.includes(searchTerm);
      }

      return true;
    });

    setFilterGroups(filterGroups);
  }, [location, groups, selectedCategories, selectedVendors, searchTerm]);

  return {
    searchTerm,
    filteredGroups,
    selectedCategories,
    selectedVendors,
    onCategorySelection: React.useCallback(
      (values: string[]) => {
        params.categories = values;
        history.replace(`${location.pathname}?${qs.stringify(params)}`);

        setCategories(values);
      },
      [location, history, params],
    ),

    onVendorSelection: React.useCallback(
      (values: string[]) => {
        params.vendors = values;
        history.replace(`${location.pathname}?${qs.stringify(params)}`);
        setVendors(values);
      },
      [location, history, params],
    ),

    onSearchTermChange: React.useCallback(
      (term: string) => {
        params.search = term;
        history.replace(`${location.pathname}?${qs.stringify(params)}`);

        setSearchTerm(term);
      },
      [location, history, params],
    ),

    onFilterReset: React.useCallback(
      (/**term: string*/) => {
        delete params.vendors;
        delete params.search;
        delete params.categories;

        setVendors([]);
        setSearchTerm('');
        setCategories([]);

        history.replace(`${location.pathname}?${qs.stringify(params)}`);
      },
      [location, history, params],
    ),

    onGroupClick: (g: FormulationGroup) => {
      history.push(routeUtils.formulationGroupDetail(g.id));
    },
  };
}
interface ViewProps {
  groups: FormulationGroup[];
}
export default function GroupList({ groups }: ViewProps) {
  const {
    filteredGroups,
    searchTerm,
    selectedCategories,
    selectedVendors,
    onCategorySelection,
    onGroupClick,
    onVendorSelection,
    onSearchTermChange,
    onFilterReset,
  } = useFilters(groups);

  return (
    <ModuleSegment title="Formulation Libraries" buttons={useGroupListButtons()} containerStyle={{ minWidth: 600 }}>
      <FlexRows>
        <ProductGroupFilters
          defaultValue={searchTerm}
          categories={_.uniq(_.map(groups, 'category'))}
          selectedCats={selectedCategories}
          onCategorySelection={onCategorySelection}
          selectedVendors={selectedVendors}
          vendors={_.uniq(_.map(groups, 'vendor'))}
          onVendorSelection={onVendorSelection}
          onSearchTermChange={onSearchTermChange}
          onFilterReset={onFilterReset}
        />
        <ProductGroupsList productGroups={filteredGroups} onProductGroupClick={onGroupClick} />
      </FlexRows>
    </ModuleSegment>
  );
}
