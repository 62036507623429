import * as React from 'react';
import { DropdownProps } from '@components/common';
import titleize from 'titleize';
import type { Editable } from '../../../api/vpapi/models';
import { Dropdown, PrimaryText } from '../../common';
import { Container } from './components';

type FilterableDropdownProps = {
  filter: Editable;
  handleChange: (filter: Editable, values: string[]) => void | any;
  selectedValues?: string[];
};

export default function FilterableDropdown({ filter, handleChange, selectedValues }: FilterableDropdownProps) {
  return (
    <Container>
      <PrimaryText fontWeight={500}>{titleize(filter.name)}</PrimaryText>

      <Dropdown
        style={{ margin: 0 }}
        placeholder={`select ${filter.name}`}
        multiple
        search
        minCharacters={0}
        selection
        icon={null}
        options={filter.items.map(item => ({
          key: item.name,
          text: item.name,
          value: item.name,
        }))}
        value={selectedValues || []}
        onChange={(_, { value }: DropdownProps) => {
          handleChange(filter, value as string[]);
        }}
      />
    </Container>
  );
}
