import React from 'react';

import type { Roles } from '@api/cas/models';

import { SmallText, MediumText, PermissionText, Pad, Container } from './components';

export type Item = {
  id: string;
  name: string;
  permission_level: Roles;
  package_count: number;
  theme_count: number;
  user_count: number;
};

export default ({ brand, onClick }: { onClick: (x: Item) => void; brand: Item }) => (
  <Container onClick={() => onClick(brand)}>
    <MediumText>{brand.name}</MediumText>

    <SmallText>{`${brand.user_count} Users`}</SmallText>

    <Pad />

    <SmallText>{`${brand.package_count} App Packages `}</SmallText>

    <Pad />

    <PermissionText permission_level={brand.permission_level} />
    <SmallText>{brand.id}</SmallText>
  </Container>
);
