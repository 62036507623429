import * as React from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';

import AddSpectrumView from './ColorFormulaDetail/AddSpectrum';
import ColorFormulaDetail from './ColorFormulaDetail';
import routeUtils from '@utils/route-utils';
import { Loading, Modal } from '@components/common';
import api from '@api/formulation';
import DetailContainer from './FormulationGroupDetailContainer';
import { FormulationGroup } from '@api/formulation/models';
import ProductDetail from '../ProductDetail';
import GroupInfo from './GroupInfo';
import { FormulaUploader, FormulationProductUploader } from '../GroupCreation';

type ViewState = {
  group?: FormulationGroup;
};
type ViewProps = {
  groupID?: string;
  history: ReturnType<typeof useHistory>;
};

class FormulationGroupDetail extends React.Component<ViewProps, ViewState> {
  state = {
    group: undefined,
  };
  async componentDidMount() {
    const { groupID, history } = this.props;
    if (!groupID) {
      history.replace(routeUtils.formulationGroups());
      return;
    }

    try {
      const group = await api.formulation_groups.detail(groupID);

      this.setState({ group });
    } catch (err) {
      history.replace(routeUtils.formulationGroups());
    }
  }

  back = () => {
    const { history } = this.props;
    if (history.length > 2) {
      history.goBack();
    }
  };

  ProductDetailPage = (props: any) => {
    const { group } = this.state;
    if (!group) {
      return null;
    }

    return (
      <Modal modalProps={{ defaultOpen: true, onClose: this.back }}>
        <ProductDetail group={group} {...props} />
      </Modal>
    );
  };

  handleFormulationGroupChange = async (group: FormulationGroup) => {
    this.setState({
      group,
    });

    api.formulation_groups.upsert(group);
  };

  ProductsPage = (props: any) => {
    const { group } = this.state;
    if (!group) {
      return null;
    }

    return <DetailContainer {...props} group={group} />;
  };

  SettingsModal = (props: any) => {
    const { group } = this.state;
    if (!group) {
      return null;
    }

    return (
      <Modal modalProps={{ defaultOpen: true, onClose: this.back }}>
        <GroupInfo {...props} group={group} onFormulationGroupChange={this.handleFormulationGroupChange} />
      </Modal>
    );
  };

  asModal = (Component: React.ElementType) => (props: any) => {
    const { group } = this.state;
    if (!group) {
      return null;
    }

    return (
      <Modal modalProps={{ defaultOpen: true, onClose: this.back }}>
        <Component {...props} group={group} />
      </Modal>
    );
  };

  render = () => {
    const { group } = this.state;
    if (!group) {
      return <Loading text="Fetching formulation library" />;
    }

    return (
      <div>
        <Route exact path="/formulations/library/:id/details" key="details" component={this.SettingsModal} />

        <Route key="library-products" exact path="/formulations/library/:id" component={this.ProductsPage} />

        <Route
          key="color-formula-detail"
          exact
          path="/formulations/library/:group_id/formula/:color_formula_id"
          component={this.asModal(ColorFormulaDetail)}
        />

        <Route
          key="import-color-formula-spectrum"
          exact
          path="/formulations/library/:group_id/formula/:color_formula_id/spectrum"
          component={this.asModal(AddSpectrumView)}
        />

        <Route
          key="formulation-product-upload"
          exact
          path="/formulations/library/:group_id/products/_upload"
          component={this.asModal(FormulationProductUploader)}
        />

        <Route
          key="color-formula-upload"
          exact
          path="/formulations/library/:group_id/formulas/_upload"
          component={this.asModal(FormulaUploader)}
        />

        <Route
          key="product-detail"
          exact
          path="/formulations/library/:group_id/product/:product_id"
          component={this.ProductDetailPage}
        />
      </div>
    );
  };
}
export default function __Page() {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  return <FormulationGroupDetail groupID={id} history={history} />;
}
