import _ from 'lodash';

import { AnyLabJSON, Illuminant, IlluminantT, LabColor, Observer, ObserverT } from '@variablecolor/colormath';
import { MeasuredColor, MeasuredColorJSON } from '@api/vpapi/models';

import type { ColorProvider } from '../types';
import { Optional } from '@app/utils/types';

export interface ProductColorJSON {
  comp: number;
  hex: string;

  mcs: Optional<MeasuredColorJSON[]>;

  name: Optional<string>;

  lab: AnyLabJSON;
}
export default class ProductColor implements ColorProvider {
  //#region Members
  id: string;

  //utilized by component render only...do not syncing
  name: string;

  lab: LabColor;

  hex: string;

  mcs: Array<MeasuredColor>;

  comp: number;

  get spectrum() {
    return this.mcs?.find(c => !!c.spectrum)?.spectrum;
  }

  //#endregion

  constructor(json: ProductColorJSON) {
    this.id = _.uniqueId('color_');

    this.comp = json.comp;

    this.hex = json.hex;
    this.mcs = (json.mcs || []).map(mc => new MeasuredColor(mc));
    this.name = json.name || '';

    //dangerous to assume never missing
    this.lab = LabColor.fromJSON(json.lab);
  }

  toAppDataJSON = (): ProductColorJSON => ({
    comp: this.comp,
    hex: this.hex,
    lab: this.lab.toAppDataJSON(),
    mcs: this.mcs.map(mc => mc.toAppDataJSON()),
    name: this.name,
  });

  labColor = (illuminant: IlluminantT = Illuminant.D50, observer: ObserverT = Observer.TWO_DEGREE) => {
    if (this.mcs !== undefined && this.mcs.length > 0) {
      return (
        this.mcs.find(x => !!x.spectrum || !!x.spectrum_labs)?.labColor(illuminant, observer) ??
        this.lab.toLab(illuminant)
      );
    }
    return this.lab.toLab(illuminant);
  };
}
