import * as React from 'react';

import Pager from '@utils/Pager';

export default function useScroll(
  itemCount: number,
  itemsPerPage: number,
  load: (prevPage: number, nextPage: number, itemsPerPage: number) => void,
) {
  const iScroll = React.useRef<HTMLDivElement | null>(null);
  const [pager] = React.useState<Pager>(
    new Pager({
      perPage: itemsPerPage,
      page: 1,
      numItems: itemCount,
      windowSize: 5,
    }),
  );

  pager.numItems = itemCount;

  const componentDidScroll = React.useCallback(() => {
    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    if (!pager.hasMore) {
      return;
    }

    const current = iScroll?.current;
    if (!current) {
      return;
    }

    // Checks that the page has scrolled to the bottom
    if (current.scrollTop + current.clientHeight >= current.scrollHeight * 0.99) {
      load(pager.page, pager.page + 1, pager.perPage);
      pager.nextPage();
    }
  }, [load, pager]);

  React.useEffect(() => {
    const x = iScroll;
    if (x.current) {
      x.current.addEventListener('scroll', componentDidScroll);
    }

    return function cleanup() {
      if (x.current) {
        x.current.removeEventListener('scroll', componentDidScroll);
      }
    };
  });

  return {
    ref: iScroll,
  };
}
