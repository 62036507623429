import * as React from 'react';

import { FlexColumns } from './common';
import { RectangleButton } from './Buttons';
import { IconInput, useEnterKeyPress, OnInputChangeFunc } from './Input/Input';
import theme from '@theme';

type Props = {
  containerStyle?: React.CSSProperties;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  name?: string;

  buttonText?: string;

  onSubmit: (value: string) => void;
  onChange?: OnInputChangeFunc;
};

export default function SearchBar({ buttonText, onChange, onSubmit, containerStyle, ...props }: Props) {
  const [v, setValue] = React.useState<string>(
    (props.inputProps?.value as string) || (props.inputProps?.defaultValue as string) || '',
  );
  const submit = React.useCallback(() => onSubmit(v), [v, onSubmit]);

  // Keep the !==
  if (props.inputProps?.defaultValue !== undefined) {
    delete props.inputProps.defaultValue;
  }

  return (
    <FlexColumns style={{ alignItems: 'center', width: '100%' }}>
      <IconInput
        name="search"
        containerStyle={containerStyle}
        inputProps={{
          ...props.inputProps,
          value: v,
          onKeyPress: useEnterKeyPress(submit),
        }}
        onChange={(e, data) => {
          setValue(e.currentTarget.value);

          if (onChange) {
            onChange(e, data);
          }
        }}
      />
      <RectangleButton
        show
        backgroundColor={theme.button.alternativeActionColor}
        text={buttonText || 'Search'}
        containerStyle={{ height: theme.dimensions.input.height }}
        handleClick={submit}
      />
    </FlexColumns>
  );
}
