import React from 'react';

type Props = {
  onReorder: (movedID: string, aboveDestinationID: string) => void;
  currentID: string;
  children: React.ReactNode;
};

class DnDReorderable extends React.Component<Props, any> {
  onDragStart = (e: React.DragEvent, id: string) => {
    const jsonStr = JSON.stringify({ id, type: 'id' });
    e.dataTransfer.setData('text/plain', jsonStr);
    console.log('drag start: ', jsonStr);
  };

  onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  onDragEnter = ({ currentTarget }: React.DragEvent<HTMLElement>) => {
    if (currentTarget.parentElement) {
      currentTarget.parentElement.style.borderTop = 'solid 4px white';
    }
  };

  onDragLeave = ({ currentTarget }: React.DragEvent<HTMLElement>) => {
    if (currentTarget.parentElement) {
      currentTarget.parentElement.style.borderTop = '';
    }
  };

  onDrop = (e: React.DragEvent, destAttrId: string) => {
    const { onReorder } = this.props;
    e.preventDefault();
    if (e.currentTarget.parentElement) {
      e.currentTarget.parentElement.style.borderTop = '';
    }
    const json = JSON.parse(e.dataTransfer.getData('text/plain'));
    if (!json.type || !(json.type === 'id')) {
      return;
    }
    const { id } = json;
    onReorder(id, destAttrId);
  };

  render() {
    const { children, currentID } = this.props;

    return (
      <div
        draggable
        onDragStart={e => this.onDragStart(e, currentID)}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={e => this.onDrop(e, currentID)}
      >
        {children}
      </div>
    );
  }
}

export default DnDReorderable;
