import React from 'react';
import styled from 'styled-components';
import { Dimmer, Header, Loader } from 'semantic-ui-react';

import api from '@api/vpapi';
import { Button, FlexColumns, LabeledInput, PrimaryText, StyledDropZone } from '@components/common';
import theme from '@theme';
import { ProductImage } from '@api/vpapi/models';

const AddButton = styled.div`
  background-color: ${theme.button.createColor};
  color: ${theme.text.primary};

  align-self: center;

  display: flex;
  align-items: center;
  justify-content: center;


  margin-top: ${theme.dimensions.input.margin}px;
  margin-bottom: ${theme.dimensions.input.margin}px;
  padding-left: 12px;
  padding-right: 12px;
  height: ${theme.dimensions.input.height}

  :hover {
    opacity: 0.5;
  }
`;

type ViewProps = {
  onImageUpload: (x: ProductImage[]) => void | any;
};
type ViewState = {
  isLoading: boolean;
  urlInput: string;
};
class AddProductImage extends React.Component<ViewProps, ViewState> {
  state = { isLoading: false, urlInput: '' };

  render() {
    const { isLoading } = this.state;

    const { ImageUpload, AddByURL } = this;
    return (
      <div>
        <ImageUpload />

        <FlexColumns alignItems="center" style={{ marginTop: 8 }}>
          <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
          <PrimaryText style={{ marginLeft: 8, marginRight: 8 }}>OR</PrimaryText>
          <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
        </FlexColumns>
        <AddByURL />

        {isLoading && (
          <Dimmer inverted active>
            <Loader>Loading...</Loader>
          </Dimmer>
        )}
      </div>
    );
  }

  ImageUpload = () => (
    <StyledDropZone onDrop={this.handleDrop} accept="image/*">
      <Header inverted size="medium">
        Drop an image to upload
      </Header>
      <Button outline color={theme.button.alternativeActionColor} text="Choose File" />
    </StyledDropZone>
  );

  AddByURL = () => {
    const { urlInput } = this.state;
    return (
      <FlexColumns>
        <LabeledInput
          label="URL"
          onChange={this.handleURLInputChange}
          inputProps={{
            placeholder: 'Enter image URL',
            value: urlInput,
          }}
        />

        <AddButton onClick={this.handleAddImageViaURL}>
          <PrimaryText textAlign="center" fontWeight="bold" fontSize="22px">
            +
          </PrimaryText>
        </AddButton>
      </FlexColumns>
    );
  };

  handleDrop = async (files: File[]) => {
    this.setState({ isLoading: true });

    const images = [] as ProductImage[];
    await Promise.all(
      files.map(async file => {
        try {
          images.push(await api.images.uploadImage(file));
        } catch (e) {
          console.log('image upload failed', e);
        } finally {
          window.URL.revokeObjectURL((file as any).preview);
        }
      }),
    );

    this.setState({ isLoading: false }, () => this.props.onImageUpload(images));
  };

  handleURLInputChange = (_e: React.SyntheticEvent<HTMLInputElement>, v: { value: string }) => {
    this.setState({
      urlInput: v.value,
    });
  };

  handleAddImageViaURL = () => {
    const { urlInput } = this.state;

    this.setState({ isLoading: true });

    api.images
      .transferImage(urlInput)
      .then(pIMG => {
        this.props.onImageUpload([{ image_key: '', ...pIMG }]);
        this.setState({ isLoading: false, urlInput: '' });
      })
      .catch(() => {
        this.setState({ isLoading: false, urlInput: '' });
      });
  };
}

export default AddProductImage;
