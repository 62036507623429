import React from 'react';
import _ from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { FlexColumns, PrimaryText } from '../common';

import type { RoutableNavigationItemProps } from './types';

const MenuItems = styled.div`
  display: flex;

  width: 100%;

  flex-direction: column;

  justify-content: center;
`;
const InlineIcon = styled.img`
  height: 24px;
  width: 24px;
  padding: 2px;
`;

const Item = styled.div`
  @media (max-width: 900px) {
    justify-content: center;
  }

  @media (min-width: 900px) {
    justify-content: flex-start;
  }
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;

  width: 100%;

  position: relative;

  :hover {
    background-color: rgba(59, 178, 96, 0.15);
  }
`;
const NonMobilePrimaryText = styled(PrimaryText)`
  @media (max-width: 900px) {
    display: none;
  }
`;

const ActiveContainer = styled.div<{ isActive?: boolean }>`
  ${({ isActive }) => {
    if (isActive) {
      return `
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(59,178,96, 0.15);
      `;
    }
    return '';
  }}
`;

const ActiveIndicator = styled.div<{ isActive?: boolean }>`
  ${({ isActive }) => {
    if (isActive) {
      return `
        position: absolute;
        
        top: 10%;
        left: 0;
        width: 4px;
        height: 80%;
        background-color: rgba(59,178,96);
      `;
    }
    return '';
  }}
`;

function MenuItem({ name, icon, path, onClick }: RoutableNavigationItemProps) {
  const location = useLocation();
  let isActive = false;
  if (path) {
    isActive = location.pathname.startsWith(path);
  }
  return (
    <NavLink to={path || ''} onClick={onClick}>
      <Item draggable={false} key={name}>
        <ActiveContainer id={path} isActive={isActive} />
        <ActiveIndicator isActive={isActive} />

        <FlexColumns alignItems="center">
          <InlineIcon src={icon} alt={icon} />
          <NonMobilePrimaryText style={{ marginLeft: 6 }}>{name}</NonMobilePrimaryText>
        </FlexColumns>
      </Item>
    </NavLink>
  );
}
export default function VerticalRoutedTabMenu({ routes }: { routes: RoutableNavigationItemProps[] }) {
  return (
    <MenuItems>
      {routes.map(r => (
        <MenuItem key={_.uniqueId('menuitem_')} {...r} />
      ))}
    </MenuItems>
  );
}
