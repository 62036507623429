import React from 'react';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import { LabeledInput } from '../../../common';
import attrUtils, { ProductAttribute } from '@api/vpapi/models/ProductAttribute';
import theme from '../../../../theme';

const AttrItemContainer = styled.div`
  width: 100%;
  display: flex;
`;

const AttrActionButtons = styled.div`
  padding-left: 14px;
  padding-right: 6px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  background-color: ${theme.table.rowBackground};
  margin: 1px;
  margin-left: -1px;

  z-index: 0;
`;

type ButtonProps = {
  attr: ProductAttribute;
  toggleDisplayable: (attr: ProductAttribute) => void;
};
function ToggleDisplayableButton({ attr, toggleDisplayable }: ButtonProps) {
  if (attrUtils.isHideable(attr) === false) {
    return <Icon />;
  }

  const Trigger = (
    <Icon
      name={attr.displayable ? 'eye' : 'low vision'}
      color={attr.displayable ? 'green' : 'red'}
      onClick={() => {
        toggleDisplayable(attr);
      }}
    />
  );
  return <Popup trigger={Trigger} content="Changes visibility when viewing product in Variable Color" />;
}

function DisplayOrderButton() {
  const TriggerButton = <Icon inverted bordered={false} name="ellipsis vertical" />;
  return <Popup trigger={TriggerButton} content="Drag to change order of attribute when viewing product details" />;
}

type ProductAttributeProps = {
  attr: ProductAttribute; /// yikes
  toggleDisplayable: (attr: ProductAttribute) => void | any;
  onProductChange: () => void;
};

export default function ProductAttributeView(props: ProductAttributeProps) {
  const { attr, toggleDisplayable, onProductChange } = props;

  const inputType = attrUtils.isNumerical(attr) ? 'number' : 'text';

  return (
    <AttrItemContainer>
      <LabeledInput
        containerStyle={{ flex: 1, margin: 1 }}
        label={attr.attr_key}
        inputProps={{
          placeholder: '',
          type: inputType,
          value: attr.attr_value,
        }}
        onChange={(_e, v) => {
          attr.attr_value = v.value;
          onProductChange();
        }}
      />

      <AttrActionButtons>
        <ToggleDisplayableButton attr={attr} toggleDisplayable={toggleDisplayable} />

        <DisplayOrderButton />
      </AttrActionButtons>
    </AttrItemContainer>
  );
}
