import _ from 'lodash';
import moment from 'moment';

import { ProductAttribute } from './ProductAttribute';
import ProductFilter, { FilterParams } from './ProductFilter';
import { DBProductCompositionDetailJSON, ProductCompositionDetail } from './ProductCompositionDetail';
import type { ProductColorDataPoint } from '../../../utils/three/ThreeContainer';
import { Optional } from '@app/utils/types';

export interface ProductJSON {
  collection_uuids: string[];
  composition_details: Optional<DBProductCompositionDetailJSON[]>;
  filters: Optional<FilterParams[]>;
  product_images: Optional<ProductImage>[];
  product_attributes: Omit<ProductAttribute, 'id'>[];
  product_uuid: string;
  dbid: string;
  created_at: string;
  updated_at: string;
}
export interface ProductChangeSet {
  readonly dbid: string;
  readonly product_attributes: Optional<ProductAttribute[]>;
  readonly composition_details: Optional<ProductCompositionDetail[]>;
  readonly filters: Optional<ProductFilter[]>;
  readonly product_images: Optional<ProductImage[]>;
}
export interface ProductImage {
  id?: string;
  url: string;
  image_key: 'full' | 'thumb' | string;
}
class Product {
  product_uuid: string;

  dbid: string;

  product_attributes: Array<ProductAttribute> = [];

  product_images: ProductImage[] = [];

  // A collection of ProductGroup uuids
  collection_uuids: string[] = [];

  composition_details: Array<ProductCompositionDetail> = [];

  filters: ProductFilter[];

  created_at: string;
  updated_at: string;

  constructor(serverJSON: ProductJSON) {
    Object.assign(this, serverJSON);

    this.product_uuid = serverJSON.product_uuid;
    this.dbid = serverJSON.dbid;
    this.created_at = serverJSON.created_at;
    this.updated_at = serverJSON.updated_at;

    this.product_attributes = (this.product_attributes || []).map(
      json => ({ ...json, id: _.uniqueId('attr_') } as ProductAttribute),
    );

    this.filters = (serverJSON.filters || []).map(json => new ProductFilter(json));

    this.collection_uuids = this.collection_uuids ?? [];
    this.product_images = this.product_images ?? [];

    this.product_images.forEach(img => {
      img.id = _.uniqueId('pimg_');
    });
    this.composition_details = (serverJSON.composition_details || []).map(json => new ProductCompositionDetail(json));
  }

  get limit_product_colors() {
    return (this.composition_details || []).length >= 10;
  }

  get hex_colors() {
    return (this.composition_details || []).map(c => c.hex);
  }

  get image_url() {
    if (this.product_images.length === 0) {
      return undefined;
    }

    const url = this.urlForFullProductImage();
    if (url) {
      return url;
    }

    return this.product_images[0].url;
  }

  updatedAt() {
    return moment(this.updated_at);
  }

  attrValForKey(k: 'name' | 'code' | string) {
    const attr = this.product_attributes.find(a => a.attr_key.toLowerCase().trim() === k.toLowerCase().trim());

    return attr?.attr_value ?? '';
  }

  productGroupID() {
    return (this.collection_uuids || [])[0] || '';
  }

  hexColor() {
    if (this.composition_details.length === 0) {
      return null;
    }

    return this.composition_details[0].hex;
  }

  hasColor() {
    return (this.composition_details || []).length > 0;
  }

  urlForImageWithKey(k: 'full' | 'thumb' | string) {
    const img = this.product_images.find(x => x.image_key === k);
    return img?.url;
  }

  urlForThumbProductImage() {
    return this.urlForImageWithKey('thumb');
  }

  urlForFullProductImage() {
    return this.urlForImageWithKey('full');
  }

  addAttr(k: string, v: string, displayable: boolean = true) {
    let attr = this.product_attributes.find(x => x.attr_key.toLowerCase().trim() === k.toLowerCase().trim());
    if (!attr) {
      attr = {
        id: _.uniqueId('_attr_'),
        attr_key: k.trim(),
        attr_value: v.trim(),
        displayable,
      };
      this.product_attributes.push(attr);
    }

    attr.attr_value = v;
    return attr;
  }

  deleteAttr = (attrID: string) => {
    this.product_attributes = this.product_attributes.filter(attr => attr.id !== attrID);
  };

  moveAttr(attrID: string, attrIDfornewIndex: string) {
    const newIndex = _.findIndex(this.product_attributes, a => a.id === attrIDfornewIndex);
    if (newIndex < 0 || newIndex >= this.product_attributes.length) {
      return;
    }
    const idx = _.findIndex(this.product_attributes, a => a.id === attrID);
    if (idx === -1 || idx === newIndex) {
      return;
    }

    const x = this.product_attributes[idx];
    const y = this.product_attributes[newIndex];
    this.product_attributes[idx] = y;
    this.product_attributes[newIndex] = x;
  }

  filterForKey(k: string) {
    const targetStr = k.trim();
    return this.filters.find(f => f.filter_key.toLowerCase().trim() === targetStr);
  }

  addFilter(k: string, v: string) {
    const value = v.trim();

    let filter = this.filterForKey(k);
    if (!filter) {
      filter = new ProductFilter({
        filter_key: k.trim(),
        name: k.trim(),
        filter_value: [],
      });
      this.filters.push(filter);
    }

    if (_.includes(filter.filter_value, value)) {
      return filter;
    }

    filter.filter_value.push(value);

    return filter;
  }

  deleteFilter(filterID: string) {
    this.filters = this.filters.filter(f => f.id !== filterID);
  }

  addCompositionDetail(d: DBProductCompositionDetailJSON) {
    this.composition_details.push(new ProductCompositionDetail(d));
  }

  deleteCompositionDetail({ id }: ProductCompositionDetail) {
    this.composition_details = this.composition_details.filter(_cd => _cd.id !== id);
  }

  findImageByType(type: 'full' | 'thumb' | string) {
    return this.product_images.find(image => image.image_key === type);
  }

  removeImage(imgToRemove: ProductImage) {
    this.product_images = this.product_images.filter(img => img.url !== imgToRemove.url);

    this.ensureThumbImageExist();
    this.saveImages();
  }

  addImages(images: ProductImage[]) {
    this.product_images.push(...images);

    this.ensureThumbImageExist();
    this.saveImages();
  }

  private saveImages() {
    const images = [] as ProductImage[];
    this.product_images.forEach((img, i) => {
      const product_image = {
        image_key: _.uniqueId('extra_'),
        url: img.url,
      };

      //special case: save 2 copies if image is full & thumb
      if (i === 0 && img.image_key === 'thumb') {
        images.push({ image_key: 'full', url: img.url }, { image_key: 'thumb', url: img.url });
      } else if (i === 0) {
        product_image.image_key = 'full';

        images.push(product_image);
      }
    });

    this.product_images = images;
  }

  private ensureThumbImageExist() {
    const thumb = this.product_images.find(img => img.image_key === 'thumb');
    if (!thumb) {
      (this.product_images[0] || {}).image_key = 'thumb';
    }
  }
  /**
   * Deep diff between two products, using lodash.
   *
   * Always adds the dbid
   */
  findDifferences = (original: Product): ProductChangeSet => {
    const diff: {
      filters: Optional<ProductFilter[]>;
      product_attributes: Optional<ProductAttribute[]>;
      product_images: Optional<ProductImage[]>;
      composition_details: Optional<ProductCompositionDetail[]>;
      dbid: string;
    } = {
      dbid: this.dbid,
      filters: undefined,
      product_attributes: undefined,
      product_images: undefined,
      composition_details: undefined,
    };

    let hasChanged = _.isEqual(this.product_attributes, original.product_attributes) === false;
    if (hasChanged) {
      diff.product_attributes = this.product_attributes;
    }

    hasChanged = _.isEqual(this.product_images, original.product_images) === false;
    if (hasChanged) {
      diff.product_images = this.product_images;
    }

    hasChanged = _.isEqual(this.composition_details, original.composition_details) === false;
    if (hasChanged) {
      diff.composition_details = this.composition_details;
    }

    hasChanged = _.isEqual(this.filters, original.filters) === false;
    if (hasChanged) {
      diff.filters = this.filters;
    }

    return diff;
  };

  static toDataPoints(products: Product[]) {
    const colors = [] as ProductColorDataPoint[];
    products.forEach(product => {
      const newColors = product.composition_details.map((c, index) => ({
        color_index: index,
        hex: c.hex,
        lab: c.adjusted_lab,
        product_group_id: product.collection_uuids[0],
        product_id: product.dbid,
      }));

      colors.push(...newColors);
    });

    return colors;
  }
}

export default Product;
