import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EmailValidation, Home, ResetPassword } from '../components';
import User from '@api/cas/user';

export default {
  UnauthedRouter({ onAuth }: { onAuth: (u: User) => void }) {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <img
            style={{ left: 0, position: 'absolute', width: '100%' }}
            src="https://d1uxfrxqms1qk3.cloudfront.net/uploads/6A79E02A-2D5C-48E6-86BE-A33FE61B3295/cloud_banner_bg.svg"
            alt="Variable Cloud Logo"
          />
          <div style={{ alignSelf: 'center', margin: '12.5%', width: 512 }}>
            <img style={{ width: '100%' }} src="/ColorCloudLogo.svg" alt="Variable Cloud Logo" />
          </div>
        </div>

        <Switch>
          <Route exact path="/account/verify-email" key="account-verification">
            <div style={{ marginTop: 110 }}>
              <EmailValidation />
            </div>
          </Route>

          <Route exact path="/account/reset-password" key="account-pw-reset">
            <ResetPassword />
          </Route>

          <Route path="*" key="default">
            <Home onAuth={onAuth} />
          </Route>
        </Switch>
      </div>
    );
  },
};
