import { Illuminant, Observer } from '@variablecolor/colormath';
import ADBase, { BaseModelJSON } from './ADBase';
import PaletteItem from './PaletteItem';

export interface PaletteJSON extends BaseModelJSON {
  lastViewedAt: string;

  notes: string;
  name: string;
}

export default class Palette extends ADBase {
  name: string;

  notes: string;

  lastViewedAt: string;

  paletteItems: PaletteItem[];
  paletteItemCount: number;

  //{name= '',notes = ''} ={}
  constructor(json: PaletteJSON) {
    super(json);
    this.name = json.name || '';
    this.notes = json.notes || '';
    this.lastViewedAt = json.lastViewedAt;

    this.paletteItems = [];
    this.paletteItemCount = 0;
  }

  get image_url() {
    return this.paletteItems.find(x => x.images().length > 0)?.images()[0];
  }

  get hex_colors(): string[] {
    return this.paletteItems.reduce((arr, item) => {
      return [...arr, ...item.colors().map(c => c.labColor(Illuminant.D50, Observer.TWO_DEGREE).hex())];
    }, [] as string[]);
  }

  //Palettes From AppData JSON
  toJSONWithItems = () => ({
    paletteItems: this.paletteItems.map(x => x.toServerJSON()),
    ...this.toServerJSON(),
  });

  //convert to server format for saving back to disk (and eventually upsyncing)
  toServerJSON = () => ({
    ...this.baseToServerJSON(),
    lastViewedAt: this.lastViewedAt,
    name: this.name,
    notes: this.notes,
  });
}
