import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import ProductPreview from '@components/products/ProductPreview';
import { useHistory } from 'react-router';
import routeUtils from '@utils/route-utils';
import theme from '@theme';
import { AlternateText, PrimaryText } from '@components/common';

const Cell = styled.div`
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.18);
  background-color: ${theme.card.alternateBackground};

  width: ${96 + 3 * 2}px;

  margin: 9px;
  padding: 3px;

  &:hover {
    border-style: solid;
    border-color: ${theme.table.hoverBackground};
    border-width: 3px;
    padding: 0px;
    border-radius: 2px;
  }
`;

type ViewProps = {
  title: string;
  item: {
    uuid: string;
    locallyUpdatedAt: string;
    readonly hex_colors: string[];
    readonly image_url?: string;
  };
  dragData?: any;
  onClick: 'Scan' | ((uuid: string) => void);
};

function onDragStart(e: React.DragEvent, data: any) {
  if (!e.dataTransfer) {
    console.log('browser is not supported for drag and drop');
    return;
  }

  e.dataTransfer.effectAllowed = 'all';
  e.dataTransfer.setData('text/plain', JSON.stringify(data));
}

export default function ({ title, item, dragData, onClick }: ViewProps) {
  const history = useHistory();
  const handleClick = React.useCallback(() => {
    if (onClick === 'Scan') {
      history.push(routeUtils.scanDetails(item.uuid));
    } else {
      onClick(item.uuid);
    }
  }, [history, item, onClick]);

  const handleDragStart = React.useCallback(
    (e: React.DragEvent) => {
      if (dragData) {
        onDragStart(e, dragData);
      }
    },
    [dragData],
  );

  return (
    <Cell onClick={handleClick} draggable onDragStart={handleDragStart}>
      <ProductPreview height={96} hex_colors={item.hex_colors} image_url={item.image_url} />

      <PrimaryText single fontSize="11px" textAlign="center">
        {title}
      </PrimaryText>
      <AlternateText single textAlign="center" fontSize="11px">
        {moment(item.locallyUpdatedAt).format('MMM DD, YYYY')}
      </AlternateText>
      <AlternateText single textAlign="center" fontSize="11px">
        {moment(item.locallyUpdatedAt).format('h:mm A')}
      </AlternateText>
    </Cell>
  );
}
