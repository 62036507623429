import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, FlexRows, Loading, ModuleSegment, PrimaryText } from '@components/common';
import vpapi from '@api/vpapi';
import { Product, ProductGroup } from '@api/vpapi/models';
import type { ProductColorDataPoint } from '@utils/three/ThreeContainer';
import theme from '@theme';
import { Optional } from '@app/utils/types';

const ThreeContainer = React.lazy(() => import('../../utils/three/ThreeContainer'));
type ViewProps = {
  productGroupID: string;
  history: ReturnType<typeof useHistory>;
};

type ViewState = {
  colors?: ProductColorDataPoint[];
  loading?: boolean;
  selectedItem?: ProductColorDataPoint;
};

function SelectedItem({ item }: { item?: ProductColorDataPoint }) {
  const history = useHistory();
  if (!item) {
    return null;
  }

  return (
    <FlexRows
      style={{
        alignItems: 'center',
        backgroundColor: theme.table.rowBackground,
        left: '1%',
        margin: 12,
        position: 'absolute',
        top: '1%',
        width: 256,
      }}
      onClick={() => {
        history.push(`/admin/product-groups/${item.product_group_id}/products/edit/${item.product_id}`);
      }}
    >
      <div
        style={{
          backgroundColor: item.hex,
          height: 128,
          width: 256,
        }}
      />

      <PrimaryText style={{ padding: 18 }} fontWeight="bold">
        View Product
      </PrimaryText>
    </FlexRows>
  );
}
class ProductPlotter extends React.Component<ViewProps, ViewState> {
  state = {
    colors: undefined as Optional<ProductColorDataPoint[]>,
    loading: true,
    selectedItem: undefined,
  };

  get productGroupID(): Optional<string> {
    return this.props.productGroupID;
  }
  addColors = (product: Product) => {
    const newColors = product.composition_details.map((c, index) => ({
      color_index: index,
      hex: c.hex,
      lab: c.adjusted_lab,
      product_group_id: product.collection_uuids[0],
      product_id: product.dbid,
    }));

    const { colors } = this.state;
    if (colors) {
      colors.push(...newColors);
    }
  };
  loadProducts = async (productGroup: ProductGroup) => {
    if (productGroup.product_count === 0) {
      return;
    }
    const products = await vpapi.productGroups.fetchAllProducts(productGroup.dbid, productGroup.product_count);

    const colours = Product.toDataPoints(products);
    const { colors } = this.state;
    if (colors) {
      colors.push(...colours);
    }

    this.forceUpdate();
  };

  fetchProductGroups = async (): Promise<ProductGroup[]> => {
    if (this.productGroupID) {
      const productGroup = await vpapi.productGroups.getProductGroup(this.productGroupID);
      return [productGroup];
    }

    return (await vpapi.productGroups.getAll({ from: 0, size: 10000 })).docs;
  };
  componentDidMount = async () => {
    const productGroups = await this.fetchProductGroups();

    this.setState({ colors: [] });
    await Promise.all(productGroups.filter(p => p.product_count > 0).map(this.loadProducts));

    this.setState({
      loading: undefined,
    });
  };

  handleColorClick = (selectedItem?: ProductColorDataPoint) => {
    this.setState({
      selectedItem,
    });
  };

  handleBackClick = () => {
    const { history } = this.props;
    history.goBack();
  };

  get moduleButtons() {
    return [<Button key="back" outline color={theme.button.cancelColor} text="Back" onClick={this.handleBackClick} />];
  }

  render = () => {
    const { colors, loading, selectedItem } = this.state;
    if (loading || !colors) {
      return (
        <ModuleSegment containerStyle={{ margin: theme.dimensions.contentPadding }} title="Color Vizualization">
          <Loading text="Loading Product Libraries, Products, and Colors" />
        </ModuleSegment>
      );
    }

    return (
      <ModuleSegment
        title="Color Vizualization"
        buttons={this.moduleButtons}
        containerStyle={{ margin: theme.dimensions.contentPadding }}
      >
        <div
          style={{
            height: '100%',
            minHeight: '100vh',
            position: 'relative',
            width: '100%',
          }}
        >
          <SelectedItem item={selectedItem} />
          <ThreeContainer productColors={colors} onClick={this.handleColorClick} />
        </div>
      </ModuleSegment>
    );

    // return (
    //   <div style={{backgroundColor: 'white', padding: 12}}>
    //     <ScatterPlot
    //       width={1024}
    //       height={1024}
    //       padding={4}
    //       data={colors}
    //     />
    //   </div>
    // );
  };
}

export default function __Page() {
  const history = useHistory();
  const { productGroupID } = useParams<{ productGroupID: string }>();

  return <ProductPlotter history={history} productGroupID={productGroupID} />;
}
