import * as React from 'react';
import qs from 'query-string';
import theme from '@theme';
import validator from 'email-validator';
import cas from '@api/cas';
import { Purchase } from '@api/cas/models';
import { useHistory, useLocation } from 'react-router';
import { Button, Flash, FlashContentT, LabeledInput, ModuleSegment } from '@components/common';
import { Optional } from '@app/utils/types';

type ViewProps = {
  onPurchases: (p: Purchase[]) => void;
  showBackButton?: boolean;
};

type LookupState = {
  orderID: string;
  email: string;
  error?: FlashContentT;
};

const defaultLookupState = {
  email: '',
  error: undefined,
  orderID: '',
};

function validateState(state: LookupState): Optional<FlashContentT> {
  if (state.orderID.length < 3) {
    return {
      color: 'red',
      message: 'Please specify a valid order id.',
    };
  }

  if (!validator.validate(state.email)) {
    return {
      color: 'red',
      message: 'Please specify a coomplete email address.',
    };
  }

  return undefined;
}

type HandlerProps = {
  lookupInfo: LookupState;
  onChange: (s: LookupState) => void;
  onPurchases: (items: Purchase[]) => void;

  history: ReturnType<typeof useHistory>;
  location: ReturnType<typeof useLocation>;
};
function handleClick({ lookupInfo, onChange, onPurchases, history, location }: HandlerProps) {
  return async () => {
    const error = validateState(lookupInfo);
    if (error) {
      onChange({ ...lookupInfo, error });
      return;
    }

    try {
      const purchases = await cas.users.getPurchases({
        filter: { email: lookupInfo.email, order_id: lookupInfo.orderID, pos: 'shopify' },
      });
      if (purchases.length === 1) {
        onPurchases(purchases);

        const params = qs.parse(location.search);
        delete params.a;

        params.order_id = lookupInfo.orderID;
        params.code = purchases[0].code;
        params.pos = purchases[0].source_type;
        params.email = purchases[0].email;

        history.replace(`${location.pathname}?${qs.stringify(params)}`);
      }
    } catch (err: any) {
      if (err.message === 'vi-not-found') {
        onChange({
          ...lookupInfo,
          error: {
            color: 'red',
            message: 'Unable to locate order',
          },
        });
      } else {
        onChange({
          ...lookupInfo,
          error: err.flashProps,
        });
      }
      console.warn(err);
    }
  };
}
export default function ({ showBackButton, onPurchases }: ViewProps) {
  const history = useHistory();
  const location = useLocation();

  const [lookupInfo, setLookupInfo] = React.useState<LookupState>(defaultLookupState);
  const buttons = [
    <Button
      key="order-finder"
      color={theme.button.actionColor}
      text="Find Order"
      onClick={handleClick({
        history,
        location,
        lookupInfo,
        onChange: setLookupInfo,
        onPurchases,
      })}
    />,
  ];

  if (showBackButton) {
    buttons.push(<Button color={theme.button.cancelColor} outline text="Back" onClick={history.goBack} />);
  }
  return (
    <ModuleSegment
      title={ModuleSegment.ModuleTitle('Order Lookup', 'Provide the information below to find your external purchase')}
      buttons={buttons}
    >
      <Flash content={lookupInfo.error} />
      <LabeledInput
        label="OrderId"
        onChange={(_: React.SyntheticEvent<HTMLInputElement>, { value }: { label: string; value: any }) => {
          setLookupInfo({ ...lookupInfo, orderID: value });
        }}
        inputProps={{
          onKeyPress: e => {
            if (e.key !== 'Enter') {
              return;
            }

            const err = validateState(lookupInfo);
            if (!err) {
              handleClick({
                history,
                location,
                lookupInfo,
                onChange: setLookupInfo,
                onPurchases,
              })();
            }
          },
          placeholder: 'Enter the order id sent in the purchase confirmation email',
          value: lookupInfo.orderID,
        }}
      />
      <LabeledInput
        label="Email"
        onChange={(_, { value }) => {
          setLookupInfo({ ...lookupInfo, email: value });
        }}
        inputProps={{
          onKeyPress: e => {
            if (e.key !== 'Enter') {
              return;
            }

            const err = validateState(lookupInfo);
            if (!err) {
              handleClick({
                history,
                location,
                lookupInfo,
                onChange: setLookupInfo,
                onPurchases,
              })();
            }
          },
          placeholder: 'Enter the email used to place the purchase',
          value: lookupInfo.email,
        }}
      />
    </ModuleSegment>
  );
}
