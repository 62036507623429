import { Optional } from './types';

const maxNumItems = 1000000;

export type PagerParams = {
  perPage: number;
  numItems: number;
  page?: number;
  windowSize?: number;
};
class Pager {
  _perPage: number;
  _numItems: number;
  _windowSize: number;
  _page: number;

  /**
   * Creates a new pager
   * @param {Number} perPage The number of items to show on each page
   * @param {Number} numItems The total number of items
   */
  constructor({ perPage, numItems = 0, page = 1, windowSize = 5 }: PagerParams) {
    this._perPage = Math.round(perPage);
    this._numItems = Math.min(maxNumItems, Math.round(numItems));
    this._windowSize = Math.max(1, Math.round(windowSize));
    this._page = Math.max(1, page);
  }

  static fromParams = (perPage: number) =>
    new Pager({
      numItems: 0,
      page: 1,
      perPage,
      windowSize: 5,
    });

  update = ({ page = 1 }: { page: Optional<number | string> }) => {
    this.page = parseInt(page.toString(), 10);
  };

  get hasMore() {
    return this._perPage * this._page < this._numItems;
  }

  get windowSize(): number {
    return this._windowSize;
  }

  set windowSize(n: number) {
    this._windowSize = n;
  }

  get perPage(): number {
    return this._perPage;
  }

  get numItems(): number {
    return this._numItems;
  }

  set numItems(n: number) {
    this._numItems = Math.min(maxNumItems, Math.round(n));
  }

  get pages() {
    const current = this.page;
    const last = this.numPages;
    const delta = Math.ceil(this._windowSize);
    let left = current - delta;

    let right = current + delta;
    if (current < 2 * delta - 1) {
      right = 2 * delta + 1;
      left = 1;
    } else if (right > last) {
      left = last - 2 * delta;
    }

    const pageNumbers = [];

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i > left && i < right)) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers.map((i, arrayIndex) => {
      if (right < last && arrayIndex === pageNumbers.length - 1) {
        return { display: false, page: (i + i + 1) / 2.0 };
      }
      if (right < last && i === 2) {
        return { display: false, page: (i - 1 + i) / 2.0 };
      }

      return { display: true, page: i };
    });
  }

  nextPage() {
    if (!this.hasMore) {
      return;
    }

    this.page += 1;
  }

  get page() {
    return this._page;
  }

  set page(n: number) {
    this._page = Math.max(Math.min(n, this.numPages), 1) || 1;
  }

  get from() {
    return (this.page - 1) * this.perPage;
  }

  get to() {
    return this.from + (this.perPage - 1);
  }

  get numPages() {
    return Math.max(Math.ceil(this.numItems / this.perPage), 1);
  }

  pageIsValid() {
    return (this._page - 1) * this._perPage + 1 <= this._numItems;
  }
}

export default Pager;
