import * as React from 'react';

import cas from '@api/cas';
import { NavLink, useHistory } from 'react-router-dom';
import routeUtils from '@utils/route-utils';
import theme from '@theme';
import {
  AccessControlQuad,
  AlternateText,
  Button,
  Flash,
  FlashContentT,
  FlexColumns,
  FlexRows,
  LabeledInput,
  ModuleSegment,
  SystemAdminOnly,
} from '@components/common';
import ModelStatistics from './ModelStatistics';
import type { Colorant, FormulationGroup, Base } from '@api/formulation/models';
import { FormulationGroupInfoContainer, renderColorant } from './styled-components';

import api from '@api/formulation';
import { useEnterKeyPress } from '@app/components/common/Input';
import User from '@api/cas/user';

function ModuleTitle({ title, subTitle }: { title: string; subTitle: string }) {
  return () => (
    <FlexRows>
      <ModuleSegment.TitleText>{title}</ModuleSegment.TitleText>
      <ModuleSegment.SubTitleText>{`${subTitle}`}</ModuleSegment.SubTitleText>
    </FlexRows>
  );
}

type ViewState = {
  flash?: FlashContentT;
  modal?: 'confirm_delete';
};

type ExternalProps = {
  group: FormulationGroup;
  onFormulationGroupChange: (group: FormulationGroup) => void | any;
};

type ViewProps = ExternalProps & {
  user: User;
  history: ReturnType<typeof useHistory>;
};

function Colorants({
  group,
  onGroupChange,
}: {
  onGroupChange: (g: FormulationGroup) => void | any;
  group: FormulationGroup;
}) {
  const [selectedColorant, setSelected] = React.useState<Colorant | null>(null);
  const [input, setColorantName] = React.useState<string>('');
  if (group.colorant_lookup.length === 0) {
    return null;
  }

  return (
    <ModuleSegment title="Colorants" containerStyle={{ marginLeft: theme.dimensions.invertedContentPadding }}>
      {selectedColorant && (
        <LabeledInput
          label={selectedColorant.id}
          onChange={e => {
            setColorantName(e.currentTarget.value);
          }}
          inputProps={{
            onBlur() {
              setSelected(null);
              setColorantName('');
            },
            onKeyDown: e => {
              if (e.key !== 'Enter') {
                return;
              }

              const c = group.colorant_lookup.find(x => x.id === selectedColorant.id);
              if (c) {
                c.name = input;
                onGroupChange(group);
              }
              setSelected(null);
              setColorantName('');
            },
            placeholder: 'Enter name for colorant id.',
            value: input,
          }}
        />
      )}
      <FlexColumns style={{ flexWrap: 'wrap' }}>
        {group.colorant_lookup.map((x: Colorant) =>
          renderColorant({
            ...x,
            onClick() {
              setSelected(x);
            },
            selected: selectedColorant?.id === x.id,
          }),
        )}
      </FlexColumns>
    </ModuleSegment>
  );
}

function Bases({
  group,
  onGroupChange,
}: {
  onGroupChange: (g: FormulationGroup) => void | any;
  group: FormulationGroup;
}) {
  const [selectedBase, setSelected] = React.useState<Base | null>(null);
  const [input, setBaseName] = React.useState<string>('');
  const onKeyDown = useEnterKeyPress(_value => {
    if (selectedBase) {
      const c = group.base_lookup.find(x => x.id === selectedBase.id);
      if (c) {
        c.name = input;
        onGroupChange(group);
      }
      setSelected(null);
      setBaseName('');
    }
  });
  if (group.base_lookup.length === 0) {
    return null;
  }

  return (
    <ModuleSegment title="Bases" containerStyle={{ marginLeft: theme.dimensions.invertedContentPadding }}>
      {selectedBase && (
        <LabeledInput
          label={selectedBase.id}
          onChange={e => {
            setBaseName(e.currentTarget.value);
          }}
          inputProps={{
            onKeyDown,
            placeholder: 'Enter name for base id, and press enter to save',
            value: input,
            onBlur: () => {
              setSelected(null);
              setBaseName('');
            },
          }}
        />
      )}
      <FlexColumns style={{ flexWrap: 'wrap' }}>
        {group.base_lookup.map((x: Base) =>
          renderColorant({
            ...x,
            onClick() {
              setSelected(x);
            },
            selected: selectedBase?.id === x.id,
          }),
        )}
      </FlexColumns>
    </ModuleSegment>
  );
}

class GroupSettings extends React.Component<ViewProps, ViewState> {
  state = {
    flash: undefined,
    modal: undefined,
  };

  dismissModal = () => {
    this.setState({
      modal: undefined,
    });
  };

  handleDeleteClick = async () => {
    const {
      group: { id },
      history,
    } = this.props;
    await api.formulation_groups.delete(id);

    history.replace(routeUtils.formulationGroups());
  };
  askUserForDeletion = () => {
    this.setState({
      modal: 'confirm_delete',
    });
  };
  get buttons() {
    const { group } = this.props;
    return [
      <NavLink key="view-products" to={routeUtils.formulationGroupDetail(group.id)}>
        <Button outline color={theme.button.cancelColor} text="Close" />
      </NavLink>,
      <Button
        key="publish-library"
        text="Publish"
        outline
        color={theme.button.alternativeActionColor}
        onClick={this.handlePublish}
      />,
      <Button
        key="refresh-library"
        text="Refresh"
        outline
        color={theme.button.alternativeActionColor}
        onClick={this.handleRefresh}
      />,
      <Button
        key="train-library"
        text="Train"
        outline
        color={theme.button.createColor}
        onClick={this.handleTrainLibraryClick}
      />,
      <Button
        key="delete-library"
        text="Delete Library"
        onClick={this.askUserForDeletion}
        color={theme.button.cancelColor}
      />,
    ];
  }

  handlePublish = () => {
    const {
      group: { id },
    } = this.props;
    api.formulation_groups.publish(id);
  };

  handleRefresh = () => {
    const {
      group: { id },
    } = this.props;
    api.formulation_groups.refresh(id);
  };
  handleTrainLibraryClick = async () => {
    const { group } = this.props;
    await api.formulation_groups.train(group.id);

    this.setState({
      flash: {
        color: 'green',
        message: 'Training request has been acknowledged.',
      },
    });
  };

  handleChange(field: 'name' | 'notes' | 'unit_standard' | 'unit_resolution' | 'native_sample_size') {
    return (_e: React.SyntheticEvent<HTMLInputElement>, data: { label: string; value: any }) => {
      const { group, onFormulationGroupChange } = this.props;
      if (field === 'unit_resolution') {
        data.value = parseFloat(data.value);
      }

      (group as any)[field] = data.value;

      onFormulationGroupChange(group);
    };
  }
  render = () => {
    const { group, user, onFormulationGroupChange } = this.props;

    const { flash, modal } = this.state;
    if (modal === 'confirm_delete') {
      return (
        <ModuleSegment title="Confirm Action">
          <AlternateText>
            Deleting this formulation library, results in deletion of all color formulas, products, and associated App
            usages. Are you sure you want to delete this library?
          </AlternateText>

          <FlexColumns style={{ justifyContent: 'flex-end', marginTop: 12 }}>
            <Button color="red" text="Yes, Delete" onClick={this.handleDeleteClick} />
            <Button color={theme.button.actionColor} outline text="Cancel" onClick={this.dismissModal} />
          </FlexColumns>
        </ModuleSegment>
      );
    }
    return (
      <ModuleSegment
        title={ModuleTitle({
          subTitle: group.name,
          title: 'Formulation Library',
        })}
        buttons={this.buttons}
      >
        <Flash content={flash} />
        <ModuleSegment
          title="Details"
          containerStyle={{
            marginLeft: theme.dimensions.invertedContentPadding,
          }}
        >
          <FormulationGroupInfoContainer onChange={this.handleChange} group={group} user={user} />
        </ModuleSegment>

        <Colorants group={group} onGroupChange={onFormulationGroupChange} />
        <Bases group={group} onGroupChange={onFormulationGroupChange} />

        <ModelStatistics padding={0} models={group.models} />

        <div style={{ margin: theme.dimensions.invertedContentPadding }}>
          <AccessControlQuad
            accessType="formulations"
            title="Access Control"
            isOwnedByCurrentUser={api.formulation_groups.isOwnedByCurrentUser(group)}
            permissions={group.permissions}
            handleRevokeUserAccess={this.handleRevokeUserAccess}
            handleAddOwner={this.handleAddOwner}
            handleAddContributor={this.handleAddContributor}
            handleTransferOwner={undefined}
          />
        </div>
      </ModuleSegment>
    );
  };

  handleRevokeUserAccess = async (email: string) => {
    try {
      const { group } = this.props;

      await cas.revokeProductGroupAccess({
        email,
        id: group.id,
        type: 'formulation-library',
      });

      const { permissions } = group;
      group.permissions = permissions.filter(p => p.email !== email);
    } finally {
      this.forceUpdate();
    }
  };
  handleAddOwner = async (email: string) => {
    try {
      const { group } = this.props;

      await cas.addProductGroupOwner({
        email,
        id: group.id,
        type: 'formulation-library',
      });

      const { permissions } = group;
      const p = permissions.find(perm => perm.email === email);
      if (p) {
        p.permissions = { is_owner: 1 };
      } else {
        permissions.push({
          email,
          permissions: {
            is_owner: 1,
          },
        });
      }
    } finally {
      this.forceUpdate();
    }
  };
  handleAddContributor = async (email: string) => {
    try {
      const { group } = this.props;

      await cas.addProductGroupContributor({
        email,
        id: group.id,
        type: 'formulation-library',
      });

      const { permissions } = group;
      const p = permissions.find(perm => perm.email === email);
      if (p) {
        p.permissions = { is_contributor: 1 };
      } else {
        permissions.push({
          email,
          permissions: {
            is_contributor: 1,
          },
        });
      }
    } finally {
      this.forceUpdate();
    }
  };
}

export default function __Page(props: ExternalProps) {
  const history = useHistory();
  const user = User.load();
  if (!user) {
    return null;
  }
  return (
    <SystemAdminOnly>
      <GroupSettings history={history} user={user} {...props} />
    </SystemAdminOnly>
  );
}
