import Product from './Product';
import { ProductCompositionDetail } from './ProductCompositionDetail';
import { ProductAttribute } from './ProductAttribute';
import MeasuredColor from './MeasuredColor';

export type { MeasuredColorJSON } from './MeasuredColor';

export type {
  EditableResponse,
  EditableSearchRequest,
  EditableChangeSet,
  EditableType,
  EditableItem,
  Editable,
} from './Editables';

import ProductGroup from './ProductGroup';
export type { ProductImage } from './Product';

export type { ProductGroup, ProductAttribute };

export default {
  MeasuredColor,
  Product,
  ProductCompositionDetail,
};

export { ProductCompositionDetail, Product, MeasuredColor };
