import * as React from 'react';
import {
  AlternateText,
  Button,
  Flash,
  FlashContentT,
  FlexColumns,
  FlexRows,
  IconInput,
  LabeledInput,
  Modal,
  ModuleSegment,
} from '../common';

import theme from '@theme';
import { UserJSON } from '@api/cas/models';
import cas from '@api/cas';

type ViewState = {
  isChangingPassword: boolean;
  old_password: string;
  password: string;
  password_2: string;
  flash?: FlashContentT;
  modalType?: 'delete';
};
export default class AccountDetailQuad extends React.Component<{ profile: UserJSON }, ViewState> {
  state = {
    flash: undefined,
    isChangingPassword: false,
    modalType: undefined,
    old_password: '',
    password: '',
    password_2: '',
  };

  handleClear = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    this.setState({
      isChangingPassword: false,
      old_password: '',
      password: '',
      password_2: '',
    });
  };

  handleSave = () => {
    const { profile } = this.props;

    cas.users.updateProfile(profile);
  };

  handleAccountDeletion = async () => {
    try {
      const { password } = this.state;
      await cas.users.delete({ password });

      // const { history } = this.props;
      // history.replace('/login');
      window.location = window.location;
    } catch (err: any) {
      this.setState({
        flash: err.flashProps,
      });
    }

    this.showModal(undefined);
  };

  handleSubmitPassword = async () => {
    const { old_password, password, password_2 } = this.state;

    const { profile } = this.props;
    if (profile && profile.user) {
      (profile.user as any) = {
        ...profile.user,
        old_password,
        password,
        password_2,
      };
    }

    try {
      if (password !== password_2) {
        this.setState({
          flash: {
            color: 'red',
            message: 'New passwords do not match',
          },
        });
        return;
      }
      await cas.users.updateProfile(profile);

      this.setState({
        flash: undefined,
        isChangingPassword: false,
        old_password: '',
        password: '',
        password_2: '',
      });
    } catch (err: any) {
      if (err.flashProps) {
        this.setState({
          flash: err.flashProps,
          isChangingPassword: false,
        });
        return;
      }
      this.setState({
        flash: {
          color: 'red',
          message: err.message,
        },
        isChangingPassword: false,
      });
    }
  };

  toggle = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const { isChangingPassword } = this.state;
    this.setState({ isChangingPassword: !isChangingPassword });
  };

  showModal = (modalType?: 'delete') => () => {
    this.setState({
      modalType,
    });
  };

  get moduleButtons() {
    const { isChangingPassword } = this.state;
    const buttons = [];
    if (!isChangingPassword) {
      buttons.push(
        <Button
          key="change_password"
          onClick={this.toggle}
          type="submit"
          color={theme.button.neutralColor}
          text="Change Password"
        />,
      );
    }

    buttons.push(
      <Button
        key="delete"
        onClick={this.showModal('delete')}
        color={theme.button.cancelColor}
        outline
        text="Delete Account"
      />,
    );

    return buttons;
  }

  Modal = () => {
    const { modalType } = this.state;
    if (modalType === 'delete') {
      return (
        <Modal modalProps={{ defaultOpen: true }}>
          <ModuleSegment title="Confirm Delete Action">
            <AlternateText>
              Deleting your account is an irreversible action. Your Organizations, Product Libraries, App Package,
              subscriber to the App Packages, and Saved Folders will be deleted.
            </AlternateText>
            <LabeledInput
              id="password"
              onChange={({ currentTarget: { value } }) => {
                this.setState({ flash: undefined, password: value });
              }}
              label="Password"
              inputProps={{
                placeholder: 'Enter your password',
              }}
            />
            <AlternateText>Are you sure you want to delete your account?</AlternateText>

            <Button
              style={{ float: 'right' }}
              outline
              color={theme.button.actionColor}
              text="Yes, Delete Account"
              onClick={this.handleAccountDeletion}
            />

            <Button
              style={{ float: 'right' }}
              outline
              color={theme.button.cancelColor}
              text="No, Cancel"
              onClick={this.showModal()}
            />

            <div style={{ height: 9 }} />
          </ModuleSegment>
        </Modal>
      );
    }
    return null;
  };

  ProfileDetails = () => {
    const { isChangingPassword } = this.state;
    if (isChangingPassword) {
      return null;
    }

    const {
      profile: { user },
    } = this.props;
    return (
      <div>
        <LabeledInput
          inputProps={{
            disabled: true,
            placeholder: 'Email',
            type: 'email',
            value: user.email,
          }}
          id="email"
          label="EMAIL"
        />

        <LabeledInput
          inputProps={{
            placeholder: 'Enter your name',
            type: 'name',
            value: user.nickname,
          }}
          label="FULL NAME"
          id="nickname"
          onChange={(_e: React.SyntheticEvent<HTMLElement>, { value }) => {
            this.props.profile.user.nickname = value as string;

            this.setState({ flash: undefined }, this.handleSave);
          }}
        />
        <LabeledInput
          inputProps={{
            placeholder: 'Enter your company',
            type: 'text',
            value: user.company,
          }}
          label="COMPANY"
          id="company"
          onChange={(_e: React.SyntheticEvent<HTMLElement>, { value }) => {
            this.props.profile.user.company = value as string;

            this.setState({ flash: undefined }, this.handleSave);
          }}
        />
      </div>
    );
  };

  ChangePassword = () => {
    const { isChangingPassword } = this.state;
    if (!isChangingPassword) {
      return null;
    }

    const { password_2, password, old_password } = this.state;

    return (
      <FlexRows>
        <IconInput
          label="old_password"
          inputProps={{
            placeholder: 'Current Password',
            type: 'password',
            value: old_password,
          }}
          name="asterisk"
          onChange={({ currentTarget: { value } }) => {
            this.setState({ flash: undefined, old_password: value });
          }}
        />
        <IconInput
          label="password"
          containerStyle={{ marginTop: 12 }}
          inputProps={{
            placeholder: 'New Password',
            type: 'password',
            value: password,
          }}
          name="asterisk"
          onChange={({ currentTarget: { value } }) => {
            this.setState({ flash: undefined, password: value });
          }}
        />

        <IconInput
          label="password_2"
          containerStyle={{ marginTop: 12 }}
          inputProps={{
            placeholder: 'Confirm New Password',
            type: 'password',
            value: password_2,
          }}
          name="asterisk"
          onChange={({ currentTarget: { value } }) => {
            this.setState({ flash: undefined, password_2: value });
          }}
        />

        <FlexColumns style={{ margin: 12 }}>
          <Button
            onClick={this.handleSubmitPassword}
            color={theme.button.actionColor}
            type="submit"
            text="Change Password"
          />
          <Button onClick={this.handleClear} type="reset" color={theme.button.cancelColor} outline text="Cancel" />
        </FlexColumns>
      </FlexRows>
    );
  };

  render = () => {
    const { flash } = this.state;
    const { Modal: DeleteModal, ProfileDetails, ChangePassword } = this;
    return (
      <ModuleSegment title="My Profile" buttons={this.moduleButtons}>
        <DeleteModal />
        <Flash content={flash} />
        <ProfileDetails />
        <ChangePassword />
      </ModuleSegment>
    );
  };
}
