import * as React from 'react';
import { Popup } from 'semantic-ui-react';

import { PackageDetail } from '@api/cas/models';

import {
  AlternateText,
  AppVisibilityIcon,
  FlexColumns,
  LabeledInput,
  ModuleSegment,
  SystemAdminOnly,
} from '@components/common';
import cas from '@api/cas';
import packageUtils from '@api/packageUtils';

type ViewProps = {
  packageDetail: PackageDetail;
};

const Trigger = (
  <div style={{ position: 'absolute', right: 8 }}>
    <AppVisibilityIcon alt="" />
  </div>
);

const Description = 'Controls product content for a product preview inside of a Variable Cloud compatible app.';
export default function SearchPreviewQuad({ packageDetail }: ViewProps) {
  const [_1, forceUpdate] = React.useReducer(x => x + 1, 0);
  console.log(_1);
  const handleChange = React.useCallback(
    ({ currentTarget: { id } }: React.SyntheticEvent<HTMLInputElement>, { value }: { value: string }) => {
      const values = packageUtils.getProductPreviewExtra(packageDetail);

      (values as any)[id] = value;

      cas.updatePackage(packageDetail);

      forceUpdate();
    },
    [packageDetail, forceUpdate],
  );

  const values = packageUtils.getProductPreviewExtra(packageDetail);

  return (
    <SystemAdminOnly>
      <ModuleSegment title={ModuleSegment.ModuleTitle('Product Preview', Description)} containerStyle={{ flexGrow: 1 }}>
        <div style={{ flex: 1, margin: 'auto', width: 512 }}>
          <img src="/images/product_preview.svg" alt="Product Preview within app" />
        </div>

        <AlternateText>
          When creating an alternate product preview, please note that the mobile app, only looks at attributes on a
          product, and does take into account filters. Additionally, the defaults or fallbacks (when the attribute is
          not present) are listed by hovering over the eye icon on the right side.
        </AlternateText>
        <br />
        <AlternateText>Each input can use one format:</AlternateText>
        <AlternateText>
          &ensp; 1. Single attribute is displayed, enter a valid attribute key name. <i>collection</i>
        </AlternateText>
        <AlternateText>
          &ensp; 2. Multiple attributes need to be displayed use this placeholder syntax.{' '}
          <i>
            {'{collection}'} in {'{category}'}
          </i>
          . The word `in` is a static word, this means that it will show up for all products.
        </AlternateText>
        <div style={{ flex: 1 }}>
          <FlexColumns style={{ alignItems: 'center', position: 'relative' }}>
            <Popup
              content={
                'This attribute controls what is the top and most prominant attribute. ' +
                'Defaults to name, and code if name is not present '
              }
              trigger={Trigger}
            />
            <LabeledInput
              id="header"
              inputProps={{
                value: values.header,
              }}
              onChange={handleChange}
              label="header attribute"
            />
          </FlexColumns>

          <FlexColumns style={{ alignItems: 'center', position: 'relative' }}>
            <Popup
              content="A secondary attribute. Defaults to vendor, and if vendor is not present, then brand. "
              trigger={Trigger}
            />
            <LabeledInput
              id="secondary"
              inputProps={{ value: values.secondary }}
              onChange={handleChange}
              label="line 1 attribute"
            />
          </FlexColumns>

          <FlexColumns style={{ alignItems: 'center', position: 'relative' }}>
            <Popup
              id="line_2"
              content={
                'An optional attribute, that is by default not used when a ' +
                'secondary attribute not used. When line 1 is present then defaults to collection. '
              }
              trigger={Trigger}
            />
            <LabeledInput
              id="line_3"
              inputProps={{ value: values.line_3 }}
              onChange={handleChange}
              label="line 2 attribute"
            />
          </FlexColumns>
        </div>
      </ModuleSegment>
    </SystemAdminOnly>
  );
}
