import * as React from 'react';
import qs from 'query-string';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import routeUtils from '@utils/route-utils';
import { Header } from '@components/tools/calculator/components';
import FormulationParser from '@utils/parser/csv';
import theme from '@theme';
import {
  AlternateText,
  Button,
  Flash,
  FlashContentT,
  FlexRows,
  Loading,
  ModuleSegment,
  StyledDropZone,
} from '@components/common';
import { renderItem } from './styled-components';
import type { FormulationGroup, FormulationProduct, ParsedFormulationProduct } from '@api/formulation/models';
import api from '@api/formulation';
import User from '@api/cas/user';

type ViewState = {
  progress?: string;

  flash?: FlashContentT;
};

type ViewProps = {
  location: ReturnType<typeof useLocation>;
  history: ReturnType<typeof useHistory>;
  group: FormulationGroup;
};

const requiredAttributes = [
  { description: 'The name of the brand of paint', name: 'attr.Brand Name' },
  {
    description: 'The name of the paint used in making an exact color.',
    name: 'attr.product_name',
  },
  {
    description: 'A customer friendly product id. It is commonly shown in mobile apps.',
    name: 'attr.product_id',
  },
  {
    description: 'Valid values are as following, but not limited to: Matte, Glossy, High Gloss, Eggshell',
    name: 'attr.sheen',
  },
  {
    description: 'Paint base is the type of fluid to make up the base. (e.g. oil, latex, water)',
    name: 'attr.paint_base',
  },
];
const sampleSizeInfo = [
  {
    description: 'Indicate size availability by marking YES or NO for each row (product)',
    name: 'sample_size.your_sample_size',
  },
];
const applications = [
  {
    description: 'Indicate product can be used on exterior surfaces by marking YES or NO for each row',
    name: 'application.exterior',
  },
  {
    description: 'Indicates product can be used on interior surfaces by marking YES or NO for each row (product)',
    name: 'application.interior',
  },
];
export default class FormulationProductUploader extends React.Component<ViewProps, ViewState> {
  state = {
    flash: undefined,
    progress: undefined,
  };

  downloadTemplate = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    window.location.href = '/templates/Formulations/product.template.csv';
  };

  get isNewGroup() {
    const { location } = this.props;
    const options = qs.parse(location.search);
    return options && options.t === 'n';
  }

  get buttons() {
    const buttons = [
      <Button
        key="download-template"
        text="Download Template"
        outline
        color={theme.button.alternativeActionColor}
        onClick={this.downloadTemplate}
      />,
    ];

    if (this.isNewGroup) {
      const {
        group,
        location: { search },
      } = this.props;
      buttons.push(
        <NavLink to={`${routeUtils.uploadColorFormulas(group.id)}${search}`} key="create-formulas">
          <Button outline text="Next (Color Formulas)" color={theme.button.createColor} />
        </NavLink>,
      );
    }

    return buttons;
  }

  upload = (products: FormulationProduct[]) => async () => {
    const {
      group: { id: groupID, name },
      history,
    } = this.props;
    const { error_message, ids } = await api.products.bulk(groupID, products);
    if (error_message) {
      this.setState({
        progress: 'Error in file format, and now, deleting uploaded color formulas . (Do not close this window)',
      });

      for (const id of ids) {
        await api.products.delete(groupID, id);
      }
      this.setState({
        flash: {
          color: 'red',
          message: `Upload failed. (Reason: ${error_message})`,
        },
        progress: undefined,
      });

      return;
    }

    if (!this.isNewGroup) {
      history.goBack();
    } else {
      this.setState({
        flash: {
          color: 'green',
          message: `Congratulations, you have added ${products.length} products to ${name}.`,
        },
      });
    }
  };

  handleParseComplete = (parsed_products: ParsedFormulationProduct[]) => {
    console.log('handleComplete', parsed_products);

    const {
      group: {
        customer: { _id: customer_id },
        id: formulation_group_id,
      },
    } = this.props;

    const now = new Date();
    const products = parsed_products.map(p => ({
      ...p,

      is_image_multicolor: false,

      created_at: now.toISOString(),
      updated_at: now.toISOString(),

      models: [],

      customer_id,
      formulation_group_id,
    }));

    this.setState(
      {
        progress: undefined,
      },
      this.upload(products as any),
    );
  };

  handleProgress = (/**p: ParsedFormulationProduct, count: number**/) => {
    // console.log('handleProgress');
  };
  handleDrop = (acceptedFiles: File[] /**, rejectedFiles: File[] */) => {
    if (acceptedFiles.length === 1) {
      const user = User.load();
      const parser = new FormulationParser<ParsedFormulationProduct>({
        productGroupID: this.props.group.id,
        file: acceptedFiles[0],
        file_type: 'formulation_product',
        onComplete: this.handleParseComplete,
        progressCallback: this.handleProgress,
        email: user?.email || '',
      });

      parser.start();

      this.setState({
        progress: 'parsing file contents for products',
      });
    }
  };

  Content = () => {
    const { progress } = this.state;

    if (progress) {
      return <Loading text={progress} />;
    }

    return (
      <FlexRows>
        <Header>What is a product?</Header>
        <AlternateText>
          A product is a list of attributes describing a physical good used when dispensing an exact color.
        </AlternateText>

        <Header>File Checklist</Header>
        {requiredAttributes.map(renderItem)}
        {sampleSizeInfo.map(renderItem)}
        {applications.map(renderItem)}
        {renderItem({
          description: 'An optional url location to use as a product image when displaying products to a user.',
          name: 'image.product',
        })}

        <br />
        <StyledDropZone multiple onDrop={this.handleDrop}>
          <AlternateText textAlign="center" style={{ marginBottom: 24 }}>
            Drop a csv to containg product data into this formulation library, and then then import will start
          </AlternateText>

          <div style={{ marginTop: 12 }} />

          <Button
            paddingLeft={45}
            paddingRight={45}
            padding={8}
            color={theme.button.alternativeActionColor}
            text="Choose File"
          />
        </StyledDropZone>
      </FlexRows>
    );
  };
  render() {
    const { Content } = this;
    const { flash } = this.state;
    return (
      <ModuleSegment
        title={ModuleSegment.ModuleTitle(
          'Upload Products',
          'Import products with ease, to get started with Variable Cloud formulations',
        )}
        buttons={this.buttons}
      >
        <Flash content={flash} />
        <Content />
      </ModuleSegment>
    );
  }
}
