import React from 'react';
import User from '@api/cas/user';
import { FlexColumns, PrimaryText, withUser } from '@components/common';
import packageUtils from '@api/packageUtils';
import theme from '@theme';
import { PackageDetail } from '@api/cas/models';

type ExternalProps = { packageDetail: PackageDetail };
type Props = ExternalProps & {
  user: User;
};

export default withUser<ExternalProps>(({ packageDetail, user }: Props) => {
  if (!packageDetail) {
    return null;
  }

  return (
    <FlexColumns style={{ alignItems: 'center' }}>
      {packageUtils.filterPlatforms(packageDetail, user).map(p => (
        <div
          key={`${p.id}`}
          style={{
            margin: 4,
            padding: 8,
            backgroundColor: theme.button.neutralColor,
            borderRadius: 8,
          }}
        >
          <PrimaryText style={{ justifyContent: 'center' }} fontSize="65%">
            {p.name}
          </PrimaryText>
        </div>
      ))}
    </FlexColumns>
  );
});
