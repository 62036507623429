// uses this only if the api is fully json complaint including errors.
import { deviceUUID } from '@utils';
import User from './cas/user';
import config from '../config';
import ApiError from './ApiError';
import { Optional } from '@app/utils/types';

function fetchInit(m = 'POST', options: { headers?: any }, body: any) {
  const user = User.load();

  return {
    headers: {
      Accept: 'application/json',
      CASP: config.getPlatformID(),
      CAST: config.api_token,
      'Content-Type': 'application/json',
      Device: deviceUUID(),
      'Session-Token': user?.session_token,

      // Allow for overriding ^^^ headers
      ...options.headers,
    },
    method: m,

    body: body ? JSON.stringify(body) : undefined,
  };
}
class JsonFetch {
  onSessionInvalidCallback?: () => void | any;

  async request<T>(
    m: 'POST' | 'GET' | 'PUT' | 'DELETE',
    url: string,
    bodyObj: Optional<any>,
    options: { headers: any },
  ) {
    const init = fetchInit(m, options, bodyObj);

    const res = await fetch(url, init);
    const json = await res.json();

    if (json.status_code === 401 && ['vi-invalid-session', 'vi-unauthorized-session'].includes(json.message)) {
      console.log('invalid-session');
      User.clearAuth();

      if (this.onSessionInvalidCallback) {
        this.onSessionInvalidCallback();
        throw new Error('');
      }
    }

    if (json.status_code) {
      throw new ApiError(json);
    }

    return json as T;
  }

  put<T>(url: string, bodyObj: any, headers = {}) {
    return this.request<T>('PUT', url, bodyObj, { headers });
  }

  post<T>(url: string, bodyObj: any, headers = {}) {
    return this.request<T>('POST', url, bodyObj, { headers });
  }

  get<T>(url: string, headers = {}) {
    return this.request<T>('GET', url, null, { headers });
  }

  del<T>(url: string, headers = {}) {
    return this.request<T>('DELETE', url, null, { headers });
  }
}

const api = new JsonFetch();
export default api;
