import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  AlternateText,
  Button,
  FlexRows,
  LargeButton,
  ModuleSegment,
  PrimaryText,
  ModalProps,
} from '@components/common';
import theme from '@theme';
import type { BrandDetail, PackageDetail } from '@api/cas/models';
import {
  NewProductGroupHelperForBrand,
  NewProductGroupHelperForPackage,
  ProductGroupHelper,
} from '@api/vpapi/models/ProductGroupHelper';
import ProductGroupSelector from './ProductGroupSelector';

const HelpModalInfo = {
  Content: () => (
    <div>
      <AlternateText>
        Product libraries house your products and specific product information. You can have products within a product
        library, and product libraries within your organization. Different product libraries could be used to
        distinguish between collections that make up your product library.
      </AlternateText>

      <br />
      <PrimaryText fontWeight="bold">To create a product library:</PrimaryText>
      <ul>
        <li>
          <AlternateText>- Name your product library</AlternateText>
        </li>
        <li>
          <AlternateText>- Import data from CSV file</AlternateText>
        </li>
        <li>
          <AlternateText>
            - Assign attributes to your products to make them searchable within Variable Cloud compatible app.
          </AlternateText>
        </li>

        <li>
          <AlternateText>
            - To ensure high color accuracy, using Cloud functionality within a Variable Cloud compatible app to scan
            your products.
          </AlternateText>
        </li>
      </ul>
    </div>
  ),
  title: 'Product Library Control Help',
};

type Props = ViewProps & {
  history: ReturnType<typeof useHistory>;
};
type ViewProps = {
  brand: BrandDetail;
  packageDetail?: PackageDetail;
  options?: {
    header: string;
    subHeader: string;
  };

  handleCreateProductsClick?: () => void | any;

  onChangeCommitted?: () => void | any;
};
type State = {
  pgHelper?: ProductGroupHelper;
  width?: number;
};

class ProductGroupAccessPreview extends React.Component<Props, State> {
  static defaultProps = {
    onChangeCommitted: undefined,
  };
  modalCreationRef = React.createRef<React.Component<ModalProps, any, any>>();
  state: State = {
    pgHelper: undefined,
    width: undefined,
  };

  ///#region React Lifecycle
  static getDerivedStateFromProps = (newProps: Props, prevState: State): State => {
    const newState = Object.assign(prevState, {});
    const { brand, packageDetail } = newProps;

    if (packageDetail) {
      if (prevState.pgHelper) {
        const { pgHelper } = prevState;
        if (pgHelper.backingModel.id !== packageDetail.id) {
          newState.pgHelper = NewProductGroupHelperForPackage(packageDetail);
        } else if (pgHelper.selectedGroups().length !== packageDetail.product_groups.length) {
          newState.pgHelper = NewProductGroupHelperForPackage(packageDetail);
        }
      } else {
        newState.pgHelper = NewProductGroupHelperForPackage(packageDetail);
      }
    } else if (brand) {
      if (prevState.pgHelper) {
        const { pgHelper } = prevState;
        if (pgHelper.backingModel.id !== brand.id) {
          newState.pgHelper = NewProductGroupHelperForBrand(brand);
        } else if (pgHelper.selectedGroups().length !== brand.product_groups.length) {
          newState.pgHelper = NewProductGroupHelperForBrand(brand);
        }
      } else {
        newState.pgHelper = NewProductGroupHelperForBrand(brand);
      }
    }

    return newState;
  };

  componentDidMount() {
    const { pgHelper } = this.state;
    if (pgHelper) {
      pgHelper.onCommit = this.onGroupsUpdated;
    }

    const doc = document.getElementById('product_group_selector');
    if (doc) {
      const width = doc.clientWidth;
      this.setState({ width });
    }
  }
  //#endregion

  ModuleTitle = () => {
    const { options } = this.props;
    const { pgHelper } = this.state;
    const headerTitle = options && options.header ? options.header : 'Product Libraries';
    let activateLibraryCount = `${pgHelper ? pgHelper.selectedGroups().length : 0}  ACTIVATED PRODUCT LIBRARIES`;
    if (options && options.subHeader) {
      activateLibraryCount = options.subHeader;
    }

    return (
      <FlexRows>
        <ModuleSegment.TitleText>{headerTitle}</ModuleSegment.TitleText>
        <ModuleSegment.SubTitleText>{activateLibraryCount}</ModuleSegment.SubTitleText>
      </FlexRows>
    );
  };

  render() {
    const { pgHelper } = this.state;
    if (!pgHelper) {
      return null;
    }

    if (pgHelper.groups.length === 0) {
      const { handleCreateProductsClick } = this.props;
      if (handleCreateProductsClick) {
        return (
          <ModuleSegment containerStyle={{ minWidth: '100%' }} title={this.ModuleTitle} helpModal={HelpModalInfo}>
            <LargeButton
              text="Create Product Library"
              iconSrc="/icons/add_product_group.svg"
              onClick={handleCreateProductsClick}
            />
          </ModuleSegment>
        );
      }

      return null;
    }

    return (
      <ModuleSegment
        containerStyle={{ minWidth: '100%' }}
        title={this.ModuleTitle}
        buttons={this.moduleButtons}
        helpModal={HelpModalInfo}
      >
        <div style={{ marginTop: 12 }} id="product_group_selector">
          <ProductGroupSelector pgHelper={pgHelper} />
        </div>
      </ModuleSegment>
    );
  }

  //#region Getters

  get moduleButtons() {
    return [
      <Button
        color={theme.button.createColor}
        text="Create Library"
        key="create_library"
        onClick={this.onCreateProductGroup}
      />,
    ];
  }

  //#endregion

  //#region Event Handlers

  onCreateProductGroup = () => {
    const { handleCreateProductsClick } = this.props;
    if (handleCreateProductsClick) {
      handleCreateProductsClick();
    }
  };

  onGroupsUpdated = () => {
    const { onChangeCommitted } = this.props;
    if (onChangeCommitted) {
      onChangeCommitted();
    }
    this.forceUpdate();
  };
}

export default function __Page(props: ViewProps) {
  const history = useHistory();
  return <ProductGroupAccessPreview {...props} history={history} />;
}
