import React from 'react';
import routes from '@utils/route-utils';
import { DropdownTitleArea } from '../common/Input/BrandDropdown';

import { ProductGroup } from '@api/vpapi/models';
import { SemanticICONS } from 'semantic-ui-react';

type ProductGroupModalLabelProps = {
  icon: SemanticICONS;
  productGroup: ProductGroup;
};
export function ProductGroupModalLabel({ icon, productGroup }: ProductGroupModalLabelProps) {
  return (
    <DropdownTitleArea
      title="Product Libraries"
      item={productGroup}
      to={routes.productGroups()}
      icon={icon}
      imageSrc="/product_libraries.svg"
    />
  );
}

export function ProductModalLabel({ title, cuuid }: { title: string; cuuid: string }) {
  return (
    <DropdownTitleArea
      title="Products"
      icon="chevron down"
      imageSrc="/product_libraries.svg"
      item={{ name: title }}
      to={routes.products(cuuid)}
    />
  );
}
