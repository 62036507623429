import * as React from 'react';

import { DropdownProps } from '@components/common';

import type { FormulationGroup, FormulationProduct } from '@api/formulation/models';
import type { AttributeOption } from '@api/models';
import imageAPI from '@api/vpapi';
import api from '@api/formulation';
import theme from '@theme';
import routeUtils from '../../../utils/route-utils';
import {
  AlternateText,
  Button,
  Dropdown,
  FlexColumns,
  FlexRows,
  Checkbox,
  InputLabel,
  LabeledInput,
  Loading,
  ModuleSegment,
  StyledDropZone,
} from '../../common';
import { Optional } from '@app/utils/types';
import { useHistory, useParams } from 'react-router';

type ExternalProps = {
  group: FormulationGroup;
};

type ViewProps = ExternalProps & {
  productID: string;
  history: ReturnType<typeof useHistory>;
};

type ViewState = {
  loadingImage?: boolean;
  product?: FormulationProduct;
  options: AttributeOption[];
};

type ControlledInputField = string;
type InputFields = string;

function ModuleTitle(title: string, subTitle: string) {
  return () => (
    <FlexRows>
      <ModuleSegment.TitleText>{title}</ModuleSegment.TitleText>
      <ModuleSegment.SubTitleText>{`${subTitle}`}</ModuleSegment.SubTitleText>
    </FlexRows>
  );
}

class ProductDetail extends React.Component<ViewProps, ViewState> {
  state = {
    loadingImage: undefined,
    options: [] as AttributeOption[],
    product: undefined as Optional<FormulationProduct>,
  };

  get buttons() {
    return [
      <Button
        outline
        color={theme.button.cancelColor}
        text="Delete Product"
        key="delete-product"
        onClick={this.handleDeleteClick}
      />,
    ];
  }

  handleDeleteClick = async () => {
    const { product } = this.state;
    if (!product) {
      return;
    }

    await api.products.delete(product.formulation_group_id, product._id);

    const { history } = this.props;
    history.goBack();
  };
  componentDidMount = async () => {
    try {
      const { productID, group } = this.props;

      const product = await api.products.detail(productID);
      const res = await api.formulation_groups.attribute_options(group._id);

      this.setState({ options: res.docs, product });
    } catch (err) {
      //  go back to the formulation group detail (products page)
      const { history, group } = this.props;
      history.replace(routeUtils.formulationGroupDetail(group.id));
    }
  };

  onProductAttributeChange(field: string, value: string | string[]) {
    const { product } = this.state;
    if (!product) {
      console.log('oh my, something went nil');
      return;
    }

    const a = api.products.utils.findAttribute(product, field);
    if (!a) {
      console.error('attribute not found');
      return;
    }
    a.v = value;
    this.setState({ product });

    api.products.upsert(product);
  }
  handleDropdownAddition(field: ControlledInputField) {
    return async (_e: React.SyntheticEvent<HTMLInputElement>, data: DropdownProps) => {
      this.onProductAttributeChange(field, data.value as string | string[]);

      try {
        // refetch _options. don't have mapping and don't want to hard-code local mapping for key --> value
        const { group } = this.props;
        const res = await api.formulation_groups.attribute_options(group._id);
        this.setState({
          options: res.docs,
        });
      } catch (err) {
        console.log(err);
      }
    };
  }

  handleDropdownChange(field: ControlledInputField) {
    return (_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
      const { options } = this.state;
      if (!options) {
        return;
      }

      let value: Optional<string[] | string>;
      if (Array.isArray(data.value)) {
        value = data.value.reduce((arr, id) => {
          const option = options.find(x => x.id === id);
          if (!option) {
            return arr;
          }
          return [...arr, option.value];
        }, [] as string[]);

        switch (field) {
          case 'allowed_application':
          case 'allowed_sample_size':
            if (value.length === 0) {
              console.log('preventing no items from being selected');
              return;
            }
        }
      } else {
        const option = options.find(x => x.id === data.value);
        if (option) {
          ({ value } = option);
        }
      }

      if (value) {
        this.onProductAttributeChange(field, value);
      }
    };
  }

  handleInputChange(field: InputFields) {
    return (_e: React.SyntheticEvent<HTMLInputElement>, data: { label: string; value: any }) => {
      this.onProductAttributeChange(field, data.value);
    };
  }

  handleDrop = async (files: File[]) => {
    this.setState({ loadingImage: true });
    const pIMG = await imageAPI.images.uploadImage(files[0]);
    const { product } = this.state;
    if (!product) {
      return;
    }

    product.product_image = pIMG.url;
    await api.products.upsert(product);

    this.setState({ loadingImage: false, product });
  };

  DropzoneContent = () => {
    const { loadingImage, product } = this.state;
    if (!product) {
      return null;
    }

    if (loadingImage) {
      return (
        <div
          style={{
            backgroundColor: theme.segment.moduleBackground,
            height: '100%',
            width: '100%',
          }}
        >
          <Loading text="Transfering Image" />;
        </div>
      );
    }

    let url = product.product_image;
    if (url.length === 0) {
      url = 'https://s3.amazonaws.com/colorcloud.io/images/formulations/empty_product.png';
    }
    return (
      <div
        style={{
          alignItems: 'center',
          height: '50vh',
          justifyContent: 'center',
          width: '50vh',
        }}
      >
        <img style={{ height: '75%', objectFit: 'contain', width: '33%' }} src={url} />
        <AlternateText textAlign="center" style={{ marginTop: 12 }}>
          Drag and drop product image to update
        </AlternateText>
      </div>
    );
  };
  handleCheckChange = (_e: React.SyntheticEvent<HTMLElement>, isChecked: boolean) => {
    const { product } = this.state;
    if (!product) {
      return;
    }

    product.is_image_multicolor = isChecked;

    this.setState({ product }, () => {
      api.products.upsert(product);
    });
  };
  renderContent = () => {
    const { product, options } = this.state;
    if (!product) {
      return <Loading text="Fetching product details" />;
    }

    const { DropzoneContent } = this;
    return (
      <FlexRows>
        <StyledDropZone onDrop={this.handleDrop} accept="image/*">
          <DropzoneContent />
        </StyledDropZone>

        <Checkbox
          text="Keep Image Colors"
          checked={product.is_image_multicolor}
          onCheckChange={this.handleCheckChange}
        />
        <Attributes
          onInputChange={this.handleInputChange}
          product={product}
          options={options}
          onChange={this.handleDropdownChange}
        />
        {/*
        <FlexColumns>
          <InputLabel containerStyle={{ flex: 0 }} label="Brand" />
          <Dropdown
            style={{ flexGrow: 1 }}
            allowAdditions
            search
            selection
            onAddItem={this.handleDropdownAddition('product_brand')}
            onChange={this.handleDropdownChange('product_brand')}
            value={`product_brand$$${product.product_brand.toLowerCase().replace(' ', '_')}`}
            options={options.filter(o => o.key === 'Product Brand').map(o => ({ text: o.value, value: o.id }))}
          />
        </FlexColumns> */}
      </FlexRows>
    );
  };
  render() {
    return (
      <ModuleSegment
        title={ModuleTitle('Product Detail', 'All changes auto-save, and are reflected in the app.')}
        buttons={this.buttons}
      >
        {/* <Debug data={this.state.product} /> */}
        {this.renderContent()}
      </ModuleSegment>
    );
  }
}

export default function __Page(props: ExternalProps) {
  const history = useHistory();

  const { product_id } = useParams<{ product_id: string }>();
  if (!product_id) {
    throw new Error('invalid routing');
  }
  return <ProductDetail history={history} productID={product_id} {...props} />;
}

function Attributes({
  product,
  options,
  onChange,
  onInputChange,
}: {
  onChange: (field: ControlledInputField) => (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  product?: FormulationProduct;
  options: AttributeOption[];
  onInputChange: (
    field: InputFields,
  ) => (e: React.SyntheticEvent<HTMLInputElement>, data: { label: string; value: any }) => void;
}) {
  if (!product) {
    return null;
  }

  return (
    <>
      {product.attributes.map(x => {
        if (Array.isArray(x.v)) {
          const values: string[] = x.v;
          return (
            <FlexColumns key={`${x.k}-${values.join('-')}`}>
              <InputLabel containerStyle={{ flex: 0 }} label={x.k} />
              <Dropdown
                style={{ flexGrow: 1 }}
                selection
                multiple
                search
                onChange={onChange(x.k)}
                value={values.map(
                  s => `${x.k.toLowerCase().replace(/\s/g, '_')}$$${s.toLowerCase().replace(/\s/g, '_')}`,
                )}
                options={options
                  ?.filter(o => o.key.toLowerCase() === x.k.toLowerCase())
                  .map(o => ({ text: o.value, value: o.id }))}
              />
            </FlexColumns>
          );
        }

        const value: string = x.v;
        const availableOptions = options.filter(o => o.key.toLowerCase() === x.k.toLowerCase());
        if (availableOptions.length > 1) {
          const key = `${x.k.toLowerCase().replace(/\s/g, '_')}$$${value.toLowerCase().replace(/\s/g, '_')}`;
          return (
            <FlexColumns key={key}>
              <InputLabel containerStyle={{ flex: 0 }} label={x.k} />
              <Dropdown
                style={{ flexGrow: 1 }}
                selection
                search
                onChange={onChange(x.k)}
                value={key}
                options={availableOptions.map(o => ({
                  text: o.value,
                  value: o.id,
                }))}
              />
            </FlexColumns>
          );
        }

        return (
          <LabeledInput label={x.k} key={`${x.k}-${value}`} inputProps={{ value }} onChange={onInputChange(x.k)} />
        );
      })}
    </>
  );
}
