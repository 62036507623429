import * as React from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { BrandListItem } from '@components/brands';
import routeUtils from '@utils/route-utils';
import ProductGroupList from '@components/productGroups/ProductGroupsList';
import brandUtils from '@api/brandUtils';
import {
  FlashContentT,
  Flash,
  FlexColumns,
  Loading,
  ModuleSegment,
  SearchBar,
  SystemAdminOnly,
} from '@components/common';
import cas from '@api/cas';
import theme from '@theme';
import UserDetailQuad from './UserDetailQuad';
import { PaidPlanJSON, Subscription, UserSearchJSON, BrandSummary } from '@api/cas/models';
import PaidSubscriptionQuad from './PaidSubscriptionQuad';
import SubscriptionList from '../account/SubscriptionsList';
import { Optional } from '@app/utils/types';

function useEmailFromLocation() {
  const location = useLocation();
  const { id } = qs.parse(location.search) as { id?: string };
  if (id) {
    return decodeURIComponent(id).toLowerCase();
  }

  return undefined;
}

function useUserSearch() {
  const location = useLocation();
  const history = useHistory();
  const locationEmail = useEmailFromLocation();
  const [email, setEmail] = React.useState(locationEmail);
  const [user, setUser] = React.useState<Optional<UserSearchJSON>>();
  const [loading, setLoading] = React.useState(false);
  const [flash, setFlash] = React.useState<Optional<FlashContentT>>();

  React.useEffect(() => {
    if (locationEmail && locationEmail !== user?.user.email) {
      console.log('locationEmail=', locationEmail, 'user?.user.email');
      setLoading(true);

      cas.users
        .find(locationEmail)
        .then(u => {
          setUser(u);
          setLoading(false);
        })
        .catch(e => {
          setUser(undefined);
          setLoading(false);
          setFlash(e.flashProps);
        });
    }
  }, [locationEmail, user, setUser]);

  return {
    user,
    email,
    loading,
    flash,

    changeEmail: React.useCallback(
      ({ currentTarget: { value } }: React.SyntheticEvent<HTMLInputElement>) => {
        setFlash(undefined);
        setEmail(value);
      },
      [setEmail],
    ),

    searchUser: React.useCallback(
      (id: string) => {
        history.replace(
          `${location.pathname}?${qs.stringify({
            ...qs.parse(location.search),
            id,
          })}`,
        );
      },
      [history, location],
    ),

    deleteSubscription: React.useCallback(
      (subscription: Subscription<any>) => {
        if (!locationEmail) {
          return;
        }
        cas
          .unsubscribeUser(subscription)
          .then(() => cas.users.find(locationEmail))
          .then(setUser)
          .catch(e => {
            setFlash(e.flashProps);
          });
      },
      [setUser, locationEmail],
    ),

    subscribeUser: React.useCallback(
      (plan: PaidPlanJSON) => {
        if (user) {
          cas.users
            .paidSubscription(user.user.email, plan)
            .then(() => {
              window.location = window.location;
            })
            .catch(e => {
              setFlash(e.flashProps);
            });
        }
      },
      [user],
    ),
  };
}
export default function UsersWrapper() {
  const history = useHistory();
  const { flash, searchUser, subscribeUser, deleteSubscription, email, changeEmail, user, loading } = useUserSearch();

  if (loading) {
    return <Loading text="Searching for account" />;
  }

  if (!user) {
    return (
      <Route path="/admin/users" key="search">
        <SearchBar
          inputProps={{
            value: email,
            placeholder: 'Search by user email...',
          }}
          onSubmit={searchUser}
          onChange={changeEmail}
        />
      </Route>
    );
  }

  return (
    <div style={{ margin: theme.dimensions.contentPadding }}>
      <Route path="/admin/users" key="search">
        <SearchBar
          inputProps={{
            value: email,
            placeholder: 'Search by user email...',
          }}
          onSubmit={searchUser}
          onChange={changeEmail}
        />
      </Route>
      <Flash content={flash} />
      <Route path="/admin/users" key="paid-subscription">
        <SystemAdminOnly>
          <>
            <PaidSubscriptionQuad onSubscribeUser={subscribeUser} user={user} />

            <div style={{ marginTop: theme.dimensions.contentPadding }}>
              <ModuleSegment
                title={ModuleSegment.ModuleTitle(
                  'Organization Memberships',
                  `${brandUtils.ownershipCount(user?.brands)} Organizations as Owner, and ${brandUtils.contributorCount(
                    user?.brands,
                  )} Organizations as Contributor`,
                )}
              >
                <FlexColumns style={{ maxHeight: '66vh', overflowY: 'scroll' }}>
                  {user.brands.map((b: BrandSummary) => (
                    <BrandListItem
                      onClick={({ id }) => history.push(routeUtils.brandDashboard(id))}
                      brand={b}
                      key={b.id}
                    />
                  ))}
                </FlexColumns>
              </ModuleSegment>
            </div>

            <div style={{ marginTop: theme.dimensions.contentPadding }}>
              <ModuleSegment
                title={ModuleSegment.ModuleTitle(
                  'Product Library Ownership',
                  `${user?.product_groups.length || 0} Owned Libraries`,
                )}
              >
                <ProductGroupList
                  containerStyle={{ maxHeight: '66vh', overflowY: 'scroll' }}
                  productGroups={user.product_groups || []}
                  onProductGroupClick={({ id }) => history.push(routeUtils.productGroupSettings(id))}
                />
              </ModuleSegment>
            </div>
          </>
        </SystemAdminOnly>
      </Route>

      <Route path={['/admin/users', '/users']} key="user-detail">
        <div style={{ marginTop: theme.dimensions.contentPadding }}>
          <UserDetailQuad user={user} />
        </div>
      </Route>

      <Route path={['/admin/users', '/users']} key="user-subscriptions">
        <SubscriptionList handleDeleteClick={deleteSubscription} subscriptions={user?.subscriptions} />
      </Route>
    </div>
  );
}
