import * as React from 'react';

import PermissionItem from './PermissionItem';
import { FlexColumns } from '../common';
import AddPermissionModal from './AddPermissionModal';
import Flash, { FlashContentT } from '../FlashNotice';
import { PermissionT } from '@api/cas/models';

import theme from '@theme';
import Modal from '../Modal';
import ModuleSegment from '../ModuleSegment';
import { Button } from '../Buttons';

export type ViewProps = {
  permissions: PermissionT[];

  title: string;

  handleAddContributor: (email: string) => Promise<void | any>;
  handleRevokeUserAccess: (email: string) => void | any;
  handleAddOwner: (email: string) => Promise<void | any>;

  handleTransferOwner?: (email: string) => Promise<void | any>;
};

type ModalType = 'add';
type ViewState = {
  flash?: FlashContentT;

  modalType?: ModalType;
};

export default class Permissions extends React.Component<ViewProps, ViewState> {
  constructor(props: ViewProps) {
    super(props);

    this.state = {
      modalType: undefined,
    };
  }

  handleRevokeUserAccess = ({ email }: { email: string }) => {
    try {
      const { handleRevokeUserAccess } = this.props;
      handleRevokeUserAccess(email);
    } catch (err: any) {
      this.setState({
        flash: err.flashProps as FlashContentT,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      modalType: undefined,
    });
  };

  showModal = (modalType?: ModalType) => () => {
    this.setState({ modalType });
  };
  get moduleButtons() {
    return [<Button key="add-user" text="Add User" color={theme.button.actionColor} onClick={this.showModal('add')} />];
  }
  render = () => {
    const { permissions, title } = this.props;
    const { modalType } = this.state;
    if (modalType === 'add') {
      const { handleAddContributor, handleAddOwner, handleTransferOwner } = this.props;
      return (
        <Modal modalProps={{ onClose: this.showModal(undefined), open: true }}>
          <AddPermissionModal
            handleAddContributor={handleAddContributor}
            handleAddOwner={handleAddOwner}
            handleTransferOwner={handleTransferOwner}
            permissions={permissions}
          />
        </Modal>
      );
    }

    const { flash } = this.state;
    return (
      <ModuleSegment title={title} buttons={this.moduleButtons}>
        <Flash content={flash} />

        <FlexColumns>
          {(permissions || []).map(p => (
            <PermissionItem
              roles={p.permissions}
              key={p.email}
              email={p.email}
              handleRevoke={this.handleRevokeUserAccess}
            />
          ))}
        </FlexColumns>
      </ModuleSegment>
    );
  };
}
