import * as React from 'react';
import _ from 'lodash';
import FilterItemCreator from './FilterItemCreator';

import vpapi from '../../../../api/vpapi';
import type { Editable } from '../../../../api/vpapi/models';

import FilterItem from './FilterItem';
import { AlternateText, FlexRows } from '../../../common';
import ModuleSegment from '../../../common/ModuleSegment';
import Product from '../../../../api/vpapi/models/Product';
import ProductFilter from '../../../../api/vpapi/models/ProductFilter';

const HelpModalInfo = {
  Content: () => (
    <AlternateText>
      Filters are search criteria that can be created and added to your products. Users can apply filters to refine
      their product search so they can efficiently discover what they’re looking for. By assigning filters to your
      products, you’re allowing users to more easily discover your product within the Variable Color app.
    </AlternateText>
  ),
  title: 'About Product Filters',
};

type ViewProps = {
  product: Product;
  onProductChange: (p: Product) => any | void;
};

type ViewState = {
  editables: Editable[];
};
class ProductFilterEditor extends React.Component<ViewProps, ViewState> {
  state = {
    editables: [] as Editable[],
  };

  componentDidMount() {
    const { product } = this.props;

    vpapi.filters
      .getEditables({ product_group_ids: product.collection_uuids })
      .then(({ editables }) => {
        this.setState({
          editables,
        });
      })
      .catch(err => console.error(err));
  }

  handleAddFilter = (filter_key: string, filter_value: string) => {
    const { product, onProductChange } = this.props;

    //don't add filters with key or value of 0 length
    if (filter_key.length === 0) {
      return;
    }

    // Add the filter value to both the product and the
    // available filterables
    product.addFilter(filter_key, filter_value);

    const { editables } = this.state;
    const item = editables.find(x => x.name === filter_key);
    if (!item) {
      this.setState({
        editables: [
          ...editables,
          {
            items: [
              {
                is_selected: false,
                name: filter_value,
                product_count: 1,
              },
            ],
            name: filter_key,
            type: 'filters',
          },
        ],
      });
    } else if (item.items.find(x => x.name === filter_value) === undefined) {
      item.items.push({
        is_selected: false,
        name: filter_value,
        product_count: 1,
      });
    }

    onProductChange(product);
  };

  handleRemoveFilterValue = (filter_key: string, filter_value: string) => {
    const { product, onProductChange } = this.props;

    // Filter out the exact filter_value and filter_key
    product.filters = product.filters.filter(f => {
      if (f.filter_key !== filter_key) {
        return f;
      }

      const index = f.filter_value.indexOf(filter_value);
      if (index === -1) {
        return f;
      }

      f.filter_value.splice(index, 1);

      if (f.filter_value.length > 0) {
        return f;
      }

      return undefined;
    });

    // Check to make sure that there still exists at least one FilterItem in the array that has
    // a matching filter_key. This is to ensure that the line for said filter_key is not removed.
    if (_.findIndex(product.filters, f => f.filter_key === filter_key) === -1) {
      product.filters.push(
        new ProductFilter({
          filter_key,
          filter_value: '',
          name: filter_key,
        }),
      );
    }

    onProductChange?.(product);
    this.forceUpdate();
  };

  handleClearFilterValues = (f: ProductFilter) => {
    const { onProductChange, product } = this.props;
    const productFilter = product.filters.find(filter => f.id === filter.id);
    if (productFilter) {
      productFilter.filter_value = [];
      onProductChange?.(product);
    }
  };

  ModuleTitle = () => (
    <FlexRows>
      <ModuleSegment.TitleText>Filters</ModuleSegment.TitleText>
      <ModuleSegment.SubTitleText>Click to learn more</ModuleSegment.SubTitleText>
    </FlexRows>
  );
  render() {
    const { product, onProductChange } = this.props;
    const { editables } = this.state;

    return (
      <ModuleSegment
        containerStyle={{ backgroundColor: 'transparent' }}
        bodyStyle={{ marginTop: 8 }}
        title={this.ModuleTitle}
        helpModal={HelpModalInfo}
      >
        {product.filters.map(f => (
          <FilterItem
            key={f.id}
            filter={f}
            editables={editables}
            handleClearFilterValues={this.handleClearFilterValues}
            handleAddFilter={this.handleAddFilter}
            handleRemoveFilterValue={this.handleRemoveFilterValue}
            onProductChange={() => onProductChange(product)}
          />
        ))}

        <FilterItemCreator onAddItem={this.handleAddFilter} />
      </ModuleSegment>
    );
  }
}

export default ProductFilterEditor;
