import _ from 'lodash';
import config from '../../config';
import api from '../fetch2';
import { DBProductCompositionDetailJSON } from './models/ProductCompositionDetail';

type ImageTransferResponse = {
  imageURLS: {
    original_url: string;
    url: string;
  }[];
};

export default {
  async bulkImageTransfer(imageURLS: string[]): Promise<ImageTransferResponse> {
    return api.post(config.server.imageTransfer, { imageURLS });
  },

  async extractColors(imageURL: string) {
    return api.post<DBProductCompositionDetailJSON[]>(config.server.image_extractions, { imageURL }).then(json => json);
  },

  async transferImage(imageURL: String) {
    const json: ImageTransferResponse = await api.post(config.server.imageTransfer, { imageURL });

    return { type: '', url: json.imageURLS[0].url };
  },

  async uploadImage(file: File) {
    const b = new FormData();
    b.append('uploadfile', file);

    return fetch(config.server.imageUpload, {
      body: b,
      headers: {
        CASP: config.getPlatformID(),
        CAST: config.api_token,
        'Session-Token': localStorage.getItem('session_token') ?? '',
      },
      method: 'POST',
    })
      .then(res => res.json())
      .then(json => ({
        image_key: _.uniqueId('extra_'),
        url: json.imageURL,
      }));
  },
};
