import * as React from 'react';
import _ from 'lodash';
import { ColorDelta, DeltaT, Illuminant, IlluminantT, LabColor, Observer, ObserverT } from '@variablecolor/colormath';
import { CardView, FlexColumns, FlexRows, ModuleSegment } from '@components/common';
import { LabContainer } from './components';
import theme from '@theme';
import { round } from '@utils';
import { DropdownProps } from '@components/common';

type OutputProps = {
  standard: LabColor;
  sample: LabColor;
};

function Output({ sample, standard }: OutputProps) {
  const keys = Object.keys(ColorDelta.deltaDisplays) as DeltaT[];
  const cards = keys.map((k: DeltaT) => [
    { text: ColorDelta.deltaDisplays[k] },
    { text: `${round(ColorDelta.compare(k, sample, standard), 3)}` },
  ]);
  return (
    <FlexColumns>
      {cards.map(lines => (
        <CardView lines={lines} key={_.uniqueId('_')} />
      ))}
    </FlexColumns>
  );
}

function useCalculator() {
  const [sample, setSample] = React.useState(new LabColor(0, 0, 0, Illuminant.D50, Observer.TWO_DEGREE));
  const [standard, setStandard] = React.useState(new LabColor(0, 0, 0, Illuminant.D50, Observer.TWO_DEGREE));

  function copy(lab: LabColor) {
    return new LabColor(lab.L, lab.a, lab.b, lab.illuminant, lab.observer);
  }
  return {
    standard: {
      lab: standard,
      onLabColorChange: React.useCallback(
        (index: 'L' | 'a' | 'b') => {
          return ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => {
            if (currentTarget.valueAsNumber) {
              (standard[index] as any) = currentTarget.valueAsNumber;
              setStandard(copy(standard));
            }
          };
        },
        [standard, setStandard],
      ),
      onColorSpaceChange(_e: React.SyntheticEvent, data: DropdownProps) {
        const id = data.id as string;
        if (id === 'illuminant') {
          (sample.illuminant as any) = data.value as IlluminantT;
          setStandard(copy(standard));
        } else if (id === 'observer') {
          (standard.observer as any) = data.value as ObserverT;
          setStandard(standard);
        }
      },
    },

    sample: {
      lab: sample,
      onColorSpaceChange(_e: React.SyntheticEvent, data: DropdownProps) {
        if (data.id === 'illuminant') {
          (sample.illuminant as any) = data.value as IlluminantT;
          setSample(copy(sample));
        } else if (data.id === 'observer') {
          (sample.observer as any) = data.value as ObserverT;
          setSample(copy(sample));
        }
      },
      onLabColorChange: React.useCallback(
        (index: 'L' | 'a' | 'b') => {
          return ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => {
            if (currentTarget.valueAsNumber) {
              (sample[index] as any) = currentTarget.valueAsNumber;
              setSample(copy(sample));
            }
          };
        },
        [sample, setSample],
      ),
    },
  };
}
export default function ComparisonCalc() {
  const { sample, standard } = useCalculator();

  return (
    <FlexRows style={{ margin: theme.dimensions.contentPadding, minWidth: '80vw' }}>
      <FlexColumns
        style={{
          justifyContent: 'space-around',
          marginBottom: 8,
          width: '100%',
        }}
      >
        <ModuleSegment containerStyle={{ flex: 1 }} title="Sample Lab">
          <LabContainer
            onChange={sample.onLabColorChange}
            lab={sample.lab}
            onColorSpaceChange={sample.onColorSpaceChange}
          />
        </ModuleSegment>

        <ModuleSegment containerStyle={{ flex: 1, marginLeft: 8, marginRight: 8 }} title="Standard Lab">
          <LabContainer
            onChange={standard.onLabColorChange}
            lab={standard.lab}
            onColorSpaceChange={standard.onColorSpaceChange}
          />
        </ModuleSegment>
      </FlexColumns>

      <ModuleSegment bodyStyle={{ width: '100%' }} title="Output">
        <Output sample={sample.lab} standard={standard.lab} />
      </ModuleSegment>
    </FlexRows>
  );
}
