import React from 'react';
import { Route } from 'react-router-dom';
import cas from '../../api/cas';
import SubscriptionList from './SubscriptionsList';
import { Flash, FlashContentT } from '@components/common';
import theme from '@theme';
import type { Subscription, UserJSON } from '@api/cas/models';
import AccountDetailQuad from './AccountDetailQuad';
import ExternalPurchasesLoader from '../users/Purchases/load-purchases';
import User from '@api/cas/user';
import { Optional } from '@app/utils/types';

export default function Profile(props: { user: User }) {
  const [profile, setUserProfile] = React.useState<UserJSON>(props.user);
  const [flash, setFlash] = React.useState<Optional<FlashContentT>>();

  React.useEffect(() => {
    cas.users
      .getProfile()
      .then(setUserProfile)
      .catch(e => {
        setFlash(e.flashProps as FlashContentT);
      });
  }, [setUserProfile]);

  const handleSubscriptionDeletion = React.useCallback(
    async (subscription: Subscription<any>) => {
      try {
        await cas.unsubscribeSubscription({ id: subscription.id });

        setUserProfile(await cas.users.getProfile());
      } catch (error: any) {
        setFlash(error.flashProps as FlashContentT);
      }
    },
    [setUserProfile],
  );

  return (
    <>
      <Flash content={flash} />
      <div style={{ margin: theme.dimensions.contentPadding }}>
        <Route path="/account/profile">
          <AccountDetailQuad profile={profile} />
        </Route>
      </div>

      <div style={{ margin: theme.dimensions.contentPadding }}>
        <Route path="/account/profile">
          <ExternalPurchasesLoader />
        </Route>
      </div>

      <div style={{ margin: theme.dimensions.contentPadding }}>
        <Route path="/account/profile">
          <SubscriptionList subscriptions={profile.subscriptions} handleDeleteClick={handleSubscriptionDeletion} />
        </Route>
      </div>
    </>
  );
}
