import * as React from 'react';
import { Button, FlexColumns, Modal, ModuleSegment } from '../../../common';
import EditCompositionDetail from './EditCompositionDetail';
import { ProductCompositionDetail } from '../../../../api/vpapi/models';
import theme from '../../../../theme';
import { ModalProps } from 'semantic-ui-react';

type ViewProps = {
  trigger: React.ReactNode;
  composition: ProductCompositionDetail;

  handleSubmit: (d: ProductCompositionDetail) => void | any;
};

export default class AddColorModal extends React.PureComponent<ViewProps> {
  modalRef = React.createRef<React.Component<ModalProps, any, any>>();

  handleSubmit = (composition: ProductCompositionDetail) => () => {
    this.props.handleSubmit?.(composition);

    this.handleModalClose();
  };

  handleModalClose = () => {
    if (this.modalRef.current) {
      this.modalRef.current.setState({ open: false });
    }
  };

  renderManualColorCreation = () => {
    const { composition } = this.props;
    return (
      <div>
        <EditCompositionDetail showManualPreview composition={composition} />

        <FlexColumns style={{ justifyContent: 'flex-end', marginTop: 12 }}>
          <Button
            outline
            color={theme.button.createColor}
            text="Add Product Color"
            onClick={this.handleSubmit(composition)}
          />

          <Button color={theme.button.cancelColor} text="Cancel" onClick={this.handleModalClose} />
        </FlexColumns>
      </div>
    );
  };

  render = () => {
    const { trigger } = this.props;

    return (
      <Modal modalRef={this.modalRef} modalProps={{ trigger }}>
        <ModuleSegment title="Color Creator">{this.renderManualColorCreation()}</ModuleSegment>
      </Modal>
    );
  };
}
