import styled from 'styled-components';
import { FlexRows } from '@components/common';
import theme from '@theme';

export const Container = styled(FlexRows)`
  background-color: ${theme.dropdown.background};
  border-radius: 4px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
  margin: 8px;
  padding: 8px;

  :hover {
    border: 1px ${theme.dropdown.hoverBackground} solid;

    margin: 7px;
  }
`;
