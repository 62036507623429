import React from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import { AlternateText, Button, ModuleSegment, PrimaryText } from '../../common';

const Bold = styled.p`
  font-weight: bold;
  color: ${theme.text.primary};
`;

const RowHeaderItem = styled(PrimaryText)`
  margin-top: 12px;
  font-weight: bold;
`;
const AboutProducts = () => (
  <ModuleSegment containerStyle={{ margin: theme.dimensions.contentPadding }} title="What is a Product?">
    <AlternateText>A product can be broken into 4 distinct groups </AlternateText>

    <div style={{ margin: 18 }} />
    <PrimaryText>
      <Bold>Filters </Bold>
    </PrimaryText>
    <AlternateText>
      Product filters describe shared details of a product, and contain both a field title and value (i.e. field title
      is “Country” and value is “United States”). App users use these filters to to refine their search.
    </AlternateText>

    <div style={{ margin: 18 }} />
    <PrimaryText>
      <Bold>Attributes </Bold>
    </PrimaryText>
    <AlternateText>
      Attributes describe details of a product. Attributes contain both a field title and value. For example, a field
      title of SKU and value of SG-9541V. They are different from filters, and can be thought of product fields. It is
      common a set of attributes belonging to a product overlap with its filters. However, the attributes, are displayed
      to the user when viewing more details on a individual product.
    </AlternateText>

    <div style={{ margin: 18 }} />
    <PrimaryText>
      <Bold>Images </Bold>
    </PrimaryText>
    <AlternateText>
      A product can contain a set of images. The primary image is the first image in the set. The primary image is shown
      to an app user in a search result. All images are presented in a detail view.
    </AlternateText>

    <div style={{ margin: 18 }} />
    <PrimaryText>
      <Bold>Product Colors </Bold>
    </PrimaryText>
    <AlternateText>
      A product is comprised of compositions. A composition is made up of a percentage, lab, and optional name. Each
      color should be described in the CIE Lab D50 2° space.
    </AlternateText>
  </ModuleSegment>
);

type ViewProps = {};
export default class ProductImportInstructions extends React.Component<ViewProps> {
  productModuleButtons = () => [
    <a href="/templates/LibraryManager/example_product_group_with_images.csv" key="sample_with_image">
      <Button outline color={theme.button.actionColor} text="Download Sample with Images" />
    </a>,
  ];
  render = () => (
    <div style={{ margin: theme.dimensions.contentPadding }}>
      <ModuleSegment title="About CSV Template" buttons={this.productModuleButtons()}>
        <AlternateText>
          The first row of any csv must defined headers that each product contains. Below are a list of fully supported
          row headers:
        </AlternateText>

        <RowHeaderItem>filter.field_title </RowHeaderItem>
        <AlternateText>
          adds a new filter to every product where the field_title is the title and each row contains the value that
          corresponds to it. Always prefix the field_title with filter. to mark this column as a filter. Empty is a
          accepted as valid.
        </AlternateText>

        <RowHeaderItem>filter.category</RowHeaderItem>
        <AlternateText>
          Category is a suggested filter for each product. Suggested categories can be Paint, Textiles, or Flooring
        </AlternateText>

        <RowHeaderItem>attr.field_title </RowHeaderItem>
        <AlternateText>
          Add a new attribute to every product where field title is the title and each row contains the value that
          corresponds to it. All attributes headers need to be prefixed with attr. to mark the column as a product
          attribute.
        </AlternateText>

        <RowHeaderItem>attr.name </RowHeaderItem>
        <AlternateText>The name of the product, a required attribute.</AlternateText>

        <RowHeaderItem>attr.code </RowHeaderItem>
        <AlternateText>A distinct code for each product, a required attribute.</AlternateText>

        <RowHeaderItem>attr.sort_order </RowHeaderItem>
        <AlternateText>
          an optional attribute that is highly recommended. This attribute defines a specific order that can be used to
          sort products while scanning their colors.
        </AlternateText>

        <RowHeaderItem>attr.product_url </RowHeaderItem>
        <AlternateText>
          an optional attribute. This attribute embeds a link inside the app. The link navigates the user to view or buy
          the product.
        </AlternateText>

        <RowHeaderItem>image.full </RowHeaderItem>
        <AlternateText>
          A url linking to a high resolution photo. During the import process, there is an option to extract colors.
          This image is used if available. If additional images are required, use image.image_type, where image_type is
          a one word desription of the type of image.
        </AlternateText>
      </ModuleSegment>
    </div>
  );
}

export { AboutProducts };
