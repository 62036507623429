import React from 'react';
import styled from 'styled-components';

import { ModuleSegment } from '../../../common';

const ImageLink = styled.a`
  height: 256px;
  width: 256px;

  display: inline-block;

  margin: 4px;

  border: 1px solid #ccc;

  :hover {
    opacity: 0.85;
  }
`;

type ViewProps = {
  images: { url: string; id: string }[];
};
export default ({ images }: ViewProps) => {
  if (images.length === 0) {
    return null;
  }

  return (
    <ModuleSegment title="Images">
      {images.map(image => (
        <ImageLink key={image.url} href={image.url} target="_new">
          <img
            alt="Product"
            style={{
              height: 254,
              margin: 'auto',
              objectFit: 'scale-down',
              width: 254,
            }}
            src={image.url}
          />
        </ImageLink>
      ))}
    </ModuleSegment>
  );
};
