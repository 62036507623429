import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { AlternateText } from '../common';
import VerticalRoutedTabMenu from './RoutedTabMenu';
import type { RoutableNavigationItemProps } from './types';
import theme from '@theme';

const { sidebarBackground, dimensions } = theme;

const RouteHeader = styled(AlternateText)`
  ${({ hide }: { hide?: boolean }) => (hide ? 'display: none;' : undefined)}

  @media (max-width: 900px) {
    display: none;
  }

  font-weight: 600;
  margin-left: 4px;
  font-size: 11px;
`;

//#region Styled Components
const LeftSideBar = styled.div`
  @media (max-width: 900px) {
    width: ${dimensions.sideBarWidthMobile}px;
  }

  @media (min-width: 900px) {
    width: ${dimensions.sideBarWidthDesktop}px;
  }

  min-height: 100vh;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  background-color: ${sidebarBackground};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  z-index: 1000;
`;

const LogoImage = styled.div`
  margin: auto;

  @media only screen and (max-width: 768px) {
    background-image: url(/icons/variable_v.png);
    background-size: contain;
    background-repeat: no-repeat;

    height: 24px;
    width: 24px;
  }

  @media only screen and (min-width: 768px) {
    background-image: url(/ColorCloudLogo.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    height: 64px;
    width: 128px;
  }
`;

//#endregion

type LeftMenuProps = {
  general: RoutableNavigationItemProps[];
  tools: RoutableNavigationItemProps[];
  system: RoutableNavigationItemProps[];
  formulations: RoutableNavigationItemProps[];
  account: RoutableNavigationItemProps[];
};

function Divider() {
  return <div style={{ marginTop: 32 }} />;
}
export default function LeftMenu({ account, formulations, tools, general, system }: LeftMenuProps) {
  return (
    <LeftSideBar id="left-side-bar">
      <Link to="/" style={{ padding: '5%', width: '100%' }}>
        <LogoImage />
      </Link>

      <RouteHeader>PRODUCT MANAGEMENT</RouteHeader>
      <VerticalRoutedTabMenu routes={general} />
      <Divider />
      <RouteHeader hide={system.length === 0}>ADMIN MANAGEMENT</RouteHeader>
      <VerticalRoutedTabMenu routes={system} />

      <Divider />
      <RouteHeader>TOOLS</RouteHeader>
      <VerticalRoutedTabMenu routes={tools} />

      <Divider />
      <RouteHeader hide={formulations.length === 0}>FORMULATIONS</RouteHeader>
      <VerticalRoutedTabMenu routes={formulations} />

      <div style={{ flexGrow: 1 }} />

      <VerticalRoutedTabMenu routes={account} />
    </LeftSideBar>
  );
}
