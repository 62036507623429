import * as React from 'react';

import ProductGroupsList from '@components/productGroups/ProductGroupsList';
import pgUtils from '@api/productGroupUtils';

import { ProductGroupDescriptor } from '@api/cas/models';

import ProductGroupActionButtons, { ProductGroupSaveButtons } from './ProductGroupActionButtons';
import ProductGroupFilters from './ProductGroupFilters';
import { ModuleSegment } from '@components/common';
import { ProductGroupHelper } from '@api/vpapi/models/ProductGroupHelper';

type ViewProps = {
  pgHelper: ProductGroupHelper;
};

type ViewState = {
  includeActive: boolean;
  includeInactive: boolean;
  selectedCats: string[];
  selectedVendors: string[];
  searchTerm: string;
};

interface SelectableProductGroupDescriptor extends ProductGroupDescriptor {
  isSelected: boolean;
}

class ProductGroupSelector extends React.Component<ViewProps, ViewState> {
  state: ViewState = {
    includeActive: true,
    includeInactive: true,
    searchTerm: '',
    selectedCats: [],
    selectedVendors: [],
  };

  render() {
    const { selectedCats, selectedVendors, searchTerm, includeActive, includeInactive } = this.state;

    const { pgHelper } = this.props;

    const groupsForCategory = pgUtils.filteredByCategories(pgHelper.groups, selectedCats);
    const groupsForVendor = pgUtils.filteredByVendors(groupsForCategory, selectedVendors);

    let groupsForDisplay: Array<SelectableProductGroupDescriptor> = pgUtils.search(groupsForVendor, searchTerm);
    if (!(searchTerm && searchTerm.length > 0)) {
      groupsForDisplay = pgUtils.orderedByVendor(groupsForDisplay);
    }

    if (!includeActive || !includeInactive) {
      groupsForDisplay = groupsForDisplay.filter(
        pg => (includeActive && pg.isSelected) || (includeInactive && !pg.isSelected),
      );
    }

    const onEnableAll = () => {
      groupsForDisplay.forEach(g => {
        if (g.isSelected === false) {
          this.toggleGroup(g);
        }
      });

      this.forceUpdate();
    };

    const onDisableAll = () => {
      groupsForDisplay.forEach(g => {
        if (g.isSelected === true) {
          this.toggleGroup(g);
        }
      });

      this.forceUpdate();
    };

    return (
      <ModuleSegment title="" containerStyle={{ padding: 0 }}>
        <ProductGroupFilters
          vendors={pgUtils.vendors(groupsForCategory)}
          categories={pgUtils.categories(pgHelper.groups)}
          onCategorySelection={this.onCategorySelection}
          onVendorSelection={this.onVendorSelection}
          onSearchTermChange={this.onSearchTermChange}
          selectedCats={selectedCats}
          selectedVendors={selectedVendors}
          onFilterReset={this.onFilterReset}
        />

        <ProductGroupActionButtons
          enabledGroupCount={pgHelper.changes.enable.length}
          disabledGroupCount={pgHelper.changes.disable.length}
          onEnableAll={onEnableAll}
          onDisableAll={onDisableAll}
          onCheckChange={(key: 'includeActive' | 'includeInactive', checked: boolean) => {
            if (key === 'includeActive') {
              this.setState({
                includeActive: checked,
              });
            } else if (key === 'includeInactive') {
              this.setState({
                includeInactive: checked,
              });
            }
          }}
          includeActive={includeActive}
          includeInactive={includeInactive}
        />

        <ProductGroupsList productGroups={groupsForDisplay} onProductGroupClick={this.onGroupClick} />

        <ProductGroupSaveButtons onSave={this.onSave} onReset={this.onReset} pgHelper={pgHelper} />
      </ModuleSegment>
    );
  }

  onGroupClick = (g: ProductGroupDescriptor) => {
    this.toggleGroup(g);
    this.forceUpdate();
  };

  onReset = () => {
    const { pgHelper } = this.props;
    pgHelper.revertChanges();

    this.forceUpdate();
  };

  onSave = () => {
    const { pgHelper } = this.props;
    pgHelper.commitChanges();

    this.forceUpdate();
  };

  toggleGroup = (g: ProductGroupDescriptor) => {
    const { pgHelper } = this.props;

    pgHelper.toggleGroup(g);
  };

  onCategorySelection = (cat: string[]) => {
    this.setState({ selectedCats: cat, selectedVendors: [] });
  };

  onVendorSelection = (vs: string[]) => {
    this.setState({ selectedVendors: vs });
  };

  onSearchTermChange = (searchTerm: string) => {
    this.setState({ searchTerm });
  };

  onFilterReset = () => {
    this.setState({
      searchTerm: '',
      selectedCats: [],
      selectedVendors: [],
    });
  };
}

export default ProductGroupSelector;
