import * as React from 'react';
import styled from 'styled-components';

const FlexRows = styled.div<{
  flex?: number;
  justifyContent?: string;
  alignItems?: 'left' | 'center' | string;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  flex: ${({ flex }) => flex};

  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
`;

const FlexColumns = styled.div<{
  flex?: number;
  justifyContent?: string;
  alignItems?: 'left' | 'center' | string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  flex: ${({ flex }) => flex};

  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
`;

//#endregion

function Debug({ data }: { data: any }) {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  const str = JSON.stringify(data, undefined, 2);
  return (
    <pre
      style={{
        background: '#ccc',
        height: 'auto',
        overflow: 'auto',
        paddingBottom: 16,
        paddingTop: 16,
        width: '100%',
      }}
    >
      {str}
    </pre>
  );
}

export { Debug, FlexColumns, FlexRows };
