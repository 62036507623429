function parseValue(model: string | number): number {
  if (typeof model === 'string') {
    return parseFloat(model);
  }
  return model;
}

function isColorMuse2(model: string | number): boolean {
  const m = parseValue(model);
  return m >= 16.0 && m < 17.0;
}

function isColorMuse(model: string | number): boolean {
  return parseValue(model) < 10.0;
}

function isColorMusePro(model: string | number): boolean {
  const m = parseValue(model);
  return m >= 12.0 && m < 13.0;
}

function isSpectro(model: string | number): boolean {
  const m = parseValue(model);
  return (m >= 10.0 && m < 12.0) || (m >= 13.0 && m < 14.0);
}

function isSpectroSCE(model: string | number): boolean {
  const m = parseValue(model);
  return m === 11.0;
}

function isSpectroSCI(model: string | number): boolean {
  const m = parseValue(model);
  return m === 11.1;
}

function name(model: string | number, batch: string = '') {
  if (isColorMuse2(model)) {
    return 'CMGEN2';
  }

  if (isColorMuse(model) || model === '') {
    return 'Color Muse';
  }

  if (isColorMusePro(model)) {
    return 'Color Muse Pro';
  }

  if (isSpectroSCE(model)) {
    return 'Spectro 1';
  }

  if (isSpectroSCI(model)) {
    if (batch === 'sw-2') {
      return 'Spectro 1 Pro (SW)';
    }
    return 'Spectro 1 Pro';
  }

  return 'Unknown Instrument';
}

function measurementDeviceClass(model: string | number, batch: string) {
  switch (parseValue(model)) {
    case 16.1:
    case 16.0:
      return {
        batch,
        device_type: 'cm_gen2',
        id: 518,
        model: model.toString(),
      };

    case 11.0: {
      return {
        batch,
        device_type: 'spectro',
        id: 511,
        model: model.toString(),
      };
    }

    case 11.1: {
      return {
        batch,
        model: model.toString(),
        id: 513,
        device_type: 'spectro"',
      };
    }

    case 12.1: {
      return {
        batch,
        model: model.toString(),
        id: 514,
        device_type: 'spectro',
      };
    }

    default:

    case 4.0: {
      return {
        batch,
        model: model.toString(),
        device_type: 'color_muse',
        id: parseInt(batch, 10),
      };
    }
  }
}

export default {
  isColorMuse,
  isColorMusePro,
  isSpectro,
  name,
  measurementDeviceClass,
};
