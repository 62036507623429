import * as React from 'react';
import styled from 'styled-components';
import { AlternateText } from './Text';

type ViewProps = {
  checked: boolean;
  text: string;
  onCheckChange: (e: React.SyntheticEvent<HTMLElement>, isChecked: boolean) => void | any;
};

const Circle = styled.div`
  position: absolute;
  top: 3px;
  left: 3px;
  
  border-radius: 9px;

  background-color: white;
  width: 18px
  height: 18px;

  z-index: 1;
`;

const Checkmark = styled.div`
  position: absolute;
  left: 10px;
  top: 4px;

  /*Make it a small rectangle so the border will create an L-shape*/
  width: 6px;
  height: 12px;

  /*Add a white border on the bottom and left, creating that 'L' */
  border: solid black;
  border-width: 0 2px 2px 0;

  /*Rotate the L 45 degrees to turn it into a checkmark*/
  transform: rotate(45deg);

  z-index: 1000;
`;

export default class Radio extends React.Component<ViewProps> {
  static Container = styled.div`
    display: inline-flex;
    flex-direction: row;

    align-items: center;

    margin-left: 3px;
    cursor: pointer;
  `;

  static CircleWrapper = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 3px;
  `;

  handleCheckChange = (e: React.SyntheticEvent<HTMLElement>) => {
    const { checked, onCheckChange } = this.props;
    if (onCheckChange) {
      onCheckChange(e, !checked);
    }
  };

  render = () => {
    const { checked, text } = this.props;
    return (
      <Radio.Container style={{ alignItems: 'center' }} onClick={this.handleCheckChange}>
        <Radio.CircleWrapper>
          {checked && <Checkmark />}
          <Circle />
        </Radio.CircleWrapper>
        <AlternateText>{text}</AlternateText>
      </Radio.Container>
    );
  };
}
