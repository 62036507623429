import styled from 'styled-components';

export const HorizontalScrollView = styled.div<{ width?: string }>`
  width: ${({ width = '75vw' }) => width};

  margin-right: 4px;

  display: flex;
  flex-direction: row;

  flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;

  overflow-x: scroll;
  -ms-overflow-style: none;
`;
