import * as React from 'react';

import { DropdownProps } from '@components/common';
import routeUtils from '@utils/route-utils';
import {
  AddFormulationGroupView,
  AlternateText,
  Dropdown,
  Flash,
  FlashContentT,
  FlexColumns,
  InputLabel,
  ModuleSegment,
} from '@components/common';
import type { Customer } from '@api/formulation/models';

import api from '@api/formulation';
import { useHistory } from 'react-router';
import { Optional } from '@app/utils/types';

type ViewProps = {
  history: ReturnType<typeof useHistory>;
};

type ViewState = {
  customers: Customer[];
  selectedCustomer?: Customer;

  flash?: FlashContentT;
};
export default class FormulationGroupCreator extends React.Component<ViewProps, ViewState> {
  state = {
    customers: [] as Customer[],
    flash: undefined,
    selectedCustomer: undefined as Optional<Customer>,
  };

  async componentDidMount() {
    try {
      const res = await api.customers.all();
      this.setState({
        customers: res.docs,
      });
    } catch (err) {
      console.log('failed to fetch customer list');
    }
  }

  dismiss = () => {
    const { history } = this.props;
    if (history.length > 2) {
      history.goBack();
    }
  };
  handleCreateClick = async (name: string) => {
    const { selectedCustomer } = this.state;
    if (!selectedCustomer) {
      this.setState({
        flash: {
          color: 'red',
          message: 'Please select or create a customer before continuing.',
        },
      });
      return;
    }

    const group = await api.formulation_groups.create({
      customer_id: selectedCustomer._id,
      name,
    });

    const { history } = this.props;
    history.replace(`${routeUtils.uploadFormulationProducts(group._id)}?t=n`);
  };

  handleCreateCustomer = async (_event: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    const { customers } = this.state;
    const v = (value as string).trim().toLowerCase();
    let selectedCustomer = customers.find(({ name }) => name.toLowerCase() === v);
    if (!selectedCustomer) {
      selectedCustomer = await api.customers.create(value as string);
    }

    this.setState({
      selectedCustomer,
    });
  };

  handleCustomerChange = (_event: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    const { customers } = this.state;
    const selectedCustomer = customers.find(c => c._id === value);
    this.setState({
      selectedCustomer,
    });
  };
  render() {
    const { customers, flash } = this.state;
    return (
      <ModuleSegment title="Create Formulation Library" containerStyle={{ lineHeight: 1.5 }}>
        <div style={{ alignItems: 'center', display: 'flex', margin: 36 }}>
          <img
            alt="Formulation Library Creator"
            style={{
              height: 'auto',
              margin: 'auto',
              objectFit: 'contain',
              width: '66%',
            }}
            src="/images/product-library.png"
          />
        </div>
        <AlternateText>Obtain copy of required content here from Marketing</AlternateText>
        <Flash content={flash} />
        <FlexColumns>
          <InputLabel label="Customer" containerStyle={{ flex: 0 }} />
          <Dropdown
            allowAdditions
            search
            selection
            style={{ flexGrow: 1 }}
            options={customers.map(({ name, _id }) => ({
              text: name,
              value: _id,
            }))}
            onChange={this.handleCustomerChange}
            onAddItem={this.handleCreateCustomer}
          />
        </FlexColumns>
        <AddFormulationGroupView handleSubmit={this.handleCreateClick} handleCancel={this.dismiss} />
      </ModuleSegment>
    );
  }
}
