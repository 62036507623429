import * as React from 'react';
import qs from 'query-string';

import styled from 'styled-components';
import { Link, useLocation, useHistory } from 'react-router-dom';

import routeUtils from '@utils/route-utils';
import { Flash, FlashContentT, Loading, ModuleSegment as Segment } from '../common';
import cas from '@api/cas';
import theme from '@theme';
import User from '@api/cas/user';
import { Optional } from '@app/utils/types';

function ModuleSegment({ title, children }: { children: any; title: string }) {
  return (
    <Segment
      containerStyle={{
        borderColor: theme.button.createColor,
        borderStyle: 'solid',
        margin: 'auto',
        minWidth: 768,
        width: '33vw',
      }}
      title={title}
    >
      {children}
    </Segment>
  );
}

const Container = styled.div`
  margin: 12px 0 0 0;
  text-align: center;
  color: white;
`;

export default function EmailValidation() {
  const { search } = useLocation();
  const history = useHistory();

  const [flash, setFlash] = React.useState<Optional<FlashContentT>>();
  const [status, setStatus] = React.useState<{
    isValidated: boolean;
    loading: boolean;
  }>({ loading: false, isValidated: false });

  React.useEffect(() => {
    const { token } = qs.parse(search);
    if (!token) {
      console.log('no token');
      history.replace('/');
      return;
    }

    async function doWork() {
      const currentUser = User.load();
      if (currentUser?.is_email_verified) {
        await cas.users.logout();
      }

      await cas.users.verifyAccount({ token: token as string });
    }

    doWork()
      .then(() => {
        setStatus({ loading: false, isValidated: true });
        setTimeout(() => history.replace(routeUtils.userProfile()), 3 * 1000);
      })
      .catch(e => {
        setStatus({ loading: false, isValidated: false });
        setFlash(e.flashProps);
      });
  }, [history, search, setStatus]);

  if (status.loading) {
    return <Loading text="" />;
  }

  if (status.isValidated) {
    return (
      <ModuleSegment title="Email Verification Success">
        <Container>Your email has been verified. This page will redirect in 5 seconds.</Container>
      </ModuleSegment>
    );
  }

  return (
    <ModuleSegment title="">
      <Flash content={flash} />

      {!flash && <Loading text="Verifying Email" />}

      <Container>
        <Link to="/">Go back to login</Link>
      </Container>
    </ModuleSegment>
  );
}
