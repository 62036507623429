import _ from 'lodash';
import { Illuminant, Observer } from '@variablecolor/colormath';
import AWSPubSub from '@api/aws/AWSPubSub';
import config from '../../config';
import api from '../fetch2';
import { Palette, PaletteItem, Scan } from './model';
import { ProductGroupID } from '@api/cas/models';
import cas from '@api/cas';
import { ProductColorDataPoint } from '@utils/three/ThreeContainer';
import { PaletteJSON } from './model/Palette';
import { PaletteItemJSON } from './model/PaletteItem';
import { ProductJSON } from './model/Product/Product';

export type SearchQuery = {
  query: any;
  limit?: number;
  skip?: number;
  sort?: string[];
};
export type ScanHistoryResponse = {
  scans: Array<Scan>;
  dataPoints: ProductColorDataPoint[];
  count: number;
};
export type PaletteSearchResponse = {
  palettes: Array<Palette>;
  count: number;
  last_updated_at: Date;
};

export type PaletteItemSearchResponse = {
  items: Array<PaletteItem>;
  count: number;
};

type PaletteMigrationRequestOptions = {
  product_group_id: ProductGroupID;
  palette_ids: string[];
  update_library: boolean;
};

type PaletteItemMigrationRequestOptions = {
  product_group_id: ProductGroupID;
  palette_item_ids: string[];
  update_library: boolean;
};

class AppDataWebService {
  static fetchOptions = {
    get subscriptionID() {
      return cas.getUser()?.subscriptions[0].id;
    },
  };

  async deletePaletteItems(paletteID: string) {
    let res: PaletteItemSearchResponse = { items: [], count: -1 };
    while (res.count !== res.items.length && res.count !== 0) {
      console.log('deleting');
      res = await api.post(
        config.app_data.search_palette_items,
        {
          limit: 250,
          query: { isDeleted: false, paletteID },
          sort: ['locallyUpdatedAt:-1'],
        },
        AppDataWebService.fetchOptions,
      );
      if (res.items.length > 0) {
        res.items.forEach(x => {
          x.isDeleted = true;
        });

        await api.post(config.app_data.palette_items, { paletteItems: res.items }, AppDataWebService.fetchOptions);
      }
    }
  }

  async addPaletteItems(p: Palette): Promise<Palette> {
    const { items, count } = await api.post<{ items: PaletteItemJSON[]; count: number }>(
      config.app_data.search_palette_items,
      {
        limit: 10,
        query: { isDeleted: false, paletteID: p.uuid },
        sort: ['locallyUpdatedAt:-1'],
      },
      AppDataWebService.fetchOptions,
    );

    p.paletteItems = items
      .filter(({ isDeleted }) => !isDeleted)
      .filter(item => !item.chromaReading || (item.chromaReading && item.chromaReading.senseValues))
      .filter(item => !item.product || item.product.source !== 'pantone-bridge-api')
      .map(itemJSON => new PaletteItem(itemJSON));
    p.paletteItemCount = count;

    return p;
  }

  async _fetchReadings(endpoint: string): Promise<{ count: number; scans: [] }> {
    const body = {
      limit: 250,
      query: {
        createdAt: {
          $gt: new Date('07/10/2017').toISOString(),
        },
        isDeleted: false,
        // This is for ChromaReading... but querying against spectrums has no side effect.
        sensevalues: endpoint.indexOf('scans') !== -1 ? { $ne: null } : undefined,
      },
      sort: ['-createdAt'],
    };

    //count is the total count of scans this user has available....
    const json: { count: number; scans: [] } = await api.post(endpoint, body, AppDataWebService.fetchOptions);

    // Use total_scans available to calculate the number of requests needed.
    const chunks = Math.ceil(json.count / body.limit);

    const promises = [];
    for (let i = 1; i < chunks; i++) {
      const func = async (skip: number) => {
        const params = {
          ...body,
          query: {
            ...body.query,
            skip,
          },
        };
        const temp: { count: number; scans: [] } = await api.post(endpoint, params, AppDataWebService.fetchOptions);

        json.scans.push(...temp.scans);
      };

      promises.push(func(i * body.limit));
    }

    await Promise.all(promises);

    return json;
  }

  async fetchReading(id: string): Promise<Scan> {
    const query = {
      _id: {
        $eq: id.toLowerCase(),
      },
    };

    try {
      let results = await api.post<{ scans: Array<any>; count: number }>(
        config.app_data.spectrums_all,
        { query },
        AppDataWebService.fetchOptions,
      );
      if (results.count === 0) {
        results = await api.post(config.app_data.scans_all, { query }, AppDataWebService.fetchOptions);
      }

      if (results.count === 0) {
        throw new Error('failed to locate scan by id');
      }

      return Scan.fromJSON(results.scans[0]);
    } catch (err) {
      console.warn(err);
      throw err;
    }
  }

  async fetchReadings(): Promise<ScanHistoryResponse> {
    const scans = await Promise.all([
      this._fetchReadings(config.app_data.spectrums_all),
      this._fetchReadings(config.app_data.scans_all),
    ]);

    const flatten = {
      count: 0,
      dataPoints: [] as ProductColorDataPoint[],
      scans: [] as Scan[],
    };

    scans.forEach(s => {
      s.scans.forEach((sJSON: any) => {
        if (sJSON.chromaReading && !sJSON.chromaReading.senseValues) {
          return;
        }
        const scan = Scan.fromJSON(sJSON);
        flatten.scans.push(scan);
        flatten.dataPoints.push({
          color_index: flatten.scans.length - 1,
          hex: scan.hex(),
          lab: scan.labColor(Illuminant.D50, Observer.TWO_DEGREE),
          product_group_id: undefined,
          product_id: scan.uuid,
        });
      });

      flatten.count += s.count;
    });

    flatten.scans = flatten.scans.sort(
      (a, b) => new Date(b.locallyUpdatedAt).getTime() - new Date(a.locallyUpdatedAt).getTime(),
    );
    return flatten;
  }

  async uploadSpectrumReadings(scans: Scan[]) {
    return api.post(
      config.app_data.spectrums,
      { scans: scans.map(s => s.toServerJSON()) },
      AppDataWebService.fetchOptions,
    );
  }

  async searchPaletteItems(query: SearchQuery): Promise<PaletteItemSearchResponse> {
    const json = await api.post<{ items: PaletteItemJSON[]; count: number }>(
      config.app_data.search_palette_items,
      query,
      AppDataWebService.fetchOptions,
    );

    return {
      items: json.items.map((x: PaletteItemJSON) => new PaletteItem(x)),
      count: json.count,
    };
  }

  async searchPalettes(query: SearchQuery): Promise<PaletteSearchResponse> {
    const json = await api.post<{ palettes: PaletteJSON[]; count: number }>(config.app_data.palettes_all, query, {
      ...AppDataWebService.fetchOptions,
      'No-Items': 'T',
    });

    const result: PaletteSearchResponse = {
      palettes: json.palettes.map((p: PaletteJSON) => new Palette(p)),
      count: json.count,
      last_updated_at: new Date(),
    };

    result.palettes = await Promise.all(result.palettes.map(this.addPaletteItems));
    result.last_updated_at = _(json.palettes)
      .map('updatedAt')
      .map(d => new Date(d))
      .max()!;

    result.palettes.sort((a: Palette, b: Palette) => {
      return new Date(b.locallyUpdatedAt).getTime() - new Date(a.locallyUpdatedAt).getTime();
    });

    return result;
  }

  async uploadPaletteItems(paletteItems: PaletteItem[]) {
    return api.post(
      config.app_data.palette_items,
      { paletteItems: paletteItems.map(item => item.toServerJSON()) },
      AppDataWebService.fetchOptions,
    );
  }

  async exportQTX(items: any) {
    return api.post(config.app_data.generic_qtx_export, { items }, AppDataWebService.fetchOptions);
  }

  async uploadPalettes(palettes: Palette[]) {
    if (palettes.length === 0) {
      return;
    }

    api.post(
      config.app_data.palettes,
      { palettes: palettes.map(p => p.toServerJSON()) },
      AppDataWebService.fetchOptions,
    );

    // This may be a more efficient, but appside it would force a full sync for each publish
    // palettes.map(palette => AWSPubSub.publish(AWSPubSub.palettesTopic(palette.id), { palette }));

    AWSPubSub.publish(AWSPubSub.palettesTopic(palettes[0].uuid), {
      msg: 'sync',
    });
  }

  async migratePaletteItems(options: PaletteItemMigrationRequestOptions) {
    return api.post(config.app_data.migrate_palette_items, options, AppDataWebService.fetchOptions);
  }

  //migrates palettes (update_library saves generated products on the VPAPI server)
  async migratePalette(options: PaletteMigrationRequestOptions) {
    return api.post<ProductJSON[]>(config.app_data.migrate_palette, options, AppDataWebService.fetchOptions);
  }

  async downloadASE(palette_item_ids: string[]) {
    return api.post<{ export_id: string }>(
      config.app_data.ase_download,
      { palette_item_ids },
      AppDataWebService.fetchOptions,
    );
  }

  async aggregateScan<T>(opts: { search: any[]; options?: { timeout: number } }) {
    return api.post<T>(`${config.app_data.scans}/_agg`, opts, AppDataWebService.fetchOptions);
  }

  async aggregateSpectrumScan(opts: { search: any; options?: { timeout: number } }) {
    return api.post(`${config.app_data.spectrums}/_agg`, opts, AppDataWebService.fetchOptions);
  }

  analytics = {
    async aggregateColorScanData<T>(brandID: string, opts: any = {}) {
      return api.post<{ docs: T[]; count: number }>(
        `${config.app_data.analytics}/brands/${brandID}/_colorscan`,
        opts,
        AppDataWebService.fetchOptions,
      );
    },

    async userAggregation<T>(email: string, collection: 'scans' | 'spectrums', opts: any = {}) {
      return api.post<{ docs: T[]; count: number }>(
        `${config.app_data.analytics}/users/${encodeURI(email)}/${collection}`,
        opts,
      );
    },
  };
}

const appData = new AppDataWebService();
export default appData;
