import * as React from 'react';
import papa from 'papaparse';
import styled from 'styled-components';
import { downloadContent } from '@utils';

import cas from '../../../api/cas';
import type { PlatformDetailPackageUI, PlatformDetailT } from '../types';
import {
  AlternateText,
  Button,
  Checkbox,
  FlexColumns,
  FlexRows,
  LabeledInput,
  ModuleSegment,
  PrimaryText,
  StatusBar,
} from '../../common';

import { dimensions } from '../../../utils';
import PackageSelection from './PackageSelection';

import { ExclusivePackages, DefaultPackages } from './PackageGrid';
import theme from '@theme';

type PlatformHandlerFunc = (p: PlatformDetailT) => void;
type ViewProps = {
  platform: PlatformDetailT;
  onChange: PlatformHandlerFunc;

  refreshPlatform: Function;

  onSave: PlatformHandlerFunc;
  onReset: () => void;
};

const FlexGrid = styled(FlexColumns)`
  margin: ${theme.dimensions.contentPadding};
  flex-wrap: wrap;
  flex-direction: none;
`;

// padding 2.5% x3
interface WidthProps {
  full?: boolean;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
}
function getWidths(props: WidthProps) {
  if (props.full || dimensions().width <= 1024) {
    return `
      min-width: 100%;
    `;
  }

  if (props.large) {
    return `
      min-width: 70%;
      max-width: 70%;
    `;
  }

  if (props.medium) {
    return `
      min-width: 48%;
      max-width: 48%;
    `;
  }

  if (props.small) {
    return `
      max-width: 26%;
    `;
  }

  return '';
}

const HelpModalInfo = {
  Content: () => (
    <FlexRows>
      <PrimaryText fontWeight="bold">Redeem any Package</PrimaryText>
      <AlternateText>
        By setting an app to Redeem any Packages, a user can scan app codes that are not redeemable (exclusive) in
        another app. Mobile app shows the package selection screen.
      </AlternateText>
      <br />
      <PrimaryText fontWeight="bold">Restrict Package Redemption</PrimaryText>
      <AlternateText>
        By setting an app to Restrict Package Redemption, a user can only scan app codes that are listed in the
        redeemable packages section. By dragging packages into the Redeemable Packages section, these packages are only
        redeemable inside this app, unless another app has them marked as redeemable. Mobile app shows the package
        selection screen.
      </AlternateText>
      <br />
      <PrimaryText fontWeight="bold">No Package Redemption</PrimaryText>
      <AlternateText>
        By setting an app to No Package Redemption, a user cannot redeem any packages inside the app. They are only are
        presented with a list of their subscriptions if the app has multiple default packages.
      </AlternateText>
    </FlexRows>
  ),
  title: 'App Configuration Help',
};

const Container = styled(FlexRows).attrs({ id: 'container' })`
  ${(props: WidthProps) => getWidths(props)};
  margin-right: 1%;
`;

export default function PlatformDetail({ platform, onReset, onSave, refreshPlatform, onChange }: ViewProps) {
  const downloadUserList = async () => {
    const users = await cas.platforms.getUsers(platform.id);

    downloadContent(`${platform.name}_users_${Date.now()}.csv`, papa.unparse(users, { delimiter: ',', header: true }));
  };

  const { default_packages, exclusive_packages } = platform;
  const hasDirtyPackages =
    (default_packages as PlatformDetailPackageUI[]).find(x => x.is_dirty) ||
    (exclusive_packages as PlatformDetailPackageUI[]).find(x => x.is_dirty);

  const handlePlatformPackageSave = async () => {
    let packages = [
      ...(platform.default_packages as PlatformDetailPackageUI[]).filter(p => p.is_dirty),
      ...(platform.exclusive_packages as PlatformDetailPackageUI[]).filter(p => p.is_dirty),
    ].map(p => ({ ...p, platform_id: platform.id }));

    await cas.platforms.alterPlatformPackages({
      add_packages: packages.filter(
        p => (p.is_dirty === 'is_default' && p.is_default) || (p.is_exclusive && p.is_dirty === 'is_exclusive'),
      ),
      delete_packages: packages.filter(
        p => (p.is_dirty === 'is_default' && !p.is_default) || (p.is_dirty === 'is_exclusive' && !p.is_exclusive),
      ),
      platform_id: platform.id,
    });

    refreshPlatform();
  };

  return (
    <div>
      <FlexGrid>
        <Container medium>
          <ModuleSegment
            helpModal={HelpModalInfo}
            title={ModuleSegment.ModuleTitle(`${platform.name} App`, 'App Configuration')}
            containerStyle={{ marginTop: 0, width: '100%' }}
            bodyStyle={{ marginTop: 12 }}
            buttons={[
              <Button
                outline
                text="Download User List"
                key="download-users"
                color={theme.button.actionColor}
                onClick={downloadUserList}
              />,
            ]}
          >
            <LabeledInput
              label="id"
              inputProps={{
                disabled: true,
                value: platform.id,
              }}
            />
            <LabeledInput
              label="name"
              inputProps={{ value: platform.name }}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
                platform.name = e.currentTarget.value;

                onSave(platform);
              }}
            />

            <Checkbox
              onCheckChange={(_e: React.SyntheticEvent<HTMLDivElement>, checked: boolean) => {
                platform.allow_redeeming_package_codes = checked;
                onSave(platform);
              }}
              text="Enable Package Redemption via Code"
              checked={platform.allow_redeeming_package_codes}
            />

            <Checkbox
              text="Restrict App Packages"
              checked={platform.only_platform_packages}
              onCheckChange={(_e: React.SyntheticEvent<HTMLDivElement>, checked: boolean) => {
                platform.only_platform_packages = checked;
                onSave(platform);
              }}
            />

            <Checkbox
              checked={platform.display_region_selection}
              text="Enable Region Selection"
              onCheckChange={(_e: React.SyntheticEvent<HTMLDivElement>, checked: boolean) => {
                platform.display_region_selection = checked;
                onSave(platform);
              }}
            />
          </ModuleSegment>

          <DefaultPackages
            onItemClick={(p: PlatformDetailPackageUI) => {
              if (p.is_dirty) {
                p.is_dirty = undefined;
              } else {
                p.is_dirty = 'is_default';
                p.is_default = !p.is_default;
              }

              onChange(platform);
            }}
            platform={platform}
            onDrop={p => {
              p.is_dirty = 'is_default';
              p.is_default = true;

              onChange(platform);
            }}
          />

          <ExclusivePackages
            onItemClick={(p: PlatformDetailPackageUI) => {
              if (p.is_dirty) {
                p.is_dirty = undefined;
              } else {
                p.is_dirty = 'is_exclusive';
                p.is_exclusive = !p.is_exclusive;
              }
              onChange(platform);
            }}
            platform={platform}
            onDrop={p => {
              p.is_dirty = 'is_exclusive';
              p.is_exclusive = true;

              onChange(platform);
            }}
          />
        </Container>

        <Container medium>
          <PackageSelection platform={platform} />
        </Container>
      </FlexGrid>
      <StatusBar show={hasDirtyPackages !== undefined} handleSave={handlePlatformPackageSave} handleReset={onReset} />
    </div>
  );
}
