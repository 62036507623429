import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import cas from '@api/cas';
import { Button, CardView, FlexColumns, Loading, Modal, ModuleSegment, SearchBar } from '../common';

import routeUtils from '@utils/route-utils';
import theme from '@theme';
import { Platform } from '@api/cas/models';

function usePlatforms() {
  let [all, setAllPlatforms] = React.useState<Platform[]>([]);
  const [platforms, setPlatforms] = React.useState<Platform[]>([]);

  React.useEffect(() => {
    cas.platforms
      .all()
      .then((arr: Platform[]) => {
        setAllPlatforms([...arr]);
        setPlatforms(arr);
      })
      .catch(console.log);
  }, [setAllPlatforms, setPlatforms]);

  return {
    platforms,

    filterPlatforms: React.useCallback(
      (term: string) => {
        const searchTerm = term.toLowerCase();

        setPlatforms(all.filter((p: Platform) => p.name.toLowerCase().includes(searchTerm)));
      },
      [setPlatforms, all],
    ),
  };
}

export default function Platforms() {
  const history = useHistory();
  const { platforms, filterPlatforms } = usePlatforms();

  if (!platforms) {
    return <Loading text="Loading Apps" />;
  }

  const buttons = [
    <NavLink key="_create" to="/apps/_create">
      <Button text="Create App" color={theme.button.createColor} />
    </NavLink>,
  ];
  return (
    <Modal modalProps={{ defaultOpen: true, onClose: history.goBack }}>
      <ModuleSegment title="Apps" buttons={buttons}>
        {/* <Debug data={platforms} /> */}
        <SearchBar
          onSubmit={filterPlatforms}
          onChange={({ currentTarget: { value } }) => {
            filterPlatforms(value);
          }}
        />
        <FlexColumns>
          {platforms.map(p => (
            <NavLink key={p.id} to={routeUtils.platformDetail(p.id)}>
              <CardView key={p.id} lines={[{ text: p.name }, { text: p.id }, { text: '' }]} />
            </NavLink>
          ))}
        </FlexColumns>
      </ModuleSegment>
    </Modal>
  );
}
