import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import theme from '../../theme';

const Colorbar = styled.div`
  width: 100%;
  height: 12px;

  display: flex;
  flex-direction: row;
`;

const ImageItemImage = styled.img<{ withColorBar?: boolean; height: number }>`
  height: ${({ withColorBar = false, height }) => (withColorBar ? height - 12 : height)}px;

  width: 100%;
  object-fit: contain;
`;

const PreviewContainer = styled.div<{ height?: number }>`
  height: ${({ height }) => height || 128}px;
  width: ${({ height }) => height || 128}px;

  overflow: hidden;

  display: flex;
  flex-direction: none;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
`;

const ColorItem = styled.div<{
  height: number;
  width: number;
  backgroundColor: string;
  border?: boolean;
}>`
  min-height: ${({ height }) => height}%;

  min-width: ${({ width }) => width}%;
  max-width: 100%;

  flex: 1;
  flex-grow: 1;

  background: ${props => props.backgroundColor};

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ border }) => {
    if (border) {
      return `
        border-width: 2px;
        border-style: dashed;
        border-color: ${theme.border.neutral};
      `;
    }

    return '';
  }}
`;

type ViewProps = {
  hex_colors?: string[];
  image_url?: string;
  height: number;
};
// 8  items
//  - max(4 cols)  and min(1 col)
//  - max(4 rows)  and min(1 row)
// 1.0 / (8 % 4)  = 50%
class ProductPreview extends React.Component<ViewProps> {
  render() {
    const { image_url, height } = this.props;
    let { hex_colors } = this.props;

    if (image_url) {
      if (hex_colors && hex_colors.length > 0) {
        hex_colors = hex_colors.splice(0, Math.min(hex_colors.length, 6));

        return (
          <PreviewContainer height={height}>
            <ImageItemImage height={96} withColorBar src={image_url!} />
            <Colorbar>
              {hex_colors.map(backgroundColor => (
                <div
                  key={_.uniqueId('hex_')}
                  style={{
                    backgroundColor,
                    width: (height || 128) / hex_colors!.length,
                  }}
                />
              ))}
            </Colorbar>
          </PreviewContainer>
        );
      }

      return (
        <PreviewContainer height={height}>
          <ImageItemImage height={height} src={image_url} />
        </PreviewContainer>
      );
    }

    if (hex_colors && hex_colors.length > 0) {
      //max_cols = (number_of_items % max_rows) -  height = 100.0 / max_cols
      const columns = Math.ceil(Math.sqrt(hex_colors.length));
      const rows = Math.ceil(hex_colors.length / (1.0 * columns));

      const itemHeight = 100 / rows;
      const width = 100 / columns;

      return (
        <PreviewContainer height={height}>
          {hex_colors.map(bgColor => (
            <ColorItem width={width} height={itemHeight} backgroundColor={bgColor} key={_.uniqueId('color_')} />
          ))}
        </PreviewContainer>
      );
    }

    return (
      <PreviewContainer height={height}>
        <ColorItem border height={100} width={100} backgroundColor={theme.table.rowBackground}>
          <img
            alt="no product color"
            style={{
              height: 72,
              objectFit: 'contain',
              width: 72,
            }}
            src="/icons/empty_product.png"
          />
        </ColorItem>
      </PreviewContainer>
    );
  }
}

export default ProductPreview;
// 1- ((18,900 - (15,180.61+1,239.43)) / 7) / 1,239
// (1239.43 *  (0.71 * 0.3)) * 7 = 1,841
