import * as React from 'react';

import validator from 'email-validator';
import moment from 'moment';
import cas from '@api/cas';
import type { Purchase } from '@api/cas/models';

import theme from '@theme';
import {
  Button,
  Flash,
  FlashContentT,
  FlexColumns,
  LabeledInput,
  Modal,
  ModuleSegment,
  PermissionItem,
} from '@components/common';

type ModalState = {
  input: string;
  didChange: boolean;
  flash?: FlashContentT;
};

const defaultModalState = {
  didChange: false,
  flash: undefined,
  input: '',
};

type Props = {
  purchase: Purchase;
  onClose: (x: Purchase) => () => void;
};
function SubscribeModal({ purchase, onClose }: Props) {
  const [state, setState] = React.useState<ModalState>(defaultModalState);
  return (
    <Modal modalProps={{ defaultOpen: true, onClose: onClose(purchase) }}>
      <ModuleSegment
        title={ModuleSegment.ModuleTitle('Subscribe User', 'Distribute your digital license(s) to your team members')}
      >
        <Flash content={state.flash} />
        <LabeledInput
          onChange={(_, { value }) => setState({ ...state, input: value })}
          inputProps={{
            placeholder: 'Enter an email associated with a Variable Cloud account.',
            value: state.input,
          }}
          label="Email"
        />

        <FlexColumns style={{ justifyContent: 'flex-end', marginTop: 12 }}>
          <Button
            outline
            text="Subscribe"
            color={theme.button.createColor}
            onClick={async () => {
              if (purchase.redemptions.length >= purchase.quantity) {
                setState({
                  ...state,
                  flash: {
                    color: 'red',
                    message: 'All digital licenses are in use.',
                  },
                });
                return;
              }
              if (!validator.validate(state.input)) {
                setState({
                  ...state,
                  flash: {
                    color: 'red',
                    message: 'Please enter a complete and valid email.',
                  },
                });
                return;
              }
              try {
                const redemption = purchase.redemptions.find(x => x.email === state.input);
                if (redemption) {
                  setState({
                    ...state,
                    flash: {
                      color: 'red',
                      message: `${state.input} already has a redemption for this purchase.`,
                    },
                  });
                  return;
                }
                await cas.subscribeUser({
                  email: state.input,
                  redeemable_code: purchase.code,
                });

                purchase.redemptions.push({
                  created_at: moment().toISOString(),
                  email: state.input,
                  name: '',
                  roles: {
                    is_user: 1,
                  },
                });

                setState({
                  ...state,
                  didChange: true,
                  flash: {
                    color: 'green',
                    message: `Success. You have ${purchase.quantity - purchase.redemptions.length} left`,
                  },
                });
              } catch (err: any) {
                setState({
                  ...state,
                  flash: err.flashProps as FlashContentT,
                });
              }
            }}
          />
        </FlexColumns>
      </ModuleSegment>
    </Modal>
  );
}

export function posFromSourceType(source: string) {
  switch (source) {
    case 'shopify':
      return 'variableinc.com';

    case 'Apple-iap':
      return 'Apple';
  }

  return 'unknown';
}

export default function ({
  purchase,
  onPurchaseChange,
}: {
  purchase: Purchase;
  onPurchaseChange: (p: Purchase) => void;
}) {
  const buttons = [];
  const [show, setModalStatus] = React.useState<boolean>(false);
  if (show) {
    return (
      <SubscribeModal
        purchase={purchase}
        onClose={x => () => {
          onPurchaseChange(x);
          setModalStatus(false);
        }}
      />
    );
  }

  if (purchase.status !== 'EXPIRED') {
    if (purchase.redemptions.length < purchase.quantity) {
      buttons.push(
        <Button
          color={theme.button.actionColor}
          text="Add User"
          onClick={() => {
            setModalStatus(true);
          }}
        />,
      );
    }
  }
  return (
    <ModuleSegment containerStyle={{ paddingLeft: 0, paddingRight: 0 }} title="Purchase Details">
      <LabeledInput label="STATUS" inputProps={{ disabled: true, value: purchase.status }} />
      <LabeledInput label="CODE" inputProps={{ disabled: true, value: purchase.code }} />
      <LabeledInput label="PURCHASED BY" inputProps={{ disabled: true, value: purchase.email }} />
      <LabeledInput
        label="PURCHASED DATE"
        inputProps={{
          disabled: true,
          value: moment(purchase.created_at).format('LLL'),
        }}
      />
      <LabeledInput
        label="EXPIRES ON"
        inputProps={{
          disabled: true,
          value: moment(purchase.expires_at).format('LLL'),
        }}
      />

      <ModuleSegment
        containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
        title={ModuleSegment.ModuleTitle(
          'User Access',
          `${purchase.redemptions.length} of ${purchase.quantity} Usages`,
        )}
        buttons={buttons}
      >
        <FlexColumns>
          {purchase.redemptions.map(x => (
            <PermissionItem
              roles={x.roles}
              key={x.email}
              email={x.email}
              handleRevoke={() => {
                onPurchaseChange({
                  ...purchase,
                  redemptions: purchase.redemptions.filter(y => y.email !== x.email),
                });

                cas.users.reclaimPurchase({
                  code: purchase.code,
                  email: x.email,
                });
              }}
            />
          ))}
        </FlexColumns>
      </ModuleSegment>
    </ModuleSegment>
  );
}
