import * as React from 'react';
import QueryString from 'query-string';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import AWSPubSub, { VariableCloudNotification } from '@api/aws/AWSPubSub';
import { EventSubscription } from 'fbemitter';

import vpapi from '../../api/vpapi/products';
import { SearchConfig } from '../../api/vpapi/SearchConfig';

import { Button, Loading, ModuleSegment, NoProductContent, SearchBar } from '../common';

import ProductFilterBar from './search/ProductFilterBar';

import { Pager } from '@utils';

import routes from '@utils/route-utils';
import { ProductList } from './ProductList';
import theme from '@theme';
import { Product, ProductGroup } from '@api/vpapi/models';
import routeUtils from '@utils/route-utils';
import User from '@api/cas/user';

const loadingState = {
  initial: 0,
  newPage: 2,
  none: -1,
  searchChanges: 1,
};

type ExternalProps = {
  user: User;

  showModuleButtons?: boolean;
  showFilters?: boolean;

  productGroup: ProductGroup;

  zeroProducts?: any;

  // When undefined, <Products /> component navigated to edit the product
  onProductClick?: (product: Product) => void;
};
type ViewProps = ExternalProps & {
  location: ReturnType<typeof useLocation>;
  history: ReturnType<typeof useHistory>;
};

type ViewState = {
  loading: number;
  products: Product[];
  pager: Pager;
};
class Products extends React.Component<ViewProps, ViewState> {
  static defaultProps = {
    onProductClick: undefined,
    showFilters: true,
    showModuleButtons: true,
    zeroProducts: NoProductContent,
  };

  iScroll: { current: null | HTMLDivElement } = React.createRef();

  awsSubscription?: EventSubscription;

  searchConfig: SearchConfig;

  state: ViewState = {
    loading: loadingState.initial,
    pager: Pager.fromParams(100),
    products: [],
  };

  constructor(props: ViewProps) {
    super(props);

    const params = QueryString.parse(props.location.search);
    this.searchConfig = SearchConfig.fromParams(this.onConfigChange, params);
    this.searchConfig.filter.cuuids.push(props.productGroup.dbid);
  }

  shouldComponentUpdate = (_: ViewProps, nextState: ViewState): boolean => {
    const { products, loading } = this.state;
    if (loading !== nextState.loading) {
      return true;
    }

    if (!products || !nextState.products) {
      return true;
    }

    if (products.length !== nextState.products.length) {
      return true;
    }

    /** two arrays are of equal length */
    if (products.length > 0) {
      // let's do a peeksy to see if this update is a page update
      if (products[0].dbid !== nextState.products[0].dbid) {
        return true;
      }
    }

    return false;
  };

  componentDidMount() {
    this.awsSubscription = AWSPubSub.emitter.addListener(
      AWSPubSub.eventNames.productGroupNotification,
      this.handlePubSubNotification,
    );
    this.setState({ loading: loadingState.initial });
    this.updateProducts();
  }

  componentWillUnmount() {
    if (this.awsSubscription) {
      this.awsSubscription.remove();
    }
  }

  setupScrollRef = (ref: HTMLDivElement | null) => {
    this.iScroll = { current: ref };
    // Binds our scroll event handler
    if (this.iScroll.current) {
      this.iScroll.current.addEventListener('scroll', this.componentDidScroll);
    }
  };

  componentDidScroll = () => {
    const { pager } = this.state;
    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    if (!pager.hasMore) {
      console.log('end of product content');
      return;
    }
    const { current } = this.iScroll;
    if (!current) {
      return;
    }

    // Checks that the page has scrolled to the bottom
    if (current.scrollTop + current.clientHeight >= current.scrollHeight * 0.9) {
      this.setState({ loading: loadingState.newPage }, () => {
        pager.nextPage();
        this.updateProducts();
      });
    }
  };

  onConfigChange = () => {
    this.setState({ loading: loadingState.initial, products: [], pager: Pager.fromParams(100) }, () => {
      this.updateProducts();
      this.navigate();
    });
  };

  handlePubSubNotification = ({ id, type }: VariableCloudNotification) => {
    const { productGroup } = this.props;
    if (type !== 'product-group' || !productGroup || id !== productGroup.dbid) {
      return;
    }

    this.setState({ loading: loadingState.initial, products: [] });
    console.log('load products from pubsub noti for this product group');
    this.updateProducts();
  };

  handleSearchTermChange = (searchTerm: string) => {
    this.searchConfig.searchTerm = searchTerm;
    this.onConfigChange();
  };

  updateProducts = async () => {
    const { pager } = this.state;

    const opts = {
      from: pager.from,
      searchConfig: this.searchConfig,
      size: pager.perPage,
    };

    let json: { docs: Product[]; count: number } = { docs: [], count: 0 };
    try {
      json = await vpapi.fetchProducts(opts);

      pager.numItems = json.count;
    } catch (err) {
      alert('Failed fetching products');
      console.error(err);
    } finally {
      const { products } = this.state;

      products.push(...json.docs);

      this.setState({
        // This is a hacky and unperformant way to make sure the state is updated from shouldComponentUpdate
        loading: loadingState.none,
        products,
      });
    }
  };

  onProductClick = (p: Product) => {
    const { onProductClick, history } = this.props;
    if (onProductClick) {
      onProductClick(p);
      return;
    }

    history.push(routes.editProduct(p));
  };

  //sets URL params based on searchTerm / page
  navigate = () => {
    // Use the location
    const { location, history } = this.props;
    const queryString = this.searchConfig.toQueryString();
    history.push(`${location.pathname}?${queryString}`);
  };

  get moduleButtons() {
    const { productGroup, showModuleButtons, user } = this.props;
    if (!showModuleButtons) {
      return undefined;
    }

    const buttons = [];

    buttons.push(
      <NavLink key="product-creator" to={routeUtils.productImport(productGroup.dbid)}>
        <Button text="Add Products" color={theme.button.actionColor} />
      </NavLink>,
    );

    if (productGroup.product_count > 0 && user.hasPaidFeature('vizualizations')) {
      buttons.push(
        <NavLink key="visualize" to={routes.productVizualization(productGroup.dbid)}>
          <Button outline text="Visualize" color={theme.button.alternativeActionColor} />
        </NavLink>,
      );
    }

    if (user.hasPaidFeature('bulk_edit')) {
      buttons.push(
        <NavLink to={routeUtils.bulkEdit(productGroup.dbid)} key="bulk-edit">
          <Button outline text="Bulk Editing" color={theme.button.alternativeActionColor} />
        </NavLink>,
      );
    }

    buttons.push(
      <NavLink key="details" to={routes.productGroupSettings(productGroup.dbid)}>
        <Button outline text="Library Details" color={theme.button.neutralColor} />
      </NavLink>,
    );

    return buttons;
  }

  render() {
    const { productGroup, showFilters, zeroProducts: NoProductsDefined, location } = this.props;
    const { loading, products, pager } = this.state;

    const params = QueryString.parse(location.search);
    if (loading === loadingState.initial) {
      return <Loading text="Loading Products" />;
    }

    if (products.length === 0 && pager.page === 1 && !params.search) {
      return (
        <ModuleSegment
          title={`${productGroup.name}`}
          containerStyle={{ alignItems: 'center', justifyContent: 'center' }}
          buttons={this.moduleButtons}
        >
          {NoProductsDefined && NoProductsDefined()}
          {!NoProductsDefined && <div />}
        </ModuleSegment>
      );
    }

    return (
      <ModuleSegment
        title={`${productGroup.name}`}
        buttons={this.moduleButtons}
        containerStyle={{ alignItems: 'center', justifyContent: 'center' }}
        bodyStyle={{ marginTop: 18 }}
      >
        {showFilters && (
          <div>
            <SearchBar
              inputProps={{ defaultValue: (params.search as string) || '' }}
              onSubmit={this.handleSearchTermChange}
            />
            <ProductFilterBar searchConfig={this.searchConfig} />
          </div>
        )}

        <div ref={this.setupScrollRef} style={{ maxHeight: 768, overflowY: 'auto' }}>
          <ProductList products={products} onItemClick={this.onProductClick} />
          {loading === loadingState.newPage && <Loading text="Fetching more products" />}
        </div>
      </ModuleSegment>
    );
  }
}

export default function __Page(props: ExternalProps) {
  const location = useLocation();
  const history = useHistory();

  return <Products {...props} history={history} location={location} />;
}
