const baseURL: string = process.env.REACT_APP_CAS_URL || 'https://gdn.colourcloud.net/cas';
const baseFormulationURL: string = process.env.REACT_APP_FORMULA_URL || 'https://gdn.colourcloud.net/cf';
const baseVPURL: string = process.env.REACT_APP_VP_URL || 'https://gdn.colourcloud.net/vp';
const baseAppDataURL: string = process.env.REACT_APP_AD_URL || 'https://gdn.colourcloud.net/appdata';

const config = {
  api_token: 'b942485c-693f-4746-ace2-2489dce18e6a',

  request_form_url: 'https://www.variableinc.com/variable-cloud.html#request',
  app_data: {
    analytics: `${baseAppDataURL}/analytics`,
    ase_download: `${baseAppDataURL}/v1/paletteItems/_ase`,
    ase_file_download_url: (exportID: string) => `${baseAppDataURL}/v1/ase/${exportID}/_download`,
    generic_qtx_export: `${baseAppDataURL}/v1/exportables/_qtx`,
    image_upload: `${baseAppDataURL}/v1/images/_upload`,
    migrate_palette: `${baseAppDataURL}/v1/palettes/_migrate`,
    migrate_palette_items: `${baseAppDataURL}/v1/paletteItems/_migrate`,
    palette_items: `${baseAppDataURL}/v1/paletteItems`,
    search_palette_items: `${baseAppDataURL}/v1/paletteItems/_search`,
    palette_share: `${baseAppDataURL}/inspire/share`,
    palettes: `${baseAppDataURL}/v1/palettes`,
    palettes_all: `${baseAppDataURL}/v1/palettes/_all`,
    palettes_search: `${baseAppDataURL}/v1/palettes/_search`,
    scans: `${baseAppDataURL}/v1/scans`,
    scans_all: `${baseAppDataURL}/v1/scans/_all`,
    // scans_search: `${baseAppDataURL}/v1/scans/_search`,
    spectrums: `${baseAppDataURL}/v1/spectrums`,
    spectrums_all: `${baseAppDataURL}/v1/spectrums/_all`,
    // spectrums_search: `${baseAppDataURL}/v1/spectrums/_search`,
  },

  getPlatformID: () => localStorage.getItem('platform_id') || 'Jxc692X7b',
  // google_analytics_id: 'UA-137133350-1',

  isDebug: () => process.env.NODE_ENV === 'development',

  server: {
    access_request: `${baseURL}/admin/_waitlist`,
    account: `${baseURL}/api/account`,
    account2: `${baseURL}/api/account2`,
    add_editable: `${baseVPURL}/editables/_add`,
    brand: `${baseURL}/admin/brand`,
    brand_summary: `${baseURL}/admin/brands`,
    bulk_products: `${baseVPURL}/products/_bulk`,
    vpapi: {
      bulk_measured_colors: `${baseVPURL}/products/_bulkColors`,
    },
    cas: {
      brand: `${baseURL}/admin/brand`,
      customer: `${baseURL}/admin/bt/customer`,
      free_tier_signup: `${baseURL}/admin/bt/variableCloud/subscriptions/_free`,
      plans: `${baseURL}/admin/bt/variableCloud/plans`,
      product_groups: `${baseURL}/admin/product_groups`,
      subscriptions: `${baseURL}/admin/bt/variableCloud/subscriptions`,
      transaction: `${baseURL}/admin/bt/variableCloud/subscriptions/_buy`,
    },
    editable_key: `${baseVPURL}/editables/_key`,
    editables: `${baseVPURL}/editables`,
    editables_value: `${baseVPURL}/editables/_value`,
    features: `${baseURL}/admin/features`,
    formulations: {
      bulk_product: (id: string) => `${baseFormulationURL}/formulation_group/${id}/products/_bulk`,
      color_formulas: (id: string) => `${baseFormulationURL}/formulation_group/${id}/color_formula`,
      customers: `${baseFormulationURL}/customer`,
      formulation_groups: `${baseFormulationURL}/formulation_group`,
      formulation_groups_refresh: (id: string) => `${baseFormulationURL}/formulation_group/${id}/_refresh`,
      formulation_models: (id: string) => `${baseFormulationURL}/formulation_group/${id}/formulation_model`,
      products: `${baseFormulationURL}/formulation_product`,
      train: `${baseFormulationURL}/_make_train_data`,
    },
    get_products: `${baseVPURL}/products`,
    imageTransfer: `${baseVPURL}/images/_xfer`,
    imageUpload: `${baseVPURL}/images/_upload`,
    image_extractions: `${baseVPURL}/images/_extract`,

    image_upload: `${baseURL}/admin/images/_upload`,

    login: `${baseURL}/admin/auth`,

    logout: `${baseURL}/me/logout`,

    me: `${baseURL}/me`,
    noti: `${baseURL}/api/_noti`,

    package_product_groups: `${baseURL}/admin/package_product_group`,
    packages: `${baseURL}/admin/packages`,

    paid_user_subscription: (email: string) =>
      `${baseURL}/admin/users/_subscription?user_id=${encodeURIComponent(email)}`,
    passwordReset: `${baseURL}/api/forgot_password`,
    platforms: `${baseURL}/admin/platforms`,
    productGroupsURL: `${baseVPURL}/productGroups`,
    product_groups: `${baseURL}/admin/product_groups`,
    productsURL: `${baseVPURL}/products`,

    purchases: `${baseURL}/admin/purchases`,

    remove_editables_value: `${baseVPURL}/editables/_remove`,
    subscribe: `${baseURL}/api/subscribe`,
    themes: `${baseURL}/admin/themes`,
    unsubscribe: `${baseURL}/api/unsubscribe`,

    user_features: (email: string) => `${baseURL}/admin/users/_features?user_id=${encodeURIComponent(email)}`,
    users: `${baseURL}/admin/users/_search`,
  },
};

if (config.getPlatformID() === null || config.getPlatformID() === undefined) {
  localStorage.setItem('platform_id', 'Jxc692X7b');
}
export default config;
