import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { round } from '@utils';
import { AlternateText, FlexColumns, ModuleSegment, PrimaryText, requiresSystemAdmin } from '@components/common';
import type { FormulationModel } from '@api/formulation/models';
import theme from '@theme';

//#region Type Definitions
type ViewProps = {
  models: FormulationModel[];
  padding?: any;
};
////#endregion

//#region Styled Components

const Card = styled.div`
  background-color: ${theme.card.alternateBackground};

  color: white;
  margin-right: 12px;
  margin-top: 12px;

  width: 30vh;

  min-width: 256px;

  padding: 12px;
`;
//#endregion

function renderFormulationModel(model: FormulationModel) {
  return (
    <Card key={model._id}>
      <PrimaryText>{moment(model.created_at).format('LLL')}</PrimaryText>
      <AlternateText>{model.is_default ? 'Active' : 'Archived'}</AlternateText>

      <div>
        {model.models.map(m => (
          <div
            style={{
              background: theme.card.headerBackground,
              lineHeight: '1.5em',
              marginTop: 6,
              padding: 12,
            }}
            key={m.base_id}
          >
            <PrimaryText>{`${m.base_id} (${m.specular})`}</PrimaryText>
            <AlternateText>{`TEST: [${round(m.test_min || 0, 3)}, ${round(m.test_max, 3)}]  X̄: ${round(
              m.test_avg,
              3,
            )} with ${m.test_samples}`}</AlternateText>
            <AlternateText>{`TRAIN: [${round(m.train_min || 0, 3)}, ${round(m.train_max, 3)}]  X̄: ${round(
              m.train_avg,
              3,
            )} with ${m.train_samples}`}</AlternateText>
          </div>
        ))}
      </div>
    </Card>
  );
}
export default requiresSystemAdmin(({ models, padding }: ViewProps) => {
  if (models.length === 0) {
    return null;
  }

  return (
    <ModuleSegment title="Model Statistics" containerStyle={{ padding }}>
      <FlexColumns style={{ flexWrap: 'wrap' }}>{models.map(renderFormulationModel)}</FlexColumns>
    </ModuleSegment>
  );
});
