import React from 'react';
import { Flash, FlashContentT, FlexRows, Loading } from '@components/common';
import cas from '@api/cas';
import type { SdkDetail, PackageID } from '@api/cas/models';
import PlatformSdkList from './SdkList';
import type { PlatformDetailT } from '../types';
import ModuleSegment from '@components/common/ModuleSegment';
import PackageSelection from '../PlatformDetail/PackageSelection';
import routeUtils from '@utils/route-utils';
import { useHistory } from 'react-router';
import { Optional } from '@app/utils/types';

type Props = {
  handleCreate: () => void | any;
  platform: PlatformDetailT;
};

function usePlatformSdkSegmentActions(handleCreate: () => void) {
  const [flash, setFlash] = React.useState<Optional<FlashContentT>>();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  return {
    flash,
    loading,
    setFlash,
    setLoading,
    createSDK: React.useCallback(
      (sdk: { package_code: string; developer_email: string; platform_id: string }) => {
        setLoading(true);

        cas
          .createSdk(sdk)
          .then(() => {
            setLoading(false);
            handleCreate();
          })
          .catch(e => {
            setLoading(false);
            setFlash(e.flashProps);
          });
      },
      [setFlash, setLoading, handleCreate],
    ),
    navigateToPackage: React.useCallback(
      (sdk: SdkDetail) => {
        // probably could use some cleanup....
        const { brand_id } = sdk.subscription;
        cas.brands.detail(brand_id).then(brand => {
          const _package = brand.packages.find(p => p.name === sdk.subscription.package_name);

          history.push(routeUtils.packageDashboard(brand_id, _package!.id));
        });
      },
      [history],
    ),
  };
}
export default function PlatformSdkSegment({ platform, handleCreate }: Props) {
  const { flash, loading, createSDK, navigateToPackage, setLoading, setFlash } =
    usePlatformSdkSegmentActions(handleCreate);

  const handlePackageDrop = React.useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      if (!e.dataTransfer) {
        return;
      }

      const data: { type: string; package_id: PackageID } = JSON.parse(e.dataTransfer.getData('text/plain'));
      if (!data.type) {
        console.log('unknown drop content');
      }

      setLoading(true);

      const _package = platform.available_packages.find(
        (p: { package_id: number }) => p.package_id === data.package_id,
      );
      if (!_package) {
        return;
      }

      cas.brands.detail(_package.brand_id).then(brand => {
        if (brand.codes.length < 1) {
          setFlash({
            color: 'red',
            message: 'See Developer, package has no codes',
          });
          return;
        }

        createSDK({
          developer_email: 'corey.mann@variableinc.com',
          package_code: brand.codes[0].code,
          platform_id: platform.id,
        });
      });
    },
    [createSDK, setLoading, setFlash, platform],
  );

  if (loading) {
    return (
      <ModuleSegment title="Third Party SDK Tokens">
        <Loading text="Creating new SDK key" />
      </ModuleSegment>
    );
  }
  return (
    <FlexRows>
      <ModuleSegment
        title="Third Party SDK Tokens"
        handleDragOver={(e: React.DragEvent) => {
          e.preventDefault();
        }}
        handleDrop={handlePackageDrop}
      >
        <Flash content={flash} />
        <PlatformSdkList onItemClick={navigateToPackage} sdks={platform.sdks} />
      </ModuleSegment>
      <div style={{ margin: 8 }} />
      <PackageSelection platform={platform} />
    </FlexRows>
  );
}
