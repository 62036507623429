import * as React from 'react';

import { Illuminant, Observer } from '@variablecolor/colormath';
import { useHistory, useParams } from 'react-router';
import appData from '@api/appdata';
import { Loading, Modal, ModuleSegment } from '@components/common';
import PaletteItemView from '../paletteItems/PaletteItemView';
import { Scan } from '@api/appdata/model';
import { MeasuredColor, ProductAttribute, ProductCompositionDetail } from '@api/vpapi/models';

type PartialItem = {
  product_attributes: ProductAttribute[];
  composition_details: ProductCompositionDetail[];
  images: () => string[];
  uuid: string;
  name: string;
  notes: string;
};

export default function ScanHistoryItemModal({ onClose }: { onClose: () => void }) {
  const [item, setItem] = React.useState<PartialItem | null>(null);
  const { scanID } = useParams<{ scanID: string }>();
  const history = useHistory();

  React.useEffect(() => {
    appData
      .fetchReading(scanID)
      .then((s: Scan) => {
        let name = 'Muse Scan Details';

        if (s.isSpectro()) {
          name = 'Spectro Scan Details';
        }

        const lab = s.labColor(Illuminant.D50, Observer.TWO_DEGREE);
        const cd = new ProductCompositionDetail({
          adjusted_lab: lab.toAppDataJSON(),
          hex: s.hex(),
          measured_colors: [JSON.parse(JSON.stringify(MeasuredColor.fromColorReading(s)))],
          percent_composition: 1,

          created_at: s.locallyCreatedAt || new Date().toISOString(),
        });

        setItem({
          product_attributes: [],
          images: () => [],
          uuid: s.uuid,
          composition_details: [cd],
          notes: '',
          name,
        });
      })
      .catch(onClose);
  }, [scanID, onClose, history]);

  return (
    <Modal modalProps={{ defaultOpen: true, onClose }} key={scanID}>
      <ModuleSegment title={item?.name || ''}>
        {!item && <Loading text="Fetching Scan Data" />}
        {item && <PaletteItemView paletteItem={item} handleSave={undefined} />}
      </ModuleSegment>
    </Modal>
  );
}
