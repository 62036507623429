import * as React from 'react';
import _ from 'lodash';
import validator from 'email-validator';

import theme from '@theme';
import { PermissionT, Roles } from '@api/cas/models.js';
import ModuleSegment from '../ModuleSegment';
import { Button } from '../Buttons';
import { FlexColumns } from '@components/common';
import Flash, { FlashContentT } from '../FlashNotice';
import { withUser } from '../withUser';
import { LabeledInput } from '../Input';
import User from '@api/cas/user';
import { Optional } from '@app/utils/types';

type HandleFunc = (email: string) => Promise<void>;
type ExternalProps = {
  permissions: PermissionT[];

  handleAddContributor?: HandleFunc;
  handleTransferOwner?: HandleFunc;
  handleAddOwner?: HandleFunc;
};

async function handleClick(email: string, permissions: PermissionT[], role: Optional<Roles>, handler: HandleFunc) {
  if (!validator.validate(email)) {
    throw new Error(`${email} is not a valid email address.`);
  }
  const x: { email: string; permissions?: Roles } = { email };
  if (role) {
    x.permissions = role;
  }

  const isAlreadyOwnedBy = _.some(permissions, x);
  if (isAlreadyOwnedBy) {
    throw new Error(`${email} is already assigned this role`);
  }

  try {
    await handler(email);
  } catch (err: any) {
    if (err.flashProps) {
      throw new Error(err.flashProps.message);
    }

    throw new Error(`${email} not linked to a Variable Cloud account. Please try a different email`);
  }
}

type ActionButtonProps = ExternalProps & {
  email: string;
  user: User;
  setFlashMessage: (c: FlashContentT) => void;
};
function ActionButtons(props: ActionButtonProps) {
  const {
    setFlashMessage,
    handleAddContributor,
    handleAddOwner,
    handleTransferOwner,
    email,
    permissions,
    user: { is_system_admin },
    user,
  } = props;

  const isOwnedByCurrentUser = _.some(permissions, {
    email: user.email,
    permissions: { is_owner: 1 },
  });
  const buttons = [] as React.ReactElement[];
  if (handleTransferOwner && isOwnedByCurrentUser && !is_system_admin) {
    buttons.push(
      <Button
        key="transfer"
        outline
        color={theme.button.actionColor}
        text="Transfer Ownership"
        onClick={async () => {
          try {
            await handleClick(email, permissions, { is_owner: 1 }, handleTransferOwner);
            setFlashMessage({ color: 'green', message: 'Success' });
          } catch (err: any) {
            setFlashMessage({ color: 'red', message: err.message });
          }
        }}
      />,
    );
  }

  if (handleAddContributor && (isOwnedByCurrentUser || is_system_admin)) {
    buttons.push(
      <Button
        key="contributor"
        outline
        color={theme.button.actionColor}
        onClick={async () => {
          try {
            await handleClick(email, permissions, undefined, handleAddContributor);
            setFlashMessage({ color: 'green', message: 'Success' });
          } catch (err: any) {
            setFlashMessage({ color: 'red', message: err.message });
          }
        }}
        text="Add &amp; Notify Contributor"
      />,
    );
  }

  if (handleAddOwner && is_system_admin) {
    buttons.push(
      <Button
        outline
        key="add-owner"
        color={theme.button.actionColor}
        text="Add &amp; Notify Owner"
        onClick={async () => {
          try {
            await handleClick(email, permissions, { is_owner: 1 }, handleAddOwner);
            setFlashMessage({ color: 'green', message: 'Success' });
          } catch (err: any) {
            setFlashMessage({ color: 'red', message: err.message });
          }
        }}
      />,
    );
  }

  return <>{buttons}</>;
}

export default withUser<ExternalProps>(props => {
  const [email, setEmail] = React.useState<string>('');
  const [flash, setFlashMessage] = React.useState<undefined | FlashContentT>(undefined);
  return (
    <ModuleSegment title="Add User">
      <Flash content={flash} />
      <LabeledInput
        label="EMAIL"
        inputProps={{
          placeholder: 'Enter an email linked to a Variable Cloud user.',
          value: email,
        }}
        onChange={(_1, data: { label: string; value: any }) => {
          setEmail(data.value);
        }}
      />
      <FlexColumns style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }} />
        <ActionButtons email={email} setFlashMessage={setFlashMessage} {...props} />
      </FlexColumns>
    </ModuleSegment>
  );
});
