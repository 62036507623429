import * as React from 'react';
import { FlexRows, ModuleSegment, SearchBar } from '@components/common';
import { useHistory, useParams } from 'react-router';
import { CardView } from '../common/Containers';
import api from '@api/appdata';
import ModelDetector from '@api/models/ModelDetector';

type ParamShape = {
  brand_id?: string;
  brandID?: string;
};
type ViewProps = {
  history: ReturnType<typeof useHistory>;
  brand_id?: string;

  email?: string;
};

type SerialItem = {
  serial: string;
  name: string;
  model: string;
  count: number;
};

type ViewState = {
  serials: SerialItem[];
  lifetime_scan_count: number;

  loading?: boolean;

  searchTerm: string;
};

function ModuleTitle(title: string, subTitle: string) {
  return () => (
    <FlexRows>
      <ModuleSegment.TitleText>{title}</ModuleSegment.TitleText>
      <ModuleSegment.SubTitleText>{`${subTitle}`}</ModuleSegment.SubTitleText>
    </FlexRows>
  );
}

function query() {
  return {
    search: [
      {
        $group: {
          _id: {
            model: '$model',
            serial: '$serial',
          },
          count: { $sum: 1 },
        },
      },
      {
        $project: {
          _id: 0,
          count: '$count',
          model: '$_id.model',
          serial: '$_id.serial',
        },
      },
    ],
  };
}

class ColorScanAnalytics extends React.Component<ViewProps, ViewState> {
  state = {
    lifetime_scan_count: 0,
    loading: true,
    searchTerm: '',
    serials: [] as SerialItem[],
  };

  loadState = async () => {
    const { email, brand_id } = this.props;
    let serials;
    if (email) {
      const results = await Promise.all([
        api.analytics.userAggregation<SerialItem>(email, 'scans', query()),
        api.analytics.userAggregation<SerialItem>(email, 'spectrums', query()),
      ]);

      serials = results.reduce((arr, x: { docs: SerialItem[] }) => arr.concat(...x.docs), [] as SerialItem[]);
    } else if (brand_id) {
      try {
        const { docs } = await api.analytics.aggregateColorScanData<SerialItem>(brand_id, query());

        serials = docs;
      } catch (err) {
        console.log(err);
      }
    }

    if (serials) {
      this.setState({
        lifetime_scan_count: serials.reduce((val: number, x: { count: number }) => val + x.count, 0),
        loading: undefined,
        serials: serials.map(x => ({
          ...x,
          name: ModelDetector.name(x.model),
        })),
      });
    }
  };

  //#region Component Lifecycle
  componentDidMount() {
    this.loadState();
  }
  //#endregion

  get items() {
    let { serials } = this.state;
    const { searchTerm } = this.state;
    if (searchTerm !== '') {
      serials = serials.filter(
        x => x.name.includes(searchTerm) || x.serial.includes(searchTerm) || x.model === searchTerm,
      );
    }

    return serials;
  }

  handleChange = (_e: React.SyntheticEvent<HTMLInputElement>, data: { label: string; value: any }) => {
    this.setState({
      searchTerm: data.value,
    });
  };

  renderSerialItem = (item: SerialItem) => {
    return (
      <CardView
        key={item.serial}
        lines={[{ text: item.serial }, { text: item.name }, { text: '' }, { text: `${item.count} Scans` }]}
      />
    );
  };

  render() {
    const { serials, loading, lifetime_scan_count } = this.state;
    if (loading) {
      return null;
    }
    return (
      <ModuleSegment
        containerStyle={{ maxWidth: '90vw' }}
        title={ModuleTitle('Device Management', `${lifetime_scan_count} scans from ${serials.length} devices`)}
      >
        <SearchBar
          onSubmit={term => {
            this.setState({
              searchTerm: term,
            });
          }}
          onChange={this.handleChange}
          inputProps={{
            placeholder: 'Search by serial, device name, or model number',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 16,
            maxHeight: '66vh',
            maxWidth: '90vw',
            overflowY: 'scroll',
          }}
        >
          {this.items.map(this.renderSerialItem)}
        </div>
      </ModuleSegment>
    );
  }
}

export default function __Page({ email }: { email?: string }) {
  const { brand_id, brandID } = useParams<ParamShape>();
  const history = useHistory();
  return <ColorScanAnalytics email={email} brand_id={brand_id || brandID} history={history} />;
}
