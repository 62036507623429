import React from 'react';
import cas from '@api/cas';
import { FlexRows } from '@components/common';
import { BrandDropdown, Dropdown } from '../common/Input/BrandDropdown';
import type { BrandDetail } from '@api/cas/models';
import { BrandManagementRedirect } from '..';
import { ProductGroupAccessPreview } from '../brands/packages/ProductGroupSelector';
import theme from '@theme';
import routes from '@utils/route-utils';
import { useHistory } from 'react-router';

type ViewProps = {
  history: ReturnType<typeof useHistory>;
  match: any;
};
type ViewState = {
  selectedBrand: BrandDetail;
};
export default class ProductGroupAccess extends React.Component<ViewProps, ViewState> {
  state = {
    selectedBrand: undefined as any,
  };

  get selectedBrandID() {
    const { match } = this.props;
    return match.params.brandID;
  }
  //#region LifeCycle

  componentDidMount = async () => {
    let id = this.selectedBrandID ?? sessionStorage.getItem(BrandManagementRedirect.SELECTED_BRAND_ID_KEY);
    if (!id) {
      this.showBrandSelection();
    }

    let selectedBrand;
    if (id) {
      selectedBrand = await cas.brands.detail(id);
    }

    if (!id || !selectedBrand) {
      this.showBrandSelection();
      return;
    }

    this.setState({
      selectedBrand,
    });
  };
  //#endregion

  //#region Handlers

  showBrandSelection = () => {
    const { history } = this.props;
    history.push(routes.brandSelection('product-group-access'));
  };
  //#endregion

  ProductGroupAccess = () => {
    const { selectedBrand } = this.state;
    if (!selectedBrand) {
      return null;
    }

    return (
      <ProductGroupAccessPreview
        handleCreateProductsClick={undefined}
        options={{
          header: 'Product Libraries',
          subHeader: 'Enable libraries for organization usage',
        }}
        brand={selectedBrand}
      />
    );
  };

  //#region Components and renderers
  render = () => {
    const { selectedBrand } = this.state;

    const { ProductGroupAccess: ProductGroupAccessView } = this;
    return (
      <div>
        <Dropdown.ContentWrapper>
          <BrandDropdown selectedBrand={selectedBrand} handleClick={this.showBrandSelection} />
        </Dropdown.ContentWrapper>

        <FlexRows style={{ margin: theme.dimensions.contentPadding }}>
          <ProductGroupAccessView />
        </FlexRows>
      </div>
    );
  };

  //#endregion
}
