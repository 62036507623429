import * as React from 'react';
import routeUtils from '@utils/route-utils';
import theme from '@theme';
import type { ColorFormula, FormulationGroup } from '@api/formulation/models';
import sd from './styled-components';
import { useHistory } from 'react-router';

type ViewProps = {
  history: ReturnType<typeof useHistory>;

  group: FormulationGroup;
  formulas: ColorFormula[];
  count: number;

  loadMore: () => Promise<any>;
};

export default class ColorFormulaSection extends React.PureComponent<ViewProps> {
  iScroll: { current: null | HTMLDivElement } = React.createRef();

  componentDidScroll = () => {
    const { formulas, count } = this.props;

    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    if (formulas.length >= count) {
      return;
    }

    const {
      iScroll: { current: iScroll },
    } = this;
    if (!iScroll) {
      return;
    }
    if (iScroll.scrollTop + iScroll.clientHeight >= iScroll.scrollHeight * 0.75) {
      const { loadMore } = this.props;
      loadMore();
    }
  };

  async componentDidMount() {
    // Binds our scroll event handler
    if (this.iScroll.current) {
      this.iScroll.current.addEventListener('scroll', this.componentDidScroll);
    }
  }

  handleFormulaClick = (f: ColorFormula) => () => {
    const { history, group } = this.props;

    history.push(routeUtils.colorFormulaDetail(group.id, f._id));
  };

  render = () => {
    const { formulas } = this.props;

    return (
      <div
        ref={this.iScroll}
        style={{
          backgroundColor: theme.segment.moduleBackground,

          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',

          height: '75vh',

          marginTop: 16,
          overflowX: undefined,
          overflowY: 'scroll',
          width: '100%',
        }}
      >
        {formulas.map(c => (
          <sd.ColorFormulaPreview onClick={this.handleFormulaClick} formula={c} key={c._id} />
        ))}
      </div>
    );
  };
}
