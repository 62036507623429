import React from 'react';
import styled from 'styled-components';
import { NavLink, Route, useHistory } from 'react-router-dom';

import theme from '../../../theme';
import appData from '../../../api/appdata';
import { PaletteItem } from '../../../api/appdata/model';

import PaletteDetail from '../PaletteDetail';
import { AlternateText, Button, Loading, ModuleSegment } from '../../common';

import CreatePaletteView from '../CreatePaletteView';
import ScanHistory from '../scanHistory/ScanHistory';
import PaletteList from '../PaletteList';
import routes from '../../../utils/route-utils';
import PaletteDetailModal from '../PaletteDetailModal';
import ScanHistoryItemModal from '../scanHistory/ScanHistoryItemModal';
import { PaletteItemModal } from '../paletteItems/PalettteItemModal';
import routeUtils from '@utils/route-utils';
import usePalettes from './usePalettes';
import User from '@api/cas/user';

type PageProps = {
  user: User;
};
const Container = styled.div`
  min-width: 768px;

  height: 100%;
  display: flex;
  flex-direction: row;
  margin: ${theme.dimensions.contentPadding};
`;

const PaletteSelector = styled(ModuleSegment).attrs({
  containerStyle: {
    marginRight: 4,
    padding: '1%',
    paddingTop: '2.5%',
    minWidth: 256,
  },
})`
  overflow-y: scroll;
  -ms-overflow-style: none;
`;

const helpModalInfo = {
  Content: () => (
    <div>
      <AlternateText>
        Saved Colors include your Scan History and Saved Palettes / Saved Color Folders from our mobile apps.
      </AlternateText>
      <br />
      <AlternateText>
        If you haven&#39;t already, install one of our apps (Spectro by Variable, Color Muse, NCS ColourPin, Variable
        Color) on your mobile device.
      </AlternateText>
      <br />
      <AlternateText>
        In the mobile app you can browse (and save) products, connect to a colorimeter/spectro device and take scans /
        save scans, etc. These saved scans and products will then appear here in the Saved Colors section of Variable
        Cloud.
      </AlternateText>
    </div>
  ),
  title: 'What are Saved Colors?',
};

function useModuleButtons() {
  return [
    <NavLink to={routeUtils.savedFolderCreator()} key="create-folder">
      <Button color={theme.button.createColor} text="Create Folder" />
    </NavLink>,
  ];
}

export default function PalettesPage({ user }: PageProps) {
  const history = useHistory();
  const {
    palettes,
    moveSavedColorTo,
    deletePalette,
    loadPalettes,
    saveScanTo,
    selectedPalette,
    createPalette,
    savePalette,
  } = usePalettes();
  const buttons = useModuleButtons();

  if (!palettes) {
    return <Loading text="Loading Saved Folders" />;
  }

  return (
    <Container>
      <Route exact path={routeUtils.savedFolderCreator()}>
        <CreatePaletteView handleSubmit={createPalette} />
      </Route>

      <Route exact path={['/saved-colors/:paletteID', '/saved-colors']}>
        <PaletteSelector
          buttons={buttons}
          title={ModuleSegment.ModuleTitle('Saved Folders', `${palettes.count} Folders`)}
          helpModal={helpModalInfo}
        >
          <PaletteList
            loadPalettes={loadPalettes}
            palettes={palettes}
            selectedPalette={selectedPalette}
            paletteSelected={p => history.push(routes.savedColors(p))}
            onPaletteItemMoved={moveSavedColorTo}
            onScanSavedToPalette={saveScanTo}
          />
        </PaletteSelector>
      </Route>

      <Route exact path="/scans/:scanID">
        <ScanHistoryItemModal onClose={history.goBack} />
      </Route>

      <Route path="/saved-colors/:paletteID/details">
        {selectedPalette && (
          <PaletteDetailModal onPaletteUpdate={savePalette} onPaletteDelete={deletePalette} palette={selectedPalette} />
        )}
      </Route>

      <Route exact path="/saved-colors/:paletteID">
        {selectedPalette && <PaletteDetail palette={selectedPalette} />}
      </Route>

      <Route exact path="/saved-colors/:paletteID/items/:itemID">
        <PaletteItemModal
          onItemChange={async (item: PaletteItem) => {
            await appData.uploadPaletteItems([item]);
            history.goBack();
          }}
          onClose={history.goBack}
        />
      </Route>

      <Route exact path="/saved-colors">
        <ScanHistory user={user} />
      </Route>
    </Container>
  );
}
