import * as React from 'react';
import styled from 'styled-components';
import { Button } from '../Buttons';
import cardComponents from '../Containers/CardView';
import theme from '@theme';

import { AlternateText, PrimaryText } from '../Text';
import { FlexColumns } from '@components/common';

import { withUser, InjectedProps as withUserViewProps } from '../withUser';

import ModuleSegment from '../ModuleSegment';
import Modal from '../Modal';

import type { PermissionT, Roles } from '@api/cas/models';
import { Optional } from '@app/utils/types';

type ExternalProps = {
  // The email associated with this PermissionItem
  email: string;

  // The permissions attribute map[string] int
  roles: Roles;

  // callback for handling when revoke is clicked
  handleRevoke: (permission: PermissionT) => void | any;
};

type ViewProps = ExternalProps & withUserViewProps;

function usePermissions({ handleRevoke, roles, user, email }: ViewProps) {
  const [modalType, setModalType] = React.useState<Optional<'confirm_delete'>>();

  return {
    modalType,
    email,
    isCurrentUserPermission: email === user.email,

    async handleDelete() {
      if (handleRevoke) {
        await handleRevoke({ email, permissions: roles });
      }

      setModalType(undefined);
    },

    handleDeleteClick() {
      setModalType('confirm_delete');
    },
    handleCancel() {
      setModalType(undefined);
    },

    get role() {
      if (roles.is_owner) {
        return 'Owner';
      }

      if (roles.is_contributor) {
        return 'Contributor';
      }

      if (roles.is_user) {
        return 'User';
      }
      return 'System Admin';
    },
  };
}
function PermissionItem(props: ViewProps) {
  const { modalType, email, role, isCurrentUserPermission, handleCancel, handleDeleteClick, handleDelete } =
    usePermissions(props);

  if (modalType === 'confirm_delete') {
    return (
      <Modal modalProps={{ onClose: handleCancel, open: true }}>
        <ModuleSegment title="Confirm Action">
          <AlternateText>Are you sure you want to remove access?</AlternateText>

          <FlexColumns style={{ justifyContent: 'flex-end' }}>
            <Button outline color={theme.button.actionColor} text="Yes" onClick={handleDelete} />

            <Button outline color={theme.button.cancelColor} text="No" onClick={handleCancel} />
          </FlexColumns>
        </ModuleSegment>
      </Modal>
    );
  }

  return (
    <cardComponents.Container
      onClick={() => {
        console.log(email);
      }}
    >
      <PrimaryText fontWeight={500}>{email}</PrimaryText>

      <FlexColumns>
        <div>
          <AlternateText>{role}</AlternateText>
        </div>

        <div style={{ flex: 1 }} />

        <div style={{ display: isCurrentUserPermission ? 'flex' : 'none' }}>
          <AlternateText>You</AlternateText>
        </div>
      </FlexColumns>

      <DeleteContainer style={{ display: isCurrentUserPermission ? 'none' : 'flex' }} onClick={handleDeleteClick}>
        <DeleteIcon />
      </DeleteContainer>
    </cardComponents.Container>
  );
}

const DeleteContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  height: 32px;
  width: 32px;
`;
const DeleteIcon = styled.img.attrs({ src: '/icons/delete.png' })`
  height: 32px;
  width: 32px;
  align-self: right;
  object-fit: contain;

  padding: 8px;

  :hover {
    background-color: ${theme.button.cancelColor};
    opacity: 0.75;

    cursor: pointer;
  }
`;
export default withUser<ExternalProps>(PermissionItem);
