import React from 'react';
import styled from 'styled-components';
import { ProductCompositionDetail } from '../../../../api/vpapi/models';
import { FlexColumns } from '../../../common';
import theme from '../../../../theme';

const Container = styled.div<{ bgColor?: string; isSelected?: boolean }>`
  background-color: ${({ bgColor }) => bgColor};
  height: 48px;
  width: 96px;

  border-style: solid;
  border-width: ${({ isSelected = false }) => (isSelected ? 4 : 0)}px;
  border-color: ${theme.table.selectedBackground};

  :hover {
    border-style: solid;
    border-width: 4px;
    border-color: ${theme.table.hoverBackground};
  }
`;
type ViewProps = {
  selected: ProductCompositionDetail;
  compositions: ProductCompositionDetail[];
  handleClick: (p: ProductCompositionDetail) => void | any;
};

export default function CompositionPreviewList({ selected, compositions, handleClick }: ViewProps) {
  return (
    <FlexColumns style={{ alignItems: 'center', marginTop: 12 }}>
      {compositions.map(composition => {
        return (
          <Container
            key={`CompPreview-${composition.id}`}
            bgColor={composition.hex}
            isSelected={composition.id === selected?.id}
            onClick={() => handleClick(composition)}
          />
        );
      })}
    </FlexColumns>
  );
}
