import * as React from 'react';
import styled from 'styled-components';
import { PrimaryText } from './Text';
import { FlexColumns } from './common';

type ViewProps = {
  checked: boolean;
  text: string;
  fill: string;
  onCheckChange: (isChecked: boolean) => void | any;
};

const Circle = styled.div<{ bgColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  
  border-radius: 25px;
  background-color: ${({ bgColor }) => bgColor};
  width: 24px
  height: 24px;

  z-index: 1;
`;

const Checkmark = styled.div`
  position: absolute;
  left: 10px;
  top: 4px;

  /*Make it a small rectangle so the border will create an L-shape*/
  width: 6px;
  height: 12px;

  /*Add a white border on the bottom and left, creating that 'L' */
  border: solid white;
  border-width: 0 2px 2px 0;

  /*Rotate the L 45 degrees to turn it into a checkmark*/
  transform: rotate(45deg);

  z-index: 1000;
`;

const Wrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  cursor: pointer;
`;
export default function FilledRadio({ onCheckChange, checked, fill, text }: ViewProps) {
  const handleCheckChange = React.useCallback(() => {
    if (onCheckChange) {
      onCheckChange(!checked);
    }
  }, [onCheckChange, checked]);

  return (
    <FlexColumns style={{ alignItems: 'center' }}>
      <Wrapper onClick={handleCheckChange}>
        {checked && <Checkmark />}
        <Circle bgColor={fill} />
      </Wrapper>
      <PrimaryText>{text}</PrimaryText>
    </FlexColumns>
  );
}
