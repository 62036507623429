import * as React from 'react';

import styled from 'styled-components';
import theme from '@theme';
import { ProductImage } from '@api/vpapi/models';
import { Optional } from '@app/utils/types';

type ViewProps = {
  onItemClick?: (image: ProductImage) => void | any;
  images: ProductImage[];
  selectedImage: Optional<ProductImage>;
};
export default function ImagePreviewList({ images, selectedImage, onItemClick }: ViewProps) {
  if (images.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        display: 'inline-flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 16,
      }}
    >
      {images.map((image: ProductImage) => {
        return (
          <Container
            selected={image.url === selectedImage?.url && image?.image_key === selectedImage?.image_key}
            onClick={() => onItemClick?.(image)}
            key={image.url}
          >
            <Image src={image.url} />
          </Container>
        );
      })}
    </div>
  );
}

const Image = styled.img.attrs(({ src }) => {
  return {
    src, //: imageUtils.encodedImageURL(src, { rotate: null, resize: { fit: 'contain', height: 40, width: 86 } }),
  };
})`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
const Container = styled.div<{ selected?: boolean }>`
  width: 96px;
  height: 48px;

  padding: 4px;

  ${({ selected }) => {
    if (!selected) {
      return '';
    }

    return `
      border-style: solid;
      border-color: ${theme.table.selectedBackground};
      border-width: 4px;
      padding: 0px;
    `;
  }}

  :hover {
    border-style: solid;
    border-color: ${theme.table.hoverBackground};
    border-width: 4px;
    padding: 0px;
  }
`;
