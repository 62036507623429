import * as React from 'react';

import { FlexRows, LabeledInput } from '../../common';

import type { UserProfile } from '../../../api/cas/models';

export default function Profile({ user }: { user: UserProfile }) {
  return (
    <FlexRows>
      <LabeledInput label="Email" inputProps={{ disabled: true, value: user.email }} />
      <LabeledInput label="Name" inputProps={{ disabled: true, value: user.nickname || '-' }} />
      <LabeledInput label="Company" inputProps={{ disabled: true, value: user.company || '-' }} />

      {user.email_token && (
        <LabeledInput
          label="Verify Email"
          inputProps={{
            disabled: true,
            value: `https://cloud.variableinc.com/account/verify-email?token=${user.email_token}`,
          }}
        />
      )}
      {user.password_token && (
        <LabeledInput
          label="Reset Password"
          inputProps={{
            disabled: true,
            value: `https://cloud.variableinc.com/account/reset-password?token=${user.password_token}`,
          }}
        />
      )}
    </FlexRows>
  );
}
