import * as React from 'react';
import { useLocation } from 'react-router';

import ProductPreview from '@components/products/ProductPreview';
import routeUtils from '@utils/route-utils';
import { PaletteListCellContainer } from '../PaletteListCell';
import { FlexRows, PrimaryText } from '../../common';
import { NavLink } from 'react-router-dom';

export default React.memo<{}>(() => {
  const location = useLocation();

  return (
    <NavLink to={routeUtils.savedColors()}>
      <PaletteListCellContainer selected={location.pathname === '/saved-colors'}>
        <FlexRows style={{ flex: 1 }}>
          <PrimaryText fontWeight="bold">Scan History</PrimaryText>
        </FlexRows>

        <ProductPreview height={72} image_url="/scan_history.svg" />
      </PaletteListCellContainer>
    </NavLink>
  );
});
