import * as React from 'react';
import type { PackageDetail } from '@api/cas/models';

export default ({ packageDetail }: { packageDetail?: PackageDetail }) => {
  if (!packageDetail || !packageDetail.publish?.status) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: 'red',
        color: 'white',
        padding: 10,
        width: '100%',
      }}
    >
      App Package is being published. (This may take a few minutes)
    </div>
  );
};
