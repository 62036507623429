import * as React from 'react';
import styled from 'styled-components';
import theme from '@theme';

// $FlowFixMe

const HTML = {
  Label: styled.label`
    height: ${theme.dimensions.input.height};
    width: ${theme.dimensions.input.label_width};
    padding: 10px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    background-color: ${theme.label.background};
    color: ${theme.dropdown.text};

    /** do not touch weight, size and letter-spacing without Wes' consent **/
    font-weight: 500;
    letter-spacing: 3px;
    font-size: 65%;

    margin-right: ${theme.dimensions.input.margin}px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Input: styled.input`
    outline: none;

    flex: 1;
    height: ${theme.dimensions.input.height};
    padding-left: 8px;
    border: none;
    white-space: nowrap;
    background-color: ${({ disabled }) => (disabled ? theme.table.rowAltBackground : theme.table.rowBackground)};
    color: ${theme.label.text};
  `,

  //#region Inputs
  IconContainer: styled.div`
    height: ${theme.dimensions.input.height};
    background-color: ${theme.label.background};
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  Icon: styled.i`
    color: ${theme.icon.primary} !important;
    min-width: 35px;
    margin: 0px !important;
  `,
};

const LabelInputContainer = styled.div<{ focus?: boolean }>`
  ${({ focus }) => {
    if (focus) {
      return `
      outline: 1px solid ${theme.label.text};
      margin-left: 1px;  
    `;
    }
    return '';
  }}

  margin-right: 2px;

  height: ${theme.dimensions.input.height};

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${theme.dimensions.input.containerVerticalMargin}px;
  margin-bottom: ${theme.dimensions.input.containerVerticalMargin}px;
  flex-direction: row;
`;

export function useEnterKeyPress(cb: (value: string) => void) {
  return React.useCallback(
    ({ key, currentTarget: { value } }: React.KeyboardEvent<HTMLInputElement>) => {
      if (key === 'Enter') {
        cb(value);
      }
    },
    [cb],
  );
}

export interface OnInputChangeFunc {
  (e: React.SyntheticEvent<HTMLInputElement>, data: { label: string; value: any }): void | any;
}
export type BaseInputProps = {
  label?: string;
  containerStyle?: React.CSSProperties;
  onChange?: OnInputChangeFunc;
  inputProps?: Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'ref'>;
};

export type IconInputProps = BaseInputProps & {
  name: string;
};
function IconInput(props: IconInputProps) {
  const { label, name, onChange, inputProps = {}, containerStyle = {} } = props;
  const [focus, setFocus] = React.useState(false);

  return (
    <LabelInputContainer focus={focus} style={containerStyle}>
      <HTML.IconContainer>
        <HTML.Icon className={`${name} icon`} />
      </HTML.IconContainer>
      <HTML.Input
        id={label}
        {...inputProps}
        onFocusCapture={() => setFocus(true)}
        onBlurCapture={() => setFocus(false)}
        onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
          onChange?.(e, { label: label || '', value: e.currentTarget.value });
        }}
      />
    </LabelInputContainer>
  );
}

function Input({ label, containerStyle, inputProps, onChange }: BaseInputProps) {
  const [focus, setFocus] = React.useState(false);
  return (
    <LabelInputContainer style={containerStyle} focus={focus}>
      <HTML.Input
        {...inputProps}
        onFocusCapture={() => setFocus(true)}
        onBlurCapture={() => setFocus(false)}
        onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
          onChange?.(e, { label: label || '', value: e.currentTarget.value })
        }
      />
    </LabelInputContainer>
  );
}

export type LabeledInputProps = BaseInputProps & {
  label: string;
  labelStyle?: React.CSSProperties;

  id?: string;
};

function LabeledInput({ id, label, onChange, containerStyle, labelStyle, inputProps }: LabeledInputProps) {
  const [focus, setFocus] = React.useState(false);
  return (
    <LabelInputContainer style={containerStyle} focus={focus}>
      <HTML.Label style={labelStyle}>{label.toUpperCase()}</HTML.Label>
      <HTML.Input
        {...inputProps}
        onFocusCapture={() => setFocus(true)}
        onBlurCapture={() => setFocus(false)}
        onChange={e => {
          if (id) {
            e.currentTarget.id = id;
          }

          onChange?.(e, { label: label || '', value: e.target.value });
        }}
      />
    </LabelInputContainer>
  );
}

type LabelInputProps = {
  containerStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  label: string;
};
const InputLabel = ({ containerStyle, labelStyle, label }: LabelInputProps) => (
  <LabelInputContainer style={containerStyle}>
    <HTML.Label style={labelStyle}>{label.toUpperCase()}</HTML.Label>
  </LabelInputContainer>
);

export { LabeledInput, InputLabel, IconInput, Input };
