import Amplify, { Auth, PubSub } from 'aws-amplify';

import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { EventEmitter } from 'fbemitter';
import aws_exports from './aws-exports';
import cas from '../cas';

import { deviceUUID } from '../../utils';
import User from '@api/cas/user';
import { Optional } from '@app/utils/types';

Amplify.Logger.LOG_LEVEL = 'ERROR';
Amplify.configure(aws_exports);

const PUBSUB_ENDPOINT = 'wss://a229rrnhml8h1u-ats.iot.us-east-1.amazonaws.com/mqtt';

export type VariableCloudNotification = {
  action: 'update' | 'create' | 'delete';
  id: string;
  type: 'product-group' | 'product';
};

function productGroupsActionData(productGroupID: string, action: 'create' | 'update' | 'delete') {
  return {
    variableCloud: {
      action,
      id: productGroupID,
      type: 'product-group',
    },
  };
}

function productGroupsTopic(email: string, productGroupID: string) {
  return `variableCloud/${email}/productGroups/${productGroupID}`;
}

function topics(email: string) {
  return [`appdata/${email}/palettes/#`, `variableCloud/${email}/productGroups/#`];
}

class AWSPubSub {
  eventNames = {
    appDataNotification: 'didReceiveAppDataUpdateNotification',
    productGroupNotification: 'didReceiveProductGroupNotification',
  };
  appDataSubscription: Optional<{ unsubscribe: () => void }>;
  emitter: EventEmitter;

  constructor() {
    this.emitter = new EventEmitter();
  }

  palettesTopic = (paletteID?: string) => {
    const { email } = cas.getUser()!;
    if (!paletteID) {
      return `appdata/${email}/palettes/`;
    }
    return `appdata/${email}/palettes/${paletteID}`;
  };

  init = async (user: User) => {
    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_endpoint: PUBSUB_ENDPOINT,
        aws_pubsub_region: 'us-east-1',
      }),
    );

    // Sign in (unauthenticated) using cognito
    await Auth.currentCredentials();

    await this.subscribe(user.email);
  };

  unsubscribe = () => {
    this.appDataSubscription?.unsubscribe();
  };

  get productGroupActions() {
    return {
      create: (email: string, productGroupID: string) =>
        this.publish(productGroupsTopic(email, productGroupID), productGroupsActionData(productGroupID, 'create')),
      delete: (email: string, productGroupID: string) =>
        this.publish(productGroupsTopic(email, productGroupID), productGroupsActionData(productGroupID, 'delete')),

      update: (email: string, productGroupID: string) =>
        this.publish(productGroupsTopic(email, productGroupID), productGroupsActionData(productGroupID, 'update')),
    };
  }

  publish = (topic: string = '', data: any) => {
    if (topic === '') {
      return;
    }

    try {
      PubSub.publish(topic, { source_device_uuid: deviceUUID(), ...data });
    } catch (err) {
      console.log(err);
    }
  };

  onData = ({
    value,
  }: {
    value: {
      source_device_uuid: string;
      msg: 'sync';
      variableCloud?: VariableCloudNotification;
    };
  }) => {
    const { msg, variableCloud, source_device_uuid } = value;
    if (deviceUUID() === source_device_uuid) {
      console.log('pub notification from self');
      return;
    }

    if (variableCloud) {
      console.log('onData for productGroupNotification', value);
      this.emitter.emit(this.eventNames.productGroupNotification, variableCloud);
    } else if (msg === 'sync') {
      this.emitter.emit(this.eventNames.appDataNotification, value);
    }
  };

  onError = (error: any) => {
    console.log('Subscription error:', error);
    setTimeout(this.subscribe, 5 * 1000);
  };

  subscribe = (email: string) => {
    try {
      this.appDataSubscription = PubSub.subscribe(topics(email)).subscribe({
        error: this.onError,
        next: this.onData,
      });
    } catch (err) {
      console.log(err);
    }
  };
}

export default new AWSPubSub();
