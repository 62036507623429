import React from 'react';
import styled from 'styled-components';
import { FlexColumns } from '@components/common';
import { Text } from './Text';
import theme from '@theme';

export const Loader = styled.div`
  width: 3em;
  height: 3em;
  border: 0.5em solid ${theme.loading.backgroundColor};
  border-left: 0.5em solid ${theme.loading.color};
  border-radius: 50%;
  animation: load8 1s infinite linear;

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
type ViewProps = {
  text?: string;
  inverted?: boolean;
};

export function Loading({ text = 'Loading', inverted = false }: ViewProps) {
  return (
    <FlexColumns
      style={{
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: inverted ? theme.text.primary : theme.segment.moduleBackground,
        display: 'flex',
        justifyContent: 'center',
        padding: '12.5%',
      }}
    >
      <Loader />
      <Text
        color={inverted ? theme.text.primary : theme.text.alternate}
        textAlign="center"
        fontSize="16px"
        style={{ margin: 12 }}
      >
        {text || 'Loading...'}
      </Text>
    </FlexColumns>
  );
}
