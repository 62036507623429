import * as React from 'react';
import styled from 'styled-components';
import { Route, useHistory, useParams, useLocation } from 'react-router-dom';
import PublishingNotice from './publishingNotice';
import _ from 'lodash';
import cas from '../../api/cas';
import { DropdownTitleArea, FlexColumns, FlexRows, Loading, PrimaryText, SystemAdminOnly } from '@components/common';
import type { BrandDetail, BrandID, PackageDetail } from '../../api/cas/models';
import User from '@api/cas/user';
import theme from '@theme';
import { BrandDropdown } from '../common/Input';
import PackageDetailQuad from './PackageDetailQuad';
import { BrandManagementRedirect } from '../brands/BrandSelectionRedirect';
import { ProductGroupAccessPreview } from '../brands/packages/ProductGroupSelector';
import { Dropdown } from '@components/common/Input/BrandDropdown';
import routes from '@utils/route-utils';
import PackagePublishQuad from './PackagePublishQuad';
import SearchPreviewQuad from './SearchPreviewQuad';
import { Optional } from '@app/utils/types';
import { ModuleSegment } from '../account/Login';

type ExternalProps = {
  user: User;
};

function usePackageAndBrandFromRouteParams(): [Optional<BrandDetail>, Optional<PackageDetail>] {
  const history = useHistory();
  const location = useLocation<{
    brand?: BrandDetail;
    packageDetail?: PackageDetail;
  }>();
  const { brandID, packageID: pID } = useParams<{
    brandID: BrandID;
    packageID: string;
  }>();
  const packageID = parseInt(pID, 10);

  const [brand, setBrand] = React.useState<Optional<BrandDetail>>(location.state?.brand);
  const [packageDetail, setPackageDetail] = React.useState<Optional<PackageDetail>>(location.state?.packageDetail);

  React.useEffect(() => {
    if (brandID) {
      return;
    }

    // Prompt the user to select a package for this organization.
    const path = routes.packageDashboard(sessionStorage.getItem(BrandManagementRedirect.SELECTED_BRAND_ID_KEY)!);
    history.replace(path);
  }, [history, brandID]);

  React.useEffect(() => {
    if (brandID && packageID) {
      cas
        .fetchPackageDetail({ brand_id: brandID, package_id: packageID })
        .then(setPackageDetail)
        .catch(e => {
          console.log(e);
          history.replace(routes.packageDashboard());
        });
    }
  }, [history, brandID, packageID]);

  React.useEffect(() => {
    if (brandID) {
      cas.brands
        .detail(brandID)
        .then(b => {
          if (brandID && !packageID) {
            history.replace(routes.packageDashboard(b.id, undefined, b));
            return;
          }

          setBrand(b);
        })
        .catch(e => {
          console.log(e);
          history.replace(routes.packageDashboard());
        });
    }
  }, [history, packageID, brandID]);
  return [brand, packageDetail];
}

export default function PackageDashboard({ user }: ExternalProps) {
  const history = useHistory();
  const [selectedBrand, packageDetail] = usePackageAndBrandFromRouteParams();

  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  const handlePackageDeletion = React.useCallback(() => {
    // it is enforced no deletion of the last package
    if (selectedBrand) {
      history.replace(routes.packageDashboard(selectedBrand.id.toString(), undefined, selectedBrand));
    }
  }, [history, selectedBrand]);

  if (!packageDetail || !selectedBrand) {
    return <Loading />;
  }

  return (
    <FlexRows>
      <Dropdown.ContentWrapper>
        <BrandDropdown
          selectedBrand={selectedBrand}
          rightIconName="chevron right"
          handleClick={() => {
            history.replace(routes.brandDashboard(selectedBrand.id));
          }}
        />

        <DropdownTitleArea
          title="Package"
          icon="chevron down"
          imageSrc="/images/packages.svg"
          item={packageDetail}
          to={routes.packageDashboard(selectedBrand.id, undefined, selectedBrand)}
        />
      </Dropdown.ContentWrapper>

      {/* <Debug data={packageDetail} /> */}
      <FlexGrid id="flex-grid">
        <PublishingNotice packageDetail={packageDetail} />

        <Route exact path="/packages/:brandID/:packageID">
          <PackageDetailQuad
            user={user}
            brand={selectedBrand}
            onDelete={handlePackageDeletion}
            packageDetail={packageDetail}
          />
        </Route>

        <Route exact path="/packages/:brandID/:packageID">
          <SystemAdminOnly>
            <FlexColumns style={{ width: '50%', justifyContent: 'space-between', marginTop: '2.5%' }}>
              <ModuleSegment title="Activated Vendors">
                <FlexRows>
                  {_(packageDetail.product_groups)
                    .map('vendor')
                    .uniq()
                    .value()
                    .map(vendor => (
                      <PrimaryText key="vendor">{vendor + '   '}</PrimaryText>
                    ))}
                </FlexRows>
              </ModuleSegment>
              <ModuleSegment title="Activated Categories">
                <FlexRows>
                  {_(packageDetail.product_groups)
                    .map('category')
                    .uniq()
                    .value()
                    .map(vendor => (
                      <PrimaryText key="vendor">{vendor + '   '}</PrimaryText>
                    ))}
                </FlexRows>
              </ModuleSegment>
            </FlexColumns>
          </SystemAdminOnly>
        </Route>

        <Route exact path="/packages/:brandID/:packageID">
          <Item flexGrow={1} style={{ width: '100%' }}>
            <ProductGroupAccessPreview
              brand={selectedBrand}
              packageDetail={packageDetail}
              onChangeCommitted={forceUpdate}
              handleCreateProductsClick={() => {
                history.push(routes.productGroupCreation(), {
                  brand_id: selectedBrand.id,
                  package_id: packageDetail.id,
                });
              }}
            />
          </Item>
        </Route>

        <Route exact path="/packages/:brandID/:packageID">
          <SystemAdminOnly>
            <Item flexGrow={0.25}>
              <PackagePublishQuad packageDetail={packageDetail} />
            </Item>
          </SystemAdminOnly>
        </Route>

        <Route exact path="/packages/:brandID/:packageID">
          <Item flexGrow={1}>
            <SearchPreviewQuad packageDetail={packageDetail} />
          </Item>
        </Route>
      </FlexGrid>
    </FlexRows>
  );
}

const Item = styled.div<{ flexGrow?: number }>`
  margin-top: ${theme.dimensions.contentPadding};
  margin-right: 12px;
  flex-grow: ${({ flexGrow = 0 }) => flexGrow};
`;
// padding 2.5% x3
const FlexGrid = styled(FlexColumns)`
  flex-wrap: wrap;
  flex-direction: none;
  justify-content: space-between;
  margin: ${theme.dimensions.contentPadding};
`;
