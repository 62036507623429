import * as React from 'react';
import styled from 'styled-components';
import theme from '@theme';
import { AlternateText, PrimaryText } from '@components/common';

const Wrapper = styled.div`
  background-color: ${theme.card.alternateBackground};
  display: flex;
  flex-direction: column;

  padding: 8px;
  margin-top: 8px;

  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
`;

function renderItem({ name, description }: { name: string; description?: string }) {
  return (
    <Wrapper key={name}>
      <PrimaryText style={{ fontStyle: 'italic' }}>{name}</PrimaryText>
      {description && <AlternateText>{description}</AlternateText>}
    </Wrapper>
  );
}

export { renderItem };
