import qs from 'query-string';
import _ from 'lodash';
import moment from 'moment';

import ApiError from '@api/ApiError';
import themeUtils from '../themeUtils';
import api from '../fetch2';
import config from '../../config';
import { deviceUUID, uuid } from '@utils';
import User from './user';
import {
  ProductGroupDescriptor,
  BrandDetail,
  BrandSummary,
  Feature,
  PackageCampaign,
  PackageCode,
  PackageDetail,
  PackageID,
  PaidPlanJSON,
  Platform,
  PlatformDetail,
  PlatformID,
  PlatformPackage,
  ProductGroupID,
  Purchase,
  Subscription,
  ThemeDetail,
  UserJSON,
  UserSearchJSON,
  UserSubscriptionSummary,
  SubscriptionSummary,
  BrandID,
  CampaignType,
} from './models';
import { Optional } from '@app/utils/types';

const QR_TYPE_MULTIPLE_CODES = 0 as CampaignType;
const QR_TYPE_SINGLE_CODE = 1 as CampaignType;

function isAuthenticated() {
  return localStorage.getItem('session_token') != null;
}

function getUser(): Optional<User> {
  return User.load();
}

const downloadPlatforms = async (): Promise<Platform[]> => {
  const platforms = await api.get<Array<Platform>>(config.server.platforms);
  localStorage.setItem('platforms', JSON.stringify(platforms));

  return platforms;
};

async function uploadImage(file: File): Promise<{ imageURL: string }> {
  const b = new FormData();
  b.append('uploadfile', file);
  b.append('path', '/cas/themes');
  b.append('ext', '.png');

  const user = User.load();

  const res = await fetch(config.server.image_upload, {
    body: b,
    headers: {
      CASP: config.getPlatformID(),
      CAST: config.api_token,
      'Session-Token': user ? user.session_token : 'bad-state',
    },
    method: 'POST',
  });

  return res.json();
}

async function fetchPackageTagSuggestions(packageID: number) {
  return api.get<string[]>(`${config.server.packages}/${packageID}/tags/_suggestions`);
}

async function fetchPackageRegionOptions(packageID: number) {
  return api.get<string[]>(`${config.server.packages}/${packageID}/regions/_suggestions`);
}

async function fetchFeatures<T>(): Promise<Feature<T>[]> {
  return api.get(config.server.features);
}

const themes = {
  async create(theme: ThemeDetail) {
    return api.post<ThemeDetail>(`${config.server.themes}/${theme.id}`, theme);
  },

  async all(brand_id: BrandID) {
    return api.get<ThemeDetail>(`${config.server.themes}?brand_id=${brand_id}}`);
  },

  async get({ brand_id, theme_id }: { brand_id: string; theme_id: string }) {
    return api.get<ThemeDetail>(`${config.server.themes}?brand_id=${brand_id}&id=${theme_id}`);
  },

  async placeholder(brand: { id: string }) {
    return themes.create(themeUtils.placeholderThemeForBrand(brand));
  },

  async update(theme: ThemeDetail) {
    return api.put(`${config.server.themes}/${theme.id}`, theme);
  },
};

type PlatformPackageModificationRequest = {
  platform_id: PlatformID;
  delete_packages: PlatformPackage[];
  add_packages: PlatformPackage[];
};

const platforms = {
  async all(): Promise<Platform[]> {
    const storedPlatforms = localStorage.getItem('platforms');
    if (storedPlatforms) {
      return new Promise(fulfill => {
        fulfill(JSON.parse(storedPlatforms) || []);
        downloadPlatforms();
      });
    }
    return downloadPlatforms();
  },

  async alterPlatformPackages(b: PlatformPackageModificationRequest) {
    return api.post(`${config.server.platforms}/${b.platform_id}/packages/_modify`, b);
  },

  async create(platform: Platform) {
    return api.post<Platform>(config.server.platforms, platform);
  },

  async get(id: PlatformID) {
    return api.get<PlatformDetail>(`${config.server.platforms}/${id}`);
  },

  async getUsers(id: PlatformID): Promise<any> {
    return api.get(`${config.server.platforms}/${id}/_users`);
  },

  async update(platform: Platform) {
    return api.put(config.server.platforms, platform);
  },
};

async function createCampaign(campaign: PackageCampaign) {
  /**
   * Name      string       `json:"name"`
   * Type      CampaignType `json:"type"`
   * Redemptions int `json:"redemptions"`
   * CodeCount   int `json:"code_count"`
   **/
  return api.post(`${config.server.packages}/${campaign.package_id}/campaign`, campaign);
}

async function createPackage(p: PackageDetail): Promise<PackageDetail> {
  const pkg = await api.post<PackageDetail>(`${config.server.packages}/_create`, p);
  await createCampaign({
    code_count: 1,
    id: `BASIC-${pkg.id}`,
    name: 'Generated',
    package_id: pkg.id,
    redemptions: 10000,
    type: QR_TYPE_SINGLE_CODE,
  } as any);

  return fetchPackageDetail({ brand_id: pkg.brand_id, package_id: pkg.id });
}

function updatePackage(p: PackageDetail) {
  return api.put(`${config.server.packages}/${p.id}`, p);
}

type SearchPurchasesRequest = {
  filter?: {
    email?: string;
    code?: string;
    order_id?: string;
    pos: 'Apple-iap' | 'shopify' | '';
  };
};
const users = {
  async create(user: { email: string; nickname: string; password: string; password_2: string }): Promise<void> {
    /**const json = **/
    await api.post(config.server.account, {
      ...user,
      device_uuid: deviceUUID(),
    });
    // User.storeAuth(json as UserJSON);
  },

  async delete(opts: { email?: string; password?: string }): Promise<void> {
    await api.post(`${config.server.me}/_delete`, opts);

    if (opts.password) {
      User.clearAuth();
    }
  },

  async find(email: string): Promise<UserSearchJSON> {
    return api.post(`${config.server.users}`, { email });
  },

  async forgotPassword(email: string) {
    return api.post(config.server.passwordReset, {
      device_uuid: uuid(),
      email,
    });
  },

  async getProfile(): Promise<UserJSON> {
    const p = await api.get<UserJSON>(config.server.me, {});
    p.user.company = p.user.company || '';
    p.user.nickname = p.user.nickname || '';
    User.storeAuth(p);

    return p;
  },

  async getPurchases(search: SearchPurchasesRequest): Promise<Purchase[]> {
    const purchases = (await api.post(`${config.server.purchases}/_search`, search)) as Purchase[];

    return purchases.sort((a, b) => moment(b.expires_at).unix() - moment(a.expires_at).unix());
  },
  async linkExternalPurchase(code: string) {
    // do not return response.... it is an incomplete purchase struct for clients
    await api.post(`${config.server.purchases}/_link`, { code });
  },

  async login(credentials: { password: string; email: string }) {
    const reqBody = {
      device_uuid: deviceUUID(),
      p: credentials.password,
      u: credentials.email,
    };

    const json = await api.post<UserJSON>(config.server.login, reqBody);
    User.storeAuth(json);
    downloadPlatforms();

    return User.load()!;
  },

  async logout() {
    return api.post(config.server.logout, {}).then(User.clearAuth);
  },
  async paidSubscription(email: string, opts: PaidPlanJSON) {
    return api.post(config.server.paid_user_subscription(email), opts);
  },
  async reclaimPurchase(data: { email: string; code: string }) {
    const response = await api.post<{ purchase: Purchase }>(`${config.server.purchases}/_revoke`, data);
    const isActive = moment(response.purchase.expires_at).isAfter(moment.now());
    if (isActive) {
      response.purchase.status = 'ACTIVE';
    } else {
      response.purchase.status = 'EXPIRED';
    }
    return response;
  },

  async resetPassword(x: { password: string; password_2: string; reset_token: string }): Promise<any> {
    if (x.password.length < 6) {
      throw new ApiError({
        message: 'Password is too short',
        status_code: 400,
      });
    }

    if (x.password !== x.password_2) {
      throw new ApiError({
        message: 'Password do not match',
        status_code: 400,
      });
    }
    /**
     * reset_token:
     * password:
     * password_2:
     */
    return api.post(`${config.server.account}/reset-password`, x);
  },

  async updateFeatures(email: string, user_features: Feature<any>[]): Promise<any> {
    return api.post(config.server.user_features(email), user_features);
  },

  async updateProfile(p: UserJSON & { user: { old_password?: string } }): Promise<any> {
    return api.post(config.server.me, p.user);
  },

  async verifyAccount(params: { token: string }): Promise<UserJSON> {
    const body = {
      ...params,
      device_uuid: deviceUUID(),
      platform_id: config.getPlatformID(),
    };

    const auth = await api.post<UserJSON>(`${config.server.account}/verify`, body);

    User.storeAuth(auth);

    return auth;
  },
};
type ChangeBrandPermissionRequest = {
  brand_id: string;
  email: string;
};
const brands = {
  async addContributor({ brand_id, email }: ChangeBrandPermissionRequest): Promise<any> {
    return api.post(`${config.server.brand}/${brand_id}/owner/_share`, {
      email,
    });
  },

  async addOwner({ brand_id, email }: ChangeBrandPermissionRequest) {
    return api.post(`${config.server.brand}/${brand_id}/permissions/_add_owner`, { email });
  },

  async all(queryParams = {}) {
    return api.get<BrandSummary[]>(`${config.server.brand_summary}?${qs.stringify(queryParams)}`);
  },

  async destroy(brand_id: string) {
    return api.del(`${config.server.brand}/${brand_id}/_delete`);
  },

  async detail(brandID: string): Promise<BrandDetail> {
    const url = `${config.server.brand_summary}?id=${brandID}`;
    const brand: BrandDetail = await api.get(url);
    if (!brand.is_valid) {
      throw new Error('Invalid Brand');
    }

    if (brand.permissions) {
      brand.permissions.sort((a, b) => a.email.localeCompare(b.email));
    }

    // Patch for legacy brands that have multiple themes.
    // ---> those legacy brands that have a theme with brand_id equaling theme_id should bump that theme to index 0
    const indexOf = brand.themes.findIndex(t => t.id === brand.id);
    if (indexOf !== -1) {
      const items = brand.themes.splice(indexOf, 1);
      brand.themes.unshift(items[0]);
    }

    return brand;
  },

  async subscriptions(brandID: string): Promise<{
    subscriptions: SubscriptionSummary[];
    alternate: UserSubscriptionSummary[];
  }> {
    const res: { subscriptions: SubscriptionSummary[] } = await api.get(
      `${config.server.cas.brand}/${brandID}/subscriptions`,
    );

    // const groupByEmail = _.groupBy(res.subscriptions, "email")
    return {
      subscriptions: res.subscriptions,

      alternate: _.chain(res.subscriptions)
        .groupBy('email')
        .entries()
        .map(([email, arr]) => {
          return {
            email,
            name: '',
            subscriptions: arr,
          };
        })
        .value(),
    };
  },

  async transferOwner({ brand_id, email }: ChangeBrandPermissionRequest): Promise<any> {
    return api.post(`${config.server.brand}/${brand_id}/owner/_transfer`, {
      email,
    });
  },

  async update(brand: BrandDetail) {
    return api.put(`${config.server.brand}/${brand.id}`, brand);
  },
};

function createPackageCode(code: PackageCode) {
  return api.post(`${config.server.packages}/${code.package_id}/code`, code);
}

async function fetchPackageDetail({
  brand_id,
  package_id,
}: {
  brand_id: BrandID;
  package_id: PackageID;
}): Promise<PackageDetail> {
  const json = await api.get<PackageDetail>(`${config.server.packages}?brand_id=${brand_id}&id=${package_id}`);

  // json.theme = json.available_themes.find((t: { id: string }) => t.id === json.theme_id);

  json.codes.forEach(c => {
    c.campaign = c.campaign || {
      id: c.code,
      name: 'Unnamed Campaign',
    };
  });

  const codesByCampaign = _.groupBy(json.codes, 'campaign.id');
  json.campaigns = Object.keys(codesByCampaign).map(key => ({
    ...codesByCampaign[key][0].campaign,
    codes: codesByCampaign[key],
  }));

  return json;
}

// function pgURL(pkgID, pgID) {
//   const baseURL = config.server.package_product_groups;
//   return `${baseURL}/${pkgID}/${pgID}`;
// }
async function modifyPackageProductGroups({
  packageID,
  deletions,
  additions,
}: {
  packageID: number;
  deletions: ProductGroupID[];
  additions: ProductGroupID[];
}) {
  return api.post<{ status: 'ok' }>(`${config.server.package_product_groups}/${packageID}/modify`, {
    add_product_groups: additions,
    delete_product_groups: deletions,
  });
}
async function addProductGroupToPackage({
  package_id,
  product_group_id,
}: {
  package_id: PackageID;
  product_group_id: ProductGroupID;
}) {
  return api.post(`${config.server.package_product_groups}/${package_id}/${product_group_id}`, {});
}

async function removeProductGroupFromPackage({
  package_id,
  product_group_id,
}: {
  package_id: PackageID;
  product_group_id: ProductGroupID;
}) {
  return api.del(`${config.server.package_product_groups}/${package_id}/${product_group_id}`, {});
}

async function internalGetProductGroups(): Promise<{
  items: ProductGroupDescriptor[];
}> {
  return api.get(config.server.cas.product_groups);
}

function downloadCampaignZipFile(options: { package_id: number; tracking_id: string }) {
  /**
   * package_id: required
   * tracking_id: required
   * download_file_name:  required
   */

  return api.post<{ url: string }>(`${config.server.packages}/${options.package_id}/campaign/_zip`, options);
}

type ChangeRequest = {
  id: string;
  email: string;
  type: 'product-group' | 'brand' | 'formulation-library';
};
async function transferProductGroupOwnership({ id, email, type }: ChangeRequest) {
  return api.post(`${config.server.product_groups}/${id}/owner/_transfer`, {
    email,
    type,
  });
}
async function revokeProductGroupAccess({ id, email, type }: ChangeRequest) {
  return api.post(`${config.server.product_groups}/${id}/owner/_revoke`, {
    email,
    type,
  });
}

async function addProductGroupContributor({ id, email, type }: ChangeRequest) {
  return api.post(`${config.server.product_groups}/${id}/owner/_share`, {
    email,
    type,
  });
}

async function addProductGroupOwner({ id, email, type }: ChangeRequest) {
  return api.post(`${config.server.product_groups}/${id}/permissions/_add_owner`, { email, type });
}

async function revokeBrandAccess({ id, email, type }: ChangeRequest) {
  return api.post(`${config.server.brand}/${id}/owner/_revoke`, {
    email,
    type,
  });
}

type ProductGroupPermissionRequest = {
  brand_id: string;
  product_group_id: string;
};
const addProductGroupBrandAccess = ({ brand_id, product_group_id }: ProductGroupPermissionRequest) =>
  api.post(`${config.server.brand}/${brand_id}/product_groups/${product_group_id}`, {});

async function revokeProductGroupBrandAccess({ brand_id, product_group_id }: ProductGroupPermissionRequest) {
  return api.del(`${config.server.brand}/${brand_id}/product_groups/${product_group_id}`, {});
}

async function deletePackage(package_id: number) {
  return api.del(`${config.server.packages}/${package_id}/_delete`);
}

const sendEmail = (opts: any) => api.post(`${config.server.noti}`, opts);

type BrandCreationResponse = {
  brand: BrandDetail;
  package: PackageDetail;
};
const createBrandWithPackage = async (
  // DO NOT POPULATE 'id', CLIENT SIDE.
  // (The server does not verify id is not taken when client specifies id.)
  // (This is in here so to allow developer creation of production brands.
  // ---->  Thus allowing, saved_colors to operate properly)....
  brand: BrandDetail,
  _package: { name: string } = { name: '' },
): Promise<BrandCreationResponse> => {
  //TODO: Move most of this logic to server side after release of variable-cloud-beta
  /**{
   *  id,
   *  name
   * }
   */
  const result = {
    brand: {} as Optional<BrandDetail>,
    package: undefined as Optional<PackageDetail>,
  };

  let { name } = brand;
  if (name.length === 0) {
    const user = User.load();
    if (user) {
      name = `${user.nickname} Organization`;
    } else {
      name = 'My new organization';
    }
  }

  // Create a new brand
  result.brand = await api.post<BrandDetail>(config.server.brand, { name });

  // Create a placeholder brand
  let theme;
  if (brand.themes.length === 0) {
    theme = await themes.placeholder(result.brand as BrandDetail);
  } else {
    theme = brand.themes[0];

    if (!theme.colors) {
      theme.colors = {};
    }

    if (!theme.images) {
      theme.images = {
        splash: {
          png: '',
        },
        banner: {
          png: '',
        },
        loading: {
          png: '',
        },
      };
    }

    if (!theme.images.splash) {
      theme.images.splash = {
        png: themeUtils.defaultImage('splash'),
      };
    }

    if (!theme.images.banner) {
      theme.images.banner = {
        png: themeUtils.defaultImage('banner'),
      };
    }

    theme.id = (result.brand as BrandDetail).id;
    theme.brand_id = (result.brand as BrandDetail).id;
    brand.themes[0] = await themes.create(theme);
  }

  result.brand.themes.push(theme);

  result.package = await createPackage({
    brand_id: theme.brand_id,
    theme_id: theme.id,
    ..._package,
    name: 'Basic Access',
  } as any);

  (result.brand as BrandDetail).packages.push(result.package as any);

  // Return the package and the brand
  return result as BrandCreationResponse;
};

async function createSdk(sdk: any) {
  return api.put(`${config.server.platforms}/${sdk.platform_id}/sdk`, sdk);
}

async function unsubscribeSubscription(opts: { id: number; email?: string }) {
  return api.post(config.server.unsubscribe, opts);
}

async function unsubscribeUser<T>(subscription: Subscription<T>) {
  return api.post(`${config.server.brand}/${subscription.brand_id}/owner/_unsubscribe`, subscription);
}

async function subscribeUser<T>(opts: { redeemable_code: string; email?: string }): Promise<Subscription<T>> {
  const subscription = await api.post<Subscription<T>>(config.server.subscribe, opts);
  if (!opts.email) {
    // Refresh the profile with subscriptions
    // (if not subscribing on behalf of a user)
    await users.getProfile();
  }

  return subscription;
}

async function requestAccess(params: { name: string; comments: string; company: string }) {
  return api.post(config.server.access_request, params);
}

async function fetchBrandsWithProductGroup(productGroupID: string) {
  const json = await api.get<{
    docs: { package_name: string; package_id: number; brand_id: string; brand: BrandDetail }[];
  }>(`${config.server.cas.product_groups}/${productGroupID}`);
  json.docs = json.docs ?? [];
  json.docs
    .filter(x => x.brand.is_valid)
    .forEach(({ brand }) => {
      // Patch for legacy brands that have multiple themes.
      // ---> those legacy brands that have a theme with brand_id equaling theme_id should bump that theme to index 0
      const indexOf = brand.themes.findIndex(t => t.id === brand.id);
      if (indexOf !== -1) {
        const items = brand.themes.splice(indexOf, 1);
        brand.themes.unshift(items[0]);
      }
    });

  return json;
}

const payment = {
  async cancelSubscription() {
    return api.del(`${config.server.cas.subscriptions}/cancel`);
  },

  async customer() {
    return api.get<{ id: string; token: string }>(`${config.server.cas.customer}`);
  },

  async freeTierTransaction() {
    return api.post(`${config.server.cas.free_tier_signup}`, {});
  },

  async plans() {
    return api.get(`${config.server.cas.plans}`);
  },

  async subscriptions(email?: string) {
    if (email) {
      return api.get<any>(`${config.server.cas.subscriptions}?user_id=${encodeURIComponent(email)}`);
    }
    return api.get<any>(`${config.server.cas.subscriptions}`);
  },

  async transaction(options: { payment_method_nonce: string; plan_id: string }) {
    return api.post(`${config.server.cas.transaction}`, options);
  },
};

export default {
  User,
  QR_TYPE_MULTIPLE_CODES,
  QR_TYPE_SINGLE_CODE,
  addProductGroupBrandAccess,
  addProductGroupContributor,
  addProductGroupOwner,
  addProductGroupToPackage,
  brands,
  createBrandWithPackage,
  createCampaign,
  createPackage,
  createPackageCode,
  createSdk,
  deletePackage,
  downloadCampaignZipFile,
  downloadPlatforms,
  fetchBrandsWithProductGroup,
  fetchFeatures,
  fetchPackageDetail,
  fetchPackageRegionOptions,
  fetchPackageTagSuggestions,
  getUser,
  isAuthenticated,
  internalGetProductGroups,
  modifyPackageProductGroups,
  payment,
  platforms,
  removeProductGroupFromPackage,
  requestAccess,
  revokeBrandAccess,
  revokeProductGroupAccess,
  revokeProductGroupBrandAccess,
  sendEmail,
  subscribeUser,
  themes,
  transferProductGroupOwnership,
  unsubscribeSubscription,
  unsubscribeUser,
  updatePackage,
  // updatePackageCode,
  uploadImage,
  users,
};
