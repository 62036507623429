import React from 'react';
import moment from 'moment';
import { PackageDetail } from '@api/cas/models';
import { Button, LabeledInput, ModuleSegment } from '@components/common';
import cas from '@api/cas';
import theme from '@theme';
import { formatBytes } from '@utils';

type ViewProps = {
  packageDetail: PackageDetail;
};

function QuadButtons(packageDetail: PackageDetail) {
  const buttons = [];

  const url = packageDetail.publish?.url;
  if (url) {
    buttons.push(
      <a href={url} target="_new" key="download-archive">
        <Button color={theme.button.neutralColor} text="Download Archive" />
      </a>,
    );
  }

  buttons.push(
    <Button
      outline
      color={theme.button.neutralColor}
      key="publish-realm-maker"
      text="Publish (Realm Maker)"
      onClick={() => {
        cas.modifyPackageProductGroups({ packageID: packageDetail.id, additions: [], deletions: [] });
      }}
    />,
  );

  return buttons;
}

export default function PackagePublishQuad({ packageDetail }: ViewProps) {
  const { publish } = packageDetail;
  if (!publish) {
    return null;
  }

  return (
    <ModuleSegment title="Production Details" buttons={QuadButtons(packageDetail)}>
      <LabeledInput label="Download Size" inputProps={{ value: formatBytes(publish.download_size) }} />
      <LabeledInput
        label="Updated At"
        inputProps={{
          value: moment(publish.updated_at).format('MMMM Do YYYY, h:mm:ss a'),
        }}
      />
    </ModuleSegment>
  );
}
