import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

import { Palette } from '../../api/appdata/model';
import ProductPreview from '../products/ProductPreview';

import { AlternateText, FlexRows, PrimaryText } from '../common';

const PaletteListCellContainer = styled.div<{ selected?: boolean }>`
  height: 100px;
  padding: 6px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => (props.selected ? theme.table.selectedBackground : theme.table.rowBackground)};

  &:hover {
    cursor: pointer;
    background: ${theme.table.selectedBackground};
  }
`;

export type PaletteListCellHandlers = {
  onPaletteItemMoved?: (fromPaletteID: string, targetPalette: Palette, paletteItemID: string) => void | any;
  onScanSavedToPalette?: (scanID: string, palette: Palette) => void;
  paletteSelected?: (palette: Palette) => void;
};
type Props = PaletteListCellHandlers & {
  palette: Palette;
  isSelected: boolean;
};

class PaletteListCell extends React.Component<Props, any> {
  _selected = () => {
    const { palette, paletteSelected } = this.props;

    paletteSelected?.(palette);
  };

  onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  onDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();

    const { palette, onPaletteItemMoved, onScanSavedToPalette } = this.props;
    const json = JSON.parse(e.dataTransfer.getData('text/plain'));
    if (json.type === 'Scan') {
      onScanSavedToPalette?.(json.scanID, palette);
      return;
    }
    const { fromID, itemID } = json;

    onPaletteItemMoved?.(fromID, palette, itemID);
  };

  render() {
    const { palette, isSelected } = this.props;
    const { name } = palette;
    let numItems = 0;
    if (palette.paletteItems) {
      numItems = palette.paletteItemCount;
    }

    return (
      <PaletteListCellContainer
        selected={isSelected}
        onClick={this._selected}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
      >
        <FlexRows style={{ flex: 1 }}>
          <PrimaryText style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} fontWeight="bold">
            {name}
          </PrimaryText>
          <AlternateText>{numItems} Items</AlternateText>
        </FlexRows>
        <ProductPreview height={96} hex_colors={palette.hex_colors} image_url={palette.image_url} />
      </PaletteListCellContainer>
    );
  }
}

export default PaletteListCell;
export { PaletteListCellContainer };
