import * as React from 'react';
import User from '@api/cas/user';
import type { UserFeatureID, AccessControlID } from '@api/cas/models';

export type InjectedProps = { user: User };

type Props<T> = T & InjectedProps;
type ElementParam<P> = (props: Props<P>) => React.ReactElement<P> | null;
type HocElement<P> = (props: P) => React.ReactElement<P> | null;

export function withUser<P>(Component: ElementParam<P>): HocElement<P> {
  return (props: P) => {
    const user = User.load();
    if (!user) {
      return null;
    }
    return <Component {...props} user={user} />;
  };
}

export function requiresSystemAdmin<P>(Component: ElementParam<P>): HocElement<P> {
  return withUser(props => {
    if (!props.user.is_system_admin) {
      return null;
    }

    return <Component {...props} />;
  });
}

export function SystemAdminOnly<T>({ children }: { children: React.ReactElement<T> }) {
  const user = User.load();
  if (!user || !user.is_system_admin) {
    return null;
  }
  return children;
}

export function DeveloperOnly<T>({ children }: { children: React.ReactElement<T> }) {
  const user = User.load();
  if (!user || !user.is_developer) {
    return null;
  }
  return children;
}

export function requireDeveloperAccess<P>(Component: ElementParam<P>): HocElement<P> {
  return withUser(props => {
    if (!props.user.is_developer) {
      return null;
    }
    return <Component {...props} />;
  });
}

export function requireUserFeature<P>(Component: ElementParam<P>, id: UserFeatureID): HocElement<P> {
  return withUser(props => {
    const { user } = props;
    if (!user.hasFeature(id) && !user.is_system_admin) {
      return null;
    }
    return <Component {...props} />;
  });
}
export function ValidateUserFeature<T>({ id, children }: { id: UserFeatureID; children: React.ReactElement<T> }) {
  const user = User.load();
  if (!user || !user.hasFeature(id)) {
    return null;
  }
  return children;
}

export function ValidateAccessControl<T>({
  id,
  children,
  user,
}: {
  user: User;
  id: AccessControlID;
  children: React.ReactElement<T>;
}) {
  if (!user || !user.hasAccessControl(id)) {
    return null;
  }
  return children;
}

export function requirePaidFeature<P>(
  Component: ElementParam<P>,
  id: 'vizualizations' | 'package_codes' | 'bulk_edit',
): HocElement<P> {
  return withUser(props => {
    const { user } = props;
    if (!user.hasPaidFeature(id) && !user.is_system_admin) {
      return null;
    }
    return <Component {...props} />;
  });
}
