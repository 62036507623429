import React from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { LabColor } from '@variablecolor/colormath';
import theme from '@theme';
import { round } from '..';
import { Optional } from '../types';

function createTextCanvas(
  text: string,
  color: string = theme.text.primary,
  font: string = 'MontoSerrat',
  size: number = 16,
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.font = `${size * 8}px ${font}`;
    const { width } = ctx.measureText(text);
    canvas.width = width;
    canvas.height = Math.ceil(size);

    ctx.fillStyle = color;
    ctx.fillText(text, 0, Math.ceil(size * 0.8));
  }
  return canvas;
}

function createText2D(text: string, color?: string, font?: string, size?: number) {
  const canvas = createTextCanvas(text, color, font, size);
  const plane = new THREE.PlaneGeometry(canvas.width, canvas.height);
  const tex = new THREE.Texture(canvas);
  tex.needsUpdate = true;
  tex.minFilter = THREE.LinearFilter;
  const planeMat = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    map: tex,
    transparent: true,
  });
  planeMat.blendSrc = THREE.DstColorFactor;

  const mesh = new THREE.Mesh(plane, planeMat);
  mesh.scale.set(0.15, 0.15, 0.01);
  // mesh.doubleSided = true;
  return mesh;
}
//#endregion

export type ProductColorDataPoint = {
  lab: LabColor;
  product_id: string;
  product_group_id: Optional<string>;
  color_index: number;
  hex: string;

  plot_line?: any;
};
type ViewProps = {
  productColors: ProductColorDataPoint[];
  onClick?: (item?: ProductColorDataPoint) => void | any;

  backgroundColor: string;
  sphereSize: number;
};
export default class ThreeContainer extends React.Component<ViewProps> {
  static defaultProps = {
    backgroundColor: theme.segment.moduleBackground,
    sphereSize: 1,
  };
  el = React.createRef<HTMLDivElement>();

  scene?: THREE.Scene;
  camera?: THREE.PerspectiveCamera;
  renderer?: THREE.WebGLRenderer;
  controls?: OrbitControls;

  requestID?: number;

  raycaster: THREE.Raycaster;
  mouse: THREE.Vector2;

  constructor(props: ViewProps) {
    super(props);

    this.raycaster = new THREE.Raycaster();
    this.raycaster.params.Points = { threshold: 15 };

    this.mouse = new THREE.Vector2();
  }

  sceneSetup = (doc: HTMLDivElement) => {
    if (!this.el.current?.parentElement) {
      return; //crash and burn
    }
    const { clientWidth: width, clientHeight: height } = this.el.current.parentElement;

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      75, // field of view
      width / height, //aspect ratio
      0.1, // near plane
      1000, // far plane
    );
    this.controls = new OrbitControls(this.camera, doc);
    this.controls.keyPanSpeed = 1;

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      precision: 'highp',
    });
    this.renderer.setSize(width, height);

    const { backgroundColor } = this.props;
    this.renderer.setClearColor(backgroundColor, 1.0);

    // mount using React ref
    doc.appendChild(this.renderer.domElement);
  };

  add3DScatterPlot = () => {
    import('d3').then(d3 => {
      const scatterPlot = new THREE.Object3D();
      this.scene!.add(scatterPlot);

      // find the min and maxes
      const { productColors, sphereSize } = this.props;
      const [xMin, xMax] = d3.extent(productColors, (d: ProductColorDataPoint) =>
        Math.max(Math.min(d.lab.L * 1.015, 127), -128),
      ) as [number, number];
      const [yMin, yMax] = d3.extent(productColors, (d: ProductColorDataPoint) =>
        Math.max(Math.min(d.lab.a * 1.015, 127), -128),
      ) as [number, number];
      const [zMin, zMax] = d3.extent(productColors, (d: ProductColorDataPoint) =>
        Math.max(Math.min(d.lab.b * 1.015, 127), -128),
      ) as [number, number];

      const vpts = {
        xCen: (xMin + xMax) / 2,
        xMax,
        xMin,

        yCen: (yMin + yMax) / 2,
        yMax,
        yMin,

        zCen: (zMin + zMax) / 2,
        zMax,
        zMin,
      };

      // Create scaling functions
      const xScale = d3.scaleLinear().domain([xMin, xMax]).range([-50, 50]);
      const yScale = d3.scaleLinear().domain([yMin, yMax]).range([-50, 50]);
      const zScale = d3.scaleLinear().domain([zMin, zMax]).range([-50, 50]);

      // Create ticks for intervals
      const lineGeo = new THREE.BufferGeometry();
      const points = [
        // x line
        xScale(vpts.xMin),
        yScale(vpts.yCen),
        zScale(vpts.zCen),
        xScale(vpts.xMax),
        yScale(vpts.yCen),
        zScale(vpts.zCen),
        xScale(vpts.xCen),
        yScale(vpts.yCen),
        zScale(vpts.zCen),

        // y line
        xScale(vpts.xCen),
        yScale(vpts.yMin),
        zScale(vpts.zCen),
        xScale(vpts.xCen),
        yScale(vpts.yMax),
        zScale(vpts.zCen),
        xScale(vpts.xCen),
        yScale(vpts.yCen),
        zScale(vpts.zCen),

        // z line
        xScale(vpts.xCen),
        yScale(vpts.yCen),
        zScale(vpts.zMin),
        xScale(vpts.xCen),
        yScale(vpts.yCen),
        zScale(vpts.zMax),
      ];
      lineGeo.setAttribute('position', new THREE.Float32BufferAttribute(points, 3));

      //#region Create Labels for the Axes
      const lineMat = new THREE.LineBasicMaterial({
        color: theme.border.neutral,
        linewidth: 0.75,
      });
      const line = new THREE.Line(lineGeo, lineMat);
      line.type = 'Line';
      scatterPlot.add(line);

      let titleX = createText2D('-L');
      titleX.position.setX(xScale(vpts.xMin));
      titleX.position.y = -2;
      scatterPlot.add(titleX);

      let valueX = createText2D(round(xMin).toString());
      valueX.position.x = xScale(vpts.xMin);
      valueX.position.y = -2;
      scatterPlot.add(valueX);

      titleX = createText2D('L');
      titleX.position.x = xScale(vpts.xMax);
      titleX.position.y = -2;
      scatterPlot.add(titleX);

      valueX = createText2D(round(xMax).toString());
      valueX.position.x = xScale(vpts.xMax) + 2;
      valueX.position.y = -5;
      scatterPlot.add(valueX);

      let titleY = createText2D('-a');
      titleY.position.y = yScale(vpts.yMin) - 2;
      scatterPlot.add(titleY);

      let valueY = createText2D(round(yMin).toString());
      valueY.position.y = yScale(vpts.yMin) - 2;
      scatterPlot.add(valueY);

      titleY = createText2D('a');
      titleY.position.y = yScale(vpts.yMax) + 2;
      scatterPlot.add(titleY);

      valueY = createText2D(round(yMax).toString());
      valueY.position.y = yScale(vpts.yMax) + 2;
      scatterPlot.add(valueY);

      let titleZ = createText2D(`-b ${round(zMin)}`);
      titleZ.position.z = zScale(vpts.zMin) - 2;
      titleZ.position.x = 10;
      scatterPlot.add(titleZ);

      titleZ = createText2D(`b ${round(zMax)}`);
      titleZ.position.z = zScale(vpts.zMax) + 2;
      scatterPlot.add(titleZ);
      //#endregion Label Creation

      //#region Points and Vertices

      const pointCount = productColors.length;
      // const pointGeo = new THREE.Geometry();
      const positions = [];
      const colors = [];
      for (let i = 0; i < pointCount; i++) {
        const x = xScale(productColors[i].lab.L);
        const y = yScale(productColors[i].lab.a);
        const z = zScale(productColors[i].lab.b);

        positions.push(x);
        positions.push(y);
        positions.push(z);

        const color = new THREE.Color(productColors[i].hex);

        colors.push(color.r);
        colors.push(color.g);
        colors.push(color.b);
      }

      const pointGeo = new THREE.BufferGeometry();
      pointGeo.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
      pointGeo.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

      const material = new THREE.PointsMaterial({
        alphaTest: 0.5,
        map: new THREE.TextureLoader().load('/images/ball3.png'),
        size: sphereSize ?? 1,
        vertexColors: true,
        transparent: true,
      });

      scatterPlot.add(new THREE.Points(pointGeo, material));

      //#endregion
    });
  };

  startAnimationLoop = () => {
    if (this.renderer && this.scene && this.camera) {
      this.renderer.render(this.scene, this.camera);
    }

    this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
  };

  handleWindowResize = () => {
    const { clientWidth: width, clientHeight: height } = this.el.current!;

    this.renderer!.setSize(width, height);
    this.camera!.aspect = width / height;
    this.camera!.updateProjectionMatrix();
  };

  handleMouseClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // update the mouse variable
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    if (this.scene) {
      this.scene.updateMatrixWorld();
      this.renderer!.render(this.scene, this.camera!);

      this.raycaster.setFromCamera(this.mouse, this.camera!);

      let item;
      const points = this.raycaster.intersectObject(this.scene.children[0].children[11], true);
      if (points.length > 0) {
        const point = points[0];
        const { productColors } = this.props;
        item = productColors[point.index!];
      }

      this.props.onClick?.(item);
    }
  };

  init = () => {
    this.sceneSetup(this.el.current!);
    this.add3DScatterPlot();
    this.startAnimationLoop();

    // // set some distance from a cube that is located at z = 0.
    this.camera!.position.z = 100;
  };
  componentDidMount = () => {
    this.init();
    window.addEventListener('resize', this.handleWindowResize);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    if (this.requestID) {
      window.cancelAnimationFrame(this.requestID);
    }

    this.controls?.dispose();
  }

  render() {
    return <div ref={this.el} onMouseUp={this.handleMouseClick} />;
  }
}
