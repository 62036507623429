import * as React from 'react';

import { Modal, ModuleSegment, Button, FlexColumns, FlexRows, IconInput } from '../common';
import theme from '@theme';
import { useHistory } from 'react-router';
import routeUtils from '@utils/route-utils';

type Props = {
  handleSubmit: (arg: { name: string; notes: string }) => void;
  handleCancel: () => void;
};

function CreatorView({ handleSubmit, handleCancel }: Props) {
  const [name, setName] = React.useState('');
  const [notes, setNotes] = React.useState('');

  return (
    <FlexRows>
      <IconInput
        inputProps={{
          autoFocus: true,
          placeholder: 'Enter new folder name',
          type: 'text',
          value: name,
        }}
        name="info"
        onChange={(_, { value }) => {
          setName(value);
        }}
      />
      <br />
      <IconInput
        inputProps={{
          placeholder: 'Enter folder notes',
          type: 'text',
          value: notes,
        }}
        name="info"
        onChange={(_, { value }) => {
          setNotes(value);
        }}
      />
      <br />

      <FlexColumns>
        <Button
          text="Create"
          color={theme.button.createColor}
          style={{ float: 'right' }}
          onClick={e => {
            e.preventDefault();
            handleSubmit({ notes, name });
          }}
        />

        <Button
          text="Cancel"
          style={{ float: 'right' }}
          color={theme.button.cancelColor}
          outline
          onClick={e => {
            e.preventDefault();
            handleCancel();
          }}
        />
      </FlexColumns>
    </FlexRows>
  );
}

export default function CreatePaletteView({
  handleSubmit,
}: {
  handleSubmit: (param: { name: string; notes: string }) => void;
}) {
  const history = useHistory();
  return (
    <Modal key="create_folder" modalProps={{ defaultOpen: true }}>
      <ModuleSegment title="Create a Saved Folder">
        <CreatorView handleSubmit={handleSubmit} handleCancel={() => history.replace(routeUtils.savedColors())} />
      </ModuleSegment>
    </Modal>
  );
}
