import React from 'react';
import styled from 'styled-components';
import { AlternateText, PrimaryText } from '../Text';

const LogoContainer = styled.div`
  justify-content: center;
  margin: 32px auto;
`;

const VariableCloudLogoImage = styled.img.attrs({
  src: '/ColorCloudLogo.svg',
})`
  width: 100%;
  height: 300px;
  object-fit: contain;
`;

const VariableCloudLogo = () => (
  <LogoContainer>
    <VariableCloudLogoImage />
  </LogoContainer>
);

const Container = styled.div`
  height: 512px;
  width: 512px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;
`;

const NoContentCreatedImage = styled.img.attrs({
  src: '/images/empty.svg',
})`
  height: 80%;
  width: 100%;
  object-fit: contain;
`;

type ViewProps = {
  title: string;
  subTitle?: string;
};
const NoContentCreatedContainer = ({ title, subTitle }: ViewProps) => (
  <Container>
    <NoContentCreatedImage />
    <PrimaryText style={{ lineHeight: 1.75, marginTop: 12 }} textAlign="center" fontSize="24px" fontWeight={700}>
      {title}
    </PrimaryText>
    <AlternateText textAlign="center">{subTitle}</AlternateText>
  </Container>
);

const NoSearchContent = (props: any) =>
  React.cloneElement(<NoContentCreatedContainer title="No matching products found." />, props);

const NoProductLibraryContent = (props: any) =>
  React.cloneElement(
    <NoContentCreatedContainer
      title="You have no product libraries yet."
      subTitle={'Click the "Create Library" button to get started'}
    />,
    props,
  );

const NoProductContent = (props: any) =>
  React.cloneElement(
    <NoContentCreatedContainer
      title="You have no products yet."
      subTitle={'Click the "Add Products" button to get started'}
    />,
    props,
  );

const NoOrganizationContent = (props: any) =>
  React.cloneElement(
    <NoContentCreatedContainer
      title="You have no organization yet."
      subTitle={'Click the "Create Organization" button to get started'}
    />,
    props,
  );

const NoScanHistoryContent = (props: any) =>
  React.cloneElement(
    <NoContentCreatedContainer
      title="You have no scans yet."
      subTitle="Use a Variable Cloud compatible mobile app to get started"
    />,
    props,
  );

export {
  VariableCloudLogo,
  NoScanHistoryContent,
  NoOrganizationContent,
  NoProductContent,
  NoProductLibraryContent,
  NoContentCreatedContainer,
  NoSearchContent,
};
