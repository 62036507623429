import _ from 'lodash';
import cas from './cas';
import { ProductGroupDescriptor } from './cas/models';
import { ProductGroup } from './vpapi/models';

export default {
  categories<T>(pgs: Array<T & { category: string }>): string[] {
    return _(pgs).map('category').uniq().sort().value();
  },

  orderedByVendor<T>(pgs: Array<T & { vendor: string; name: string }>): Array<T & { vendor: string; name: string }> {
    return _.orderBy(pgs, [g => g.vendor.toLowerCase(), g => g.name.toLowerCase()], ['asc', 'asc']);
  },

  async enableProductGroupBrandAccess<T>(brand_id: string, groups: (T & { id: string })[]) {
    try {
      await Promise.all(
        groups.map(g => {
          const product_group_id = g.id;
          return cas.addProductGroupBrandAccess({ brand_id, product_group_id });
        }),
      );
    } catch (err) {
      console.error(err);
    }
  },

  filteredByCategories<T>(pgs: Array<T & { category: string }>, cats: string[]): Array<T & { category: string }> {
    if (cats === null || cats.length === 0) {
      return pgs;
    }

    return pgs.filter(g => _.includes(cats, g.category));
  },

  filteredByVendors<T>(pgs: Array<T & { vendor: string }>, vs: string[]): Array<T & { vendor: string }> {
    if (vs === null || vs.length === 0) {
      return pgs;
    }

    return pgs.filter(g => _.includes(vs, g.vendor));
  },

  modifyPackageProductGroupsOnCas<T>(
    packageID: number,
    addableGroups: Array<T & { id: string }>,
    deletableGroups: Array<T & { id: string }>,
  ) {
    return cas.modifyPackageProductGroups({
      additions: addableGroups.map(pg => pg.id),
      deletions: deletableGroups.map(pg => pg.id),
      packageID,
    });
  },

  async revokeProductGroupBrandAccess<T>(brand_id: string, groups: (T & { id: string })[]) {
    try {
      await Promise.all(
        groups.map(g => {
          const product_group_id = g.id;
          return cas.revokeProductGroupBrandAccess({
            brand_id,
            product_group_id,
          });
        }),
      );
    } catch (err) {
      console.error(err);
    }
  },

  search<T extends ProductGroupDescriptor>(pgs: Array<T>, terms: string): Array<T> {
    if (terms === null || terms === '') {
      return pgs;
    }

    return pgs.filter(x => {
      return (
        x?.vendor.includes(terms) || x?.category.includes(terms) || x?.id.includes(terms) || x?.name.includes(terms)
      );
    });
  },

  vendors(pgs: Array<Object>): Array<string> {
    const byVendor = _.groupBy(_.sortBy(pgs, 'name'), 'vendor');

    return Object.keys(byVendor).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  },

  async grantContributorAccess(email: string, pg: ProductGroup): Promise<void> {
    await cas.addProductGroupContributor({
      email,
      id: pg.dbid,
      type: 'product-group',
    });
    const p = pg.permissions?.find(x => x.email === email);
    const permissions = { is_contributor: 1 };
    if (p) {
      p.permissions = permissions;
    } else {
      pg.permissions?.push({ email, permissions });
    }
  },

  async grantOwnership(email: string, pg: ProductGroup) {
    await cas.addProductGroupOwner({
      email,
      id: pg.dbid,
      type: 'product-group',
    });

    const p = pg.permissions?.find(perm => perm.email === email);
    if (p) {
      p.permissions = { is_owner: 1 };
    } else {
      pg.permissions!.push({
        email,
        permissions: {
          is_owner: 1,
        },
      });
    }
  },

  async revokeAccess(email: string, pg: ProductGroup): Promise<void> {
    await cas.revokeProductGroupAccess({
      email,
      id: pg.dbid,
      type: 'product-group',
    });

    pg.permissions = pg.permissions?.filter(p => p.email !== email);
  },
};
