import React from 'react';

import { useParams } from 'react-router';
import appData from '@api/appdata';
import theme from '@theme';
import { Button, Loading, Modal, ModuleSegment } from '@components/common';
import PaletteItemView from './PaletteItemView';
import { PaletteItem } from '@api/appdata/model';

type PaletteItemModalProps = {
  onItemChange: (pi: PaletteItem) => void | any;
  onClose: () => void;
};

export function PaletteItemModal({ onItemChange, onClose }: PaletteItemModalProps) {
  const [item, setItem] = React.useState<PaletteItem | null>(null);

  const { itemID, paletteID } = useParams<{ itemID: string; paletteID: string }>();
  React.useEffect(() => {
    appData
      .searchPaletteItems({ query: { paletteID } })
      .then(x => setItem(x.items.find(y => y.uuid === itemID)!))
      .catch(onClose);
  }, [itemID, onClose, paletteID]);

  const buttons = [
    <Button
      key="save"
      text="Save"
      color={theme.button.createColor}
      onClick={() => {
        if (item) {
          onItemChange(item);
        }
      }}
    />,
    <Button
      key="delete"
      text="Delete"
      color={theme.button.cancelColor}
      onClick={() => {
        if (item) {
          item.isDeleted = true;
          onItemChange(item);
        }
      }}
    />,
  ];

  return (
    <Modal modalProps={{ defaultOpen: true, size: 'large', onClose }}>
      <ModuleSegment title={item?.titleString() || 'Saved Item Details'} buttons={buttons}>
        {!item && <Loading text="Fetching Item Details" />}
        {item && <PaletteItemView handleSave={onItemChange} paletteItem={item} />}
      </ModuleSegment>
    </Modal>
  );
}
