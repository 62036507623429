import { dimensions } from '../utils';

export default {
  /** Place Dimension Data Below */
  cardMarginTop: 20,
  contentPadding: '2.5%',
  getSideBarWidthPixels: () => dimensions().width * (0.15 + 0.025 * 2),
  headerHeight: '45px',
  input: {
    containerVerticalMargin: 10,
    height: '40px',
    label_width: '275px',
    margin: 3,
  },
  invertedContentPadding: '-2.5%',
  largeButtonPadding: 12,
  mainContentPadding: '2.5%',
  moduleMarginTop: 20,

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  ////// When changing width update formula on next line
  rowHeight: '60px',
  sideBarWidthDesktop: 300,
  sideBarWidthMobile: 70,
  spacerHeight: '2.5%',
};
