import _ from 'lodash';

export interface FilterParams {
  // Server stores as [] and expects [], but client may pass as
  filter_value: string | string[];

  filter_key: string;

  // not sure
  name: string;
}
export default class ProductFilter {
  filter_key: string;

  filter_value: Array<string>;

  name: string;

  id: string;

  constructor(serverJSON: FilterParams) {
    if (!serverJSON.filter_value) {
      serverJSON.filter_value = [];
    } else if (!(serverJSON.filter_value instanceof Array)) {
      serverJSON.filter_value = [serverJSON.filter_value];
    }

    this.filter_key = serverJSON.filter_key;
    this.filter_value = serverJSON.filter_value.map(x => x.trim());
    this.name = serverJSON.name;

    this.id = _.uniqueId('filter_');
  }

  clear = () => {
    this.filter_value = [];
  };

  hasValue(v: string) {
    return _.includes(this.filter_value, v);
  }

  addValue(v: string) {
    if (this.hasValue(v)) {
      return;
    }

    this.filter_value.push(v);
  }
}
