import React from 'react';
import styled from 'styled-components';
import theme from '@theme';

const TextBox = styled.textarea`
  width: 100%;
  display: block;
  border: 0px;
  padding: 10px;
  background: ${theme.segment.bodyBackground};
  color: ${theme.text.alternate};
  resize: none;

  :focus {
    background-color: ${theme.label.background};
    outline-color: white;
  }
`;

type Props = {
  value: string;
  rows: number;
  valueUpdated: (value: string) => void;
  placeholder?: string;
};

export default React.memo<Props>(({ rows, value: defaultValue, valueUpdated, placeholder }: Props) => {
  const [value, setValue] = React.useState<string>('');
  React.useEffect(() => {
    if (defaultValue !== '' && value === '') {
      setValue(defaultValue);
    }
  }, [defaultValue, value]);

  const onBlur = React.useCallback(() => valueUpdated(value), [value, valueUpdated]);

  return (
    <TextBox
      placeholder={placeholder}
      rows={rows}
      value={value}
      onChange={event => setValue(event.target.value)}
      onBlur={onBlur}
    />
  );
});
