import React from 'react';
import styled from 'styled-components';
import type { Roles } from '../../../../api/cas/models';

import theme from '../../../../theme';

type Props = {
  permission_level: Roles;
};
export function PermissionText({ permission_level }: Props) {
  const isOwner = permission_level && permission_level.is_owner;
  const isContributor = permission_level && permission_level.is_contributor;

  let options = {
    color: theme.button.alternativeActionColor,
    text: 'Contributor',
  };
  if (isOwner) {
    options = { color: theme.button.createColor, text: 'Owner' };
  } else if (isContributor) {
    options = {
      color: theme.button.alternativeActionColor,
      text: 'Contributor',
    };
  } else {
    options = { color: theme.button.neutralColor, text: 'System Admin' };
  }

  return <MediumText>{options.text}</MediumText>;
}

export const Pad = styled.div`
  padding-top: 20px;
`;

export const Text = styled.div`
  color: ${({ color }) => color || theme.text.primary};
`;

export const SmallText = styled(Text)`
  font-size: 75%;
  font-weight: 300;
`;

export const MediumText = styled(Text)`
  font-size: 90%;
  font-weight: bold;
`;

export const Container = styled.div<{ isSelected?: boolean }>`
  margin: 4px;
  margin-right: 8px;
  height: 150px;

  background-color: ${props => (props.isSelected ? theme.table.selectedBackground : theme.card.alternateBackground)};
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 400px;

  padding: 12px;

  cursor: pointer;

  &:hover {
    border: 1px solid ${theme.border.selectedItem};
  }
`;
