import cas from '@api/cas';
import User from '@api/cas/user';
import type { PackageDetail, Feature } from '@api/cas/models';
import * as React from 'react';

import { FlexColumns, PrimaryText, Checkbox } from '@components/common';
import { Optional } from '@app/utils/types';

type ViewProps = {
  user: User;
  packageDetail: PackageDetail;
  handleFeatureChange: (x: Feature<any>) => (e: React.SyntheticEvent<HTMLElement>, checked: boolean) => void | any;
};

function useFeatures(user: User) {
  const [availableFeatures, setAvailableFeatures] = React.useState<Feature<any>[]>([]);

  React.useEffect(() => {
    cas
      .fetchFeatures()
      .then(features => features.filter(({ options }: Optional<any>) => options?.access === 'package'))
      .then(allFeatures => {
        if (user.is_system_admin) {
          return allFeatures;
        }

        return allFeatures.filter(f => user.hasFeature(f.id));
      })
      .then(setAvailableFeatures)
      .catch(console.log);
  }, [user, setAvailableFeatures]);

  return { features: availableFeatures };
}

export default function FeatureSection({ user, packageDetail, handleFeatureChange }: ViewProps) {
  const { features } = useFeatures(user);

  if (features.length === 0) {
    return null;
  }

  return (
    <>
      <PrimaryText fontWeight="bold"> Features </PrimaryText>
      <FlexColumns>
        {features.map(f => (
          <Checkbox
            key={f.id}
            containerStyle={{ marginRight: 12 }}
            text={f.name}
            checked={packageDetail.features.find(x => x.name === f.id) !== undefined}
            onCheckChange={handleFeatureChange(f)}
          />
        ))}
      </FlexColumns>
    </>
  );
}
