import React from 'react';
import { Header, Popup } from 'semantic-ui-react';
import QRCode from 'qrcode.react';
import { PackageCampaign } from '@api/cas/models';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 256px;
  min-width: 256px

  background-color: white;

`;

export default function DefaultPackageCode({ d }: { d?: PackageCampaign }) {
  if (!d) {
    return null;
  }

  const code = d.codes[0];
  const Trigger = (
    <Container>
      <Header style={{ color: 'white' }}>1</Header>
      <QRCode size={192} value={code.code} />
      <Header>{code.code}</Header>
    </Container>
  );
  return (
    <Popup
      content={
        'Share this app code with a Variable Cloud compatible app user. ' +
        ' The user scans this code to gain access to activated ' +
        ' Product Libraries inside a Variable Cloud compatible app.'
      }
      trigger={Trigger}
    />
  );
}
