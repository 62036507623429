import React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import styled from 'styled-components';
import theme from '@theme';
import { FlexColumns } from '../common';
import { PrimaryText } from '../Text';
import { BrandDetail, ThemeDetail } from '@api/cas/models';
import { NavLink } from 'react-router-dom';

//#region Styled Components for Dropdown
const Dropdown = {
  Container: styled.div`
    :hover {
      background-color: ${theme.table.hoverBackground};
    }
  `,
  ContentWrapper: styled(FlexColumns)`
    height: auto;
    background-color: ${theme.sidebarBackground};
    align-items: center;

    width: 100%;
  `,
};

const Thumbnail = styled.img`
  object-fit: contain;
  height: 48px;
  width: 48px;

  padding: 8px;

  overflow: hidden;
`;

const ThumbnailContainer = styled.div<{ backgroundColor?: string }>`
  height: 48px;
  width: 48px;

  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 32px;

  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
`;

const TitleItem = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
const SubtitleText = styled.div`
  color: ${theme.text.alternate};
  font-size: 12px;
  margin-top: 2;
  font-style: italic;
`;
const TitleContent = styled.div`
  display: inline-flex;

  white-space: nowrap;
  width: auto;

  align-items: center;

  color: white;
  padding: 8px;
  font-size: 16px;
  border: none;
  cursor: pointer;

  z-index: 1003;
`;

export type DropdownLabelProps = {
  to: any;
  item: { name: string };
  icon: SemanticICONS;
  title: string;
  imageSrc: string;
};

const DropdownTitleArea = ({ to, imageSrc, title, item, icon }: DropdownLabelProps) => (
  <Dropdown.Container>
    <NavLink to={to}>
      <TitleContent>
        <ThumbnailContainer backgroundColor={theme.icon.primaryBackground}>
          <Thumbnail draggable={false} src={imageSrc} />
        </ThumbnailContainer>

        <TitleItem>
          <PrimaryText>{title}</PrimaryText>
          <SubtitleText>{item.name}</SubtitleText>
        </TitleItem>
        <TitleItem>
          <Icon name={icon} />
        </TitleItem>
      </TitleContent>
    </NavLink>
  </Dropdown.Container>
);

//#endregion

//#region Image

const Image = ({ themes }: { themes: ThemeDetail[] }) => {
  let source;
  let backgroundColor;
  if (themes && themes.length > 0) {
    if (themes[0].images.splash) {
      source = themes[0].images.splash.png;
      backgroundColor = themes[0].colors.splashBackgroundColor;
    }
  }

  if (!source) {
    return null;
  }

  return (
    <ThumbnailContainer backgroundColor={backgroundColor}>
      <Thumbnail draggable={false} src={source} />
    </ThumbnailContainer>
  );
};

//#endregion
export type Item = { id: string; name: string };
export type DropdownProps = {
  handleClick: () => void | any;
  selectedBrand?: BrandDetail;
  rightIconName?: SemanticICONS;
};

function DropdownLabel({ selectedBrand, rightIconName = 'chevron down', handleClick }: DropdownProps) {
  if (!selectedBrand) {
    return null;
  }

  return (
    <TitleContent onClick={handleClick}>
      <Image themes={selectedBrand.themes} />
      <TitleItem>
        <PrimaryText>Organization</PrimaryText>
        <SubtitleText>{selectedBrand.name}</SubtitleText>
      </TitleItem>
      <TitleItem>
        <Icon name={rightIconName} />
      </TitleItem>
    </TitleContent>
  );
}

export function BrandDropdown(props: DropdownProps) {
  return (
    <Dropdown.Container>
      <DropdownLabel {...props} />
    </Dropdown.Container>
  );
}

export { Dropdown, DropdownTitleArea };
