import React from 'react';

import filters from '@api/vpapi/filters';
import FilterableDropdown from './FilterableDropdown';
import { Dropdown, FlexColumns, PrimaryText } from '@components/common';
import theme from '@theme';
import { Editable } from '@api/vpapi/models';
import { SearchConfig } from '@api/vpapi/SearchConfig';
import { Container } from './components';
import { Optional } from '@app/utils/types';
import { DropdownProps } from '@components/common';

type ViewProps = {
  searchConfig: SearchConfig;
};
type ViewState = {
  filterables?: Editable[];
};

const sortOptions = [
  { key: 'sorts.name', text: 'Product Name', value: 'sorts.name' },
  {
    key: 'updated_at',
    text: 'Last Modification Date/Time',
    value: 'updated_at',
  },
  { key: 'sorts.saturation', text: 'Saturation', value: 'sorts.saturation' },
  { key: 'sorts.brightness', text: 'Brightness', value: 'sorts.brightness' },
  { key: 'sorts.hue', text: 'Hue', value: 'sorts.hue' },
  {
    key: 'sorts.vendor_order',
    text: 'Scan Order',
    value: 'sorts.vendor_order',
  },
];

export default class ProductFilterBar extends React.Component<ViewProps, ViewState> {
  state = {
    filterables: undefined as Optional<Editable[]>,
  };

  get searchConfig() {
    const { searchConfig } = this.props;
    return searchConfig;
  }

  handleDirectionClick = () => {
    const {
      searchConfig: {
        sorts: { name },
        setSortBy,
      },
    } = this;
    let {
      searchConfig: {
        sorts: { direction },
      },
    } = this;
    if (direction === 'asc') {
      setSortBy(name, 'desc');
    } else {
      setSortBy(name, 'asc');
    }
  };

  handleSortChange = (_1: React.SyntheticEvent, { value }: DropdownProps) => {
    this.searchConfig.setSortBy(value as string, this.searchConfig.sorts.direction);
  };

  componentDidMount = async () => {
    let filterables;
    try {
      // editables: ?Editable[],
      // search_term: ?string,
      // product_group_ids: string[],
      const res = await filters.getEditables(this.searchConfig.editableSearchReq);

      // Filter out all attributes
      filterables = res.editables.filter(f => f.type === 'filters');
    } catch (err) {
      console.error(err);
    }
    this.setState({ filterables });
  };

  onFilterSelect = (filter: Editable, values: string[]) => {
    this.searchConfig.setSearchFilterValues(filter.name, values);
  };

  render = () => {
    const { filterables } = this.state;
    if (!filterables) {
      return <div />;
    }

    const {
      searchConfig: {
        sorts: { direction, name },
      },
    } = this;
    return (
      <FlexColumns style={{ marginTop: theme.dimensions.cardMarginTop }}>
        <Container>
          <PrimaryText>Sorting By</PrimaryText>
          <FlexColumns>
            <img
              alt="Sort By"
              style={{
                cursor: 'pointer',
                height: 36,
                objectFit: 'contain',
                paddingLeft: 12,
                paddingRight: 12,
              }}
              src={direction === 'asc' ? '/icons/ascending.png' : '/icons/descending.png'}
              onClick={this.handleDirectionClick}
            />
            <Dropdown
              style={{ margin: 0 }}
              placeholder="select sort"
              search
              minCharacters={0}
              selection
              options={sortOptions}
              value={name}
              onChange={this.handleSortChange}
            />
          </FlexColumns>
        </Container>
        {filterables.map(filter => (
          <FilterableDropdown
            key={filter.name}
            filter={filter}
            selectedValues={this.searchConfig.filter.attributes[filter.name]}
            handleChange={this.onFilterSelect}
          />
        ))}
      </FlexColumns>
    );
  };
}
