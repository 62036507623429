import papa, { ParseError, ParseStepResult } from 'papaparse';

import { Illuminant, Observer } from '@variablecolor/colormath';
import { Product, ProductCompositionDetail } from '@api/vpapi/models';
import helpers, { Line, Parsable } from './csvUtils';
import type { ParsedColorFormula, ParsedFormulationProduct } from '@api/formulation/models';
import _ from 'lodash';
import { uuid } from '@app/utils';

type Config<T extends Parsable> = {
  email: string;

  productGroupID: string;

  file: File;
  file_type: 'formula' | 'formulation_product' | 'product';
  progressCallback?: (f: T) => void | any;

  onComplete?: (items: T[]) => void;
};

export default class FormulationParser<T extends Parsable> {
  config: Config<T>;

  parserFunc: (data: Line, config: Config<T>) => T; //ParsedColorFormula | ParsedFormulationProduct

  promise?: {
    reject: (e: Error) => void;
    resolve: (items: T[]) => void;
  };

  count: number = 0;
  items: T[] = [];
  constructor(c: Config<T>) {
    this.config = c;

    switch (this.config.file_type) {
      case 'formula':
        this.parserFunc = FormulationParser.parseFormula;
        break;

      case 'formulation_product':
        this.parserFunc = FormulationParser.parseFormulationProduct;
        break;

      case 'product':
        this.parserFunc = FormulationParser.parseProduct;
        break;

      default:
        throw new Error();
    }
  }

  handleError(message: string) {
    const { promise } = this;
    if (!promise) {
      console.warn('got error, but disregarding', message);
      return;
    }

    console.warn('got error', message);
    promise.reject(new Error(message));
    this.promise = undefined;
  }

  handleStep = (result: ParseStepResult<any>) => {
    const { data, errors }: { data: Line; errors: Array<ParseError> } = result;

    // Check the errors array for problems parsing in the data
    if (errors.length > 0) {
      console.error(errors);
      this.handleError(errors[0].message);

      return;
    }

    // trim the data....
    Object.keys(data)
      .filter(k => typeof data[k] === 'string')
      .forEach(k => {
        const newKey = k.trim();
        if (k !== newKey) {
          data[newKey] = data[k];
          delete data[k];
        }

        data[newKey] = (data[newKey] as string).trim();
      });

    try {
      const item = this.parserFunc(data, this.config);

      if (item) {
        this.config.progressCallback?.(item);

        this.items.push(item);
      }
    } catch (err) {
      const message = (err as { message: string }).message as string;
      console.error(err);
      this.handleError(`Error on Line Number ${this.count + 2}. (reason:  ${message})`);
    } finally {
      this.count++;
    }
  };

  static parseFormulationProduct(data: Line /**, config: Config<R> */): any {
    const keys = Object.keys(data);

    const product: ParsedFormulationProduct = {
      attributes: helpers.parseAttributes(data, (k: string, v: string) => ({
        k: helpers.sanitize(k),
        v,
      })),

      product_image: helpers.parseImages(data, (_1, url) => url)[0],
    };

    let items = keys
      .filter(helpers.includes(helpers.application_headers))
      .filter(k => helpers.isValueTruthy(data[k] as number | string | boolean))
      .map(k => helpers.sanitize(helpers.valueFromHeader(k)));
    helpers.addAttribute(product, 'Application', items);

    items = keys
      .filter(helpers.includes(helpers.size_headers))
      .filter(k => helpers.isValueTruthy(data[k] as number | string | boolean))
      .map(k => helpers.sanitize(helpers.valueFromHeader(k)));
    helpers.addAttribute(product, 'Size', items);

    return product;
  }

  static parseProduct<R extends Parsable>(json: Line, config: Config<R>): any {
    // Cleans up some loose ends
    const key = Object.keys(json).find(x => x.toLowerCase() === 'id');
    const created_at = new Date().toISOString();
    const dbid = uuid();
    const product = new Product({
      composition_details: [] as any,
      product_attributes: [] as any,
      product_images: [] as any,
      filters: [] as any,
      collection_uuids: [config.productGroupID],
      created_at,
      updated_at: created_at,

      dbid,
      product_uuid: key ? json[key]?.toString() : dbid,
    });

    //Filters
    helpers.parseFilters(json, (k, v) => product.addFilter(k, v));

    // Attributes
    helpers.parseAttributes(json, (k, v) => product.addAttr(k, v));
    const name = product.attrValForKey('name');
    if (!name) {
      throw new Error('Product must have a attr.name column and value');
    }

    const code = product.attrValForKey('code');
    if (!code) {
      throw new Error('Product must have a attr.code column and value');
    }

    // Images
    product.product_images = helpers.parseImages(json, (image_key: string, url: string) => ({ image_key, url }));

    //Colors
    // Validates proper internal fields to lab and fields are numbers
    const colorKeys = Object.keys(json)
      .filter(helpers.includes(helpers.color_headers))
      .filter(x => json[x] !== null);
    if (colorKeys.length % 3 !== 0) {
      const message =
        'When specifying color definitions, 3 columns must be defined for each color. ' +
        '(e.g. color.0.d50L, color.0.d50A, color.0.d50B)';
      throw new Error(message);
    }

    product.composition_details = Object.keys(json)
      .filter(helpers.includes(helpers.color_headers))
      .filter(x => json[x] !== null)
      .reduce((previous: string[][], k: string) => {
        // Group the color headers by index (Composition)
        if (json[k] === '' || json[k] === undefined) {
          return previous;
        }

        const headers = k.split('.');
        let indexOfColor: number = 0;
        if (headers.length === 3) {
          indexOfColor = parseInt(headers[1], 10) || 0;
        }

        if (!previous[indexOfColor]) {
          previous[indexOfColor] = [k];
        } else {
          previous[indexOfColor].push(k);
        }

        return [...previous];
      }, [])
      // Filter Out any empty colors.
      /// Commonly, found if a user uploads color.1.d50L, color.1.d50a, color.1.d50b  w/o a color.0.
      .filter(x => x)
      .map((labKeys: string[]) => {
        let composition;
        try {
          const pFunc = helpers.parseLabColorComponent(json, labKeys);
          composition = ProductCompositionDetail.NewFromLab(
            {
              L: pFunc('l'),
              a: pFunc('a'),
              b: pFunc('b'),
              illuminant: Illuminant.D50,
              observer: Observer.TWO_DEGREE,
            },
            config.email,
          );
        } catch (err) {
          console.log('failed to find all labComponents for headers', labKeys);
          return undefined;
        } finally {
          if (composition) {
            const { adjusted_lab } = composition;
            if (isNaN(adjusted_lab.L) || isNaN(adjusted_lab.a) || isNaN(adjusted_lab.b)) {
              throw new Error(`Partial lab color definition ${adjusted_lab.L}, ${adjusted_lab.a}, ${adjusted_lab.b}`);
            }
          }

          return composition;
        }
      })
      .filter((cd?: ProductCompositionDetail) => !!cd) as ProductCompositionDetail[];

    return product;
  }

  static parseFormula(data: Line): any {
    const keys = Object.keys(data);

    const item: ParsedColorFormula = {
      //coerce value into a string
      attributes: helpers.parseAttributes(data, (k: string, v: string) => ({
        k,
        v,
      })),

      base_id: helpers.getValueOrDefault(data, 'base_id').toString(),

      colorants: keys
        .filter(helpers.includes(helpers.colorant_headers))
        .filter(k => data[k] > 0)
        .map(k => ({
          id: k.split('.')[1],
          value: parseFloat(data[k].toString()),
        })),

      spectrums: helpers.parseSpectrums(data),
    };

    return item;
  }

  checkIntensityScale() {
    const formulas = this.items as ParsedColorFormula[];
    const intensities = _.flatten(
      _.flatten(formulas.map(({ spectrums }) => spectrums.map(({ curve }) => curve))),
    ) as number[];
    const maxIntensity = _.max(intensities) as number;
    if (maxIntensity > 7.5) {
      formulas.forEach(({ spectrums }) => {
        spectrums.forEach(s => {
          s.curve = s.curve.map((x: number) => x / 100.0);
        });
      });
    }
  }

  handleComplete = () => {
    if (this.config.file_type === 'formula') {
      this.checkIntensityScale();
    }

    if (this.promise) {
      if (this.config.onComplete) {
        this.config.onComplete(this.items);
      }

      this.promise.resolve(this.items);
      this.promise = undefined;
    }
  };

  start = async () => {
    if (this.promise) {
      throw new Error('already parsing');
    }

    return new Promise((fulfill, reject) => {
      this.promise = { reject, resolve: fulfill };

      papa.parse(this.config.file, {
        // All CSV will have a header row
        complete: this.handleComplete,

        // If true,
        //   lines that are completely empty will be skipped.
        //   An empty line is defined to be one which evaluates to empty string.
        dynamicTyping: true,

        // Streaming is necessary for large files which would otherwise crash the browser.
        // You can call parser.abort() to abort parsing. And, except when using a Web Worker,
        // you can call parser.pause() to pause it, and parser.resume() to resume.
        //
        // In the step callback, the data array will only contain one element.
        header: true,

        skipEmptyLines: true,

        step: this.handleStep,
      });
    });
  };
}
