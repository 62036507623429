import React from 'react';
import _ from 'lodash';

import vpapi from '@api/vpapi';
import { EditProductImages } from './images/EditProductImages';
import { ProductAttributeContainer } from './attributes/ProductAttributeContainer';
import { CompositionContainer } from './composition-details';
import ProductFilterEditor from './filters/ProductFilterEditor';

import routes from '@utils/route-utils';
import { AlternateText, Button, FlexColumns, FlexRows, Loading, Modal, ModuleSegment, StatusBar } from '../../common';
import theme from '@theme';
import { Product, ProductGroup } from '@api/vpapi/models';
import { ProductGroupModalLabel, ProductModalLabel } from '../../productGroups/Breadcrumbs';
import { Dropdown } from '../../common/Input/BrandDropdown';
import { Optional } from '@app/utils/types';
import { useHistory } from 'react-router';
import { ModalProps } from 'semantic-ui-react';

const loadingState = {
  init: 1,
  none: 0,
  saving: 2,
};

//#endregion
type ViewProps = {
  history: ReturnType<typeof useHistory>;
  match: { params: { productID: string } };
};
type ViewState = {
  product?: Product;
  productGroup?: ProductGroup;
  loading: number;
};
class EditProduct extends React.Component<ViewProps, ViewState> {
  state = {
    loading: loadingState.init,
    product: undefined as Optional<Product>,
    productGroup: undefined as Optional<ProductGroup>,
  };

  storedProduct: Optional<Product>;

  get productID() {
    const { match } = this.props;
    return match.params.productID;
  }

  onProductChange = () => {
    const { product } = this.state;

    this.setState({
      product,
    });
  };

  handleSave = async () => {
    const { product } = this.state;
    if (product) {
      this.setState({
        loading: loadingState.saving,
      });

      const updateable_product = product.findDifferences(this.storedProduct!);

      await vpapi.products.update(updateable_product);
      this.storedProduct = _.cloneDeep(product);
      this.setState({ loading: loadingState.none, product });
    }
  };

  handleReset = () => {
    const product = _.cloneDeep(this.storedProduct);

    this.setState({
      product,
    });
  };

  async componentDidMount() {
    try {
      const { productID } = this;
      if (productID) {
        const product = await vpapi.products.getProduct(productID);

        this.storedProduct = _.cloneDeep(product);

        const productGroup = await vpapi.productGroups.getProductGroup(product.productGroupID());

        this.setState({
          product,
          productGroup,
        });
      }
    } catch (err) {
      alert('Failed fetching product');
      console.log(err);
    }
  }

  isDirty = () => {
    const a = this.storedProduct;
    const { product } = this.state;

    return !_.isEqual(a, product);
  };

  Buttons = () => {
    const { DeleteProductButton } = this;

    return (
      <FlexColumns>
        <DeleteProductButton />
      </FlexColumns>
    );
  };

  deletionModal = React.createRef<React.Component<ModalProps, any, any>>();
  handleModalClose = () => {
    if (this.deletionModal.current) {
      this.deletionModal.current.setState({ open: false });
    }
  };
  DeleteProductButton = () => {
    const TriggerButton = <Button color={theme.button.cancelColor} text="Delete Product" />;

    const { productGroup } = this.state;
    if (!productGroup) {
      return null;
    }
    return (
      <Modal modalRef={this.deletionModal} modalProps={{ trigger: TriggerButton }} key="product_deletion_button">
        <ModuleSegment title="Confirm Action">
          <AlternateText>
            Deleting a Product is an irreversible action. Deleting this product will remove it from the{' '}
            {productGroup.name} Product Library. Are you sure you want to delete this product?
          </AlternateText>

          <FlexColumns style={{ justifyContent: 'flex-end' }}>
            <Button onClick={this.handleModalClose} outline color={theme.button.actionColor} text="Cancel" />
            <Button onClick={this.handleDeleteProduct} color={theme.button.cancelColor} text="Delete Product" />
          </FlexColumns>
        </ModuleSegment>
      </Modal>
    );
  };

  Breadcrumbs = () => {
    const { productGroup, product } = this.state;

    return (
      <Dropdown.ContentWrapper>
        {productGroup && <ProductGroupModalLabel productGroup={productGroup} icon="chevron right" />}

        {product && (
          <ProductModalLabel
            title={`${product.attrValForKey('name')} - ${product.attrValForKey('code')}`}
            cuuid={product.collection_uuids[0]}
          />
        )}
      </Dropdown.ContentWrapper>
    );
  };
  render() {
    const { product, loading } = this.state;

    if (!product) {
      return (
        <ModuleSegment containerStyle={{ margin: 8 }} title="Edit Product">
          <Loading text="Fetching product details" />
        </ModuleSegment>
      );
    }

    const saving = loading === loadingState.saving;
    const { Breadcrumbs, Buttons } = this;

    if (saving) {
      return (
        <FlexRows style={{ margin: 8 }}>
          <Breadcrumbs />
          <ModuleSegment title="Edit Product">
            <Loading text="Saving product details" />
          </ModuleSegment>
        </FlexRows>
      );
    }

    const dirty = this.isDirty();
    return (
      <FlexRows>
        <Breadcrumbs />

        <div style={{ margin: theme.dimensions.contentPadding }}>
          <Buttons />

          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <div
              style={{
                flexGrow: 1,
                flexShrink: 0,
                margin: 8,
                minWidth: 768,
              }}
            >
              <ProductAttributeContainer product={product} onProductChange={this.onProductChange} />
            </div>

            <div
              style={{
                flexGrow: 1,
                flexShrink: 0,
                margin: 8,
                minWidth: 768,
              }}
            >
              <ProductFilterEditor product={product} onProductChange={this.onProductChange} />
            </div>

            <div
              style={{
                flexGrow: 1,
                flexShrink: 1,
                margin: 16,
                maxWidth: 1024,
                minWidth: 768,
                width: '100%',
              }}
            >
              <CompositionContainer product={product} onProductChange={this.onProductChange} />
            </div>

            <div
              style={{
                flexGrow: 1,
                flexShrink: 1,
                margin: 16,
                maxWidth: 1024,
                minWidth: 768,
              }}
            >
              <EditProductImages product={product} onProductChange={this.onProductChange} />
            </div>
          </div>
          <StatusBar show={dirty} handleReset={this.handleReset} handleSave={this.handleSave} />
        </div>
      </FlexRows>
    );
  }

  handleDeleteProduct = async () => {
    const { product, productGroup } = this.state;
    const { history } = this.props;

    if (product && productGroup) {
      this.handleModalClose();

      this.setState({
        product: undefined, //set product to null so that loading is displayed
      });

      await vpapi.products.destroy(product.dbid);

      history.push(routes.productGroupWithID(productGroup.dbid));
    }
  };
}

export default EditProduct;
