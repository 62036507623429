import * as React from 'react';
import styled from 'styled-components';
import theme from '@theme';

export type Item = {
  name: string;
  value: string;
  selected: boolean;
  onClick: (item: Item) => void;
};
type ViewProps = {
  items: Item[];
};

const SwitchContainer = styled.div`
  margin: auto;
  display: flex;
`;
const SwitchBtn = styled.div<{
  left?: boolean;
  right?: boolean;
  selected?: boolean;
}>`
  flex-grow: 1;

  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;

  float: ${props => (props.left ? 'left' : 'right')};
  margin: auto;
  text-align: center;

  background-color: ${props => (props.selected ? theme.table.rowAltBackground : theme.segment.bodyBackground)};
  color: ${props => (props.selected ? theme.text.primary : theme.text.alternate)};

  ${({ right, left }) => {
    if (right && left) {
      return `
        border-top-left-radius:     20px;
        border-bottom-left-radius:  20px;
        border-top-right-radius:    20px;
        border-bottom-right-radius: 20px;
      `;
    }
    if (right) {
      return `
        border-top-left-radius:     0px;
        border-bottom-left-radius:  0px;
        border-top-right-radius:    20px;
        border-bottom-right-radius: 20px;
      `;
    }

    if (left) {
      return `
        border-top-left-radius:     20px;
        border-bottom-left-radius:  20px;
        border-top-right-radius:    0px;
        border-bottom-right-radius: 0px;
      `;
    }

    return '';
  }}

  cursor: pointer;

  ${({ selected }) => {
    if (selected) {
      return `
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
      `;
    }

    return '';
  }}

  :hover {
    font-size: 110%;
    background-color: ${theme.table.rowAltBackground};
  }
`;

function renderButton(item: Item, index: number, items: Item[]) {
  return (
    <SwitchBtn
      left={index === 0}
      right={index === items.length - 1}
      key={item.name}
      onClick={e => {
        e.stopPropagation();

        item.onClick(item);
      }}
      selected={item.selected}
    >
      {item.name}
    </SwitchBtn>
  );
}

export default function Switch({ items }: ViewProps) {
  return <SwitchContainer>{items.map(renderButton)}</SwitchContainer>;
}
