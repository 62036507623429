import moment from 'moment';
import { uuid as uuidV4 } from '@utils';
import { Optional } from '@app/utils/types';

export interface BaseModelJSON {
  _id?: string;
  uuid?: string;
  locallyCreatedAt?: string;
  locallyUpdatedAt?: string;

  isDeleted?: boolean;

  platform_id: Optional<string>;
  organization_id: Optional<string>;

  appVersionCreator: Optional<string>;
}
export default class ADBase {
  uuid: string;

  locallyCreatedAt: string;

  locallyUpdatedAt: string;

  isDeleted: boolean;

  appVersionCreator: Optional<string>;
  platform_id: Optional<string>;
  organization_id: Optional<string>;

  constructor({
    _id,
    uuid,
    locallyCreatedAt = new Date().toISOString(),
    locallyUpdatedAt,
    isDeleted = false,
    appVersionCreator,
    platform_id,
    organization_id,
  }: BaseModelJSON) {
    this.uuid = _id || uuid || uuidV4();
    this.locallyCreatedAt = locallyCreatedAt;
    this.locallyUpdatedAt = locallyUpdatedAt || locallyCreatedAt;
    this.isDeleted = isDeleted;

    this.platform_id = platform_id;
    this.organization_id = organization_id;

    this.appVersionCreator = appVersionCreator;
  }

  get updatedAtMoment() {
    return moment(this.locallyUpdatedAt);
  }

  get locallyCreatedAtDate() {
    return new Date(this.locallyCreatedAt);
  }
  get locallyCreatedAtMoment() {
    return moment(this.locallyCreatedAt);
  }
  touch = () => {
    this.locallyUpdatedAt = new Date().toISOString();
  };

  baseToServerJSON = () => ({
    _id: this.uuid,
    isDeleted: this.isDeleted,
    locallyCreatedAt: this.locallyCreatedAt,
    locallyUpdatedAt: this.locallyUpdatedAt,

    platform_id: this.platform_id,
    organization_id: this.organization_id,

    appVersionCreator: this.appVersionCreator,
  });
}
