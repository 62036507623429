import { Illuminant, IlluminantT, LabColor, Observer, ObserverT, Spectrum } from '@variablecolor/colormath';

import { ChromaReadingJSON, SpectroReadingJSON } from './color/ColorReading';
import ADBase from './ADBase';
import { ColorProvider } from './types';
import { Optional } from '@app/utils/types';
import ModelDetector from '@api/models/ModelDetector';

export default class Scan extends ADBase implements ColorProvider {
  public readonly spectrum: Optional<Spectrum>;
  public readonly batch: string;
  public readonly model: string;

  private readonly labs: Optional<{ TWO_DEGREE: LabColor; TEN_DEGREE: LabColor }>;
  private readonly colorReading: ChromaReadingJSON | SpectroReadingJSON;
  constructor(json: ChromaReadingJSON | SpectroReadingJSON) {
    super(json);
    this.colorReading = json;
    this.batch = json.batch;
    this.model = json.model;

    const s = json as SpectroReadingJSON;
    if (s.curve) {
      this.spectrum = Spectrum.fromJSON(s);
    } else {
      const c = json as ChromaReadingJSON;
      this.labs = {
        [Observer.TWO_DEGREE]: LabColor.fromJSON(c.adjLab),
        [Observer.TEN_DEGREE]: LabColor.fromJSON(c.adjLab10deg || c.adjLab),
      };
    }
  }

  static fromJSON(json: any) {
    return new Scan(json);
  }

  //convert to server format for saving back to disk (and eventually upsyncing)
  toServerJSON() {
    return { ...this.colorReading };
  }

  public labColor(i: IlluminantT = Illuminant.D50, o: ObserverT = Observer.TWO_DEGREE) {
    if (this.spectrum) {
      return this.spectrum.labColor(i, o);
    }
    return this.labs![o].toLab(i);
  }

  hex() {
    return this.labColor().hex();
  }

  get hex_colors() {
    return [this.hex()];
  }

  get serial() {
    return this.colorReading.serial;
  }

  colors() {
    return [this];
  }

  isSpectro() {
    return ModelDetector.isSpectro(this.colorReading.model);
  }
}
