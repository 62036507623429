import qs from 'query-string';

import { uuid } from '@app/utils';
import { importProducts } from './vpapi';
import config from '../../config';
import api from '../fetch2';
import { MeasuredColor, Product } from './models';
import ProductGroup from './models/ProductGroup';
import AWSPubSub from '../aws/AWSPubSub';
import { Platform, Role } from '@api/cas/models';
import { ProductJSON } from './models/Product';

type AddProductOptions = {
  doColorExtraction?: boolean;
  productGroupID: string;
  preserveIDS?: any;
  overrideInternalUUID?: any;
};

async function addSingle(data: { name: string; dbid?: string }, email?: string): Promise<ProductGroup> {
  const url = config.server.productGroupsURL;
  const json: { dbids: string[]; status: string } = await api.post(url, [data]);

  const productGroup = await getProductGroup(data.dbid || json.dbids[0]);

  if (productGroup.permissions) {
    productGroup.permissions.forEach(p => {
      AWSPubSub.productGroupActions.create(p.email, productGroup.dbid);
    });
  } else {
    if (email) {
      AWSPubSub.productGroupActions.create(email, productGroup.dbid);
    }
  }

  return productGroup;
}

async function publish(dbid: string): Promise<ProductGroup> {
  const json = await api.post<ProductGroup>(`${config.server.productGroupsURL}/${dbid}/_publish`, {
    // auto_respond: true,
  });

  return ensureProductGroup(json);
}

async function save(productGroup: ProductGroup): Promise<void> {
  try {
    await api.post(`${config.server.productGroupsURL}`, [productGroup]);

    productGroup.permissions.forEach(permission => {
      if (permission) {
        AWSPubSub.productGroupActions.update(permission.email, productGroup.dbid);
      }
    });
  } catch (err) {
    console.log(err);
  }
}

function ensureProductGroup(json: ProductGroup) {
  // This is updated everytime a user is logged in
  // look in cas.users.login()
  const platforms = JSON.parse(localStorage.getItem('platforms') || '[]');
  json.publishes = (json.publishes || [])
    .map(p => {
      p.platform = platforms.find((platform: Platform) => platform.id === p.platform_id);
      return p;
    })
    .sort((a, b) => new Date(b.published_at).getTime() - new Date(a.published_at).getTime());

  json.unpublished_changes = json.unpublished_changes || (json.publishes.length === 0 && json.product_count > 0);

  return json;
}

async function getProductGroup(dbid: string): Promise<ProductGroup> {
  const json = await api.get<ProductGroup>(`${config.server.productGroupsURL}/${dbid}`);
  return ensureProductGroup(json);
}

async function upsertMeasureColors(dbid: string, products: Product[], models: string[]) {
  const upsertableMeasuredColors = {
    items: [] as {
      matchable_attributes: {
        [key: string]: string[];
      };
      measured_colors: Array<MeasuredColor>;
    }[],
    product_group_id: dbid,
  };

  for (const product of products) {
    const filter = {} as { name?: string[]; code?: string[] };
    const name = product.attrValForKey('name').trim();
    if (name !== '') {
      filter.name = [name];
    }

    const code = product.attrValForKey('code').trim();
    if (code !== '') {
      filter.code = [code];
    }

    upsertableMeasuredColors.items.push({
      matchable_attributes: filter,
      measured_colors: product.composition_details[0].measured_colors.filter(({ model }) => {
        return models.includes(model.toLowerCase());
      }),
    });
  }

  return api.post(config.server.vpapi.bulk_measured_colors, upsertableMeasuredColors);
}

async function addProducts(products: Product[], opts: AddProductOptions) {
  if (products.length === 0) {
    return;
  }

  products.forEach(p => {
    const product = p;
    if (opts.productGroupID) {
      product.collection_uuids = [opts.productGroupID];
    }
    if (!opts.preserveIDS) {
      product.product_uuid = uuid();
    }

    if (opts.overrideInternalUUID && product.dbid) {
      product.dbid = uuid();
    }
  });

  await importProducts(products, 0, opts.doColorExtraction);

  // delay to allow for backend to index.... run async to the completion of this method.
  const dbid = products[0].collection_uuids[0];
  setTimeout(() => {
    getProductGroup(dbid).then(productGroup => {
      productGroup.permissions.forEach(p => {
        AWSPubSub.productGroupActions.update(p.email, productGroup.dbid);
      });
    });
  }, 2 * 1000);
}

export type LegacyImportParams = {
  product_group: {
    id: string;
  };

  target_product_group_id: string;
};
async function legacyImport(params: LegacyImportParams) {
  /// This returns product json....
  ////// However, the collection_uuids array is set to be equal to the legacy library.
  return api.post<ProductJSON[]>(`${config.server.productsURL}/_import`, params);
}

async function fetchAllProducts(productGroupID: string, productCount: number): Promise<Product[]> {
  const size = 1000;
  const requestCount = Math.ceil(productCount / size);

  const products = await Promise.all(
    Array.from(Array(requestCount).keys()).map(async i => {
      const from = i * size;
      const url = `${config.server.get_products}?from=${from}&size=${size}&cuuid=${productGroupID}`;
      const json = await api.get<ApiResponse<ProductJSON>>(url);

      if (json.count > 0) {
        return json.docs.map((p: any) => new Product(p));
      }
      return [] as Product[];
    }),
  );

  return products.flat().sort((a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime());
}
export interface ApiProductGroupSearch {
  from: number;
  size: number;
  sort?: string;

  search?: string;

  with_products?: number /** > 0 */;

  vendor?: string;

  permission_filter?: Role | null;
}
export type ApiResponse<T> = {
  docs: T[];
  count: number;
};

export type AggregationResult<T> = {
  value: T;
  count: number;
};

export default {
  addProducts,
  addSingle,
  fetchAllProducts,

  ensureProductGroup,

  async destroy(productGroup: ProductGroup): Promise<void> {
    try {
      await api.del(`${config.server.productGroupsURL}/${productGroup.dbid}`);

      productGroup.permissions.forEach(p => {
        AWSPubSub.productGroupActions.delete(p.email, productGroup.dbid);
      });
    } catch (err) {
      console.log(err);
    }
  },

  async getVendors(opts: ApiProductGroupSearch): Promise<ApiResponse<AggregationResult<string>>> {
    const res: ApiResponse<AggregationResult<string>> = await api.get(
      `${config.server.productGroupsURL}/_vendors?${qs.stringify(opts)}`,
    );
    res.docs.sort((a, b) => a.value.localeCompare(b.value));
    return res;
  },

  async getAll(opts: ApiProductGroupSearch): Promise<ApiResponse<ProductGroup>> {
    try {
      const query = qs.stringify(opts);
      const json = await api.get<ApiResponse<ProductGroup>>(`${config.server.productGroupsURL}?${query}`);

      json.docs = json.docs.map(ensureProductGroup);

      return json;
    } catch (e) {
      console.error(e);
    }

    return {
      count: 0,
      docs: [],
    };
  },

  getProductGroup,

  legacyImport,
  publish,
  save,

  upsertMeasureColors,
};
