import * as React from 'react';
import styled from 'styled-components';

import PlatformPackageListItem from './ListItem';
import type { PlatformDetailPackageUI, PlatformDetailT } from '../types';
import { FlexColumns, ModuleSegment } from '../../common';
import theme from '../../../theme';

function usePackagesDragDataConverter(
  target: PlatformDetailPackageUI[],
  available: PlatformDetailPackageUI[],
  onDrop: (p: PlatformDetailPackageUI) => void,
) {
  return React.useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();

      const { type, package_id } = JSON.parse(e.dataTransfer?.getData('text/plain') || '{}');
      if (!type) {
        console.warn('ignoring unknown drop');
        return;
      }

      const platformPackage = available.find(x => x.package_id === package_id);
      if (!platformPackage) {
        console.log('ignoring drop. package is not available');
        return;
      }

      const isExistingDefault = target.find(p => p.package_id === platformPackage.package_id);
      if (!isExistingDefault) {
        target.push(platformPackage);
      }

      onDrop(platformPackage);
    },
    [target, available, onDrop],
  );
}

type PackagesProps = {
  show: boolean;
  packages: PlatformDetailPackageUI[];
  title: string;
  subTitle: string;
  handleDrop: (e: React.DragEvent) => void;

  onItemClick: (p: PlatformDetailPackageUI) => void;
};

const FlexGrid = styled(FlexColumns)`
  margin: ${theme.dimensions.contentPadding};
  flex-wrap: wrap;
  flex-direction: none;
`;

function Packages({ show, packages, title, subTitle, handleDrop, onItemClick }: PackagesProps) {
  if (!show) {
    return null;
  }

  return (
    <ModuleSegment
      bodyStyle={{ marginTop: 24 }}
      containerStyle={{ marginTop: 12, width: '100%' }}
      title={ModuleSegment.ModuleTitle(title, subTitle)}
      handleDrop={handleDrop}
      handleDragOver={e => {
        e.preventDefault();
      }}
    >
      <FlexGrid style={{ display: 'inline-flex', marginTop: 16 }}>
        {packages.map(p => (
          <PlatformPackageListItem isSelectable key={`${p.package_id}`} onClick={onItemClick} p={p} />
        ))}
      </FlexGrid>
    </ModuleSegment>
  );
}

type ViewProps = {
  onItemClick: (p: PlatformDetailPackageUI) => void | any;
  onDrop: (p: PlatformDetailPackageUI) => void;

  platform: PlatformDetailT;
};

export function ExclusivePackages({ platform, onItemClick, onDrop }: ViewProps) {
  const convertDragData = usePackagesDragDataConverter(
    platform.exclusive_packages,
    platform.available_packages,
    onDrop,
  );

  return (
    <Packages
      show={platform.only_platform_packages}
      title="Redeemable Packages"
      subTitle="User can only redeem packages in this section"
      packages={platform.exclusive_packages}
      onItemClick={onItemClick}
      handleDrop={convertDragData}
    />
  );
}

export function DefaultPackages({ platform, onItemClick, onDrop }: ViewProps) {
  const convertDragData = usePackagesDragDataConverter(platform.default_packages, platform.available_packages, onDrop);
  return (
    <Packages
      show
      title="Default Packages"
      subTitle="User is given subscriptions to all packages in this section upon login."
      packages={platform.default_packages}
      onItemClick={onItemClick}
      handleDrop={convertDragData}
    />
  );
}
