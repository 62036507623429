export type ErrorJSON = {
  status_code: number;
  message: string;
  data?: any;
};

export default class ApiError {
  status_code: number;
  message: string;
  data?: any;

  constructor(json: ErrorJSON) {
    this.status_code = json.status_code;
    this.message = json.message;
    this.data = json.data;
  }

  get flashProps() {
    switch (this.message) {
      default:
        return {
          color: 'red',
          message: this.message,
        };

      case 'vi-email-verification-failed':
        return {
          color: 'red',
          message: 'Before continuing with Variable Cloud, check your email to verify your account.',
        };

      case 'vi-invalid-token':
        return {
          color: 'red',
          message: 'Your token is invalid or expired. Please request a new reset link.',
        };

      case 'vi-no-account':
        return {
          color: 'red',
          message: 'The email provided is not linked to a Variable Cloud account.',
        };

      case 'vi-account-status':
        switch (this.data) {
          default:
            return {
              color: 'red',
              message: 'Bad Account Status',
            };

          case 'seats-in-use':
            return {
              color: 'red',
              message: 'Unable to complete operation, your account still has active subscription seats in use.',
            };
        }

      case 'vi-authentication-failure':
        return {
          color: 'red',
          message: 'Your password or username does not match our records.',
        };

      case 'vi-max-subscriptions':
        return {
          color: 'red',
          message:
            'You have reached your plans maximum allowed users ' +
            `(${this.data.current_subscriptions} of ${this.data.maximum_subscriptions}).`,
        };

      case 'vi-max-products':
        return {
          color: 'red',
          message:
            'You have reached your plans maximum allowed Products. ' +
            `(${this.data.current_product_count} of ${this.data.maximum_product_count})`,
        };

      case 'vi-transfer-failure':
        return {
          color: 'red',
          message:
            'Failed to transfer. Target user may need to upgrade their plan. ' +
            'Please contact support for more infomation (customerservice@variableinc.com).',
        };
      case 'vi-max-brands':
        return {
          color: 'red',
          message:
            'You have reached your plans maximum allowed Organizations.' +
            ` (${this.data.current_brand_count} of ${this.data.maximum_brand_count})`,
        };

      case 'vi-subscription-expired':
        return {
          color: 'red',
          message: 'Your paid subscription has expired. Please contact support (customerservice@variableinc.com)',
        };
      case 'vi-missing-subscription':
        return {
          color: 'red',
          message: 'You have not signed up for paid plan. Please contact (customerservice@variableinc.com',
        };

      case 'vi-unauthorized-request':
        return {
          color: 'red',
          message: 'Something went wrong',
        };
    }
  }
}
