import React from 'react';
import styled from 'styled-components';

type ViewProps = {
  image?: { url: string };
};
const Container = styled.a`
  width: 100%;
  height: 768px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img.attrs(({ src }) => {
  return {
    src, //: imageUtils.encodedImageURL(src, { rotate: null, resize: { fit: 'contain', height: 768 } }),
  };
})`
  width: 100%;
  height: 768px;

  object-fit: contain;
`;
export default ({ image }: ViewProps) => {
  if (!image) {
    return null;
  }

  return (
    <Container href={image.url} target="_new">
      <Image alt="selected image" src={image.url} />
    </Container>
  );
};
