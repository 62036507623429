import * as React from 'react';
import ScrollView from './ScrollView';

export { CardView } from './CardView';

export function DraggableContainer({
  children,
  generateJSON,
}: {
  children: React.ReactNode;
  generateJSON: () => string;
}) {
  return (
    <div draggable onDragStart={e => e.dataTransfer.setData('text/plain', generateJSON())}>
      {children}
    </div>
  );
}

export { HorizontalScrollView } from './HorizontalScrollView';

export { ScrollView };
