import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import routeUtils from '@utils/route-utils';

import { PaletteItem } from '@api/appdata/model';
import ScanListItem from '../scanHistory/ScanListItem';

const Container = styled.div`
  width: 100%;
  height: 1vh;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export default React.memo(({ paletteItems }: { paletteItems: PaletteItem[] }) => {
  const history = useHistory();

  if (!paletteItems) {
    return null;
  }

  return (
    <Container>
      {paletteItems.map(pi => {
        return (
          <ScanListItem
            key={`${pi.uuid}`}
            item={pi}
            title={pi.titleString()}
            onClick={() => {
              history.push(routeUtils.savedColor(pi.paletteID, pi.uuid));
            }}
            dragData={{
              fromID: pi.paletteID,
              itemID: pi.uuid,
              type: 'PaletteItem',
            }}
          />
        );
      })}
    </Container>
  );
});
