import _ from 'lodash';
import cas from './cas';
import type { BrandDetail, BrandSummary, PermissionT, Roles, ThemeDetail } from './cas/models';
import { Optional } from '@app/utils/types';

// function packagesByName(brand) {
//   return brand.packages.sort((a, b) => {
//     return a.name.localeCompare(b.name);
//   });
// }

// function defaultPackage(brand) {
//   return packagesByName(brand)[0];
// }
export function ownershipCount(brands?: BrandSummary[]) {
  return brands?.filter(x => x.permission_level.is_owner).length || 0;
}

export function contributorCount(brands?: BrandSummary[]) {
  return brands?.filter(x => x.permission_level.is_contributor).length || 0;
}

const isOwnedByCurrentUser = ({ permissions = [] }: { permissions: PermissionT[] }) => {
  const { email, is_system_admin } = cas.getUser()!;
  return is_system_admin || _.some(permissions, { email, permissions: { is_owner: 1 } });
};

interface Searchable {
  name?: string;
  id?: string;

  permission_level: Roles;
}

function search<T extends Searchable>(
  pgs: Array<T>,
  term: string,
  accessLevel: 'owner' | 'all' | 'contributor' = 'all',
  skip: number,
  limit: number,
): Array<T> {
  let brands = pgs;
  if (accessLevel) {
    if (accessLevel !== 'all') {
      brands = brands.filter(b => {
        if (!b.permission_level || b.permission_level === null) {
          return false;
        }
        return b.permission_level[`is_${accessLevel}`] !== undefined;
      });
    }

    if (term === '') {
      return brands.slice(skip, skip + limit);
    }
  }

  const lowerCaseTerm = term.toLowerCase();
  return _(pgs)
    .filter(x => !!x.id?.toLowerCase().includes(lowerCaseTerm) || !!x?.name?.toLowerCase().includes(lowerCaseTerm))
    .slice(skip, skip + limit)
    .value();
}

function owners(brand: BrandDetail): string[] {
  return brand.permissions
    .filter(p => p.permissions.is_owner === 1)
    .map(o => o.email)
    .sort((a, b) => a.localeCompare(b));
}

function contributors(brand: BrandDetail): string[] {
  return brand.permissions
    .filter(p => p.permissions.is_contributor === 1)
    .map(o => o.email)
    .sort((a, b) => a.localeCompare(b));
}

async function transferOwnership(brand: BrandDetail, email: string) {
  await cas.brands.transferOwner({ brand_id: brand.id, email });
  brand.permissions.push({ email, permissions: { is_owner: 1 } });
  return brand;
}

async function grantContributorAccess(brand: BrandDetail, email: string) {
  await cas.brands.addContributor({ brand_id: brand.id, email });
  const p = brand.permissions.find(p1 => p1.email === email);
  const permissions = { is_contributor: 1 };
  if (p) {
    p.permissions = permissions;
  } else {
    brand.permissions.push({ email, permissions });
  }
  return brand;
}
async function grantOwnership(brand: BrandDetail, email: string) {
  await cas.brands.addOwner({ brand_id: brand.id, email });

  const p = brand.permissions.find(p1 => p1.email === email);
  if (p) {
    p.permissions = { is_owner: 1 };
  } else {
    brand.permissions.push({ email, permissions: { is_owner: 1 } });
  }
  return brand;
}
async function revokeAccess(brand: BrandDetail, email: string) {
  await cas.revokeBrandAccess({ email, id: brand.id, type: 'brand' });

  return Object.assign(brand, {
    permissions: brand.permissions.filter(p => p.email !== email),
  });
}

function defaultTheme(brand: BrandDetail): Optional<ThemeDetail> {
  if (brand.themes.length === 0) {
    console.log('expected a brand to have at least one theme');
    return undefined;
  }

  return brand.themes[0];
}
export default {
  defaultTheme,
  contributorCount,
  ownershipCount,
  contributors,
  grantContributorAccess,
  grantOwnership,
  isOwnedByCurrentUser,
  owners,
  revokeAccess,
  search,
  transferOwnership,
};
