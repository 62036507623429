import * as React from 'react';
import { Illuminant, LabColor } from '@variablecolor/colormath';
import theme from '@theme';
import { Header as CommonHeader, FlexRows, Dropdown } from '@components/common';
import { DropdownProps } from '@components/common';

interface LabProps {
  lab: LabColor;
  onChange: (index: 'L' | 'a' | 'b') => React.ChangeEventHandler<HTMLInputElement>;
  onColorSpaceChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
}

type HeaderProps = {
  children: React.ReactNode | string;
};
function Header({ children }: HeaderProps) {
  return <CommonHeader style={{ marginTop: 18 }}>{children}</CommonHeader>;
}

function LabContainer({ onChange, onColorSpaceChange, lab }: LabProps) {
  return (
    <FlexRows>
      <Header>L (Range 0 to 100)</Header>
      <input
        id="L"
        type="number"
        min={0}
        max={100}
        style={{
          backgroundColor: theme.label.background,
          borderWidth: 0,
          color: 'white',
          marginTop: 8,
          padding: 12,
          width: '100%',
        }}
        value={lab.L}
        onChange={onChange('L')}
      />

      <Header>a (Range -128 to 128)</Header>
      <input
        id="a"
        type="number"
        min={-128}
        max={128}
        style={{
          backgroundColor: theme.label.background,
          borderWidth: 0,
          color: 'white',
          marginTop: 8,
          padding: 12,
          width: '100%',
        }}
        value={lab.a}
        onChange={onChange('a')}
      />

      <Header>b (Range -128 to 128)</Header>
      <input
        id="b"
        type="number"
        min={-128}
        max={128}
        value={lab.b}
        style={{
          backgroundColor: theme.label.background,
          borderWidth: 0,
          color: 'white',
          marginTop: 8,
          padding: 12,
          width: '100%',
        }}
        onChange={onChange('b')}
      />

      <Header>Illuminant</Header>
      <Dropdown
        selection
        fluid
        value={lab.illuminant}
        id="illuminant"
        onChange={onColorSpaceChange}
        options={Object.keys(Illuminant)
          .filter(k => k !== 'fromString')
          .map(ill => ({ text: ill, value: ill }))}
      />

      <Header>CIE Observer Angle</Header>
      <Dropdown
        fluid
        selection
        value={lab.observer}
        onChange={onColorSpaceChange}
        id="observer"
        options={[
          { text: 'CIE 1964 10° ', value: 'TEN_DEGREE' },
          { text: 'CIE 1931 2°', value: 'TWO_DEGREE' },
        ]}
      />
    </FlexRows>
  );
}

export { LabContainer, Header };
