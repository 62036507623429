import * as React from 'react';

import { LabeledInput } from '@components/common';
import { useEnterKeyPress } from '@components/common/Input';

export type AttributeItem = {
  k: string;
  v: string;
};

type ViewProps = {
  attributes: AttributeItem[];
  onChange: (a: AttributeItem) => (e: React.SyntheticEvent<HTMLInputElement>, data: { value: string }) => void;
  onAddItem: (a: AttributeItem) => () => void;
};

export default ({ attributes, onAddItem, onChange }: ViewProps) => {
  const onKeyPress = useEnterKeyPress(value => {
    const k = value.trim();
    const item = attributes.find(x => x.k.toLowerCase() === k.toLowerCase());
    if (item) {
      alert(`Attribute ${k} already exists.`);
      return;
    }
    onAddItem({ k, v: '' });
  });
  return (
    <div>
      {attributes.map(a => (
        <LabeledInput onChange={onChange(a)} label={a.k} key={a.k} inputProps={{ defaultValue: a.v }} />
      ))}
      <hr />
      <LabeledInput
        label="Custom Attribute"
        key="new"
        inputProps={{
          onKeyPress,
          placeholder: 'Create custom attributes by adding a name, and then press enter',
        }}
      />
    </div>
  );
};
