const colorDefs = {
  almostWhite: '#EDEDED',
  blue: '#00B9E4',
  grey: '#565A5C',
  lightGrey: '#BCBCBC',

  white: '#FFFFFF',
};

const lightColorProvider = {
  availableContent: {
    background: colorDefs.white,
    border: colorDefs.grey,
    content: colorDefs.grey,
    contentImportant: colorDefs.grey,
    contentSubtle: colorDefs.grey,
  },
  banner: {
    background: colorDefs.almostWhite,
  },

  get card() {
    return {
      ...this.primary,
      background: colorDefs.white,
      border: colorDefs.almostWhite,
    };
  },

  darkBackground: {
    background: colorDefs.grey,
    border: colorDefs.lightGrey,
    content: colorDefs.white,
    contentImportant: colorDefs.blue,
    contentSubtle: colorDefs.grey,
  },

  get disabledContent() {
    return {
      ...this.availableContent,
      background: colorDefs.grey,
      content: colorDefs.white,
    };
  },

  lightBackground: {
    background: colorDefs.white,
    border: colorDefs.grey,
    content: colorDefs.grey,
    contentImportant: colorDefs.grey,
    contentSubtle: colorDefs.grey,
  },

  get navigation() {
    return {
      background: colorDefs.white,
      border: colorDefs.grey,
      content: colorDefs.grey,
      contentImportant: colorDefs.grey,
      contentSubtle: colorDefs.lightGrey,
    };
  },

  primary: {
    background: colorDefs.almostWhite,
    border: colorDefs.lightGrey,
    content: colorDefs.grey,
    contentImportant: colorDefs.blue,
    contentSubtle: colorDefs.grey,
  },

  // used for buttons / items that can be toggled
  primaryButton: {
    background: colorDefs.grey,
    border: colorDefs.lightGrey,
    content: colorDefs.white,
    contentImportant: colorDefs.grey,
    contentSubtle: colorDefs.almostWhite,
  },

  // used for buttons /items that are selected / active
  primaryInput: {
    background: colorDefs.white,
    border: colorDefs.white,
    content: colorDefs.grey,
    contentImportant: colorDefs.grey,
    contentSubtle: colorDefs.lightGrey,
  },

  secondaryButton: {
    background: colorDefs.grey,
    border: colorDefs.grey,
    content: colorDefs.white,
    contentImportant: colorDefs.grey,
    contentSubtle: colorDefs.white,
  },

  // for elements that MUST be displayed using a light BG
  selectedContent: {
    background: colorDefs.grey,
    border: colorDefs.white,
    content: colorDefs.white,
    contentImportant: colorDefs.grey,
    contentSubtle: colorDefs.lightGrey,
  },

  // for elements that MUST be displayed using a dark BG
  type: 'light',
};

export default lightColorProvider;
