import React from 'react';
import { NavLink } from 'react-router-dom';

import routeUtils from '@utils/route-utils';
import theme from '@theme';
import usePaletteItems from './usePaletteItems';
import { AlternateText, Button, ModuleSegment, ScrollView } from '@components/common';
import PaletteItemsGrid from '../paletteItems/PaletteItemsGrid';
import Palette from '@api/appdata/model/Palette';

function useModuleButtons(palette: Palette) {
  return [
    <NavLink to={routeUtils.paletteDetails(palette)} key="more-actions">
      <Button text="More Actions" outline color={theme.button.actionColor} />
    </NavLink>,
  ];
}

function EmptyPalette() {
  return (
    <ModuleSegment
      containerStyle={{
        flex: 6,
        flexGrow: 3,
        height: '95vh',
        width: '100%',
      }}
      title="Folder Details"
    >
      <AlternateText>Nothing Found</AlternateText>
    </ModuleSegment>
  );
}
export default function PaletteDetail({ palette }: { palette: Palette }) {
  const { paletteItems, scroll } = usePaletteItems(palette);
  const buttons = useModuleButtons(palette);
  if (palette.paletteItems.length === 0) {
    return <EmptyPalette />;
  }

  return (
    <ModuleSegment
      containerStyle={{
        flex: 6,
        flexGrow: 3,
        height: '95vh',
        width: '100%',
      }}
      buttons={buttons}
      title={ModuleSegment.ModuleTitle(palette.name, `${palette.paletteItemCount} ITEMS`)}
    >
      <ScrollView ref={scroll.ref}>
        <PaletteItemsGrid paletteItems={paletteItems} />
      </ScrollView>
    </ModuleSegment>
  );
}
