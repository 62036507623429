import * as React from 'react';
import theme from '@theme';
import styled from 'styled-components';
import { AlternateText } from './Text';

export type ViewProps = {
  checked: boolean;
  text: string;
  containerStyle?: React.CSSProperties;
  onCheckChange: (e: React.SyntheticEvent<HTMLDivElement>, isChecked: boolean) => void | any;
};

const Container = styled.div<{ isChecked: boolean }>`
  position: relative;

  display: inline-flex;
  flex-direction: row;

  margin-left: 3px;
  cursor: pointer;

  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  padding: 8px;
  margin: 4px;
  border-color: ${({ isChecked }) => (isChecked ? theme.border.accent : theme.border.neutral)};

  :hover {
    cursor: pointer;
  }
`;

const Text = styled(AlternateText)`
  ${({ isChecked }: { isChecked?: boolean }) => {
    if (!isChecked) {
      return `
      ::before {
        content: '✗ ';
      }
    `;
    }

    return `
      ::before {
        content: '✓';
        color: ${theme.border.accent};
      }
    `;
  }}
`;
export default React.memo<ViewProps>(({ checked, text, containerStyle = {}, onCheckChange }: ViewProps) => {
  const handleCheckChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLDivElement>) => onCheckChange?.(e, !checked),
    [checked, onCheckChange],
  );

  return (
    <Container
      isChecked={checked}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        ...containerStyle,
      }}
      onClick={handleCheckChange}
    >
      <Text isChecked={checked}>{text}</Text>
    </Container>
  );
});
