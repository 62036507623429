import User from '@api/cas/user';
import { Flash } from '@components/common';
import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import theme from '@theme';

//Styled Components
export const Container = styled.div`
  min-height: 768px;
  margin: 0;
  background-color: ${theme.mainBackground};
  overflow: -moz-hidden-unscrollable;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export function VariableCloudPromoNotice({ user }: { user?: User }) {
  const history = useHistory();
  const onAccessRequested = React.useCallback(() => {
    history.push('/contact');
  }, [history]);

  if (!user || user.hasPaidSubscription) {
    return null;
  }
  return (
    <div style={{ cursor: 'pointer', marginTop: -20 }} onClick={onAccessRequested}>
      <Flash
        content={{
          color: 'red',
          message: 'Contact us here to request full access to Variable Cloud.',
        }}
      />
    </div>
  );
}
//Formatters gone wild
export const MainContent = styled.div`
  @media (min-width: 900px) {
    left: ${theme.dimensions.sideBarWidthDesktop}px;
  }
  @media (max-width: 900px) {
    left: ${theme.dimensions.sideBarWidthMobile}px;
  }

  position: absolute;

  min-width: 66vw;

  flex: 1;
`;
