import colorDefs from './colors';
import dimensions from './dimensions';

export default {
  border: {
    accent: colorDefs.green,
    alternateSelectedItem: colorDefs.green,
    neutral: colorDefs.lightestGray,
    selectedItem: colorDefs.blue,
  },
  button: {
    actionColor: colorDefs.green,
    alternativeActionColor: colorDefs.blue,
    cancelColor: colorDefs.red,
    createColor: colorDefs.green,
    neutralColor: colorDefs.lightestGray,
  },
  card: {
    alternateBackground: colorDefs.grayBgColor,
    headerBackground: colorDefs.lightestGray,
  },
  dimensions,
  divider: {
    accent: colorDefs.green,
    background: colorDefs.lightestGray,
  },
  dropdown: {
    background: colorDefs.lightestGray,
    hoverBackground: colorDefs.blue,
    text: colorDefs.white,
  },
  icon: {
    primary: colorDefs.white,
    primaryBackground: colorDefs.lightestGray,
  },

  label: {
    alternate2background: colorDefs.lighterBlack,
    alternateBackground: colorDefs.whitishGray,
    background: colorDefs.lightestGray,
    text: colorDefs.grayTextColor,
  },

  loading: {
    backgroundColor: colorDefs.lightestGray,
    color: colorDefs.white,
  },

  mainBackground: colorDefs.black,

  menu: {
    activeBackgroundColor: colorDefs.blue,
    background: colorDefs.lightestGray,
    hoverBackground: colorDefs.blue,
    text: colorDefs.white,
  },

  segment: {
    bodyBackground: colorDefs.grayBgColor,
    moduleBackground: colorDefs.lighterBlack,
  },
  sidebarBackground: colorDefs.sectionBgColor,
  table: {
    headerBackground: colorDefs.lightestGray,
    hoverBackground: colorDefs.blue,
    rowAltBackground: colorDefs.lightestGray,
    rowBackground: colorDefs.grayBgColor,
    selectedBackground: colorDefs.blue,
  },
  text: {
    accent: colorDefs.green,
    alternate: colorDefs.grayTextColor,
    primary: 'white',
  },
};
