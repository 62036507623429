import * as React from 'react';
import _ from 'lodash';
import { List, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { routeUtils } from '@utils';
import FeatureEditor from './FeatureEditor';
import DefaultPackageCode from './DefaultPackageCode';
import AppDownload from './AppDownload';
import {
  AlternateText,
  AppVisibilityIcon,
  Button,
  Flash,
  FlashContentT,
  FlexColumns,
  FlexRows,
  LabeledInput,
  Loading,
  Modal as ModalComponent,
  ModuleSegment,
  PrimaryText,
} from '@components/common';
import cas from '@api/cas';
import { BrandDetail, Feature, PackageDetail, RequiredFieldsFeatureOptions } from '@api/cas/models';
import theme from '@theme';

import FeatureSection from './features';
import packageUtils from '@api/packageUtils';
import { useHistory } from 'react-router';
import User from '@api/cas/user';
import { DeveloperOnly } from '@app/components/common/withUser';

const ModuleHelpInfo = {
  Content: () => (
    <AlternateText>
      App Packages determines user access to your products while using a Variable Cloud compatible app. Giving users
      access to an App Package via qr codes allows them to match colors to your products. Within App Packages, you may
      determine how many users may subscribe, and which Product Libraries they have access to.
    </AlternateText>
  ),
  title: 'What are App Packages?',
};

type ExternalProps = {
  user: User;

  brand: BrandDetail;
  packageDetail: PackageDetail;
  onDelete: () => void;
};
//#region Type Definitions
type Props = ExternalProps & {
  history: ReturnType<typeof useHistory>;
};

type ModalType = 'confirm_delete' | 'deleting' | Feature<RequiredFieldsFeatureOptions>;
type State = {
  flash?: FlashContentT;
  modalType?: ModalType;
};
//#endregion

class PackageDetailQuad extends React.Component<Props, State> {
  state = {
    flash: undefined,
    modalType: undefined as ModalType | undefined,
  };

  componentDidMount = () => {
    console.log(this.props.packageDetail);
    // const { packageDetail: pkg } = this.props;
    //
    // // This is for development environment with bad packages
    // if (config.isDebug()) {
    //   const c = packageUtils.defaultCampaign(pkg);
    //   if (!c) {
    //     cas
    //       .createCampaign({
    //         code_count: 1,
    //         id: `BASIC-${pkg.id}`,
    //         name: 'Generated',
    //         package_id: pkg.id,
    //         redemptions: 10000,
    //         type: cas.QR_TYPE_SINGLE_CODE,
    //         created_at: new Date().toISOString(),
    //         codes: [] /** satisty type requirement */,
    //       })
    //       .then(() => this.forceUpdate())
    //       .catch(console.log);
    //   }
    // }
  };

  handlePackageDeletion = async () => {
    this.showModal('deleting')();

    try {
      const { packageDetail, brand, onDelete } = this.props;
      await cas.deletePackage(packageDetail.id);
      brand.packages = brand.packages.filter(p => p.id !== packageDetail.id);
      onDelete?.();
    } catch (err: any) {
      this.setState({
        flash: {
          color: 'red',
          message: `Unable to delete - ${err.message}`,
        },
      });
    }

    this.showModal(undefined)();
  };

  handleNameInputFocusChange = () => {
    this.updatePackage();
  };

  handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { packageDetail } = this.props;
    packageDetail.name = e.currentTarget.value;

    this.forceUpdate();
  };

  updatePackage = async () => {
    const { packageDetail } = this.props;

    await cas.updatePackage(packageDetail);

    this.forceUpdate();
  };

  handleDownloadClick = async () => {
    try {
      const { packageDetail } = this.props;
      const c = packageUtils.defaultCampaign(packageDetail);
      if (!c) {
        return;
      }

      const json = await cas.downloadCampaignZipFile({
        package_id: packageDetail.id,
        tracking_id: c.id,
      });

      const win = window as any;
      win.location = json.url;
    } catch (err) {
      console.error(err);
    }
  };

  duplicatePackage = async () => {
    const { packageDetail, history } = this.props;

    const dupPackage = _.cloneDeep(packageDetail);
    dupPackage.name = `${packageDetail.name} (Duplicate)`;

    const p = await cas.createPackage(dupPackage);

    const additions = packageDetail.product_groups.map(pg => pg.id);
    await cas.modifyPackageProductGroups({
      additions,
      packageID: p.id,
      deletions: [],
    });

    history.push(routeUtils.packageDashboard(p.brand_id, p.id));
  };

  handleFeatureChange = (feature: Feature<any>) => (_e: React.SyntheticEvent<HTMLElement>, checked: boolean) => {
    const { packageDetail } = this.props;

    if (feature?.options?.user_defined_fields) {
      const packageFeature = packageDetail.features.find(x => x.id === feature.id);
      console.log(packageFeature ?? feature);
      this.setState({ modalType: (packageFeature ?? feature) as Feature<any> });
      return;
    }

    if (checked) {
      packageDetail.features.push({
        name: feature.id,
        id: feature.id,
        options: undefined,
      });
    } else {
      packageDetail.features = packageDetail.features.filter(f => f.name !== feature.id);
    }

    this.updatePackage();
  };

  get packageDetailButtons() {
    const buttons = [];

    const { brand, packageDetail, user } = this.props;
    if (
      user.hasPaidFeature('package_codes') &&
      packageUtils.defaultCampaign(packageDetail) &&
      packageDetail.product_groups.length >= 1
    ) {
      buttons.push(
        <Button
          key="download-app-code"
          color={theme.button.neutralColor}
          text="Download App Code"
          onClick={this.handleDownloadClick}
        />,
      );
    }

    buttons.push(
      <Button
        key="duplicate"
        outline
        color={theme.button.neutralColor}
        text="Duplicate Package"
        onClick={this.duplicatePackage}
      />,
    );

    if (brand.packages.length >= 2 && !packageDetail.is_default_package) {
      buttons.push(
        <Button
          key="delete"
          outline
          color={theme.button.cancelColor}
          text="Delete"
          onClick={this.showModal('confirm_delete')}
        />,
      );
    }

    return buttons;
  }
  showModal = (modalType?: ModalType) => () => {
    this.setState({ modalType });
  };

  Modal = () => {
    const { modalType } = this.state;
    switch (modalType) {
      case null:
      case undefined:
        return null;

      case 'deleting':
        return (
          <ModalComponent modalProps={{ defaultOpen: true }}>
            <Loading text="Deleting Package" />
          </ModalComponent>
        );

      case 'confirm_delete':
        return (
          <ModalComponent modalProps={{ defaultOpen: true }}>
            <ModuleSegment title="Confirm Action">
              <PrimaryText>
                Deleting an App Package is an irreverisble action. Deleting this App Package will
              </PrimaryText>

              <List bulleted>
                <List.Item>
                  <List.Description>
                    <AlternateText>Immediately revoke all active user subscriptions to this Package</AlternateText>
                  </List.Description>
                </List.Item>

                <List.Item>
                  <List.Description>
                    <AlternateText>Invalidate all active app codes</AlternateText>
                  </List.Description>
                </List.Item>
              </List>

              <AlternateText>Are you sure you want to delete this app package?</AlternateText>

              <FlexColumns style={{ justifyContent: 'flex-end' }}>
                <Button onClick={this.showModal(undefined)} outline color={theme.button.actionColor} text="Cancel" />
                <Button onClick={this.handlePackageDeletion} color={theme.button.cancelColor} text="Delete Package" />
              </FlexColumns>
            </ModuleSegment>
          </ModalComponent>
        );

      default:
        // Assumes default case to be selected feature
        return (
          <ModalComponent modalProps={{ defaultOpen: true }}>
            <FeatureEditor
              feature={modalType}
              onDelete={this.deleteFeature}
              onSubmit={this.submitFeature}
              onCancel={this.showModal(undefined)}
            />
          </ModalComponent>
        );
    }
  };

  deleteFeature = (f: Feature<any>) => {
    const { packageDetail: x } = this.props;
    x.features = x.features.filter(a => a.id !== f.id);

    this.showModal(undefined)();
    this.updatePackage();
  };
  submitFeature = (f: Feature<any>) => {
    const { packageDetail: x } = this.props;

    x.features = x.features.filter(a => a.id !== f.id).concat([f]);

    this.showModal(undefined)();
    this.updatePackage();
  };

  Content = () => {
    const {
      packageDetail,
      user,
      brand: { seats },
    } = this.props;

    if (packageDetail.product_groups.length < 1) {
      return <NoActivatedContent>Activate one library with products below to generate app code.</NoActivatedContent>;
    }

    const d = packageUtils.defaultCampaign(packageDetail);
    const showPackageCodes = user.hasPaidFeature('package_codes');
    const Trigger = (
      <div style={{ position: 'absolute', right: 8 }}>
        <AppVisibilityIcon alt="Displayed within in a Variable Cloud compatible app, during subscription selection" />
      </div>
    );

    return (
      <div style={{ marginTop: 18 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {showPackageCodes && <DefaultPackageCode d={d} />}
          <FlexRows
            style={{
              display: 'inline-flex',
              marginLeft: 18,
              minWidth: 768,
            }}
          >
            <FlexColumns style={{ alignItems: 'center', position: 'relative' }}>
              <Popup
                content="Displayed within in a Variable Cloud compatible app, during subscription selection"
                trigger={Trigger}
              />
              <LabeledInput
                label="name"
                inputProps={{
                  value: packageDetail.name,
                  onBlur: this.handleNameInputFocusChange,
                }}
                onChange={this.handleChange}
              />
            </FlexColumns>
            {showPackageCodes && (
              <Popup
                content="This indicates the number of times an app code may be used before a limit is reached."
                trigger={
                  <LabeledInput
                    label="REDEMPTION LIMIT"
                    inputProps={{
                      type: 'number',
                      value: d ? d.codes[0].redemption_limit : 'ERROR',
                    }}
                  />
                }
              />
            )}

            <DeveloperOnly>
              <>
                <LabeledInput label="Used Seats" inputProps={{ defaultValue: seats.used, disabled: true }} />

                <LabeledInput label="Free Seats" inputProps={{ defaultValue: seats.free, disabled: true }} />

                <LabeledInput label="Total Seats" inputProps={{ defaultValue: seats.total, disabled: true }} />
              </>
            </DeveloperOnly>
            {showPackageCodes && (
              <>
                <AppDownload packageDetail={packageDetail} />
                <AlternateText style={{ paddingTop: 12, paddingBottom: 12 }}>
                  Scan this QR code or enter the 5-character code to access activated Product Libraries within your{' '}
                  {packageUtils
                    .filterPlatforms(packageDetail, user)
                    .map(p => p.name)
                    .join(', ')}{' '}
                  mobile app. Download or share this image to give app access to other users.
                </AlternateText>
              </>
            )}

            <FeatureSection handleFeatureChange={this.handleFeatureChange} user={user} packageDetail={packageDetail} />
          </FlexRows>
        </div>
      </div>
    );
  };

  render = () => {
    const { flash } = this.state;
    const { Modal, Content } = this;
    return (
      <ModuleSegment
        containerStyle={{ width: '100%' }}
        helpModal={ModuleHelpInfo}
        title={ModuleSegment.ModuleTitle('App Package', 'Learn More')}
        buttons={this.packageDetailButtons}
      >
        <Modal />
        <Flash content={flash} />
        <Content />
      </ModuleSegment>
    );
  };
}

const NoActivatedContent = styled(PrimaryText)`
  border-width: 2px;
  border-color: ${theme.border.alternateSelectedItem};
  border-style: solid;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 4px;

  font-weight: 500;

  width: 100%;
  height: 256px;
`;

export default function __Page(props: ExternalProps) {
  const history = useHistory();
  return <PackageDetailQuad {...props} history={history} />;
}
