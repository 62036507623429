import React from 'react';
import styled from 'styled-components';
import { Button } from './Buttons';

const StatusContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #e3e3e3;
  padding: 8px;
  border-top: 1px solid #ccc;

  display: flex;
  align-items: center;
`;

const SaveButtons = styled.div`
  flex: 1;
  text-align: right;
`;

const SpacingDiv = styled.div`
  flex: 1;
`;

const MessageDiv = styled.div`
  flex: 1;
  text-align: center;
  color: #222;
  font-size: 125%;
`;

const SaveButton = styled(Button)`
  margin-right: 12px;
`;

type StatusBarProps = {
  show?: boolean;
  text?: string;
  positiveButtonText?: string;
  negativeButtonText?: string;
  handleSave?: () => void | any;
  handleReset?: () => void | any;

  children?: JSX.Element[];
};

export default function StatusBar({
  show,
  handleSave,
  handleReset,
  positiveButtonText = 'Save',
  negativeButtonText = 'Cancel',
  text = 'You have unsaved changed',
  children,
}: StatusBarProps) {
  if (show === false) {
    return null;
  }

  return (
    <StatusContainer>
      <SpacingDiv />

      <MessageDiv>{children || text}</MessageDiv>

      <SaveButtons>
        <SaveButton
          text={positiveButtonText}
          color="green"
          onClick={handleSave}
          padding={12}
          paddingLeft={18}
          paddingRight={18}
        />
        <SaveButton
          color="red"
          onClick={handleReset}
          text={negativeButtonText}
          padding={12}
          paddingLeft={18}
          paddingRight={18}
        />
      </SaveButtons>
    </StatusContainer>
  );
}
