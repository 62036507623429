import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import theme from '@theme';
import { AlternateText, PrimaryText } from '../Text';

type ViewProps = {
  containerStyle?: Object;

  lines: {
    text: string;
    style?: Object;
  }[];

  onClick?: () => void;

  selected?: boolean;
};

export const Container = styled.div<{ hoverable?: any; selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? theme.border.selectedItem : theme.card.alternateBackground)};
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);

  width: 256px;

  display: flex;
  flex-direction: column;

  padding: 12px;

  margin-right: 12px;
  margin-bottom: 12px;

  position: relative;

  ${({ hoverable }) => {
    if (!hoverable) {
      return '';
    }

    return `
      :hover{
        background-color: ${theme.border.selectedItem};
        cursor: pointer;
      }
    `;
  }}
`;

const styles = {
  lines: {
    minWidth: 128,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const VerticalPadding = styled.div`
  margin-top: 12px;
`;

export function CardView({ containerStyle, onClick, lines, selected }: ViewProps) {
  return (
    <Container style={containerStyle} selected={selected} hoverable={onClick} onClick={onClick}>
      {lines.map((x, i) => {
        if (x.text.length === 0) {
          return <VerticalPadding key={_.uniqueId('cv_')} style={x.style} />;
        }

        if (i === 0) {
          return (
            <PrimaryText key={_.uniqueId('cv_')} style={{ ...styles.lines, ...x.style } as React.CSSProperties}>
              {x.text}
            </PrimaryText>
          );
        }

        return (
          <AlternateText key={_.uniqueId('cv_')} style={{ ...styles.lines, ...x.style } as React.CSSProperties}>
            {x.text}
          </AlternateText>
        );
      })}
    </Container>
  );
}

export default {
  CardView,
  Container,
};
