import * as React from 'react';
import Permissions, { ViewProps as PermissionViewProps } from './Permissions';
import { withUser, ValidateAccessControl } from '../withUser';
import { AccessControlID } from '@api/cas/models';

type ViewProps = PermissionViewProps & {
  isOwnedByCurrentUser: boolean;

  accessType: AccessControlID;
};

export default withUser<ViewProps>(props => {
  const {
    user,
    isOwnedByCurrentUser,
    title,
    accessType,
    handleAddContributor,
    handleRevokeUserAccess,
    handleAddOwner,
    handleTransferOwner,
    permissions,
  } = props;
  if (!isOwnedByCurrentUser) {
    return null;
  }

  return (
    <ValidateAccessControl user={user} id={accessType}>
      <Permissions
        title={title}
        permissions={permissions}
        handleAddContributor={handleAddContributor}
        handleRevokeUserAccess={handleRevokeUserAccess}
        handleAddOwner={handleAddOwner}
        handleTransferOwner={handleTransferOwner}
      />
    </ValidateAccessControl>
  );
});
