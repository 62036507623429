import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import theme from '@theme';
import { AppVisibilityIcon, withUser } from '@components/common';
import { ProductGroupDescriptor } from '@api/cas/models';
import User from '@api/cas/user';
import ModelDetector from '@api/models/ModelDetector';

const Text = styled.div<{ paddingTop?: string }>`
  padding-top: ${({ paddingTop }) => paddingTop};
  color: ${theme.text.primary};
`;

const SmallText = styled(Text)`
  font-size: 75%;
  font-weight: 300;
`;

const MediumText = styled(Text)`
  font-size: 90%;
  font-weight: bold;
`;

const NameText = styled(Text)`
  font-size: 100%;
  font-weight: 800;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ContentWrapper = styled.div<{ isSelected?: boolean }>`
  padding: 12px;
  display: flex;
  flex-direction: column;

  font-weight: ${props => (props.isSelected ? 'bold' : '')};
  color: ${props => (props.isSelected ? 'white' : 'black')};
`;
const Container = styled.div<{ isSelected?: boolean }>`
  margin: 4px;

  height: 175px;
  width: 450px;

  background-color: ${props => (props.isSelected ? theme.table.selectedBackground : theme.card.alternateBackground)};
  display: flex;
  flex-direction: column;

  position: relative;
  cursor: pointer;

  &:hover {
    border: 1px solid ${theme.border.selectedItem};
  }
`;

type ItemProps = {
  group: ProductGroupDescriptor;

  changed?: boolean;

  onGroupClick: Function;

  selected?: boolean;
};
class ProductGroupListItem extends React.PureComponent<ItemProps> {
  static defaultProps = {
    changed: false,
  };

  onClick = () => {
    const { group, onGroupClick } = this.props;
    onGroupClick(group);
  };

  ProductCount = () => {
    const { group } = this.props;
    if (group.product_count !== undefined) {
      return <MediumText paddingTop="20px">{`${group.product_count || 0} PRODUCTS`}</MediumText>;
    }

    return null;
  };

  getText = (key: 'category' | 'vendor' | 'name') => {
    const { group } = this.props;
    switch (key) {
      case 'category':
        return group.category;

      case 'vendor':
        return group.vendor;

      case 'name':
        return group.name;
    }
    return '-';
  };

  Warning = () => {
    const { changed } = this.props;
    if (changed) {
      return (
        <div style={{ bottom: 16, position: 'absolute', right: 16 }}>
          <Icon size="large" color="yellow" name="warning sign" />
        </div>
      );
    }

    return null;
  };

  AppVisibleIcon = () => {
    const { changed, group, selected } = this.props;
    if (!changed && selected) {
      return (
        <div style={{ position: 'absolute', right: 8, top: 8 }}>
          <AppVisibilityIcon alt={`${group.name} is changed`} />
        </div>
      );
    }

    return null;
  };

  get id() {
    const { group } = this.props;
    return group.id;
  }

  render() {
    const { group, selected } = this.props;
    const vendor = this.getText('vendor');
    const name = this.getText('name');
    const category = this.getText('category');

    const { ProductCount, Warning, AppVisibleIcon } = this;
    return (
      <Container isSelected={selected}>
        <ContentWrapper onClick={this.onClick} key={this.id} isSelected={selected}>
          <NameText>{name}</NameText>
          <SmallText>{vendor}</SmallText>

          <ProductCount />
          <SmallText>{category}</SmallText>

          <div style={{ paddingTop: 20 }} />
          <FourthLine descriptor={group} />

          <UpdatedAtText descriptor={group} />

          <AppVisibleIcon />
          <Warning />
          <Measurements descriptor={group} />
        </ContentWrapper>
      </Container>
    );
  }
}

const UpdatedAtText = ({ descriptor: { updated_at } }: { descriptor: ProductGroupDescriptor }) => {
  if (!updated_at) {
    return null;
  }

  return <SmallText>{moment(updated_at).format('LLL')}</SmallText>;
};

function Measurements({ descriptor }: { descriptor: ProductGroupDescriptor }) {
  const { measurement_stats } = descriptor as any;
  if (!measurement_stats) {
    return null;
  }

  return (
    <SmallText>
      <span style={{ fontWeight: 'bold' }}>Hardware:</span>{' '}
      {measurement_stats.models.map((x: any) => ModelDetector.name(x.id)).join(', ')}
    </SmallText>
  );
}

const FourthLine = withUser<{ descriptor: ProductGroupDescriptor }>(
  (props: { user: User; descriptor: ProductGroupDescriptor }) => {
    const {
      descriptor,
      user: { is_system_admin },
    } = props;

    if (is_system_admin) {
      return <SmallText> {descriptor.id} </SmallText>;
    }
    return <div style={{ paddingTop: 10 }} />;
  },
);

export default ProductGroupListItem;
