import * as React from 'react';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

import { FlexRows, FlexColumns, SearchBar, Dropdown, PrimaryText } from '@components/common';
import theme from '@theme';
import { useQueryParams } from './ProductGroups';
import { Container } from '@components/products/search/components';
import { AggregationResult } from '@api/vpapi/productGroupsAPI';

const MenuItem = styled(Menu.Item)`
  &&& {
    color: ${theme.text.alternate} !important;
  }
`;

const AccessOptions = [
  { draggable: false, name: 'Mine', value: 'is_owner' },
  { draggable: false, name: 'Shared', value: 'is_contributor' },
  { draggable: false, name: 'All', value: null },
];

const sorts = [
  { key: 'name', text: 'Product Library Name', value: 'sorts.name' },
  {
    key: 'product_count',
    text: 'Number of Products in Library',
    value: 'product_count',
  },
  {
    key: 'updated_at',
    text: 'Last Modification Date and Time',
    value: 'updated_at',
  },
];

export default function FilterSection({ vendors }: { vendors?: AggregationResult<string>[] }) {
  const { filter, onRoleFilterClick, onSearchTermSubmit, onDirectionChange, onSortChange, onVendorChange } =
    useQueryParams();

  const values = filter?.sort?.split(':');

  return (
    <FlexRows>
      <div style={{ margin: 8 }}>
        <SearchBar
          key={filter?.search}
          onSubmit={onSearchTermSubmit}
          inputProps={{
            value: filter?.search,
            placeholder: 'Search by name, id, or category',
          }}
        />
      </div>
      <FlexColumns alignItems="flex-start">
        <Container>
          <PrimaryText fontWeight={500}>Sorting By</PrimaryText>
          <FlexColumns>
            <img
              alt="Sort By"
              style={{
                cursor: 'pointer',
                height: 'auto',
                padding: 6,
                objectFit: 'contain',
                width: theme.dimensions.input.height,
              }}
              role="presentation"
              src={(values?.[1] ?? 'asc') === 'asc' ? '/icons/ascending.png' : '/icons/descending.png'}
              onClick={onDirectionChange}
            />

            <Dropdown
              minCharacters={0}
              selection
              options={sorts}
              value={values?.[0] ?? 'updated_at '}
              onChange={onSortChange}
            />
          </FlexColumns>
        </Container>
        {vendors && (
          <Container>
            <PrimaryText fontWeight={500}>Filter by Vendor</PrimaryText>
            <Dropdown
              search
              selection
              clearable
              value={filter?.vendor}
              onChange={onVendorChange}
              options={vendors.map(x => ({
                text: `${x.value}`,
                value: x.value,
                id: x.value,
              }))}
              style={{ marginLeft: 12 }}
            />
          </Container>
        )}

        <div style={{ flex: 1 }} />

        <Menu inverted text>
          {AccessOptions.map(option => (
            <MenuItem
              {...option}
              active={option.value === filter?.permission_filter ?? null}
              key={option.value}
              onClick={onRoleFilterClick}
            />
          ))}
        </Menu>
      </FlexColumns>
    </FlexRows>
  );
}
