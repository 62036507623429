import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import validator from 'email-validator';

import cas from '@api/cas';
import {
  ModuleSegmentTitle,
  AlternateText,
  Button,
  Flash,
  FlashContentT,
  Header,
  Input,
  Loading,
  ModuleSegment as Segment,
} from '@components/common';
import { BaseInputProps } from '@components/common/Input/Input';
import theme from '@theme';
import User from '@api/cas/user';
import { Optional } from '@app/utils/types';

const inputStyle = {
  minWidth: theme.dimensions.input.label_width,
};
const ModuleSegment = ({ children, title }: { title: ModuleSegmentTitle; children: any }) => (
  <Segment
    containerStyle={{
      borderColor: theme.button.createColor,
      borderRadius: 12,
      borderStyle: 'solid',
      borderWidth: 2,

      padding: '2.5%',
      width: '100%',
    }}
    title={title}
  >
    {children}
  </Segment>
);

const TextLink = styled(AlternateText)`
  margin-top: 9px;

  color: ${theme.text.alternate};
  font-size: 90%;

  :hover {
    color: ${theme.text.primary};
    font-weight: bold;
  }
`;

type ViewProps = {
  history: ReturnType<typeof useHistory>;
  location: ReturnType<typeof useLocation>;
  onAuth: (user: User) => void | any;
};

type ViewType = 'forgot_password' | 'login' | 'account_creation';

type ViewState = {
  email: string;
  name?: string;
  password_2?: string;
  password: string;
  flash?: FlashContentT;
  loading: boolean;
  view: ViewType;
};

const ModuleTitle = ({ title, subtitle }: { title: string; subtitle?: string }) => (
  <div>
    <Header style={{ marginBottom: 18 }} color={theme.text.accent} fontSize="36px" fontWeight="bold">
      {title}
    </Header>
    <AlternateText>{subtitle}</AlternateText>
  </div>
);

class Login extends React.Component<ViewProps, ViewState> {
  constructor(props: ViewProps) {
    super(props);

    this.state = {
      email: '',
      flash: undefined,
      loading: false,
      name: undefined,
      password: '',
      password_2: undefined,
      view: 'login',
    };
  }

  static getDerivedStateFromProps = (props: ViewProps, state: ViewState): ViewState => {
    const {
      location: { pathname },
    } = props;
    switch (pathname) {
      case '/register':
        return {
          ...state,
          view: 'account_creation',
        };
    }

    return state;
  };

  toggle = (view: ViewType) => () => {
    const { history } = this.props;

    switch (view) {
      case 'account_creation':
        history.push('/register');
        return;

      case 'forgot_password':
      case 'login':
        history.push('/login');
    }
    this.setState({ view });
  };

  handleButtonClick = (e: React.SyntheticEvent<HTMLElement>) => {
    const { view } = this.state;

    if (view === 'forgot_password') {
      this.handleRequestPasswordReset();
    } else if (view === 'login') {
      this.handleAuth(e);
    } else if (view === 'account_creation') {
      this.handleAccountCreation();
    }
  };

  handleAccountCreation = async () => {
    this.setState({ flash: undefined, loading: true });
    const { name, email, password, password_2 } = this.state;
    console.log('isFormValid', this.isFormValid);
    if (!this.isFormValid) {
      this.setState({
        loading: false,
        flash: {
          color: 'red',
          message: 'All fields must be complete with matching passwords.',
        },
      });
      return;
    }

    try {
      await cas.users.create({
        email,
        nickname: name ?? '',
        password,
        password_2: password_2 ?? '',
      });

      this.setState({
        flash: {
          color: 'green',
          message: 'Before continuing with Variable Cloud, check your email to verify your account.',
        },
        loading: false,
      });
    } catch (err) {
      this.setState({
        flash: {
          color: 'red',
          message: 'This email is already in use.',
        },
        loading: false,
      });
    }
  };

  handleRequestPasswordReset = async () => {
    const { email } = this.state;

    try {
      await cas.users.forgotPassword(email);

      this.setState({
        flash: {
          color: 'green',
          message: 'Check your email for a link to reset your password.',
        },
        view: 'login',
      });
    } catch (e: any) {
      this.setState({ flash: e.flashProps as FlashContentT });
    }
  };

  handleAuth = async (event: React.SyntheticEvent<HTMLElement>) => {
    this.setState({ loading: true });

    event.preventDefault();

    const { onAuth } = this.props;
    const { email, password } = this.state;
    try {
      const user = await cas.users.login({
        email: email.trim(),
        password: password.trim(),
      });

      this.setState({ flash: undefined, loading: false }, () => {
        onAuth(user);
      });
    } catch (e: any) {
      this.setState({ flash: e.flashProps, loading: false });
    }
  };

  handleChange = ({ currentTarget: { id, value } }: React.SyntheticEvent<HTMLInputElement>) => {
    const key = id as keyof ViewState;
    (this.state as any)[key] = value;
    this.setState({ ...this.state, flash: undefined });
  };

  get isFormValid() {
    const { email, password, view } = this.state;

    const emailValid = validator.validate(email);
    if (view === 'forgot_password') {
      return emailValid;
    }

    const passwordValid = password.length > 3;
    if (emailValid && passwordValid) {
      if (view === 'login') {
        return true;
      }

      if (view === 'account_creation') {
        const { password_2, name } = this.state;

        return password_2 === password && name;
      }
    }

    return false;
  }

  handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }

    if (this.isFormValid) {
      this.handleButtonClick(e);
    }
  };

  Title = () => {
    const { view } = this.state;
    if (view === 'forgot_password') {
      return ModuleTitle({
        subtitle: 'Enter the email associated with your account,  and we’ll email you a link to reset your password.',
        title: 'Reset Password',
      });
    }

    return ModuleTitle({
      subtitle: undefined,
      title: 'Welcome',
    });
  };

  ActionButton = () => {
    const { view } = this.state;
    let buttonText = 'Sign In';
    let button2Text = 'Forgot Password?';
    let button3Text = 'Dont have an account?' as Optional<string>;
    let viewType = 'forgot_password' as ViewType;
    if (view === 'forgot_password') {
      viewType = 'login';
      buttonText = 'Send Password Reset Email';
      button2Text = 'Back to Login';
      button3Text = undefined;
    }

    if (view === 'account_creation') {
      button2Text = 'Back to Login';
      button3Text = undefined;
      buttonText = 'Register';
      viewType = 'login';
    }

    return (
      <div style={{ marginTop: 9 }}>
        <Button
          padding={8}
          style={{ width: '100%' }}
          onClick={e => {
            console.log('Button Click', view);
            this.handleButtonClick(e);
          }}
          text={buttonText}
          color={theme.button.createColor}
        />

        <TextLink onClick={this.toggle(viewType)}>{button2Text}</TextLink>

        <TextLink onClick={this.toggle('account_creation')}>{button3Text}</TextLink>
      </div>
    );
  };

  render = () => {
    const { loading, flash, email, name, view, password, password_2 } = this.state;

    if (loading) {
      return (
        <ModuleSegment title={this.Title}>
          <Loading text="Authenticating your credentials" />
        </ModuleSegment>
      );
    }

    const { ActionButton } = this;
    return (
      <ModuleSegment title={this.Title}>
        <Flash content={flash} />

        <Input
          onChange={this.handleChange}
          label="Email"
          inputProps={{
            id: 'email',
            onKeyPress: this.handleKeyPress,
            placeholder: 'Email',
            style: inputStyle,
            type: 'email',
            value: email,
          }}
        />

        <LoginInput
          hide={view !== 'account_creation'}
          onChange={this.handleChange}
          label="Name"
          inputProps={{
            id: 'name',
            onKeyPress: this.handleKeyPress,
            placeholder: 'Name',
            style: inputStyle,
            type: 'name',
            value: name,
          }}
        />
        <LoginInput
          label="Password"
          hide={view === 'forgot_password'}
          onChange={this.handleChange}
          inputProps={{
            id: 'password',
            onKeyPress: this.handleKeyPress,
            placeholder: 'Password',
            style: inputStyle,
            type: 'password',
            value: password,
          }}
        />
        <LoginInput
          hide={view !== 'account_creation'}
          label="Password"
          onChange={this.handleChange}
          inputProps={{
            id: 'password_2',
            onKeyPress: this.handleKeyPress,
            placeholder: 'Confirm Password',
            style: inputStyle,
            type: 'password',
            value: password_2,
          }}
        />
        <ActionButton />
      </ModuleSegment>
    );
  };
}

function LoginInput({ hide, ...props }: BaseInputProps & { hide: boolean }) {
  if (hide) {
    return null;
  }

  return <Input {...props} />;
}

export default function __Page(props: { onAuth: (user: User) => void | any }) {
  const history = useHistory();
  const location = useLocation();
  return <Login {...props} history={history} location={location} />;
}
export { ModuleSegment };
