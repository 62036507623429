import * as React from 'react';

import { PaidPlanJSON, UserSearchJSON } from '@api/cas/models';
import { Button } from '@components/common';
import theme from '@theme';

type SaveButtonProps = {
  plan?: PaidPlanJSON;
  user: UserSearchJSON;

  onClick: () => void | any;
};

export function SaveButton({ plan, onClick }: SaveButtonProps) {
  //Check if the params are valid
  if (!plan || !plan.plan_id || !plan.effective_til) {
    return null;
  }

  return (
    <Button style={{ marginTop: 12 }} text="Grant Subscription" color={theme.button.actionColor} onClick={onClick} />
  );
}
