import * as React from 'react';
import qs from 'query-string';
import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import brandUtils from '../../api/brandUtils';
import BrandListItem, { Item as ListItemEntity } from './packages/BrandListItem';
import theme from '../../theme';
import { Button, FlexColumns, ModuleSegment, NoOrganizationContent, ScrollView, SearchBar, useScroll } from '../common';
import { useHistory, useLocation } from 'react-router';
import { Optional } from '@app/utils/types';

const FilterMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MenuItem = styled(Menu.Item)`
  &&& {
    color: ${theme.text.alternate} !important;
  }
`;

type Item = ListItemEntity & {
  id: string;
};
export type BrandListProps = {
  brands: Item[];
  handleCreateClick?: () => void;
  onItemClick: (x: Item) => void;
};

const ITEMS_PER_PAGE = 25;
type AccessOptions = 'owner' | 'all' | 'contributor';
function useBrandList(allBrands: Item[]) {
  const location = useLocation();
  const history = useHistory();
  const params = qs.parse(location.search);

  const defaultAccess = (params.access as Optional<AccessOptions>) || 'all';
  const [access, setFilterAccessOption] = React.useState<AccessOptions>(defaultAccess);

  const [searchTerm] = React.useState<string>((params.search as Optional<string>) || '');

  const defaultBrands = brandUtils.search(allBrands, searchTerm, access, 0, ITEMS_PER_PAGE);
  const [brands, setFilteredBrands] = React.useState<Item[]>(defaultBrands);

  const scroll = useScroll(
    allBrands.length,
    25,
    React.useCallback(
      (_previous: number, next: number, _pageSize: number) => {
        const filteredBrands = brandUtils.search(allBrands, searchTerm, access, next * ITEMS_PER_PAGE, ITEMS_PER_PAGE);
        setFilteredBrands([...brands, ...filteredBrands]);
      },
      [brands, allBrands, access, searchTerm],
    ),
  );
  return {
    defaultSearchTerm: searchTerm,
    updateBrands: React.useCallback(
      (v: string = searchTerm) => {
        params.search = v;
        history.replace(`${location.pathname}?${qs.stringify(params)}`);

        setFilteredBrands(brandUtils.search(allBrands, v, access, 0, ITEMS_PER_PAGE));
      },
      [allBrands, searchTerm, access, history, location, params],
    ),

    scroll,
    brands,

    hasBrands() {
      return !(brands.length === 0 && (access === 'owner' || access === 'all') && searchTerm.length === 0);
    },

    filter: {
      access,
      get isOwnerSelected() {
        return access === 'owner';
      },

      get isContributorSelected() {
        return access === 'contributor';
      },

      get showAllItems() {
        return access === 'all';
      },

      setOwnerAccess() {
        params.access = 'owner';
        history.replace(`${location.pathname}?${qs.stringify(params)}`);

        setFilterAccessOption('owner');
        setFilteredBrands(brandUtils.search(allBrands, searchTerm, 'owner', 0, ITEMS_PER_PAGE));
      },

      setSharedAccess: () => {
        params.access = 'contributor';
        history.replace(`${location.pathname}?${qs.stringify(params)}`);

        setFilterAccessOption('contributor');
        setFilteredBrands(brandUtils.search(allBrands, searchTerm, 'contributor', 0, ITEMS_PER_PAGE));
      },

      setAll() {
        params.access = 'all';
        history.replace(`${location.pathname}?${qs.stringify(params)}`);

        setFilterAccessOption('all');
        setFilteredBrands(brandUtils.search(allBrands, searchTerm, 'all', 0, ITEMS_PER_PAGE));
      },
    },
  };
}

function useBrandListButtons(handleCreateClick?: () => void | any) {
  if (handleCreateClick) {
    return [
      <Button
        key="create_brand"
        color={theme.button.createColor}
        text="Create Organization"
        onClick={handleCreateClick}
      />,
    ];
  }

  return undefined;
}

export default function BrandList({ handleCreateClick, onItemClick, brands: allBrands }: BrandListProps) {
  const { defaultSearchTerm, brands, scroll, filter, updateBrands, hasBrands } = useBrandList(allBrands);
  const buttons = useBrandListButtons(handleCreateClick);
  console.log('rendering BrandList');
  return (
    <ModuleSegment title="Select Organization" buttons={buttons}>
      <SearchBar
        onSubmit={updateBrands}
        inputProps={{
          defaultValue: defaultSearchTerm,
          placeholder: 'Search organization name...',
        }}
      />

      <FilterMenuWrapper>
        <Menu inverted text>
          <MenuItem
            draggable={false}
            name="Mine"
            value="owner"
            active={filter.isOwnerSelected}
            onClick={filter.setOwnerAccess}
          />
          <MenuItem
            draggable={false}
            name="Shared"
            value="contributor"
            active={filter.isContributorSelected}
            onClick={filter.setSharedAccess}
          />
          <MenuItem draggable={false} name="all" value="all" active={filter.showAllItems} onClick={filter.setAll} />
        </Menu>
      </FilterMenuWrapper>

      <ScrollView ref={scroll.ref}>
        <FlexColumns style={{ margin: 'auto' }}>
          {brands.map(b => (
            <BrandListItem onClick={onItemClick} brand={b} key={b.id} />
          ))}
        </FlexColumns>
      </ScrollView>

      {!hasBrands() && <NoOrganizationContent />}
    </ModuleSegment>
  );
}
