const blackBgColor = '#060606'; //main bgcolor
const lighterBlack = '#1C1C1C'; //section bgcolor

const grayBgColor = '#2C2C2C';

const lightestGray = '#3C3C3C';

const whitishGray = '#ccc';
const white = '#fff';
const grayTextColor = '#aaa';

const green = '#3BB260';

const blue = '#406AAD';
const red = '#ea321f';
export default {
  /** Place Color Information Below\ */
  black: blackBgColor,
  blackBgColor,
  blue,
  grayBgColor,
  grayTextColor,
  green,
  lighterBlack,
  lightestGray,
  red,
  sectionBgColor: lighterBlack,
  white,
  whitishGray,
};
