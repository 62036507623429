import * as React from 'react';
import styled from 'styled-components';

import moment from 'moment';
import User from '@api/cas/user';
import { AlternateText, LabeledInput, Loader, ModuleSegment, PrimaryText } from '../../common';
import TextArea from '../../common/Input/TextArea';
import theme from '../../../theme';
import type { FormulationGroup, FormulationProduct } from '../../../api/formulation/models';

import api from '../../../api/formulation';

const Card = styled.div`
  background-color: ${theme.card.alternateBackground};

  color: white;
  margin-right: 12px;
  margin-top: 12px;

  width: 30vh;

  min-width: 256px;

  :hover {
    cursor: pointer;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
    background-color: ${theme.border.selectedItem};
  }
`;

const HeaderImage = styled.img`
  height: 100%;
  width: 100%;

  background-color: ${theme.menu.background};

  object-fit: contain;
`;

function renderProduct(onItemClick: (p: FormulationProduct) => void | any) {
  return (p: FormulationProduct) => (
    <Card
      key={p._id}
      onClick={() => {
        onItemClick(p);
      }}
    >
      <div
        style={{
          height: '15vh',
          objectFit: 'contain',
          width: '100%',
        }}
      >
        <HeaderImage
          src={p.product_image || 'https://s3.amazonaws.com/colorcloud.io/images/formulations/empty_product.png'}
        />
      </div>

      <div style={{ padding: '1vh' }}>
        <PrimaryText fontWeight="bold"> {api.products.utils.findValueOrDefault(p, 'product name', '-')} </PrimaryText>
        <AlternateText>{api.products.utils.findValueOrDefault(p, 'sheen', '-')}</AlternateText>
        <AlternateText>{moment(p.created_at).format('LLL')}</AlternateText>
      </div>
    </Card>
  );
}

type ProductListProps = {
  onItemClick: (p: FormulationProduct) => void | any;
  products: FormulationProduct[];
};
function ProductList({ products, onItemClick }: ProductListProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {products.map(renderProduct(onItemClick))}
    </div>
  );
}

type ViewProps = {
  onChange: (
    field: 'name' | 'notes' | 'unit_standard' | 'unit_resolution' | 'native_sample_size',
  ) => (e: React.SyntheticEvent<HTMLInputElement>, data: { label: string; value: any }) => void | any;
  group: FormulationGroup;
  user: User;
};
function FormulationGroupInfoContainer({ group, user, onChange }: ViewProps) {
  return (
    <div>
      {user.is_developer && <LabeledInput inputProps={{ disabled: true, value: group.id }} label="ID" />}
      <LabeledInput inputProps={{ value: group.name }} onChange={onChange('name')} label="Name" />

      <LabeledInput inputProps={{ disabled: true, value: group.product_count }} label="Product Count" />

      <LabeledInput inputProps={{ disabled: true, value: group.formula_count }} label="Formula Count" />

      <LabeledInput inputProps={{ disabled: true, value: group.customer.name }} label="Customer" />

      <LabeledInput
        inputProps={{
          disabled: true,
          value: moment(group.created_at).format('LLL'),
        }}
        label="Created At"
      />

      <LabeledInput
        inputProps={{
          disabled: true,
          value: moment(group.updated_at).format('LLL'),
        }}
        label="Updated At"
      />

      <TextArea
        rows={4}
        valueUpdated={value => {
          onChange('notes')({} as any, { value, label: 'notes' });
        }}
        value={group.notes}
      />

      <ModuleSegment
        title="Paint Dispenser"
        containerStyle={{
          marginLeft: theme.dimensions.invertedContentPadding,
          marginRight: theme.dimensions.invertedContentPadding,
        }}
      >
        <LabeledInput
          inputProps={{ value: group.native_sample_size }}
          onChange={onChange('native_sample_size')}
          label="Original Formula Sample Size"
        />
        <LabeledInput
          inputProps={{ type: 'number', value: group.unit_resolution }}
          onChange={onChange('unit_resolution')}
          label="Volume of Measurement"
        />
        <LabeledInput
          inputProps={{ value: group.unit_standard }}
          onChange={onChange('unit_standard')}
          label="Smallest Unit of Measurement"
        />
      </ModuleSegment>
    </div>
  );
}

//#region Colorant Compon ents
const ColorantWrapper = styled.div<{ loading?: boolean; selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? theme.border.selectedItem : theme.card.alternateBackground)};
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: ${({ loading }) => (loading ? 12 : 24)}px;
  padding-left: 12px;

  margin: 3px;

  ${({ onClick }) => {
    if (!onClick) {
      return undefined;
    }

    return `
      :hover {
        cursor: pointer;
        background-color: ${theme.border.selectedItem};
      }
    `;
  }}
`;

type Args = {
  id: string;
  name: string;
  selected?: any;
  onClick?: (arg: { id: string; name: string }) => void;
  loading?: any;
};
function renderColorant(c: Args) {
  const onClick = () => {
    if (c.onClick) {
      c.onClick(c);
    }
  };

  return (
    <ColorantWrapper loading={c.loading} selected={c.selected} onClick={onClick} key={`${c.id}-${c.name}`}>
      <AlternateText style={{ flexGrow: 1 }}>{`${c.name} ( ${c.id} )`}</AlternateText>
      {c.loading && <Loader />}
    </ColorantWrapper>
  );
}
//#endregion

export { renderColorant, FormulationGroupInfoContainer, ProductList };
