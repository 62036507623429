import * as React from 'react';

import { FlexColumns, ModuleSegment, SearchBar, DraggableContainer } from '@components/common';
import PlatformPackageListItem from './ListItem';
import type { PlatformDetailT, PlatformDetailPackageUI } from '../types';

type ViewProps = {
  platform: PlatformDetailT;
};

function usePackageSelection(platform: PlatformDetailT) {
  const [packages, setAvailablePackages] = React.useState<PlatformDetailPackageUI[]>(platform.available_packages);

  return {
    packages,
    filterPackages: React.useCallback(
      (x: string) => {
        const searchTerm = x.toLowerCase();
        const { available_packages, default_packages, exclusive_packages, only_platform_packages } = platform;

        const arr = available_packages.filter((p: PlatformDetailPackageUI) => {
          if (default_packages.find(d => d.package_id === p.package_id)) {
            if (!only_platform_packages) {
              return false;
            }

            if (exclusive_packages.find(d => d.package_id === p.package_id)) {
              return false;
            }

            return false;
          }

          const exists = !!platform.exclusive_packages.find(y => y.package_id === p.package_id);
          if (exists) {
            return false;
          }

          if (p.brand_name.toLowerCase().indexOf(searchTerm) !== -1) {
            return true;
          }

          if (p.package_name.toLowerCase().indexOf(searchTerm) !== -1) {
            return true;
          }

          if (p.package_id.toString() === searchTerm) {
            return true;
          }

          return false;
        });

        setAvailablePackages(arr);
      },
      [platform, setAvailablePackages],
    ),
  };
}

export default function PackageSelection({ platform }: ViewProps) {
  const { packages, filterPackages } = usePackageSelection(platform);

  return (
    <ModuleSegment
      bodyStyle={{ minWidth: '40vw', marginTop: 12 }}
      title={ModuleSegment.ModuleTitle('Available Packages', 'Drag packages from the right column, into the left')}
    >
      <SearchBar
        inputProps={{
          placeholder: 'Start by searching for an organization or package name',
        }}
        onSubmit={filterPackages}
      />

      <FlexColumns style={{ justifyContent: 'center', width: '100%' }}>
        {packages.map(p => (
          <DraggableContainer
            key={`${p.package_id}`}
            generateJSON={() => {
              return JSON.stringify({
                package_id: p.package_id,
                type: 'single',
              });
            }}
          >
            <PlatformPackageListItem onClick={undefined} p={p} isSelectable={false} />
          </DraggableContainer>
        ))}
      </FlexColumns>
    </ModuleSegment>
  );
}
