import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { LabColor, SpectrumJSON } from '@variablecolor/colormath';
import ModelDetector from '@api/models/ModelDetector';
import { FlexColumns, FlexRows, LabeledInput, Switch } from '@components/common';
import theme from '@theme';
import { MeasuredColor, ProductCompositionDetail } from '@api/vpapi/models';
import SpectrumGraphView from '../../../palettes/paletteItems/SpectrumGraph';

const CompositionDetailWrapper = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${theme.dimensions.contentPadding};
  }
`;

type ViewProps = {
  showManualPreview: boolean;
  onProductChange?: () => void | any;
  disable_color_inputs?: boolean;
  composition: ProductCompositionDetail;
};
type ViewState = {
  selectedMeasuredColor?: MeasuredColor;
};
export default class EditCompositionDetail extends React.Component<ViewProps, ViewState> {
  constructor(props: ViewProps) {
    super(props);
    const { composition } = props;
    this.state = {
      selectedMeasuredColor: composition.measured_colors.length > 0 ? composition.measured_colors[0] : undefined,
    };
  }

  get lab(): LabColor {
    const { selectedMeasuredColor } = this.state;
    if (selectedMeasuredColor) {
      return selectedMeasuredColor.lab;
    }

    const { composition } = this.props;
    return composition.adjusted_lab;
  }

  onLabChange = ({ currentTarget: { id, max, min, value } }: React.SyntheticEvent<HTMLInputElement>) => {
    const { composition, onProductChange } = this.props;
    const key = id as 'L' | 'a' | 'b';
    (composition.adjusted_lab[key] as any) = Math.min(Number(max), Math.max(Number(min), Number(value)));

    if (onProductChange) {
      onProductChange();
    } else {
      this.forceUpdate();
    }
  };

  handleMeasurementTypeClick = (selectedMeasuredColor?: MeasuredColor) => () => {
    this.setState({ selectedMeasuredColor });
  };

  Measurements = () => {
    const { composition, showManualPreview } = this.props;
    const { selectedMeasuredColor } = this.state;
    if (showManualPreview) {
      return null;
    }

    const items = composition.measured_colors.map(m => ({
      name: ModelDetector.name(m.model, m.batch),
      value: m.model,
      onClick: this.handleMeasurementTypeClick(m),
      selected: !!(
        selectedMeasuredColor &&
        selectedMeasuredColor.model === m.model &&
        selectedMeasuredColor.batch === m.batch
      ),
    }));

    if (composition.measured_colors.length === 0) {
      items.push({
        name: 'Imported',
        value: 'imported',
        onClick: this.handleMeasurementTypeClick(undefined),
        selected: !selectedMeasuredColor,
      });
    }

    return (
      <FlexColumns>
        <Switch items={items} />
      </FlexColumns>
    );
  };

  ColorPreview = () => {
    const { showManualPreview } = this.props;
    if (!showManualPreview) {
      return null;
    }

    return (
      <div
        style={{
          backgroundColor: this.lab.toRGB().toHex(),
          height: 96,
          width: '100%',
        }}
      />
    );
  };
  render() {
    const { ColorPreview, Measurements, ColorInputs, SpectrumGraph } = this;
    return (
      <CompositionDetailWrapper>
        <ColorPreview />
        <Measurements />
        <ColorInputs />
        <SpectrumGraph />
      </CompositionDetailWrapper>
    );
  }

  SpectrumGraph = () => {
    const {
      composition: { measured_colors },
    } = this.props;
    const spectras = measured_colors.reduce((arr: (SpectrumJSON & { name: string })[], { spectrum, model, batch }) => {
      if (spectrum) {
        return [
          ...arr,
          {
            name: ModelDetector.name(model, batch),
            ...spectrum,
          },
        ];
      }

      return arr;
    }, []);

    if (spectras.length === 0) {
      return null;
    }

    return (
      <div style={{ marginTop: 24 }}>
        <SpectrumGraphView spectra={spectras} />
      </div>
    );
  };

  ColorInputs = () => {
    const { composition, disable_color_inputs } = this.props;
    const { lab } = this;
    const { selectedMeasuredColor } = this.state;
    const disabled = disable_color_inputs || (composition.isEditable() === false && !selectedMeasuredColor);

    const scanner = selectedMeasuredColor?.scanner || composition?.entered_by || ' Unknown';

    return (
      <FlexRows>
        {/* <Debug data={selectedMeasuredColor} /> */}
        <LabeledInput label="RGB" inputProps={{ disabled: true, value: lab.toRGB().toString() }} />
        <LabeledInput
          inputProps={{
            disabled,
            max: 100,
            min: 0,
            step: '0.00001',
            type: 'number',
            value: lab.L.toFixed(3),
          }}
          id="L"
          label="L"
          onChange={this.onLabChange}
        />
        <LabeledInput
          inputProps={{
            disabled,
            max: 128,
            min: -128,
            step: '0.00001',
            type: 'number',
            value: lab.a.toFixed(3),
          }}
          id="a"
          label="a"
          onChange={this.onLabChange}
        />

        <LabeledInput
          inputProps={{
            disabled,
            max: 128,
            min: -128,
            step: '0.00001',
            type: 'number',
            value: lab.b.toFixed(3),
          }}
          id="b"
          label="b"
          onChange={this.onLabChange}
        />

        <LabeledInput label="Color Space" inputProps={{ disabled: true, value: 'CIE D50 2°' }} />

        {selectedMeasuredColor && selectedMeasuredColor.measurement_device && (
          <LabeledInput
            label="DEVICE SERIAL"
            inputProps={{ disabled: true, value: selectedMeasuredColor.measurement_device?.measurement_device_uuid }}
          />
        )}
        <LabeledInput label="CREATED ON" inputProps={{ disabled: true, value: this.created_at.format('LLL') }} />

        <LabeledInput label="CREATOR" inputProps={{ disabled: true, value: scanner }} />
      </FlexRows>
    );
  };

  get created_at() {
    const { composition } = this.props;
    const { selectedMeasuredColor } = this.state;
    if (selectedMeasuredColor && selectedMeasuredColor.created_at) {
      return moment(selectedMeasuredColor.created_at);
    }

    return moment(composition.created_at);
  }
}
