import { Illuminant, Observer } from '@variablecolor/colormath';
import { AttributeKV } from './types';
import { ColorProvider } from '../types';
import ProductColor, { ProductColorJSON } from './ProductColor';
import { ProductCompositionDetail } from '@api/vpapi/models/ProductCompositionDetail';
import { Optional } from '@app/utils/types';

function attrsFromAppDataJSON(attrs: { [x: string]: string }[]): AttributeKV[] {
  return attrs.map(
    attr =>
      Object.keys(attr).map(key => ({
        k: key,
        kt: key,
        v: attr[key],
        vt: attr[key],
      }))[0],
  );
}

export interface ProductJSON {
  uuid: string;

  filters: Optional<AttributeKV[]>;

  colors?: ProductColorJSON[];

  imgs: Optional<{ [imageType: string]: string }[]>;
  attrs: { [key: string]: string }[];

  source: Optional<string>;
}

export default class Product {
  //#region Members
  uuid: string;

  filters: AttributeKV[] = [];

  product_colors: ColorProvider[];

  product_attributes: AttributeKV[] = [];

  product_images: { [imageType: string]: string }[] = [];

  source: Optional<string>;
  //#endregion

  constructor(json: ProductJSON) {
    this.uuid = json.uuid as string;
    this.filters = json.filters || [];
    this.source = json.source;

    this.product_colors = (json.colors || []).map((color: ProductColorJSON) => new ProductColor(color));
    this.product_images = json.imgs || [];
    this.product_attributes = attrsFromAppDataJSON(json.attrs || []);
  }
  //#region Static Methods

  static fromAppDataJSON(productJSON: ProductJSON) {
    return new Product(productJSON);
  }
  //#endregion

  toAppDataJSON(): ProductJSON {
    return {
      source: this.source,
      uuid: this.uuid,
      filters: this.filters,
      attrs: this.product_attributes.map((attr: AttributeKV) => ({
        [attr.kt]: attr.vt,
      })),

      imgs: this.product_images,

      colors: (this.product_colors as ProductColor[])?.map(c => c.toAppDataJSON()),
    };
  }
  attrValForKey(attrKey: string): Optional<string> {
    const targetKey = attrKey.toLowerCase();
    const attributes = this.product_attributes;

    const attr = attributes.find(a => a.k.toLowerCase() === targetKey);

    if (attr) {
      if (attr.vt) {
        return attr.vt;
      }
      return attr.v;
    }

    return undefined;
  }

  images(): string[] {
    return this.product_images.map(imgDict => Object.keys(imgDict).map(key => imgDict[key])[0]);
  }

  colors(): ColorProvider[] {
    return this.product_colors.map(c => c);
  }

  //Get VendorPortal style composition details
  get composition_details(): ProductCompositionDetail[] {
    if (!this.product_colors || this.product_colors.length === 0) {
      return [];
    }
    return (this.product_colors as ProductColor[]).map(pc => {
      //convert pc to cd
      //CD expects this json:
      const lab = pc.labColor(Illuminant.D50, Observer.TWO_DEGREE);
      const { hex, mcs, comp } = pc;
      return new ProductCompositionDetail({
        adjusted_lab: lab.toAppDataJSON(),
        hex,
        measured_colors: mcs && mcs.length > 0 ? mcs.map(mc => mc.toAppDataJSON()) : [],
        percent_composition: comp,
        rgb: lab.toRGB().toInt(),
      });
    });
  }
}
