import _ from 'lodash';
import type { ThemeColorType, ThemeDetail, ThemeImageType } from './cas/models';
import lightColorProvider from './lightThemeColorProvider';
import darkColorProvider from './darkThemeColorProvider';

function defaultThemeLinks() {
  return {
    about_url: 'http://www.variableinc.com/about.html',
    customer_service_email: 'customerservice@variableinc.com',
    reset_device_video: 'https://youtu.be/0rUFgzc4KyMY',
    support: 'https://www.variableinc.com/product-support.html',
    terms_of_use: 'https://www.variableinc.com/terms-of-use.html',
  };
}

function defaultImage(imageType: ThemeImageType) {
  switch (imageType) {
    case 'banner':
      return 'https://s3.amazonaws.com/colorcloud.io/cas/themes/variable/banner.png';

    case 'splash':
      return 'https://s3.amazonaws.com/colorcloud.io/cas/themes/variable/splash.png';

    default:
      return 'https://s3.amazonaws.com/colorcloud.io/cas/themes/variable/splash.png';
  }
}
function placeholderTheme(): ThemeDetail {
  return {
    app_name: 'Default',
    customer_name: '',
    brand_name: '',
    brand_id: '',
    colors: {},
    id: '',
    images: {
      banner: {
        png: 'https://s3.amazonaws.com/colorcloud.io/cas/themes/variable/banner.png',
      },
      loading: {
        png: 'https://s3.amazonaws.com/colorcloud.io/cas/themes/variable/banner.png',
      },
      splash: {
        png: 'https://s3.amazonaws.com/colorcloud.io/cas/themes/variable/splash.png',
      },
    },
    links: defaultThemeLinks(),
  };
}

function placeholderThemeForBrand(brand: { id: string }): ThemeDetail {
  const theme = placeholderTheme();
  theme.id = brand.id;
  theme.brand_id = brand.id;

  return theme;
}

function createThemeColors(themeColorType: ThemeColorType) {
  switch (themeColorType) {
    default:
      return {};

    case 'light':
      return _.cloneDeep(lightColorProvider);

    case 'dark':
      return _.cloneDeep(darkColorProvider);
  }
}

export default {
  createThemeColors,
  defaultImage,
  defaultThemeLinks,
  placeholderTheme,
  placeholderThemeForBrand,
};
