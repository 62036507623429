import * as React from 'react';

import { useScroll } from '@components/common';
import appdata from '@api/appdata';
import { Palette, PaletteItem } from '@api/appdata/model';
import { useParams } from 'react-router';

export default function usePaletteItems(palette: Palette) {
  const pID = useParams<{ paletteID?: string }>()?.paletteID || palette?.uuid;
  const [paletteItems, setPaletteItems] = React.useState<PaletteItem[]>([]);

  const loadPaletteItems = React.useCallback(
    (_prevPage: number, _nextPage: number, _itemsPerPage: number) => {
      appdata
        .searchPaletteItems({
          limit: 100,
          query: { isDeleted: false, paletteID: pID },
          skip: paletteItems.length,
          sort: ['updatedAt:-1'],
        })
        .then(({ items }) => setPaletteItems(paletteItems.concat(items)));
    },
    [setPaletteItems, pID, paletteItems],
  );

  const scroll = useScroll(palette.paletteItemCount, 100, loadPaletteItems);

  React.useEffect(() => {
    appdata
      .searchPaletteItems({
        limit: 100,
        query: { isDeleted: false, paletteID: pID },
        skip: 0,
        sort: ['updatedAt:-1'],
      })
      .then(({ items }) => setPaletteItems(items));
  }, [setPaletteItems, pID, palette.paletteItemCount]);

  return {
    paletteItems,
    scroll,
  };
}
