import type { PackageDetail, Platform } from './cas/models';
import User from './cas/user';

const platformIDS = [
  '97f16d960ad',
  'bae29552fea',
  '846wGYByb',
  'zfde2939k5e',
  'zDSLVNRwb',
  '6d4c7503fed',
  '_hBEU5kwb',
  'exe808a5dak',
];

export default {
  defaultCampaign(p: PackageDetail) {
    const campaign = p.campaigns.find(c => c.id === `BASIC-${p.id}`);
    if (campaign) {
      return campaign;
    }

    if (!campaign) {
      if (p.campaigns.length > 0) {
        return p.campaigns[0];
      }
    }

    return undefined;
  },
  search(pgs: Array<any>, term: string) {
    if (term === null || term === '') {
      return pgs;
    }

    return pgs.filter(x => {
      return x?.name.includes(term) || x?.id.includes(term);
    });
  },

  getProductPreviewExtra(p: PackageDetail): {
    header: string;
    secondary: string;
    line_3: string;
  } {
    const { extras } = p;

    return (
      extras || {
        header: '',
        line_3: '',
        secondary: '',
      }
    );
  },

  filterPlatforms(_package: PackageDetail, user: User): Platform[] {
    if (user.is_system_admin) {
      return _package.platforms.filter(p => platformIDS.includes(p.id));
    }

    const {
      session: { plan_options },
    } = user;
    if (!plan_options) {
      return [];
    }

    const { platforms } = plan_options;
    return _package.platforms.filter(p => platforms.includes(p.id));
  },
};
