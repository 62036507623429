import config from '../../config';
import type { Editable, EditableResponse, EditableSearchRequest, EditableType } from './models';

import api from '../fetch2';

interface FilterItemCreationRequest {
  readonly item: {
    type: EditableType;
    name: string;
    items: { name: string }[];
  };

  readonly search: EditableSearchRequest;
}
export default {
  async createItem(req: FilterItemCreationRequest) {
    return api.post(config.server.add_editable, req);
  },

  async deleteItem(req: { item: Editable; search: EditableSearchRequest }) {
    return api.post(config.server.remove_editables_value, req);
  },

  async getEditables(req: EditableSearchRequest): Promise<EditableResponse> {
    return api.post(config.server.editables, req);
  },

  async updateEditableKey(req: { search: EditableSearchRequest; item: Editable; key: string }) {
    return api.post(config.server.editable_key, req);
  },

  async updateEditableValue(req: { search: EditableSearchRequest; item: Editable; value: string }) {
    return api.post(config.server.editables_value, req);
  },
};
