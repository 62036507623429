import * as React from 'react';
import styled from 'styled-components';
import { FlexColumns, FlexRows, LabeledInput, PrimaryText } from '@components/common';
import theme from '@theme';
import { useEnterKeyPress } from '@components/common/Input';

type ViewProps = {
  onAddItem: (name: string, val: string) => void | Promise<any>;
};

const Wrapper = styled(FlexColumns)`
  flex-wrap: nowrap;
`;

const AddButton = styled.div`
  height: ${theme.dimensions.input.height};
  width: ${theme.dimensions.input.height};
  background-color: ${theme.button.actionColor};
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;

  font-size: 18px;
  :hover {
    cursor: pointer;
  }

  :before {
    content: '+';
  }
`;

function validateInput(name: string, value: string, showAlerts: boolean = false) {
  console.log(name, value);
  if (value.length === 0) {
    if (showAlerts) {
      alert('Unable to create item. Reason: missing a value');
    }
    return false;
  }

  if (name.length === 0) {
    if (showAlerts) {
      alert('Unable to create item. Reason: missing a name');
    }
    return false;
  }

  return true;
}

function useFilterItemCreator(onAddItem: (x: string, y: string) => any) {
  const [name, setName] = React.useState<string>('');
  const [value, setValue] = React.useState<string>('');
  const onValidate = React.useCallback(() => {
    if (validateInput(name, value, true)) {
      onAddItem(name, value);

      setName('');
      setValue('');
    }
  }, [value, name, setName, setValue, onAddItem]);

  return {
    name,
    value,
    onKeyPress: useEnterKeyPress(onValidate),
    createItem: onValidate,
    onNameChange: ({ currentTarget: { value: v } }: React.SyntheticEvent<HTMLInputElement>) => {
      setName(v);
    },
    onValueChange: ({ currentTarget: { value: v } }: React.SyntheticEvent<HTMLInputElement>) => {
      setValue(v);
    },
  };
}

export default React.memo<ViewProps>(({ onAddItem }: ViewProps) => {
  const { name, value, onKeyPress, onNameChange, onValueChange, createItem } = useFilterItemCreator(onAddItem);

  return (
    <FlexRows>
      <FlexColumns alignItems="center" style={{ marginTop: 8 }}>
        <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
        <PrimaryText style={{ marginLeft: 8, marginRight: 8 }}>CREATE ITEM</PrimaryText>
        <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
      </FlexColumns>

      <Wrapper>
        <LabeledInput
          label="Name"
          containerStyle={{ flex: 1 }}
          onChange={onNameChange}
          inputProps={{
            onKeyPress,
            value: name,
          }}
        />
        <LabeledInput
          label="Value"
          onChange={onValueChange}
          inputProps={{
            onKeyPress,
            value,
          }}
          containerStyle={{ flex: 1, marginLeft: 3 }}
        />

        <AddButton onClick={createItem} />
      </Wrapper>
    </FlexRows>
  );
});
