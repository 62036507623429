import React from 'react';

import { Product, ProductAttribute as Attribute } from '@api/vpapi/models';
import ProductAttribute from './ProductAttribute';
import ModuleSegment from '@components/common/ModuleSegment';
import { AlternateText } from '@components/common';
import DnDReorderable from '@components/common/DnDReorderable';
import ItemCreator from '../filters/FilterItemCreator';

type Props = {
  product: Product;
  onProductChange: (p: Product) => void;
};
class ProductAttributeContainer extends React.Component<Props> {
  state = {};

  updateAttrIdx = (movedID: string, aboveDestinationID: string) => {
    const { product, onProductChange } = this.props;

    product.moveAttr(movedID, aboveDestinationID);
    onProductChange(product);
    this.forceUpdate();
  };

  toggleDisplayable = (attr: Attribute) => {
    attr.displayable = !attr.displayable;

    const { onProductChange, product } = this.props;
    onProductChange(product);
    this.forceUpdate();
  };

  addItem = (name: string, value: string) => {
    const { product, onProductChange } = this.props;
    product.addAttr(name, value, false);

    onProductChange(product);
  };

  render() {
    const { product, onProductChange } = this.props;

    return (
      <ModuleSegment
        containerStyle={{ backgroundColor: 'transparent' }}
        bodyStyle={{ marginTop: 8 }}
        title={ModuleSegment.ModuleTitle('Attributes', 'Learn More')}
        helpModal={HelpModal()}
      >
        {product.product_attributes.map(attr => (
          <DnDReorderable key={attr.id} currentID={attr.id} onReorder={this.updateAttrIdx}>
            <ProductAttribute
              attr={attr}
              onProductChange={() => onProductChange(product)}
              toggleDisplayable={this.toggleDisplayable}
            />
          </DnDReorderable>
        ))}

        <ItemCreator onAddItem={this.addItem} />
      </ModuleSegment>
    );
  }
}

const HelpModal = () => ({
  Content: () => (
    <AlternateText>
      Attributes are descriptors for each product that provide details on that product. They are elements of your
      product’s unique description. Attributes can convey any type of product information from color and product
      category to measurements or technical specifications.
    </AlternateText>
  ),
  title: 'About Product Attributes',
});

export { ProductAttributeContainer };
