import React from 'react';
import { ModalProps, Modal as SemanticModal } from 'semantic-ui-react';

type ViewProps = {
  modalRef?: React.LegacyRef<React.Component<ModalProps, any, any>>;
  modalProps: ModalProps;
  children: React.ReactNode;
};
export default function Modal({ modalRef, modalProps, children }: ViewProps) {
  return (
    <SemanticModal ref={modalRef} closeOnEscape closeIcon {...modalProps}>
      <div>{children}</div>
    </SemanticModal>
  );
}
