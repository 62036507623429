import React from 'react';
import _ from 'lodash';
import { Button, Checkbox, LabeledInput, Modal, ModuleSegment } from '@components/common';
import { Feature } from '@api/cas/models';
import theme from '@app/theme';

type FeatureListItemProps<T> = {
  handleFeatureChange(f: Feature<T>): void | any;
  handleAdd(f: Feature<T>): void | any;
  handleRemove(f: Feature<T>): void | any;
  feature: Feature<T>;
  userFeature?: Feature<T>;
};

export default function FeatureCheckBox<T>({
  handleAdd,
  handleRemove,
  handleFeatureChange,
  feature,
  userFeature,
}: FeatureListItemProps<T>) {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <Modal modalProps={{ size: 'large', open: showModal, onClose: () => setShowModal(false) }}>
        <ModuleSegment
          containerStyle={{ padding: '3%' }}
          title={ModuleSegment.ModuleTitle(
            `Configure '${feature.name}' Feature`,
            'When no date is specified, user is granted a lifetime subscription',
          )}
        >
          <LabeledInput
            inputProps={{ type: 'date', defaultValue: (userFeature?.options as any)?.expires_at }}
            id="expires_at"
            label="Expires At"
            onChange={e => {
              if (feature) {
                (feature as any).options = {
                  ...(feature ?? {}).options,
                  ...(userFeature ?? {}).options,
                  expires_at: e.currentTarget.value,
                };
              }
            }}
          />

          <Button
            style={{ float: 'right' }}
            color={theme.button.actionColor}
            text="Save"
            onClick={() => {
              handleAdd(_.cloneDeep(feature));
              setShowModal(false);
            }}
          />
          <Button
            style={{ float: 'right' }}
            outline
            color={theme.button.cancelColor}
            text="Remove Feature"
            onClick={() => {
              handleRemove(feature);
              setShowModal(false);
            }}
          />
        </ModuleSegment>
      </Modal>

      <Checkbox
        onCheckChange={() => {
          if (!(feature.options as any)?.is_purchasable) {
            handleFeatureChange(_.cloneDeep(feature));
            return;
          }

          setShowModal(true);
        }}
        checked={userFeature !== undefined}
        text={feature.name}
        containerStyle={{ marginRight: 16 }}
      />
    </>
  );
}
