import type { FormulationProduct } from './models';

export default {
  products: {
    findAttribute(product: FormulationProduct, k: string) {
      const key = k.toLowerCase();
      return product.attributes.find(x => x.k.toLowerCase() === key);
    },

    findValueOrDefault(product: FormulationProduct, k: string, defaultValue?: string) {
      const key = k.toLowerCase();
      const item = product.attributes.find(x => x.k.toLowerCase() === key);
      if (!item) {
        return defaultValue;
      }

      return item.v;
    },
  },
};
