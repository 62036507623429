import React from 'react';
import styled from 'styled-components';

import { Pager } from '@utils';
import { PaletteSearchResponse } from '@api/appdata';
import { Palette } from '@api/appdata/model';
import PaletteListCell, { PaletteListCellHandlers } from './PaletteListCell';
import ScanHistoryCell from './scanHistory/ScanHistoryCell';
import { Route } from 'react-router';

type Props = {
  palettes: PaletteSearchResponse;
  selectedPalette?: Palette;

  loadPalettes?: (skip: number) => Promise<void>;
} & PaletteListCellHandlers;

type ViewState = {};
export default class PaletteList extends React.Component<Props, ViewState> {
  iScroll: { current: null | HTMLDivElement } = React.createRef();

  pager = new Pager({
    numItems: this.props.palettes.count,
    perPage: 20,
    windowSize: 5,
    page: 1,
  });

  get listItems(): Palette[] {
    const { palettes: data } = this.props;

    return data.palettes;
  }

  componentDidMount = () => {
    // Binds our scroll event handler
    if (this.iScroll.current) {
      this.iScroll.current.addEventListener('scroll', this.componentDidScroll);
    }

    console.log('pager numitems', this.pager.numItems);
  };

  componentDidScroll = async () => {
    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    if (!this.pager.hasMore) {
      return;
    }

    const {
      iScroll: { current: iScroll },
    } = this;
    if (!iScroll) {
      return;
    }

    // Checks that the page has scrolled to the bottom
    if (iScroll.scrollTop + iScroll.clientHeight >= iScroll.scrollHeight * 0.99) {
      this.fetchData();
    }
  };

  fetchData = async () => {
    try {
      const { palettes: data } = this.props;
      if (this.props.loadPalettes) {
        await this.props.loadPalettes(data.palettes.length);

        this.pager.nextPage();
      }
    } catch (err) {
      console.log(err);
    }
  };

  render = () => {
    const { selectedPalette } = this.props;
    return (
      <ScrollView ref={this.iScroll}>
        <Route path={['/saved-colors', '/scans']}>
          <ScanHistoryCell />
        </Route>

        {this.listItems.map(p => (
          <PaletteListCell
            {...this.props}
            palette={p}
            isSelected={!!selectedPalette && p.uuid === selectedPalette.uuid}
            key={p.uuid}
          />
        ))}
      </ScrollView>
    );
  };
}

const ScrollView = styled.div`
  height: 80vh;
  margin-bottom: 12px;
  margin-top: 12px;

  overflow-y: scroll;
  overflow-x: hidden;

  width: 100%;

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
