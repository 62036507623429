import Pager from './Pager';
import imageUtils from './image-utils';
import routeUtils from './route-utils';

function uuid(a?: any) {
  return a ? (0 | (Math.random() * 16)).toString(16) : `${1e7}${-1e3}${-4e3}${-8e3}${-1e11}`.replace(/1|0/g, uuid);
}

const deviceUUID = () => {
  if (!sessionStorage.getItem('device_uuid')) {
    sessionStorage.setItem('device_uuid', uuid());
  }

  return sessionStorage.getItem('device_uuid');
};

function round(val: number, figures = 2) {
  return Math.round(val * 10 ** figures) / 10.0 ** figures;
}

const dimensions = () => {
  if (window.innerWidth < 500) {
    return { height: 102, width: 450 };
  }
  const update_width = window.innerWidth - 100;
  const update_height = Math.round(update_width / 4.4);
  return { height: update_height, width: update_width };
};

function downloadContent(filename: string, text: string, mime = 'text/plain', charset = 'utf-8') {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${mime};charset=${charset},${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';

  const { body } = document;
  if (body) {
    body.appendChild(element);

    element.click();

    body.removeChild(element);
  }
}

export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export { downloadContent, imageUtils, round, dimensions, uuid, deviceUUID, Pager, routeUtils };
