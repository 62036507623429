import React from 'react';
import { DropdownProps, PrimaryText } from '@components/common';
import moment from 'moment';
import type { PaidPlanJSON } from '@api/cas/models';
import { Checkbox, Dropdown, FlexColumns, FlexRows, InputLabel, LabeledInput } from '../../common';
import { Optional } from '@app/utils/types';
import { OnInputChangeFunc } from '@app/components/common/Input/Input';
import theme from '@app/theme';

type ViewProps = {
  plan?: { subscription: PaidPlanJSON };
  onValueChanged: OnInputChangeFunc;
  onPlanChange: (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void | any;
  onFeatureCheckChange: (
    x: 'bulk_edit' | 'vizualizations' | 'package_codes',
  ) => (e: React.SyntheticEvent<HTMLElement>, isChecked: boolean) => void | any;
  onAccessControlCheckChange: (
    t: 'organizations' | 'product_groups',
  ) => (e: React.SyntheticEvent<HTMLElement>, isChecked: boolean) => void | any;
};

const planOptions = [
  { text: 'Variable Cloud Free', value: 'VC-FREE' },
  { text: 'Variable Cloud Basic', value: 'VC-BASIC' },
  { text: 'Variable Cloud Business', value: 'VC-BUSINESS' },
  { text: 'Variable Cloud Enterprise', value: 'VC-ENTERPRISE' },
];

function formatDate(date?: string): Optional<string> {
  return date ? moment(date).format('YYYY-MM-DD') : undefined;
}

export default function ({
  plan,
  onValueChanged,
  onPlanChange,
  onFeatureCheckChange,
  onAccessControlCheckChange,
}: ViewProps) {
  const sub = plan?.subscription;
  return (
    <FlexRows>
      <FlexColumns style={{ alignContent: 'center' }}>
        <InputLabel labelStyle={{ width: 250 }} containerStyle={{ flexGrow: 0 }} label="Select Plan" />
        <Dropdown
          style={{ flexGrow: 1 }}
          selection
          value={sub?.plan_id}
          options={planOptions}
          onChange={onPlanChange}
        />
      </FlexColumns>

      <LabeledInput
        inputProps={{
          type: 'date',
          value: sub ? formatDate(sub?.effective_til) : undefined,
        }}
        id="effective_til"
        label="Effective Til"
        onChange={onValueChanged}
      />

      {sub?.options && (
        <div>
          <FlexColumns alignItems="center" style={{ marginTop: 8 }}>
            <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
            <PrimaryText style={{ marginLeft: 8, marginRight: 8 }}> Plan Options (User Overrides)</PrimaryText>
            <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
          </FlexColumns>

          <LabeledInput
            inputProps={{
              placeholder: 'no additional libraries specified. (use , between multiple product library ids)',
              type: 'text',
              value: sub.options.additional_libraries.join(','),
            }}
            label="Additional Libraries"
            id="additional_libraries"
            onChange={onValueChanged}
          />

          <LabeledInput
            inputProps={{
              placeholder: 'no color formulation libraries. (use , between multiple product library ids.)',
              type: 'text',
              value: sub.options.features.formulations.join(','),
            }}
            label="Color Formulations"
            id="formulations"
            onChange={onValueChanged}
          />

          <LabeledInput
            inputProps={{
              type: 'number',
              value: sub.options.max_products,
            }}
            label="Max Products"
            id="max_products"
            onChange={onValueChanged}
          />
          <LabeledInput
            inputProps={{
              type: 'number',
              value: sub.options.max_organizations,
            }}
            label="Max Organizations"
            id="max_organizations"
            onChange={onValueChanged}
          />
          <LabeledInput
            inputProps={{
              type: 'number',
              value: sub.options.max_distinct_users_subscriptions,
            }}
            label="Max Seats"
            id="max_distinct_users_subscriptions"
            onChange={onValueChanged}
          />

          <div style={{ margin: 24 }} />

          <FlexColumns>
            <Checkbox
              onCheckChange={onAccessControlCheckChange('organizations')}
              checked={sub.options.access_control.organizations}
              text="Enable Organizational Sharing"
            />

            <div style={{ margin: 12 }} />

            <Checkbox
              onCheckChange={onAccessControlCheckChange('product_groups')}
              checked={sub.options.access_control.product_groups}
              text="Enable Product Library Sharing"
            />

            <div style={{ margin: 12 }} />

            <Checkbox
              onCheckChange={onFeatureCheckChange('bulk_edit')}
              checked={sub.options.features.bulk_edit}
              text="Enable Bulk Editing"
            />

            <div style={{ margin: 12 }} />

            <Checkbox
              onCheckChange={onFeatureCheckChange('vizualizations')}
              checked={sub.options.features.vizualizations}
              text="Enable Color Vizualizations (3D Graphs)"
            />

            <div style={{ margin: 12 }} />

            <Checkbox
              onCheckChange={onFeatureCheckChange('package_codes')}
              checked={sub.options.features.package_codes}
              text="Enable App Package Codes"
            />
          </FlexColumns>
        </div>
      )}
    </FlexRows>
  );
}
