import * as React from 'react';
import styled from 'styled-components';
interface InlineIconProps {
  readonly circle?: {
    color: string;
  };

  hoverBackgroundColor?: string;
}
const InlineIcon = styled.div<InlineIconProps>`
  height: 32px;
  width: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ circle }) => {
    if (circle) {
      return `
      border-radius: 50%;
      border-style: solid;
      border-color: ${circle.color};
      border-width: 2px;
    `;
    }

    return null;
  }}

  :hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};

    ${({ circle }) => (circle ? ` border: none; background-color: ${circle.color}` : null)};
  }}
`;

const I = styled.img`
height: 75%;
width: 75%;

object - fit: contain;
`;

type BaseIconProps = {
  circle?: {
    color: string;
  };
  hoverBackgroundColor?: string;
  onClick?: (e: React.SyntheticEvent<HTMLElement>) => any | void;
  alt: string;
};

function Icon({ hoverBackgroundColor, onClick, circle, alt, src }: BaseIconProps & { src: string }) {
  return (
    <InlineIcon hoverBackgroundColor={hoverBackgroundColor} circle={circle} onClick={onClick}>
      <I draggable={false} src={src} alt={alt} />
    </InlineIcon>
  );
}

function AppVisibilityIcon(props: BaseIconProps) {
  return <Icon {...props} src={'/icons/app_visible.png'} />;
}

export { AppVisibilityIcon, Icon };
