import { AnyLabJSON, Illuminant, IlluminantT, LabColor, Observer } from '@variablecolor/colormath';

import AppDataProduct from './Product';
import ADBase, { BaseModelJSON } from './ADBase';
import Scan from './Scan';
import type { ColorProvider } from './types';

import { MeasuredColor, ProductAttribute, ProductCompositionDetail } from '../../vpapi/models';
import { Optional } from '@app/utils/types';
import { uuid } from '@app/utils';
import _ from 'lodash';
import { ChromaReadingJSON, SpectroReadingJSON } from './color/ColorReading';
import { ProductJSON } from './Product/Product';

export interface PaletteItemJSON extends BaseModelJSON {
  name: string;
  notes: string;

  paletteID: string;

  image?: { url: string };
  product?: ProductJSON;
  spectrumReading?: SpectroReadingJSON;
  chromaReading?: ChromaReadingJSON;
  webColor?: AnyLabJSON;

  displayOrder: number;

  sub_model_type: string;
}

export default class PaletteItem extends ADBase {
  name: string;
  notes: string;

  paletteID: string;

  displayOrder: number;

  product: Optional<AppDataProduct>;

  image: Optional<{ url: string }>;

  scan: Optional<Scan>;

  webColorLab: Optional<LabColor>;

  subModelType: string;

  modelType: string;

  toAppDataJSON: () => PaletteItemJSON;
  //constructor takes data from DB JSON (basically a copy from CC server json) and moves it to our SDK / App format
  constructor(json: PaletteItemJSON) {
    super(json);

    this.name = json.name || '';
    this.notes = json.notes;
    this.paletteID = json.paletteID;
    this.displayOrder = json.displayOrder;
    this.image = json.image;
    this.modelType = 'palette_item';
    this.subModelType = 'unknown';
    if (json.sub_model_type) {
      this.subModelType = json.sub_model_type;
      if (this.subModelType === 'spectrum') {
        this.subModelType = 'Spectro Scan';
      }
      if (this.subModelType === 'chromaReading') {
        this.subModelType = 'Muse Scan';
      }
    }
    if (json.product) {
      this.product = AppDataProduct.fromAppDataJSON(json.product);
    }

    if (json.chromaReading || json.spectrumReading) {
      this.scan = new Scan((json.chromaReading || json.spectrumReading)!);
    }

    if (json.webColor) {
      this.webColorLab = LabColor.fromJSON(json.webColor);
    }

    this.toAppDataJSON = this.toServerJSON;
  }

  copy = () => {
    const json = this.toServerJSON();

    json._id = uuid();
    json.locallyCreatedAt = new Date().toISOString();
    json.locallyUpdatedAt = json.locallyCreatedAt;

    return new PaletteItem(json);
  };

  get hex_colors(): string[] {
    return this.colors().map(x => x.labColor(Illuminant.D50, Observer.TWO_DEGREE).hex());
  }

  // hex = (illuminant: IlluminantT = Illuminant.D50, observer: ObserverT = Observer.TWO_DEGREE) => {
  //   const colors = this.colors();
  //   if (colors.length > 0){
  //     return colors[0].labColor(illuminant, observer).hex();
  //   }
  //   return '#ffffff';
  // };

  toServerJSON(): PaletteItemJSON {
    //convert to a json for saving to DB (for upsync to server) later
    const json = {
      ...this.baseToServerJSON(),
      displayOrder: this.displayOrder,
      image: this.image,
      name: this.name,
      notes: this.notes,
      paletteID: this.paletteID,
      product: this.product?.toAppDataJSON(),
      webColor: this.webColorLab?.toAppDataJSON(),

      model_type: this.modelType,
      sub_model_type: this.subModelType,

      spectrumReading: undefined as Optional<SpectroReadingJSON>,
      chromaReading: undefined as Optional<ChromaReadingJSON>,
    };
    if (this.scan) {
      if (this.scan.isSpectro()) {
        json.spectrumReading = this.scan.toServerJSON() as SpectroReadingJSON;
      } else {
        json.chromaReading = this.scan.toServerJSON() as ChromaReadingJSON;
      }
    }

    return json;
  }

  titleString = (): string => {
    if (this.name) {
      return this.name;
    }
    if (this.product) {
      return this.product.attrValForKey('name') || '';
    }

    if (this.image) {
      return 'image';
    }

    if (this.scan) {
      if (this.scan.isSpectro()) {
        return 'Spectro Scan';
      }
      return 'Muse Scan';
    }
    if (this.webColorLab) {
      return 'web color';
    }
    return 'Unknown type';
  };

  get image_url() {
    const images = this.images();
    if (images.length > 0) {
      return images[0];
    }

    return undefined;
  }

  images(): string[] {
    if (this.product) {
      return this.product.images();
    }

    if (this.image) {
      if (this.image.url) {
        return [this.image.url];
      }
    }

    return [];
  }

  colors(): ColorProvider[] {
    if (this.product) {
      return this.product.colors();
    }

    if (this.scan) {
      return this.scan.colors();
    }

    if (this.webColorLab) {
      const { webColorLab } = this;
      return [
        {
          labColor: (ill: IlluminantT /**, obs: ObserverT**/) => webColorLab.toLabColor(ill),
          spectrum: undefined,
        },
      ];
    }

    return [];
  }

  //get composition details (vendor portal product property):
  //currently used just for Saved Colors CompositionDetails (don't want colors editable)
  get composition_details() {
    if (this.product) {
      const { composition_details } = this.product;
      composition_details.forEach((_1, i) => {
        composition_details[i].created_at = this.locallyCreatedAt;
        composition_details[i].updated_at = this.locallyUpdatedAt;
      });
      return composition_details;
    }
    if (this.scan) {
      return [
        new ProductCompositionDetail({
          adjusted_lab: this.scan.labColor().toAppDataJSON(),
          created_at: this.locallyCreatedAt,
          hex: this.scan.hex(),
          measured_colors: [MeasuredColor.fromColorReading(this.scan).toAppDataJSON()],
          percent_composition: 1,
          updated_at: this.locallyUpdatedAt,
        }),
      ];
    }
    if (this.webColorLab) {
      const { webColorLab } = this;
      return [
        new ProductCompositionDetail({
          adjusted_lab: this.webColorLab.toAppDataJSON(),
          created_at: this.locallyCreatedAt,
          hex: webColorLab.hex(),
          measured_colors: [],
          percent_composition: 1,
          rgb: webColorLab.toRGB().toInt(),
          updated_at: this.locallyUpdatedAt,
        }),
      ];
    }
    return [];
  }

  //get product_attributes (vendor portal model format)
  get product_attributes(): ProductAttribute[] {
    if (!this.product) {
      return [];
    }

    return this.product.product_attributes.map(attr => ({
      id: _.uniqueId('attr_'),
      attr_key: attr.kt,
      attr_value: attr.vt,
      displayable: true,
    }));
  }
}
