import * as React from 'react';
import styled from 'styled-components';
import { FlexColumns } from '@components/common/common';
import { PrimaryText } from '../Text';
import theme from '@theme';
import { Optional } from '@app/utils/types';

type RectangleButtonProps = {
  show: boolean;
  text: string;
  textStyle?: React.CSSProperties;
  containerStyle: React.CSSProperties;
  handleClick: () => void;
  hoverBackgroundColor?: string;
  backgroundColor?: string;
};

const RectangleButtonContainer = styled(FlexColumns)<{
  backgroundColor?: string;
  height?: string;
  hoverBackgroundColor?: string;
}>`
  background-color: ${({ backgroundColor = theme.table.rowAltBackground }) => backgroundColor};
  padding: 12px;
  justify-content: center;

  height: ${({ height }) => height};
  :hover {
    cursor: pointer;
    background-color: ${({ hoverBackgroundColor = theme.table.hoverBackground }) => hoverBackgroundColor};
  }
`;

const RectangleButton = ({
  hoverBackgroundColor,
  backgroundColor,
  show,
  text,
  handleClick,
  textStyle,
  containerStyle,
}: RectangleButtonProps) => {
  if (!show) {
    return null;
  }
  return (
    <RectangleButtonContainer
      hoverBackgroundColor={hoverBackgroundColor}
      backgroundColor={backgroundColor}
      onClick={handleClick}
      style={containerStyle}
      height={containerStyle?.height as Optional<string>}
    >
      <PrimaryText style={textStyle}>{text}</PrimaryText>
    </RectangleButtonContainer>
  );
};

export default RectangleButton;
