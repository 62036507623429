const colorDefs = {
  almostBlack: '#161616',
  almostWhite: '#CCCCCC',
  darkGrey: '#232323',
  gold: '#F4B223',
  grey: '#343434',

  lightGrey: '#909090',
  white: '#FFFFFF',
};

const darkColorProvider = {
  availableContent: {
    background: colorDefs.darkGrey,
    border: colorDefs.white,
    content: colorDefs.white,
    contentImportant: colorDefs.gold,
    contentSubtle: colorDefs.lightGrey,
  },
  get card() {
    return {
      ...this.primary,
      background: colorDefs.grey,
      border: colorDefs.almostBlack,
    };
  },

  get darkBackground() {
    return this.primary;
  },

  get disabledContent() {
    return {
      ...this.availableContent,
      background: colorDefs.almostBlack,
      content: colorDefs.grey,
    };
  },

  lightBackground: {
    background: colorDefs.white,
    border: colorDefs.darkGrey,
    content: colorDefs.almostBlack,
    contentImportant: colorDefs.gold,
    contentSubtle: colorDefs.grey,
  },

  get navigation() {
    return {
      ...this.primary,
      background: colorDefs.darkGrey,
    };
  },

  primary: {
    background: colorDefs.almostBlack,
    border: colorDefs.darkGrey,
    content: colorDefs.white,
    contentImportant: colorDefs.gold,
    contentSubtle: colorDefs.lightGrey,
  },

  // used for buttons / items that can be toggled
  primaryButton: {
    background: colorDefs.white,
    border: colorDefs.grey,
    content: colorDefs.almostBlack,
    contentImportant: colorDefs.gold,
    contentSubtle: colorDefs.grey,
  },

  // used for buttons /items that are selected / active
  get primaryInput() {
    return this.primaryButton;
  },

  secondaryButton: {
    background: colorDefs.darkGrey,
    border: colorDefs.darkGrey,
    content: colorDefs.white,
    contentImportant: colorDefs.gold,
    contentSubtle: colorDefs.almostWhite,
  },

  // for elements that MUST be displayed using a light BG
  selectedContent: {
    background: colorDefs.white,
    border: colorDefs.white,
    content: colorDefs.almostBlack,
    contentImportant: colorDefs.gold,
    contentSubtle: colorDefs.grey,
  },

  // for elements that MUST be displayed using a dark BG
  type: 'dark',
};

export default darkColorProvider;
