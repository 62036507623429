import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import theme from '../../../../theme';
import type { ColorFormula } from '../../../../api/formulation/models';

import { AlternateText, FlexRows, PrimaryText } from '../../../common';

const FormulaWrapper = styled.div`
  background-color: ${theme.card.alternateBackground};
  display: flex;
  flex-direction: column
  
  margin: 16px;
  margin-left: 0px;
  margin-top: 0px;

  min-height: 128px;
  max-height: 128px;
  min-width: 128px;

  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);

  :hover {
    cursor: pointer;
    background-color: ${theme.table.hoverBackground};
  }
`;

const ColorPreview = styled.div<{ hex: string }>`
  width: 100%;
  background-color: ${({ hex }) => hex};

  height: 128px;
`;

const styles = {
  singleLine: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  } as CSSProperties,
};

function FormulaPreview({ f }: { f: ColorFormula }) {
  const name = f.attributes.find(a => a.k === 'name');
  const collection = f.attributes.find(a => a.k === 'collection');
  return (
    <FlexRows style={{ padding: 8 }}>
      <PrimaryText textAlign="center">{name?.v || 'Attribute Missing'}</PrimaryText>
      <AlternateText textAlign="center" style={styles.singleLine}>
        {collection?.v || 'Attribute Missing'}
      </AlternateText>
    </FlexRows>
  );
}

//#region Types
type ColorFormulaPreviewProps = {
  formula: ColorFormula;
  onClick: (formula: ColorFormula) => () => void | any;
};
////#endregion
const components = {
  ColorFormulaPreview({ formula, onClick }: ColorFormulaPreviewProps) {
    return (
      <FormulaWrapper onClick={onClick(formula)}>
        <ColorPreview hex={formula.hex} />
        <FormulaPreview f={formula} />
      </FormulaWrapper>
    );
  },
};

export default components;
