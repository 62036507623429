import React from 'react';
import { Popup } from 'semantic-ui-react';
import { ProductGroupHelper } from '@api/vpapi/models/ProductGroupHelper';
import { Button, FlexColumns, PrimaryText, StatusBar } from '../../../common';
import theme from '@theme';
import FilledRadio from '../../../common/FilledRadio';

type Props = {
  enabledGroupCount: number;
  disabledGroupCount: number;
  onEnableAll: () => void;
  onDisableAll: () => void;
  includeActive: boolean;
  includeInactive: boolean;
  onCheckChange: (type: 'includeActive' | 'includeInactive', checked: boolean) => void | any;
};
export default function ProductGroupActionButtons({
  disabledGroupCount,
  enabledGroupCount,
  onEnableAll,
  onDisableAll,
  includeActive,
  includeInactive,
  onCheckChange,
}: Props) {
  const handleCheckChange = (type: 'includeActive' | 'includeInactive') => (checked: boolean) => {
    onCheckChange?.(type, checked);
  };

  return (
    <div>
      <FlexColumns style={{ alignItems: 'center', marginBottom: 24, marginTop: 24 }}>
        <PrimaryText fontWeight="bold" style={{ marginRight: 6 }}>
          VIEW:
        </PrimaryText>
        <Popup
          content={
            'Active product libraries are libraries that users have access ' +
            'to within an App Package, and are visible in a Variable Cloud compatible app.'
          }
          trigger={
            <div>
              <FilledRadio
                onCheckChange={handleCheckChange('includeActive')}
                checked={includeActive}
                text="Active"
                fill={theme.table.selectedBackground}
              />
            </div>
          }
        />

        <div style={{ margin: 6 }} />

        <Popup
          content={
            'Inactive product libraries have not been added to an App Package yet, ' +
            'and are not visible in a Variable Cloud compatible app.'
          }
          trigger={
            <div>
              <FilledRadio
                onCheckChange={handleCheckChange('includeInactive')}
                checked={includeInactive}
                text="Inactive"
                fill={theme.table.rowBackground}
              />
            </div>
          }
        />

        <div style={{ flex: 1 }} />

        {disabledGroupCount > 20 && (
          <Button
            style={{ float: 'right' }}
            color={theme.button.alternativeActionColor}
            onClick={onEnableAll}
            outline
            text={`Activate ${disabledGroupCount} Product Libraries`}
            disabled={disabledGroupCount === 0}
          />
        )}

        {enabledGroupCount > 20 && (
          <Button
            style={{ float: 'right' }}
            color={theme.button.alternativeActionColor}
            onClick={onDisableAll}
            outline
            text={`Deactivate ${enabledGroupCount} Product Libraries`}
            disabled={enabledGroupCount === 0}
          />
        )}
      </FlexColumns>
    </div>
  );
}

export type SaveButtonProps = {
  onSave?: () => void;
  onReset?: () => void;
  pgHelper: ProductGroupHelper;
};
function ProductGroupSaveButtons({ onSave, onReset, pgHelper }: SaveButtonProps) {
  const { changes } = pgHelper || {};
  if (!changes) {
    return null;
  }

  const isDirty = changes.enable.length > 0 || changes.disable.length > 0;

  const enabledChangeMsg = pgHelper.pendingEnablesText();
  const disabledChangeMsg = pgHelper.pendingDisablesText();

  return (
    <StatusBar show={isDirty} handleReset={onReset} handleSave={onSave}>
      <div>{enabledChangeMsg}</div>
      <div>{disabledChangeMsg}</div>
    </StatusBar>
  );
}

export { ProductGroupSaveButtons };
