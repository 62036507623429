import * as React from 'react';
import _ from 'lodash';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import cas from '@api/cas';
import theme from '@theme';

import { CardView } from '@components/common/Containers';
import ExternalPurchase, { posFromSourceType } from './ExternalPurchase';
import { Button, HorizontalScrollView, ModuleSegment, withUser } from '@components/common';
import type { Purchase } from '@api/cas/models';
import { Optional } from '@app/utils/types';

type ExternalProps = {
  purchases: Purchase[];
};

function getActionText(selectedPurchase?: Purchase) {
  if (!selectedPurchase) {
    return 'Select a purchase to view and manage';
  }

  return 'Manage the selected purchase';
}

type SelectorProps = {
  purchases: Purchase[];

  onItemClick: (p: Purchase) => () => void;
  selectedCode?: string /** CODE */;
};

const hook = (purchases: Purchase[], setPurchase: (p: Purchase) => void) => (x: Purchase) => {
  const indexOf = purchases.findIndex(y => y.code === x.code);
  if (indexOf !== -1) {
    purchases[indexOf] = x;
  }

  setPurchase(x);
};

function ExternalPurchaseSelector({ selectedCode, purchases, onItemClick }: SelectorProps) {
  return (
    <HorizontalScrollView key={_.uniqueId('list_')}>
      {purchases.map(x => {
        let containerStyle: React.CSSProperties = { width: 350 };
        if (x.status === 'EXPIRED') {
          containerStyle = {
            ...containerStyle,
            borderColor: theme.button.cancelColor,
            borderStyle: 'solid',
            borderWidth: 1,
          };
        }
        return (
          <CardView
            key={`${x.redemptions.length}_${x.email}_${x.expires_at}`}
            containerStyle={containerStyle}
            onClick={onItemClick(x)}
            selected={selectedCode === x.code}
            lines={[
              { text: x.product_name },
              { text: `${posFromSourceType(x.source_type)}` },
              { text: '' },
              { text: `${x.status} - ${moment(x.expires_at).format('LL')}` },
            ]}
          />
        );
      })}
    </HorizontalScrollView>
  );
}

function defaultSelectedPurchase(purchases: Purchase[]) {
  if (purchases.length === 0) {
    return undefined;
  }

  if (purchases.length === 1) {
    return purchases[0];
  }

  return undefined;
}

export default withUser<ExternalProps>(({ purchases, user }) => {
  const history = useHistory();
  const location = useLocation();
  const buttons = [
    <Button
      key="purchase-finder"
      outline
      text="Find External Purchase"
      color={theme.button.neutralColor}
      onClick={() => {
        const params = qs.parse(location.search);
        params.a = 'find';
        history.push(`${location.pathname}?${qs.stringify(params)}`);
      }}
    />,
  ];

  const [selectedPurchase, setPurchase] = React.useState<Optional<Purchase>>(defaultSelectedPurchase(purchases));

  if (selectedPurchase && selectedPurchase.status !== 'EXPIRED') {
    if (user.email !== selectedPurchase.email) {
      buttons.push(
        <Button
          key="purchase-linker"
          text="Link to Variable Cloud Account"
          color={theme.button.actionColor}
          onClick={async () => {
            setPurchase({
              ...selectedPurchase,
              email: user.email,
            });

            cas.users.linkExternalPurchase(selectedPurchase.code);

            // This is not the place for this logic, it should be server-side.
            if (selectedPurchase.status !== 'EXPIRED') {
              if (selectedPurchase.redemptions.length < selectedPurchase.quantity) {
                selectedPurchase.redemptions.push({
                  created_at: moment().toISOString(),
                  email: user.email,
                  name: user.nickname,
                  roles: { is_owner: 1 },
                });
              }
            }
          }}
        />,
      );
    }
  }

  return (
    <ModuleSegment
      title={ModuleSegment.ModuleTitle('External Purchases', getActionText(selectedPurchase))}
      buttons={buttons}
    >
      <ExternalPurchaseSelector
        onItemClick={p => () => setPurchase(p)}
        selectedCode={selectedPurchase ? selectedPurchase.code : undefined}
        purchases={purchases}
      />
      {selectedPurchase && (
        <ExternalPurchase onPurchaseChange={hook(purchases, setPurchase)} purchase={selectedPurchase} />
      )}
    </ModuleSegment>
  );
});
