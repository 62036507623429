import * as React from 'react';
import styled from 'styled-components';
import { AlternateText, FlexColumns, FlexRows, Header, PrimaryText } from './common';
import { Login } from './account';
import theme from '@theme';
import cas from '@api/cas';
import User from '@api/cas/user';

type ViewProps = {
  onAuth: (u: User) => void | any;
};

type ViewState = {};

const Divider = styled.div`
  width: 100%;
  height: 1px;

  background-color: ${({ color }) => color};
`;

const CardWrapper = styled(FlexColumns)`
  background-color: ${theme.segment.moduleBackground};
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.18);

  display: flex;
  flex-direction: row;
  margin: 4px;

  padding: 16px;

  @media only screen and (max-width: 768px) {
    text-align: center;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
  }
`;

const CardContent = styled(FlexColumns)`
  width: 66%;

  @media only screen and (max-width: 768px) {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    width: 97.5%;
  }
  @media only screen and (min-width: 768px) {
    margin-left: 16px;
  }
`;

const Card = (props: { index: number; title: string; content: string; subtitle: string; icon: string }) => {
  const { title, content, subtitle, icon, index } = props;
  return (
    <CardWrapper>
      <img
        style={{
          height: (256 - 2 * 16) / 2,
          width: 256 - 2 * 16,
        }}
        alt={title}
        src={icon}
      />

      <CardContent>
        <FlexColumns>
          <Header fontWeight="bold" fontSize="12px" color={theme.divider.accent}>{`${index}.`}</Header>
          <Header fontWeight="bold" fontSize="12px">
            &nbsp; {title}
          </Header>

          <PrimaryText>&nbsp;-&nbsp; </PrimaryText>
          <PrimaryText style={{ paddingBottom: 9 }} fontWeight={500} fontSize="12px">
            {subtitle}
          </PrimaryText>
        </FlexColumns>
        <div style={{ marginBottom: 9, width: '100%' }}>
          <Divider color={theme.divider.accent} />
        </div>

        <AlternateText fontSize="12px">{content}</AlternateText>
      </CardContent>
    </CardWrapper>
  );
};
function HowItWorks() {
  return (
    <FlexRows>
      <Header style={{ marginBottom: 18, marginTop: 18 }} color={theme.text.accent} fontSize="36px" fontWeight="bold">
        How it Works
      </Header>

      <Header fontWeight="bold">An End-to-End Color Communication Solution</Header>
      <AlternateText>Color matching and product management in one system</AlternateText>

      <div style={{ height: 16 }} />
      <Card
        index={1}
        title="Organizations"
        subtitle="Brand Customization"
        content="Create your brand’s presence within Variable Cloud and
        in your customer-facing app. Add your logo and
        customer support information to your Organizations.
        Manage your brand's presence within the Variable Cloud platform."
        icon="/images/organization.png"
      />

      <Card
        index={2}
        title="Product Libraries"
        subtitle="Library Manager"
        content={
          'Manage your brand’s products through the Library Manager feature. ' +
          'Create and mantain up-to-date product and color definitions for your brand. ' +
          'Create products with images, detailed information using Variable Cloud. Assign ' +
          'color definitions to your products by using the mobile app called Variable Color. ' +
          'Variable Color is available on the Android Play Store and Apple Appstore.'
        }
        icon="/images/product-library.png"
      />

      <Card
        index={3}
        title="App Packages"
        subtitle="Tailor User Access"
        content={
          'Distribute your product libraries through App Packages. ' +
          'Upon publishing your product library, make products available in your customer-facing mobile app, ' +
          'by linking them to your Organization’s App Package. ' +
          'An App Package enables brand managers control over what product libraries are available to users. '
        }
        icon="/images/packages-with-bg.png"
      />

      <Card
        index={4}
        title="Mobile Color Matching"
        subtitle="App Connectivity"
        content={
          'Our device-compatible mobile apps like Spectro 1 and Variable Color communicate with your ' +
          'Organization and App Packages setup on Variable Cloud. For users, the products and libraries ' +
          'set up in Variable Cloud can then be found in your company’s corresponding app, or Variable Color. ' +
          'Your App Packages can be delivered by using the package code or assigning existing user’s a ' +
          'subscription to your App Package.'
        }
        icon="/images/app.png"
      />
    </FlexRows>
  );
}
export default class Home extends React.Component<ViewProps, ViewState> {
  LoginSection = () => {
    if (cas.isAuthenticated()) {
      return null;
    }

    return <Login onAuth={this.props.onAuth} />;
  };
  render = () => {
    const { LoginSection } = this;
    return (
      <FlexRows
        style={{
          margin: '2.5%',

          maxHeight: 2048,
          maxWidth: 1512,
          minHeight: 512,
          minWidth: 350,
        }}
      >
        <div style={{ marginBottom: 24, width: '100%' }}>
          <LoginSection />
        </div>

        <div style={{ marginBottom: 24, width: '100%' }}>
          <HowItWorks />
        </div>
      </FlexRows>
    );
  };
}
