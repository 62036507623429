import * as React from 'react';
import { Dropdown as SemanticDropdown, StrictDropdownProps } from 'semantic-ui-react';
import theme from '@theme';

type DropdownProps = StrictDropdownProps & {
  id?: string;
  style?: React.CSSProperties;
};

export default function Dropdown(props: DropdownProps) {
  const propsWithStyle = {
    ...props,
    style: {
      border: 'none',
      backgroundColor: theme.dropdown.background,
      color: 'white',
      margin: 'auto',
      ...props.style,
    },
  };

  return <SemanticDropdown {...propsWithStyle} />;
}
