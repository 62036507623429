import * as React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';

import { Loading, Modal } from '@components/common';
import api from '@api/formulation';
import type { FormulationGroup } from '@api/formulation/models';
import FormulationGroupCreator from '../GroupCreation/FormulationGroupCreator';
import GroupList from './FormulationGroupList';

function useRouteOptions() {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = React.useState(true);
  const [groups, setGroups] = React.useState<FormulationGroup[]>([]);

  React.useEffect(() => {
    api.formulation_groups.all().then(res => {
      setGroups(res.docs);
      setLoading(false);
    });
  }, [location]);

  return {
    groups,
    loading,

    dismiss: () => {
      if (history.length > 2) {
        // 2 because chrome say opening a tab is one.
        history.goBack();
      }
    },
  };
}

export default function FormulationGroupRoutes() {
  const { dismiss, groups, loading } = useRouteOptions();
  return (
    <Modal modalProps={{ defaultOpen: true, onClose: dismiss, size: 'large' }}>
      {loading && <Loading text="Fetching formulation libraries" />}
      {!loading && (
        <>
          <Route exact path="/formulations/libraries/_new" component={FormulationGroupCreator} />

          <Route exact path="/formulations">
            <GroupList groups={groups} />
          </Route>

          <Route exact path="/formulations/libraries">
            <GroupList groups={groups} />
          </Route>
        </>
      )}
    </Modal>
  );
}
