import * as React from 'react';

import { UserAnalytics, PlanOptions } from '@api/cas/models';
import { FlexRows, LabeledInput } from '@components/common';

export default function Analytics({ analytics, plan }: { analytics?: UserAnalytics; plan?: PlanOptions }) {
  if (!analytics) {
    return null;
  }

  return (
    <FlexRows>
      <LabeledInput label="Package Count" inputProps={{ disabled: true, value: analytics.package_count }} />

      <LabeledInput label="Products" inputProps={{ disabled: true, value: analytics.owned_products }} />

      {plan && <LabeledInput label="Used Seats" inputProps={{ disabled: true, value: analytics.seats_used }} />}

      {plan?.max_distinct_users_subscriptions && (
        <LabeledInput
          label="Available Seats"
          inputProps={{
            disabled: true,
            value: plan.max_distinct_users_subscriptions - analytics.seats_used,
          }}
        />
      )}
    </FlexRows>
  );
}
