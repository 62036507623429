import titleize from 'titleize';
import _ from 'lodash';
import type { ParsedColorFormula, ParsedFormulationProduct, SpectrumWithDescription } from '@api/formulation/models';
import { round } from '@utils';
import { Product } from '@api/vpapi/models';

const attr_headers = ['a.', 'attr.', 'attrs.', 'attributes.'];
const filter_headers = ['f.', 'filter.', 'filters.', 'filt.'];
const color_headers = ['c.', 'colors.', 'color.', 'colour.', 'colours.', 'lab.', 'labs.'];
const colorant_headers = ['c.', 'colors.', 'color.', 'colour.', 'colours.', 'colorant.', 'colorants.'];
const image_headers = ['i.', 'images', 'image.', 'img.', 'product_image.', 'image.product'];
const spectrum_headers = [
  'spectrum.',
  'spectral.',
  'curve.',
  'curves.',
  'spectrals.',
  'spectrums.',
  ...Array.from(Array(31).keys()).map(i => `${400 + i * 10} nm`),
];
const accepted_included = ['included', 'include', 'inc', 'sci'];
// const accepted_excluded = ['excluded', 'exclude', 'exc', 'sce'];

export type Line = { [k: string]: number | string | string[] };
export type Parsable = ParsedFormulationProduct | ParsedColorFormula | Product;
export default {
  attr_headers,
  filter_headers,
  color_headers,
  image_headers,
  spectrum_headers,
  colorant_headers,
  application_headers: ['application.', 'material.', 'applications.', 'materials.'],
  size_headers: ['sample_size.', 'container_size.', 'samplesize.', 'containersize.', 'size.'],

  includes(acceptableHeaders: string[]) {
    return (k: string) => {
      const fileHeader = k.toLowerCase().trim();
      return acceptableHeaders.find(x => fileHeader.startsWith(x)) !== undefined;
    };
  },

  valueFromHeader(x: string) {
    return x
      .split('.')
      .filter((_x, index) => index > 0)
      .join('.');
  },

  isValueTruthy(val: number | string | boolean) {
    return (
      val === 1 ||
      val === true ||
      val.toString() === '1' ||
      val.toString().toUpperCase() === 'YES' ||
      val.toString().toUpperCase() === 'Y'
    );
  },

  sanitize(k: string) {
    return titleize(k.replace('_', ' ')).trim().replace('Id', 'ID');
  },

  startsWith(k: string, expected: string): boolean {
    return k.toLowerCase().trim().startsWith(expected);
  },

  addAttribute(p: ParsedFormulationProduct, k: string, value: string | string[]) {
    p.attributes = p.attributes.filter(a => a.k !== k);
    p.attributes.push({ k, v: value });
  },

  getValueOrDefault(data: Line, id: string, _default: string = ''): string {
    const key = Object.keys(data).find(k => this.sanitize(k) === this.sanitize(id));
    if (key) {
      return data[key] as string;
    }

    return _default;
  },

  parseFilters<T>(data: Line, converter: (k: string, v: string) => T): T[] {
    return Object.keys(data)
      .filter(this.includes(filter_headers))
      .filter(x => data[x] !== null)
      .map(x => {
        if (_(data[x]).isArray()) {
          data[x] = (data[x] as (string | number)[]).map(y => `${y}`);
        } else {
          data[x] = `${data[x]}`;
        }

        return converter(this.valueFromHeader(x), data[x] as string);
      });
  },

  parseAttributes<T>(data: Line, converter: (k: string, v: string) => T): T[] {
    return Object.keys(data)
      .filter(this.includes(attr_headers))
      .filter(x => data[x] !== null)
      .map(x => converter(this.valueFromHeader(x), `${data[x]}`));
  },

  parseImages<T>(data: Line, convert: (k: string, url: string) => T): T[] {
    return Object.keys(data)
      .filter(this.includes(image_headers))
      .filter(x => data[x] !== null)
      .map(x => convert(this.valueFromHeader(x), `${data[x]}`));
  },

  parseSpectrums(data: Line): SpectrumWithDescription[] {
    const sortedCurveData = Object.keys(data)
      .filter(this.includes(this.spectrum_headers))
      .map(k => {
        let nmStr = this.sanitize(k).replace('nm', '');
        if (nmStr.includes('.')) {
          nmStr = nmStr.split('.')[1];
        }
        return {
          intensity: parseFloat(data[k] as any),
          wavelength: parseFloat(nmStr),
        };
      })
      .sort((a, b) => a.wavelength - b.wavelength);

    return [
      {
        curve: sortedCurveData.reduce((arr: number[], x: { intensity: number }) => [...arr, x.intensity], []),
        instrument: this.getValueOrDefault(data, 'instrument'),
        specular: this.sanitizeSpecular(data),
        start: sortedCurveData[0].wavelength,
        step: round(
          sortedCurveData.reduce((step, x, i) => {
            if (i + 1 >= sortedCurveData.length) {
              return step;
            }
            return (sortedCurveData[i + 1].wavelength - x.wavelength + step) / 2;
          }, 0),
          0,
        ),
      },
    ];
  },

  sanitizeSpecular(data: Line) {
    const val = this.getValueOrDefault(data, 'specular', 'included').toLowerCase();
    if (accepted_included.includes(val)) {
      return 'included';
    } else {
      return 'excluded';
    }
  },

  parseLabColorComponent(json: any, headers: string[]) {
    return (component: 'l' | 'a' | 'b'): number => {
      const key = headers.find(x => x.toLowerCase().endsWith(component));
      if (!key) {
        throw new Error('component not found');
      }

      return Number(json[key]);
    };
  },
};
