import React from 'react';
import { Menu, MenuItemProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { Pager } from '@utils';
import theme from '@theme';

const styles = {
  Pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2em',
  },
};

type Props = {
  pager: Pager;
  onPageChange: (p: Pager) => void;
};

export default function Pagination({ pager, onPageChange }: Props) {
  const [__page, setCurrentPage] = React.useState(pager.page);
  const handlePageSelect = React.useCallback(
    (_e: React.SyntheticEvent, data: MenuItemProps) => {
      const page = parseInt(data.name!, 10);

      pager.page = page;

      onPageChange?.(pager);

      setCurrentPage(page);
    },
    [onPageChange, pager, setCurrentPage],
  );

  console.log('__page=', __page);
  const { numPages, page, pages } = pager;
  if (numPages === 1) {
    return null;
  }

  return (
    <div style={styles.Pagination}>
      <Menu pagination>
        {pages.map(p => {
          if (p.display === false) {
            return (
              <MenuItem draggable={false} key={`PagerMenuItem-${p.page}`}>
                ...
              </MenuItem>
            );
          }

          return (
            <MenuItem
              draggable={false}
              name={p.page.toString()}
              key={p.page}
              active={page === p.page}
              onClick={handlePageSelect}
            />
          );
        })}
      </Menu>
    </div>
  );
}

const MenuItem = styled(Menu.Item)`
  background-color: ${({ active }: { active?: boolean }) =>
    active ? theme.menu.activeBackgroundColor : theme.menu.background} !important;
  color: ${theme.text.primary} !important;

  :hover {
    color: ${theme.menu.hoverBackground} !important;
  }
`;
