import React from 'react';
import styled from 'styled-components';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import theme from '@theme';

const RootContainer = styled.div<{ bgColor?: string }>`
  &&& {
    width: 100%;
    height: 300px;
    border: none;

    padding: 5%;
    text-align: center;

    background-color: ${({ bgColor = theme.table.rowBackground }) => bgColor};
    border-radius: 3px;
    border-width: 1px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
`;

type ViewProps = DropzoneOptions & {
  bgColor?: string;
  children: any;
};

export default function StyledDropZone(props: ViewProps) {
  const { children, bgColor, ...dropzoneProps } = props;
  const { getRootProps, getInputProps } = useDropzone(dropzoneProps);

  return (
    <RootContainer bgColor={bgColor} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}

      {/* {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      } */}
    </RootContainer>
  );
}
