import * as React from 'react';
import qs from 'query-string';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import routeUtils from '@utils/route-utils';
import theme from '@theme';
import { renderItem } from './styled-components';
import {
  AlternateText,
  Button,
  Flash,
  FlashContentT,
  FlexColumns,
  FlexRows,
  Header,
  HeaderWithIcon,
  Input,
  Loading,
  ModuleSegment,
  PrimaryText,
  StyledDropZone,
} from '@components/common';
import Parser from '@utils/parser/csv';
import type { ColorFormula, FormulationGroup, ParsedColorFormula } from '@api/formulation/models';
import api from '@api/formulation';
import User from '@api/cas/user';

type ViewProps = {
  user: User;
  location: ReturnType<typeof useLocation>;
  history: ReturnType<typeof useHistory>;
  group: FormulationGroup;
};

type ViewState = {
  progress?: string;
  flash?: FlashContentT;
  showFileStructure?: boolean;
};

const columnDetails = [
  {
    description: 'The code of the product that this formula represents',
    name: 'attr.code',
  },
  {
    description: 'The name of the product that this formula represents',
    name: 'attr.name',
  },
  {
    description: 'The collection of the product that this formula represents',
    name: 'attr.collection',
  },
  {
    description: 'The id of the base used in this formula. The name can be added in library details.',
    name: 'base_id',
  },
  {
    description:
      'Replace your_colorant_id with the id of a colorant. ' +
      'Use empty or 0 to discard a colorant for a row`s formula. ' +
      ' The name can be added in library details.',
    name: 'colorant.your_colorant_id',
  },
  {
    description: 'The specular mode of the spectral curve. (valid options: `included` or `excluded`',
    name: 'specular',
  },
  {
    description: 'The name of spectrophotometer used to capture the row`s spectrum.',
    name: 'instrument',
  },
  {
    description: 'Required to be 400 - 700nm inclusively in 10nm intervals.',
    name: 'spectrum.wavelength_value',
  },
];
export default class FormulaUploader extends React.Component<ViewProps, ViewState> {
  state = {
    flash: undefined,
    progress: undefined,
    showFileStructure: undefined,
  };

  downloadTemplate = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    window.location.href = '/templates/Formulations/color_formula.template.csv';
  };

  toggleFileStructureContent = () => {
    const { showFileStructure } = this.state;
    this.setState({
      showFileStructure: !showFileStructure,
    });
  };

  get isNewGroup() {
    const { location } = this.props;
    const options = qs.parse(location.search);
    return options && options.t === 'n';
  }

  get buttons() {
    const { showFileStructure } = this.state;
    const buttons = [
      <Button
        key="show-file-info"
        text={showFileStructure ? 'Back' : 'Show File Structure'}
        outline
        color={theme.button.alternativeActionColor}
        onClick={this.toggleFileStructureContent}
      />,
      <Button
        key="download-template"
        text="Download Template"
        outline
        color={theme.button.alternativeActionColor}
        onClick={this.downloadTemplate}
      />,
    ];

    if (this.isNewGroup) {
      const {
        group,
        location: { search },
      } = this.props;
      buttons.push(
        <NavLink to={`${routeUtils.formulationGroupSettings(group.id)}?${search}`} key="create-formulas">
          <Button outline text="Next (Library Details)" color={theme.button.createColor} />
        </NavLink>,
      );
    }

    return buttons;
  }

  handleRefreshGroup = (count: number) => async () => {
    const {
      group: { id, name },
      history,
    } = this.props;
    await api.formulation_groups.refresh(id);
    this.setState({ progress: undefined });

    if (!this.isNewGroup) {
      history.goBack();
    } else {
      this.setState({
        flash: {
          color: 'green',
          message: `Congratulations, you have added ${count} formulas to ${name}.`,
        },
      });
    }
  };
  upload = (formulas: ColorFormula[]) => async () => {
    const {
      group: { id: groupID },
    } = this.props;

    const { error_message, ids } = await api.color_formulas.bulk(groupID, formulas);
    if (error_message) {
      this.setState({
        progress: 'Error in file format, and now, deleting uploaded color formulas . (Do not close this window)',
      });

      for (const id of ids) {
        await api.color_formulas.delete(groupID, id);
      }

      this.setState({
        progress: undefined,
        flash: {
          color: 'red',
          message: `Upload Failed. \n Reason: (${error_message})`,
        },
      });
      return;
    }

    this.setState({ progress: 'Finishing up import' }, this.handleRefreshGroup(formulas.length));
  };

  handleComplete = (parsed_formulas: ParsedColorFormula[]) => {
    const {
      group: {
        id: formulation_group_id,
        customer: { _id: customer_id },
      },
    } = this.props;
    const formulas = parsed_formulas.map(f => ({
      ...f,
      customer_id,
      formulation_group_id,
    }));

    this.setState(
      {
        progress: `Uploading ${parsed_formulas.length} to our server.`,
      },
      this.upload(formulas as any),
    );
  };
  handleProgress = (/**formula: ParsedColorFormula, parsedCount: number**/) => {
    // if (parsedCount % 5000 === 0){
    //   this.setState({
    //     progress: `Parsing file (${parsedCount} formulas found)`,
    //   });
    // }
  };
  handleDrop = (acceptedFiles: File[] /**, rejectedFiles: File[] */) => {
    const { group } = this.props;
    console.log(
      group.unit_resolution,
      'unit_standard: ',
      group.unit_standard,
      'native_sample_size: ',
      group.native_sample_size,
    );
    if (!group.unit_resolution || group.unit_resolution === 0) {
      this.setState({
        flash: {
          color: 'red',
          message: 'Please enter a dispensing resolution, before uploading file.',
        },
      });
      return;
    }

    if (!group.unit_standard && group.unit_standard.length <= 1) {
      this.setState({
        flash: {
          color: 'red',
          message: 'Please enter a dispensing unit, before uploading file.',
        },
      });
      return;
    }

    if (!group.native_sample_size && group.native_sample_size.length <= 1) {
      this.setState({
        flash: {
          color: 'red',
          message: 'Please enter a sample size of the units in the file, before uploading file.',
        },
      });
      return;
    }

    if (acceptedFiles.length === 1) {
      const parser = new Parser<ParsedColorFormula>({
        productGroupID: this.props.group.id,
        file: acceptedFiles[0],
        file_type: 'formula',
        onComplete: this.handleComplete,
        progressCallback: this.handleProgress,
        email: this.props.user.email,
      });

      this.setState(
        {
          progress: 'parsing file contents for color formulas',
        },
        parser.start,
      );
    }
  };

  handleChange(field: 'unit_standard' | 'unit_resolution' | 'native_sample_size') {
    return (_e: React.SyntheticEvent<HTMLInputElement>, { value }: { value: string }) => {
      const { group } = this.props;
      if (field === 'unit_resolution') {
        group.unit_resolution = parseFloat(value);
      } else {
        group[field] = value;
      }

      api.formulation_groups.upsert(group);

      this.forceUpdate();
    };
  }

  Content = () => {
    const { progress, showFileStructure } = this.state;
    if (progress) {
      return <Loading text={progress} />;
    }

    if (showFileStructure) {
      return (
        <FlexRows>
          <Header>File Checklist</Header>
          {columnDetails.map(renderItem)}
        </FlexRows>
      );
    }
    const { group } = this.props;
    return (
      <FlexRows>
        <HeaderWithIcon tooltip="The container volume the formula data originates from." title="Container Size" />
        <Input
          onChange={this.handleChange('native_sample_size')}
          containerStyle={{
            marginBottom: 16,
            marginTop: -4,
          }}
          inputProps={{
            placeholder: '8 Ounces',
            value: group.native_sample_size,
          }}
        />

        <HeaderWithIcon
          tooltip="The minimum volume of measurement and units of the paint dispener."
          title="Paint Dispenser's Volume of Measurement"
        />
        <FlexColumns style={{ flexWrap: 'nowrap' }}>
          <Input
            onChange={this.handleChange('unit_resolution')}
            containerStyle={{
              flexGrow: 1,
              marginBottom: 16,
              marginTop: -4,
            }}
            inputProps={{
              placeholder: '0.25',
              type: 'number',
              value: group.unit_resolution,
            }}
          />

          <Input
            onChange={this.handleChange('unit_standard')}
            containerStyle={{
              flexGrow: 0,
              flexShrink: 1,
              marginBottom: 8,
              marginLeft: 8,
              marginTop: -4,
            }}
            inputProps={{
              placeholder: 'Ounces',
              value: group.unit_standard,
            }}
          />
        </FlexColumns>

        <HeaderWithIcon
          tooltip="Upload a properly formatted csv file containing color formula, attributes, and spectral curves"
          title="Color Formula File"
        />
        <StyledDropZone multiple onDrop={this.handleDrop}>
          <PrimaryText>Start migrating your formulas to Variable Cloud.</PrimaryText>

          <AlternateText textAlign="center" style={{ marginBottom: 24 }}>
            Drop a csv to containg formula data into this formulation library, and then then import will start
          </AlternateText>

          <div style={{ marginTop: 12 }} />

          <Button
            paddingLeft={45}
            paddingRight={45}
            padding={8}
            color={theme.button.alternativeActionColor}
            text="Choose File"
          />
        </StyledDropZone>
      </FlexRows>
    );
  };
  render() {
    const { flash } = this.state;
    const { Content } = this;
    return (
      <ModuleSegment title="Upload Color Formulas" buttons={this.buttons}>
        <Flash content={flash} />
        <Content />
      </ModuleSegment>
    );
  }
}
